import React from "react";
import "./EStat.scss";
import EBadge from "../EBadge/EBadge";
import CommaSeparator from "../../../data-access/utility";
import { BsInfoCircle } from 'react-icons/bs';
import { Tooltip } from '@mantine/core';
import InformationToolTip from "./InformationToolTip";

interface IProps {
  iconSrc?: string;
  stat: string;
  label: string;
}

export function EStat(props: any) {
  return (
    <div className="stat-container">
      <div className={`icon`}>
        <img src={props.iconSrc} alt="" />
      </div>
      <div>
        <div className="flex flex-row gap-2 items-center">
          <div className="stat">{CommaSeparator(props?.stat ?? 0)}</div>
        </div>
        <div className="label">{props.label}</div>
      </div>
      {
        props?.toolTipLabel ?
          <InformationToolTip customClassName="absolute top-3 right-3" toolTipLabel={props?.toolTipLabel} />
          : null
      }

    </div>
  );
}
