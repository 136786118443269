import { ILoginParams } from "../types";
import { showToast } from "./utility";

export const handleValidateLoginParams = (loginParams: ILoginParams) => {
  let isValidated = false;
  if (!loginParams.userName) {
    showToast({
      type: "warning",
      message: "Please provide username",
    });
    return isValidated;
  }

  if (!loginParams.password) {
    showToast({
      type: "warning",
      message: "Please provide password",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

