import { useState, useEffect, useRef } from 'react'
import { useApi } from '../../../../../libs/data-access/src/api/useApi';
import { TPaginationStatus } from '../../../../../types';
import { PAGINATION_ITEM_LIMIT } from '../../../../../libs/data-access/constant';
import { handleUpdateStateOnResponse, showToast } from '../../../../../utils/utility';
import { IOriginCountry } from '../../../../../libs/data-access/types';
import { useReactToPrint } from "react-to-print";
export const useOriginCountry = () => {

    const {
        postData,
        patchData,
        putData
    } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isSwitchLoadingIndex, setIsSwitchLoadingIndex] = useState<number>();
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isOpenModalValue, setIsOpenModalValue] = useState<"New" | "Edit" | "Delete" | undefined>(undefined);
    const [searchValue, setSearchValue] = useState("");
    const [originCountryList, setOriginCountryList] = useState<IOriginCountry[]>([]);
    const [selectedOriginCountry, setSelectedOriginCountry] = useState<IOriginCountry | undefined>(undefined);
    const [newOriginCountry, setNewOriginCountry] = useState<IOriginCountry>({ name: "" });

    useEffect(() => {
        if (searchValue?.length > 2 || searchValue === "") {
            getOriginCountryList("INITIAL_PAGE");
        }
    }, [searchValue])

    // API FUNCTION
    const getOriginCountryList = async (paginationAction: TPaginationStatus, id?: number) => {
        setIsLoading(true);
        const response = await postData(`country/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: paginationAction,
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id: id ? id : paginationAction === "INITIAL_PAGE" ? null : paginationAction === "NEXT_PAGE" ? originCountryList[PAGINATION_ITEM_LIMIT - 1]?.id : paginationAction === "PREVIOUS_PAGE" ? originCountryList[0]?.id : null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setOriginCountryList(response.data.data)
        } else {
            setOriginCountryList([])
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            });
        };
    }


    const submitNewOriginCountry = async () => {
        setIsBtnLoading(true);
        const response = await postData(`country/create`, newOriginCountry);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Country added successfully",
            })
            getOriginCountryList("INITIAL_PAGE");
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const UpdateStatus = async (id: number, status: boolean, index: number) => {
        setIsSwitchLoadingIndex(index);
        const response = await patchData(`country/${status ? "active-all" : "inActive-all"}?ids=${[id]}`, {});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Country status updated successfully",
            })
            setOriginCountryList(handleUpdateStateOnResponse(originCountryList, id, response.data.data))
            // getOriginCountryList("INITIAL_PAGE");
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsSwitchLoadingIndex(undefined);
    }

    const UpdateCountry = async (id: number, name: string) => {
        setIsBtnLoading(true);
        const response = await putData(`country/update`, { id: id, name: name });
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Country updated successfully",
            })
            setOriginCountryList(handleUpdateStateOnResponse(originCountryList, id, response.data.data))
            setSelectedOriginCountry(undefined);
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const deleteCountry = async (id: number) => {
        const isConfirm = window.confirm("Are you sure you want to delete this Country?");
        if (!isConfirm) return;
        else {
            setIsBtnLoading(true);
            showToast({
                type: "success",
                title: "Ready to delete",
                message: "Add api to delete Markup Type"
            })

        }
        // setIsBtnLoading(true);
        // const response = await putData(`country/delete`, {id: id});
        // if (response.isSuccess) {
        //     showToast({
        //         type: "success",
        //         message: "Country deleted successfully",
        //     })
        //     getOriginCountryList("INITIAL_PAGE");
        //     setSelectedOriginCountry(undefined);
        // } else {
        //     showToast({
        //         type: "error",
        //         message: response.message ?? "Something went wrong",
        //     })
        // };
        // setIsBtnLoading(false);
    }

    return {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        originCountryList,
        searchValue,
        setSearchValue,
        selectedOriginCountry,
        setSelectedOriginCountry,
        newOriginCountry,
        setNewOriginCountry,
        submitNewOriginCountry,
        UpdateStatus,
        UpdateCountry,
        deleteCountry,
        getOriginCountryList,
    }
}