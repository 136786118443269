import { useState, useEffect } from 'react';
import { useApi } from '../../../../../libs/data-access/src/api/useApi';
import { TPaginationStatus } from '../../../../../types';
import { PAGINATION_ITEM_LIMIT } from '../../../../../libs/data-access/constant';
import { handleUpdateStateOnResponse, showToast } from '../../../../../utils/utility';
import { IHotelsCategory } from '../../../../../libs/data-access/types';

const defaultNewHotelCategory : IHotelsCategory ={
    name: "",
}
export  const useHotelCategory = () => {
    
    const {
        postData,
        patchData,
        putData
    } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isSwitchLoadingIndex, setIsSwitchLoadingIndex] = useState<number>();
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isOpenModalValue, setIsOpenModalValue] = useState<"New" | "Edit" | "Delete" | undefined >(undefined);
    const [searchValue, setSearchValue] = useState("");
    const [hotelCategoryList, setHotelCategoryList] = useState<IHotelsCategory[]>([]);
    const [selectedHotelCategory, setSelectedHotelCategory] = useState<IHotelsCategory | undefined>(undefined);
    const [newHotelCategory, setNewHotelCategory] = useState<IHotelsCategory>(defaultNewHotelCategory);

    useEffect(() => {
        if(searchValue?.length > 2 || searchValue === ""){
        getHotelCategoryList("INITIAL_PAGE");
    }
    }, [searchValue])

    // API FUNCTION
    const getHotelCategoryList = async (paginationAction: TPaginationStatus, id?: number) => {
        setIsLoading(true);
        const response = await postData(`hotel-category/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: paginationAction,
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id: id ?? null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setHotelCategoryList(response.data.data)
        } else {
            setHotelCategoryList([])
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            });
        };
    }


    const submitNewHotelCategory = async () => {
        setIsBtnLoading(true);
        const response = await postData(`hotel-category/create`, newHotelCategory);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Hotel Category added successfully",
            })
            getHotelCategoryList("INITIAL_PAGE");
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const UpdateStatus = async (id: number, status: boolean, index: number) => {
        setIsSwitchLoadingIndex(index);
        const response = await patchData(`hotel-category/${status ? "active-all" :  "inActive-all" }?ids=${[id]}`,{});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Hotel Category status updated successfully",
            })
            // getHotelCategoryList("INITIAL_PAGE");
            const temp =handleUpdateStateOnResponse(hotelCategoryList, id, response.data.data)
            console.log("Temp",temp)
            setHotelCategoryList(temp)
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsSwitchLoadingIndex(undefined);
    }

    const UpdateHotelCategory = async (id: number, name: string) => {
        setIsBtnLoading(true);
        const response = await putData(`hotel-category/update`, {id: id, name: name});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Hotel Category updated successfully",
            })
            // getHotelCategoryList("INITIAL_PAGE");
            setHotelCategoryList(handleUpdateStateOnResponse(hotelCategoryList, id, response.data.data))
            setSelectedHotelCategory(undefined);
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const deleteHotelCategory = async (id: number) => {
       const isConfirm = window.confirm("Are you sure you want to delete this Hotel Category?");
        if(!isConfirm) return;
        else{
            setIsBtnLoading(true);
            showToast({
                type:"success",
                title:"Ready to delete",
                message:"Add api to delete Hotel Category"
            })

        }
        // setIsBtnLoading(true);
        // const response = await putData(`hotel-category/delete`, {id: id});
        // if (response.isSuccess) {
        //     showToast({
        //         type: "success",
        //         message: "Hotel Category deleted successfully",
        //     })
        //     getHotelCategoryList("INITIAL_PAGE");
        //     setSelectedHotelCategory(undefined);
        // } else {
        //     showToast({
        //         type: "error",
        //         message: response.message ?? "Something went wrong",
        //     })
        // };
        // setIsBtnLoading(false);
    }

    return {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        hotelCategoryList,
        searchValue,
        setSearchValue,
        selectedHotelCategory,
        setSelectedHotelCategory,
        newHotelCategory,
        setNewHotelCategory,
        submitNewHotelCategory,
        UpdateStatus,
        UpdateHotelCategory,
        deleteHotelCategory,
        defaultNewHotelCategory
    }
}