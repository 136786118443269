import moment from 'moment';
import { Grid } from '@mantine/core';

import { IFlightOnlineBookingList } from 'libs/data-access/types';
import { EButton, ETable } from 'libs/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import FareSummary from 'libs/ui/src/FareSummary/FareSummary';
import FlightDetails from './FlightDetails';


export default function FlightBookingDetails1G() {


    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const flight: IFlightOnlineBookingList = state.trip;

    return (
        <>
            <div className='mt-2'>
                <EButton
                    leftSection={<BsArrowLeft />}
                    onClick={() =>
                        navigate('/online-booking/Flight', { state: location.state })
                    }
                >Back</EButton>

                {/* Passenger Details And Fare Summery */}
                <Grid className='mt-9'>
                    <Grid.Col span={8}>
                        <div className="w-full border-[1px] border-gray-50 bg-white rounded-lg shadow-md">
                            <div className="h-20 px-4 rounded-t-lg border-b-[1px] bg-blue-50 flex items-center shadow-sm">
                                <p className="text-lg text-navy-blue font-semibold">Passenger Details</p>
                            </div>
                            <div className="p-4">
                                <ETable>
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Title</th>
                                            <th>Name</th>
                                            <th>Gender</th>
                                            <th>D.O.B.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        flight?.bookingResponses[0]?.galileoPassengerDetailResponses 
                                          ? 
                                          flight?.bookingResponses[0]?.galileoPassengerDetailResponses?.map((eachPassenger, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{eachPassenger?.title}</td>
                                                <td>{eachPassenger?.firstName} {eachPassenger?.lastName}</td>
                                                <td>{eachPassenger?.gender}</td>
                                                <td>{eachPassenger?.dateOfBirth ? moment(eachPassenger?.dateOfBirth).format('DD-MM-YYYY') : ''}</td>
                                            </tr>
                                        )
                                        :
                                        flight?.bookingResponses[0]?.navitairePassengerDetailResponses?.map((eachPassenger, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{eachPassenger?.title}</td>
                                                <td>{eachPassenger?.firstName} {eachPassenger?.lastName}</td>
                                                <td>{eachPassenger?.gender}</td>
                                                <td>{eachPassenger?.dateOfBirth ? moment(eachPassenger?.dateOfBirth).format('DD-MM-YYYY') : ''}</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </ETable>
                            </div>
                        </div>

                        <div className="border-[1px] border-gray-50 bg-white rounded-lg shadow-md mt-12">
                            <div className="h-20 px-4 rounded-t-lg border-b-[1px] bg-blue-50 flex items-center shadow-sm">
                                <p className="text-lg text-navy-blue font-semibold">Flight Details</p>
                            </div>
                            <div className="p-4">
                                {/* Flight Details Galileo */}
                                <FlightDetails flight={flight} />
                            </div>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <FareSummary supplierId={flight?.bookingResponses[0]?.flightSupplierId} airPriceJourneyResponseList={flight?.paymentOrder} />
                    </Grid.Col>
                </Grid>
            </div>
        </>
    )
}
