import { Tooltip } from '@mantine/core';
import React from 'react';

const ETableTooltipData = (data : string[]) => {
    return (
        <div>
            {
                !data?.length ? 
                 "" :
                 data?.length === 1 ? 
                 data[0] :
                 <Tooltip label={data?.map(e=><span className='capitalize'>{e?.toLowerCase() + ", "}</span>)} maw={400} multiline>
                   <div>{data[0]} <span className='text-blue-700'> +{data.length - 1}</span></div>
                 </Tooltip>
            }
        </div>
    );
}

export default ETableTooltipData;
