import React from 'react';
import { NumberFormatter } from '@mantine/core';
import { IFlightOnlineBookingPaymentOrder } from 'libs/data-access/types';


interface IProps {
    airPriceJourneyResponseList: IFlightOnlineBookingPaymentOrder | undefined;
    supplierId: number
};

const SectionTitle = ({ title }: { title: string }) => {
    return (
        <div className="flex items-center gap-2 mt-3">
            <p className="text-navy-blue font-semibold">{title}</p>
            <div className='border-t h-1 grow'></div>
        </div>
    )
}

export default function FareSummary({ ...props }: IProps) {

    return (
        <div className="border-[1px] border-gray-50 rounded-lg shadow-md sticky top-20">
            <div className="h-20 px-4 rounded-t-lg border-b-[1px] bg-blue-50 flex items-center shadow-sm">
                <p className="text-lg text-navy-blue font-semibold">Fare Summary</p>
            </div>

            <div className="p-4 flex flex-col gap- bg-white">

                <SectionTitle title='Air Fare' />
                <div className="flex justify-between items-center">
                    {/* Base Fare: Includes the air base fare and markup base charge */}
                    <span>Base Charge</span>
                    <NumberFormatter
                        thousandSeparator
                        prefix="₹ "
                        // value={props.airPriceJourneyResponseList.reduce((acc, curr) => acc + curr.feeDetail.airBasePriceWithBaseCharge, 0)}
                        value={props?.supplierId === 3 ?
                            (props.airPriceJourneyResponseList?.galelioFlightOrderDetail?.flightBasePrice || 0)
                            :
                            (props.airPriceJourneyResponseList?.naviatorOrderDetail?.indigoBasePrice || 0)
                    }
                    />
                </div>

                <div className="flex justify-between items-center">
                    {/* Tax & Charges: Includes air taxes and markup service charge */}
                    <span>Tax & Charges</span>
                    <NumberFormatter
                        thousandSeparator
                        prefix="₹ "
                        value={
                            props?.supplierId === 3 ?
                                (props.airPriceJourneyResponseList?.galelioFlightOrderDetail?.flightTaxPrice || 0)
                                :
                                (props.airPriceJourneyResponseList?.naviatorOrderDetail?.indigoTaxPrice || 0)
                        }
                    />
                </div>

                <SectionTitle title='My Markup' />
                <div className="flex justify-between items-center">
                    {/* Other Charges: Includes other charge and markup booking charge */}
                    <span>Base Charge</span>
                    <NumberFormatter
                        thousandSeparator
                        prefix="₹ "
                        value={
                            (props.airPriceJourneyResponseList?.baseCharge || 0)
                        }
                    />
                </div>
                <div className="flex justify-between items-center">
                    {/* Other Charges: Includes other charge and markup booking charge */}
                    <span>Service Charge</span>
                    <NumberFormatter
                        thousandSeparator
                        prefix="₹ "
                        value={
                            (props.airPriceJourneyResponseList?.serviceCharge || 0)
                        }
                    />
                </div>
                <div className="flex justify-between items-center">
                    {/* Other Charges: Includes other charge and markup booking charge */}
                    <span>Booking Charge</span>
                    <NumberFormatter
                        thousandSeparator
                        prefix="₹ "
                        value={
                            (props.airPriceJourneyResponseList?.bookingCharge || 0)
                        }
                    />
                </div>
                <SectionTitle title='Other Charges' />

                <div className="flex justify-between items-center">
                    {/* Other Charges: Includes other charge and markup booking charge */}
                    <span>Other Charges</span>
                    <NumberFormatter
                        thousandSeparator
                        prefix="₹ "
                        value={
                            (props.airPriceJourneyResponseList?.otherCharge || 0)
                        }
                    />
                </div>

                <div className="flex justify-between items-center">
                    {/* GST: Includes total gst */}
                    <span>GST (All Inclusive)</span>
                    <NumberFormatter
                        thousandSeparator
                        prefix="₹ "
                        value={props.airPriceJourneyResponseList?.totalGst || 0}
                    />
                </div>
            </div>

            <div className="h-14 px-4 rounded-b-lg border-b-[1px] bg-blue-50 flex justify-between items-center shadow-sm">
                {/* Total: Includes total fare including all the charges and total gst */}
                <span className="font-semibold">Total</span>
                <NumberFormatter
                    thousandSeparator
                    prefix="₹ "
                    value={

                        (props?.supplierId === 3 ?
                            (props.airPriceJourneyResponseList?.galelioFlightOrderDetail?.flightBasePrice || 0)
                            :
                            (props.airPriceJourneyResponseList?.naviatorOrderDetail?.indigoBasePrice || 0))

                        +
                        (
                            props?.supplierId === 3 ?
                                (props.airPriceJourneyResponseList?.galelioFlightOrderDetail?.flightTaxPrice || 0)
                                :
                                (props.airPriceJourneyResponseList?.naviatorOrderDetail?.indigoTaxPrice || 0)
                        )
                        +
                        (props.airPriceJourneyResponseList?.baseCharge || 0)
                        +
                        (props.airPriceJourneyResponseList?.serviceCharge || 0)
                        +
                        (props.airPriceJourneyResponseList?.bookingCharge || 0)
                        +
                        (props.airPriceJourneyResponseList?.otherCharge || 0)
                        +
                        (props.airPriceJourneyResponseList?.totalGst || 0)
                    }
                />
            </div>
        </div>
    )
}
