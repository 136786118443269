import axios from "axios";

import { AUTH_URL } from "../../constant";
import { ILoginParams } from "../../../../types";
import { handleFormatResponse, handleFormatResponseForError } from "../../utility";


export function useAuth() {

    const headers = {
        headers: {
            'X-TENANT-ID': 'pml'
        }
    };

    const handleLogin = async (
        payload: ILoginParams
    ) => {
        try {

            let response = await axios.post(
                `${AUTH_URL}signin-with-password/for/admin`,
                payload,
                headers
            );
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };


    const logout = async (payload: {
        accessToken: string;
        refreshToken: string;
    }) => {
        try {

            let response = await axios.post(
                `${AUTH_URL}logout`,
                payload,
                headers
            );
            // console.log("Response: ", response?.data);   
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;
        }

        catch (error: any) {
            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;
        };
    };

    return {
        handleLogin,
        logout
    };
}
