import { Button, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ETable } from "../../../../libs/ui";
import { useMarkupType } from "./hook/useMarkupType";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function FlightMarkupType() {
  const {
    isLoading,
    isSwitchLoadingIndex,
    isBtnLoading,
    isOpenModalValue,
    setIsOpenModalValue,
    bookingTypeList,
    newBookingType,
    setNewBookingType,
    selectedBookingType,
    setSelectedBookingType,
    submitNewBookingType,
    UpdateStatus,
    UpdateMarkup,
    deleteMarkup,
    defaultNewBookingType
  } = useMarkupType();

  return (
    <PageLayout>
      <EPageTitle
        title="Flight Markup Type"
        end={
          <div className="flex gap-1">
            <EButton
              rightSection={<IoMdAdd />}
              onClick={() => {
                setIsOpenModalValue("New");
              }}
            >
              Add New
            </EButton>
          </div>
        }
      />
      
      <ETable
      loading={isLoading}
      >
        <thead>
          <tr>
            <th>SR. No.</th>
            <th>Markup Name</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
          <tbody>
            {bookingTypeList?.map((each, index) => {
              return (
                <tr key={each?.id+"markup"}>
                  <td>{index + 1}</td>
                  <td>{each.name}</td>
                  <td>
                    <ESwitch
                      label={each.status ? "Active" : "Inactive"}
                      loading={isSwitchLoadingIndex === index}
                      checked={!!each.status}
                      onChange={() => {
                        each?.id && UpdateStatus(each?.id, !each?.status, index);
                      }}
                      withConfirmation
                    />
                  </td>
                  <td className="flex w-full gap-1 items-center justify-end">
                    <Tooltip label="Edit" position="top">
                      <div>
                        <FaRegEdit
                          size={18}
                          className="cursor-pointer text-green-400 hover:text-green-600"
                          onClick={() => {
                            setIsOpenModalValue("Edit");
                            setSelectedBookingType(each);
                          }}
                        />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
      </ETable>
      
      {/* ADD NEW Modal */}
      <EModal
        title="New Markup Type"
        opened={isOpenModalValue === "New"}

        onClose={() => {
          setNewBookingType(defaultNewBookingType);
          setIsOpenModalValue(undefined);
        }}
      >
        <div className="w-full flex flex-col gap-2">
          <TextInput
            label="Markup Name"
            placeholder="Enter Name"
            value={newBookingType?.name}
            onChange={(e) =>
              setNewBookingType({ ...newBookingType, name: e.target.value })
            }
          />

          <div className="flex justify-end mt-2">
            <Button
              disabled={!newBookingType?.name}
              loading={isBtnLoading}
              onClick={() => submitNewBookingType()}
            >
              Save
            </Button>
          </div>
        </div>
      </EModal>
      {/* Edit Markup Type */}
      <EModal
        title="Edit Markup Type"
        opened={isOpenModalValue === "Edit"}

        onClose={() => {
          setSelectedBookingType(undefined);
          setIsOpenModalValue(undefined);
        }}
      >
       { selectedBookingType &&
        <div className="w-full flex flex-col gap-2">
          <TextInput
            label="Markup Name"
            placeholder="Enter Name"
            value={selectedBookingType?.name}
            onChange={(e) =>
              setSelectedBookingType({
                ...selectedBookingType,
                name: e.target.value,
              })
            }
          />

          <div className="flex justify-end mt-2">
            <Button
              disabled={!selectedBookingType?.name}
              loading={isBtnLoading}
              onClick={() =>
                selectedBookingType?.id &&
                UpdateMarkup(selectedBookingType?.id, selectedBookingType?.name)
              }
            >
              Update
            </Button>
          </div>
        </div>}
      </EModal>
      <EModal
        title="Delete Markup Type"
        opened={isOpenModalValue === "Delete"}

        size="lg"
        onClose={() => setIsOpenModalValue(undefined)}
      >
        <div className="w-full flex flex-col gap-2">
          <p>Are you sure you want to delete this markup type?</p>
        </div>
        <div className="w-full flex justify-end mt-5 gap-2">
          <Button
            variant="outline"
            onClick={() => {
              setIsOpenModalValue(undefined);
              setSelectedBookingType(undefined);
            }}
          >
            Close
          </Button>
          <Button
            variant="filled"
            color="red"
            loading={isBtnLoading}
            onClick={() =>
              selectedBookingType?.id && deleteMarkup(selectedBookingType?.id)
            }
          >
            Delete
          </Button>
        </div>
      </EModal>
    </PageLayout>
  );
}
