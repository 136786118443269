import { Button, Select, TextInput } from "@mantine/core";

import PageLayout from "../../../../PageLayout";
import { EModal, EPageTitle, ETable } from "../../../../libs/ui";
import { useMarkupFee } from "./hook/useMarkupFee";
import { INewMarkupFee } from "../../../../types";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function MarkupFee() {

    const {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        markupFeeList,
        newMarkupFee,
        setNewMarkupFee,
        selectedMarkupFee,
        feeTypeList,
        setSelectedMarkupFee,
        submitNewMarkupFee,
        UpdateStatus,
        deleteMarkupFee,
        getMarkupFeeList
    } = useMarkupFee();


    return (
        <PageLayout>
            <EPageTitle
                title="Flight Markup Fee"
                end={
                    <div className="flex gap-1">
                        {/* <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton> */}
                    </div>
                }
            />


            <ETable
                loading={isLoading}
                pagination
                onChangePagination={(e) => getMarkupFeeList(e)}
            >
                <thead>
                    <tr>
                        <th>Service Name</th>
                        <th>Form Type</th>
                        <th>Markup Fee</th>
                        <th>Status</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        markupFeeList?.map((each, index) => {
                            return (
                                <tr>
                                    <td>{each.travelServiceCategory}</td>
                                    <td>{each.formType}</td>
                                    <td>{each.feeType?.name}</td>
                                    <td>
                                        <ESwitch

                                            label={each.status ? "Active" : "Inactive"}
                                            loading={isSwitchLoadingIndex === index}
                                            checked={!!each.status}
                                            onChange={() => { each?.id && UpdateStatus(each?.id, !each?.status, index) }}
                                            withConfirmation
                                        />
                                    </td>
                                    <td
                                        className="flex w-full gap-1 items-center justify-end">
                                        {/* <Tooltip label="Edit" position="top" >
                                                        <div>
                                                        <FaRegEdit
                                                            size={18}
                                                            className="cursor-pointer text-green-400 hover:text-green-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Edit")
                                                                setSelectedMarkupFee(each)
                                                            }}
                                                        />
                                                            </div>
                                                    </Tooltip> */}
                                        {/* <Tooltip label="Delete">
                                                        <div>
                                                        <MdDeleteForever
                                                            size={22}
                                                            className="cursor-pointer text-orange-400 hover:text-orange-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Delete")
                                                                setSelectedMarkupFee(each)
                                                            }}
                                                        />
                                                        </div>
                                                    </Tooltip> */}
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </ETable>

            {/* ADD NEW Modal */}
            <EModal
                title="New Markup Type"
                opened={isOpenModalValue === "New"}

                onClose={() => {
                    setNewMarkupFee({} as INewMarkupFee);
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <Select
                        label="Fee Type"
                        placeholder="Select Fee Type"
                        value={newMarkupFee?.feeTypeId + ""}
                        onChange={(e) => setNewMarkupFee({ ...newMarkupFee, feeTypeId: e ? +e : 0 })}
                        data={feeTypeList}
                    />
                    <TextInput
                        label="Markup Fee Form Type"
                        placeholder="Enter Name"
                        value={newMarkupFee?.formType}
                        onChange={(e) => setNewMarkupFee({ ...newMarkupFee, formType: e.target.value })}
                    />
                    <TextInput
                        label="Markup Travel Service Category"
                        placeholder="Enter Name"
                        value={newMarkupFee?.travelServiceCategory}
                        onChange={(e) => setNewMarkupFee({ ...newMarkupFee, travelServiceCategory: e.target.value })}
                    />
                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newMarkupFee?.feeTypeId && newMarkupFee?.formType && newMarkupFee?.travelServiceCategory)}
                            loading={isBtnLoading}
                            onClick={() => submitNewMarkupFee()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Markup Type */}
            {/* <EModal
                title="Edit Markup Type"
                opened={isOpenModalValue === "Edit"}
                 
                onClose={() => {
                    setSelectedMarkupFee(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                    <div className="w-full flex flex-col gap-2">
                    <Select
                        label="Fee Type"
                        placeholder="Select Fee Type"
                        value={selectedMarkupFee?.feeTypeId+""}
                        onChange={(e) => setselectedMarkupFee({ ...selectedMarkupFee, feeTypeId: e ? +e : 0})}
                        data={feeTypeList}
                     />
                    <TextInput
                        label="Markup Fee Form Type"
                        placeholder="Enter Name"
                        value={selectedMarkupFee?.formType}
                        onChange={(e) => setselectedMarkupFee({ ...selectedMarkupFee, formType: e.target.value })}
                    />
                    <TextInput
                        label="Markup Travel Service Category"
                        placeholder="Enter Name"
                        value={selectedMarkupFee?.travelServiceCategory}
                        onChange={(e) => setselectedMarkupFee({ ...selectedMarkupFee, travelServiceCategory: e.target.value })}
                    />
                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(selectedMarkupFee?.feeTypeId && selectedMarkupFee?.formType && selectedMarkupFee?.travelServiceCategory)}
                            loading={isBtnLoading}
                            onClick={() => submitselectedMarkupFee()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal> */}

            <EModal
                title="Delete Markup Type"
                opened={isOpenModalValue === "Delete"}

                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2">
                    <p>Are you sure you want to delete this markup type?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedMarkupFee(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedMarkupFee?.id && deleteMarkupFee(selectedMarkupFee?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
