import PageLayout from "PageLayout";
import "@mantine/dates/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, GridCol, Radio, Space } from "@mantine/core";
import useCreateMarkup_B2B from "./hook/useCreateMarkupB2B";
import {
  EButton,
  ESelect,
  EPageTitle,
  ETextInput,
  EDateInput,
  EMultiSelect,
  ESectionDivider,
} from "libs/ui";
import ENumberInput from "libs/ui/src/ENumberInput/ENumberInput";

const CountryOriginFiledKeys = {
  FLIGHT: "flightOriginCountryIds",
  HOTEL: "countryIds",
  BUS: "originPlaceIds",
};

const CountryDestinationFiledKeys = {
  FLIGHT: "flightDestinationCountryIds",
  HOTEL: "placeIds",
  BUS: "destinationPlaceIds",
};

const SuppliersFiledKeys = {
  FLIGHT: "flightTicketSupplierIds",
  HOTEL: "hotelBookingSupplierIds",
  BUS: "busTicketSupplierIds",
};

export default function CreateMarkup_B2C() {
  const {
    busDestinations,
    busCategoryTypes,
    busSeatTypes,
    serviceName,
    markupB2BForm,
    fareTypes,
    allFeeTypes,
    applyTypes,
    hotelPlaces,
    markupTypes,
    applyOnTypes,
    supplierList,
    serviceTypes,
    airlinesList,
    applyForTypes,
    searchTypes,
    hotelCategories,
    groupTypes,
    allCountryList,
    airportByCoutries,
    flightAllClassTypes,
    flightCabinClassTypes,
    airportByDestinationCoutries,
    setServiceName,
    getHotelPlacesByCountries,
    getAirlinesBySuppliers,
    getAllAirportByCountries,
    createMarkupFromB2B,
    updateMarkupFormB2B,
    getAllAirportByDestinationCountries,
  } = useCreateMarkup_B2B();

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location

  const formValues = markupB2BForm.getValues();

  const {
    gst,
    startDate,
    endDate,
    service,
    countryIds,
    flightTicketSupplierIds,
  } = formValues;

  return (
    <form onSubmit={
        markupB2BForm.onSubmit(
          (value) => {
           state ? 
         updateMarkupFormB2B()
         :
         createMarkupFromB2B()
        })
        } 
        >
      <PageLayout
        footer={
          <div className="flex justify-end gap-2">
            <EButton
              onClick={() => {
                navigate(`/group-settings/view-markup-b2b`, 
                  { state: location.state });
              }}
              color="gray"
            >
              Cancel
            </EButton>
            <EButton type="submit" >
              {state ? "Update" : "Save"}
            </EButton>
          </div>
        }>
        <EPageTitle
          title={state ? "Edit Markup B2B" : "Create Markup B2B"}
        />
        <ESectionDivider title="Service" />
        <Grid>
          <GridCol span={4}>
            <ESelect
              withAsterisk
              label="Service"
              placeholder="Select"
              disabled={formValues.affiliateGroupId || formValues.flightApplyTypeId}
              value={serviceName}
              data={serviceTypes || []}
              onChange={(value: any) => {
                markupB2BForm.reset();
                setServiceName(value);
              }}
            />
          </GridCol>
          <GridCol span={4}>
            <ESelect
              withAsterisk
              label="Group"
              placeholder="Select"
              data={groupTypes || []}
              value={formValues.affiliateGroupId + ""}
              key={markupB2BForm.key("affiliateGroupId")}
              {...markupB2BForm.getInputProps("affiliateGroupId")}
              onChange={(value: any) => {
                markupB2BForm.setFieldValue("affiliateGroupId", value);
              }}
            />
          </GridCol>
        </Grid>
        <Space h="lg" />
        <div className="w-full">
          <Grid hidden={service === "BUS"}>
            <GridCol span={4}>
              <ESelect
                withAsterisk
                searchable
                label="Apply For"
                placeholder="Select"
                data={applyForTypes || []}
                value={formValues.chargeApplyForTypeId + ""}
                key={markupB2BForm.key("chargeApplyForTypeId")}
                {...markupB2BForm.getInputProps("chargeApplyForTypeId")}
                onChange={(value: any) => {
                  markupB2BForm.setFieldValue("chargeApplyForTypeId", value);
                }}
              />
            </GridCol>
            <GridCol span={4}>
              <ESelect
                withAsterisk
                searchable
                label="Markup Types"
                placeholder="Select"
                data={markupTypes || []}
                value={formValues.bookingTypeId + ""}
                key={markupB2BForm.key("bookingTypeId")}
                {...markupB2BForm.getInputProps("bookingTypeId")}
                onChange={(value: any) => {
                  markupB2BForm.setFieldValue("bookingTypeId", value);
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <ESelect
                withAsterisk
                searchable
                label="Fare Types"
                placeholder="Select"
                data={fareTypes || []}
                value={formValues.flightFareTypeId + ""}
                key={markupB2BForm.key("flightFareTypeId")}
                {...markupB2BForm.getInputProps("flightFareTypeId")}
                onChange={(value: any) => {
                  markupB2BForm.setFieldValue("flightFareTypeId", value);
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <ESelect
                withAsterisk
                searchable
                label="Apply On"
                placeholder="Select"
                data={applyOnTypes || []}
                value={formValues.chargeApplyOnTypeId + ""}
                key={markupB2BForm.key("chargeApplyOnTypeId")}
                {...markupB2BForm.getInputProps("chargeApplyOnTypeId")}
                onChange={(value: any) => {
                  markupB2BForm.setFieldValue("chargeApplyOnTypeId", value);
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <ESelect
                withAsterisk
                searchable
                label="Search Type"
                placeholder="Select"
                data={searchTypes || []}
                value={formValues.flightJourneyTypeId + ""}
                key={markupB2BForm.key("flightJourneyTypeId")}
                {...markupB2BForm.getInputProps("flightJourneyTypeId")}
                onChange={(value: any) => {
                  markupB2BForm.setFieldValue("flightJourneyTypeId", value);
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <ESelect
                withAsterisk
                searchable
                label="Apply Type"
                placeholder="Select"
                data={applyTypes || []}
                value={formValues.flightApplyTypeId + ""}
                key={markupB2BForm.key("flightApplyTypeId")}
                {...markupB2BForm.getInputProps("flightApplyTypeId")}
                onChange={(value: any) => {
                  markupB2BForm.setFieldValue("flightApplyTypeId", value);
                }}
              />
            </GridCol>
          </Grid>
        </div>
        <Space h={serviceName === "BUS" ? "sm" : "xl"} />
        <div className="w-full">
          <ESectionDivider title="Markup Validity" />
          <Grid>
            <GridCol span={4}>
              <EDateInput
                withAsterisk
                label="Start Date"
                placeholder="Start Date"
                minDate={new Date()}
                valueFormat="YYYY-MM-DD"
                key={markupB2BForm.key("startDate")}
                value={startDate ? new Date(startDate) : undefined}
                {...markupB2BForm.getInputProps("startDate")}
                onChange={(value) => {
                  markupB2BForm.setFieldValue("startDate", value);
                }}
              />
            </GridCol>
            <GridCol span={4}>
              <EDateInput
                withAsterisk
                size="md"
                label="End Date"
                placeholder="End Date"
                valueFormat="YYYY-MM-DD"
                minDate={startDate || new Date()}
                disabled={!startDate}
                value={endDate ? new Date(endDate) : undefined}
                key={markupB2BForm.key("endDate")}
                {...markupB2BForm.getInputProps("endDate")}
                onChange={(value) => {
                  markupB2BForm.setFieldValue("endDate", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <ESectionDivider title="Gross Price Range" />
          <Grid>
            <GridCol span={4}>
              <ETextInput
                withAsterisk
                type="number"
                label="From Price"
                placeholder="Enter price"
                key={markupB2BForm.key("minimumGrossPrice")}
                {...markupB2BForm.getInputProps("minimumGrossPrice")}
              />
            </GridCol>
            <GridCol span={4}>
              <ETextInput
                withAsterisk
                type="number"
                label="To Price"
                placeholder="Enter price"
                key={markupB2BForm.key("maximumGrossPrice")}
                {...markupB2BForm.getInputProps("maximumGrossPrice")}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <ESectionDivider
            title={
              service === "FLIGHT"
                ? "Country Wise"
                : service === "HOTEL"
                  ? "Destination"
                  : "Destination/Route"
            }
          />
          <Grid>
            <GridCol span={4} hidden={serviceName === "BUS"}>
              <EMultiSelect
                withAsterisk
                searchable
                placeholder="Select"
                label={serviceName === "FLIGHT" ? "Origin Country" : "Country"}
                key={markupB2BForm.key(CountryOriginFiledKeys[serviceName])}
                data={allCountryList || []}
                {...markupB2BForm.getInputProps(
                  CountryOriginFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  if (serviceName === "HOTEL") {
                    getHotelPlacesByCountries(value);
                  } else if (serviceName === "FLIGHT") {
                    getAllAirportByCountries(value);
                  }
                  markupB2BForm.setFieldValue(
                    CountryOriginFiledKeys[serviceName],
                    value
                  );
                  markupB2BForm.setFieldValue(
                    CountryDestinationFiledKeys[serviceName],
                    []
                  );
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={serviceName !== "BUS"}>
              <EMultiSelect
                searchable
                withAsterisk
                placeholder="Select"
                label="Origin"
                key={markupB2BForm.key(CountryOriginFiledKeys[serviceName])}
                data={busDestinations || []}
                {...markupB2BForm.getInputProps(
                  CountryOriginFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2BForm.setFieldValue(
                    CountryOriginFiledKeys[serviceName],
                    value
                  );
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
              <EMultiSelect
                searchable
                withAsterisk
                placeholder="Select"
                label="Destination Country"
                key={markupB2BForm.key(
                  CountryDestinationFiledKeys[serviceName]
                )}
                data={allCountryList || []}
                {...markupB2BForm.getInputProps(
                  CountryDestinationFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2BForm.setFieldValue(
                    CountryDestinationFiledKeys[serviceName],
                    value
                  );
                  getAllAirportByDestinationCountries(value);
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={serviceName !== "HOTEL"}>
              <EMultiSelect
                searchable
                withAsterisk
                disabled={!countryIds?.length}
                placeholder="Select"
                label="Destination"
                key={markupB2BForm.key(
                  CountryDestinationFiledKeys[serviceName]
                )}
                data={hotelPlaces || []}
                {...markupB2BForm.getInputProps(
                  CountryDestinationFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2BForm.setFieldValue(
                    CountryDestinationFiledKeys[serviceName],
                    value
                  );
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={serviceName !== "BUS"}>
              <EMultiSelect
                searchable
                withAsterisk
                placeholder="Select"
                label="Destination"
                key={markupB2BForm.key(
                  CountryDestinationFiledKeys[serviceName]
                )}
                data={busDestinations || []}
                {...markupB2BForm.getInputProps(
                  CountryDestinationFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2BForm.setFieldValue(
                    CountryDestinationFiledKeys[serviceName],
                    value
                  );
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <ESectionDivider
            title="Destination/Route"
            hidden={service !== "FLIGHT"}
          />
          <Grid hidden={service !== "FLIGHT"}>
            <GridCol span={4}>
              <EMultiSelect
                searchable
                withAsterisk
                placeholder="Select"
                label="Route Origin"
                data={airportByCoutries || []}
                key={markupB2BForm.key("originAirportIds")}
                {...markupB2BForm.getInputProps("originAirportIds")}
                onChange={(value) => {
                  markupB2BForm.setFieldValue("originAirportIds", value);
                }}
              />
            </GridCol>
            <GridCol span={4}>
              <EMultiSelect
                searchable
                withAsterisk
                placeholder="Select"
                label="Route Destination"
                data={airportByDestinationCoutries || []}
                key={markupB2BForm.key("destinationAirportIds")}
                {...markupB2BForm.getInputProps("destinationAirportIds")}
                onChange={(value) => {
                  markupB2BForm.setFieldValue("destinationAirportIds", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" hidden={service !== "FLIGHT"} />
          <ESectionDivider
            title="Flight Class / Flight Cabin Class"
            hidden={service !== "FLIGHT"}
          />
          <Grid hidden={service !== "FLIGHT"}>
            <GridCol span={4}>
              <EMultiSelect
                withAsterisk
                label="Flight Class"
                size="md"
                placeholder="Select"
                data={flightAllClassTypes || []}
                key={markupB2BForm.key("flightClass")}
                {...markupB2BForm.getInputProps("flightClass")}
                onChange={(value) => {
                  markupB2BForm.setFieldValue("flightClass", value);
                }}
              />
            </GridCol>
            <GridCol span={4}>
              <EMultiSelect
                withAsterisk
                searchable
                label="Flight Cabin Class"
                size="md"
                placeholder="Select"
                data={flightCabinClassTypes || []}
                key={markupB2BForm.key("flightCabin")}
                {...markupB2BForm.getInputProps("flightCabin")}
                onChange={(value) => {
                  markupB2BForm.setFieldValue("flightCabin", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" hidden={service !== "FLIGHT"} />
          <ESectionDivider title="Suppliers / Airlines" />
          <Grid>
            <GridCol span={4}>
              <EMultiSelect
                withAsterisk
                searchable
                label="Suppliers"
                placeholder="Select"
                data={supplierList || []}
                key={markupB2BForm.key(SuppliersFiledKeys[serviceName])}
                {...markupB2BForm.getInputProps(
                  SuppliersFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2BForm.setFieldValue(
                    SuppliersFiledKeys[serviceName],
                    value
                  );
                  if (!value.length && service === "FLIGHT") {
                    markupB2BForm.setFieldValue("flightAirlineIds", []);
                  } else {
                    getAirlinesBySuppliers(value);
                  }
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <EMultiSelect
                withAsterisk
                searchable
                label="Airlines"
                placeholder="Select"
                data={airlinesList || []}
                disabled={!flightTicketSupplierIds?.length}
                key={markupB2BForm.key("flightAirlineIds")}
                {...markupB2BForm.getInputProps("flightAirlineIds")}
                onChange={(value) => {
                  markupB2BForm.setFieldValue("flightAirlineIds", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" hidden={service !== "HOTEL"} />
          <ESectionDivider title="Category" hidden={service !== "HOTEL"} />
          <Grid hidden={service !== "HOTEL"}>
            <GridCol span={4}>
              <EMultiSelect
                withAsterisk
                searchable
                label="Category Type"
                placeholder="Select"
                data={hotelCategories || []}
                key={markupB2BForm.key("hotelCategoryIds")}
                {...markupB2BForm.getInputProps("hotelCategoryIds")}
                onChange={(value) => {
                  markupB2BForm.setFieldValue("hotelCategoryIds", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <div hidden={service !== "BUS"}>
            <ESectionDivider title="Category" />
            <Grid>
              <GridCol span={4}>
                <EMultiSelect
                  withAsterisk
                  searchable
                  label="Category Type"
                  placeholder="Select"
                  data={busCategoryTypes || []}
                  key={markupB2BForm.key("busCategories")}
                  {...markupB2BForm.getInputProps("busCategories")}
                  onChange={(value) => {
                    markupB2BForm.setFieldValue("busCategories", value);
                  }}
                />
              </GridCol>
            </Grid>
            <Space h="xl" />
            <ESectionDivider title="Seats" />
            <Grid>
              <GridCol span={4}>
                <EMultiSelect
                  withAsterisk
                  searchable
                  label="Seat Type"
                  placeholder="Select"
                  data={busSeatTypes || []}
                  key={markupB2BForm.key("busSeatCategories")}
                  {...markupB2BForm.getInputProps("busSeatCategories")}
                  onChange={(value) => {
                    markupB2BForm.setFieldValue("busSeatCategories", value);
                  }}
                />
              </GridCol>
            </Grid>
          </div>
          <Space h={"xl"} hidden={serviceName !== "BUS"} />
          <ESectionDivider title="Markup" />
          <div className="flex gap-4">
            <Radio
              label={"Exclusive GST"}
              checked={!gst}
              onClick={() => {
                markupB2BForm.setFieldValue("gst", false);
              }}
            />
            <Radio
              label={"Inclusive GST"}
              checked={gst}
              onClick={() => {
                markupB2BForm.setFieldValue("gst", true);
              }}
            />
          </div>
          <Space h={"xl"} />
          <Grid>
            {formValues?.travelFormFeeRequests?.map((category: any, index: number) => {
              const { name } = category;
              console.log("Category ", category);
              return (
                <GridCol span={4}>
                  <h1 className="text-lg text-black font-bold mb-2">{name}</h1>
                  <ENumberInput
                    withAsterisk
                    label="Amount"
                    placeholder="Enter Amount"
                    value={category?.fee || 0}
                    key={markupB2BForm.key(
                      `travelFormFeeRequests.${index}.fee`
                    )}
                    {...markupB2BForm.getInputProps(
                      `travelFormFeeRequests.${index}.fee`
                    )}
                  // onChange={(value) => {
                  //   markupB2BForm.setFieldValue(
                  //     `travelFormFeeRequests.${index}.fee`,
                  //     value
                  //   );
                  // }}
                  />
                  <Space h="sm" />
                  <ESelect
                    withAsterisk
                    searchable
                    label="Fee Type"
                    placeholder="Select"
                    data={allFeeTypes}
                    value={category?.feeType}
                    key={markupB2BForm.key(
                      `travelFormFeeRequests.${index}.feeType`
                    )}
                    {...markupB2BForm.getInputProps(
                      `travelFormFeeRequests.${index}.feeType`
                    )}
                    onChange={(value) => {
                      markupB2BForm.setFieldValue(
                        `travelFormFeeRequests.${index}.feeType`,
                        value
                      );
                    }}
                  />
                </GridCol>
              );
            })}
          </Grid>
        </div>
      </PageLayout>
    </form>
  );
}
