/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "@mantine/form";
import useMaster from "hook/useMaster";
import { PAGINATION_ITEM_LIMIT } from "libs/data-access/constant";
import { useApi } from "libs/data-access/src/api/useApi";
import { useEffect, useState } from "react";
import { IAffiliateSignUpForm, IAffiliateList, TPaginationStatus } from "types";
import { showToast } from "utils/utility"
import { NewAffiliateStep1Validation, NewAffiliateStep2Validation } from "./affiliateValidator";

const initialFormValues: IAffiliateSignUpForm = {
  companyName: "",
  website: "",
  firstAddress: "",
  secondAddress: "",
  countryId: "",
  stateId: "",
  city: "",
  pinCode: "",
  companyType: "",
  panNumber: "",
  tdsExemptionNumber: "",
  lookToBookRatioSearch: null,
  lookToBookRatioBook: null,
  tdsExemptionLimit: null,
  tdsExemptionRate: null,
  tdsExemptionCode: "",
  xlCode: "",
  isLogoAdvice: false,
  isCorporate: false,
  isSupplierPromoCode: false,
  gstNumber: "",
  email: "",
  mobileNumber: "",
  aadhaarCardId: null,
  panCardId: null,
  gstId: null,
  drivingLicenseId: null,
  companyLogoId: null,
  title: "",
  contactNumber: "",
  firstName: "",
  lastName: "",
  pmlAffiliate: false
}

const useAffiliates = () => {
  const {
    allCountryList,
    getAllCountryList,
    allStateList,
    getAllStateListByCountryId,
    allCompanyTypes,
    getAllCompanyTypes,

  } = useMaster();

  const { postData, putData, } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isLoadingIndex, setIsLoadingIndex] = useState<number | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formStep, setFormStep] = useState<number>(1);
  const [isAgreeWithTerm, setIsAgreeWithTerm] = useState<boolean>(false);
  const [formAction, setFormAction] = useState<"add" | "edit">("add");
  const [affiliateList, setAffiliateList] = useState<IAffiliateList[]>([]);
  const [validations, setValidations] = useState({});
  

   useEffect(() => {
      if(formStep === 1){
        setValidations(NewAffiliateStep1Validation)
      }
      else{
        setValidations({...NewAffiliateStep1Validation, ...NewAffiliateStep2Validation })
      }
   },[formStep])
   
  const affiliateForm = useForm({
    mode: "uncontrolled",
    initialValues: initialFormValues,
    validate: validations,
    transformValues: (values) => ({
      ...values,
      countryId: Number(values.countryId),
      stateId: Number(values.stateId),
    })
  });

  useEffect(() => {
    getAffiliateList("INITIAL_PAGE");
    getAllCountryList();
    getAllCompanyTypes()
  }, []);

  // HANDLER FUNCTION

  const handleClickEdit = (item: IAffiliateList) => {
    let FormValue: IAffiliateSignUpForm = {
      id: item.id,
      companyName: item.companyName,
      website: item.website,
      firstAddress: item.firstAddress,
      secondAddress: item.secondAddress,
      countryId: item.countryId?.toString(),
      stateId: item.stateId?.toString(),
      city: item.city,
      pinCode: item.pinCode,
      companyType: item.companyType,
      panNumber: item.panNumber,
      tdsExemptionNumber: item.tdsExemptionNumber,
      lookToBookRatioSearch: item.searchBookingRatio,
      lookToBookRatioBook: item.bookingRatio,
      tdsExemptionLimit: item.tdsExemptionLimit,
      tdsExemptionRate: item.tdsExemptionRate,
      tdsExemptionCode: item.tdsExemptionCode,
      xlCode: item.xlcode,
      isLogoAdvice: item.logoInvoice,
      isCorporate: item.corporate,
      isSupplierPromoCode: item.supplierPromoCode,
      gstNumber: item.gstNumber,
      email: item.emailId,
      mobileNumber: item.mobileNumber,
      aadhaarCardId: item.aadharCardMediaId,
      panCardId: item.panCardMediaId,
      gstId: item.gstMediaId,
      drivingLicenseId: item.drivingLicenseMediaId,
      companyLogoId: item.companyLogoMediaId,
      title: item.title,
      contactNumber: item.contactNumber,
      firstName: item.firstName,
      lastName: item.lastName,
      pmlAffiliate: item.affiliateStatus
    }
    affiliateForm.setValues(FormValue);
    getAllStateListByCountryId(+FormValue.countryId);
    setIsModalOpen(true);
    setFormAction("edit");
  }

  const handleChangeStatus = (status: boolean, id: number, index?: number) => {
    alert(status)
    if (status) {
      setIsLoadingIndex(index);
      //   updatedStatus(id, "active");
      // }
      // else {
      //   updatedStatus(id, "inActive");
    }
  }

  //  API FUNCTION
  const getAffiliateList = async (paginationAction: TPaginationStatus) => {
    setIsLoading(true);
    const body = {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        sortingOrder: "ASC",
      }
    };
    const res = await postData(`affiliate/get-all`, body);
    if (res.isSuccess) {
      setAffiliateList(res.data.data);
    }
    else {
      showToast({
        message: res.message,
        type: "error",
      });
      setAffiliateList([])
    }
    setIsLoading(false);
  }

  const postNewAffiliate = async () => {
    setIsBtnLoading(true);
    const formValues = affiliateForm.getTransformedValues();
    console.log("Payload For Affiliate", formValues)
    const response = await postData(`affiliate/affiliate/signup`, formValues);
    if (response.isSuccess) {
      showToast({
        message: "Saved Successfully",
        type: "success",
      });
      getAffiliateList("INITIAL_PAGE");
      setIsModalOpen(false);
      affiliateForm.reset()
    }
    else {
      showToast({
        message: response.message,
        type: "error",
      });
    }
    setIsBtnLoading(false);
  };

  const updatedAffiliate = async () => {
    setIsBtnLoading(true);
    const formValues = affiliateForm.getTransformedValues();
    const { id } = formValues;
    const response = await putData(`affiliate/update/${id}`, formValues);
    if (response.isSuccess) {
      showToast({
        message: "Status Update Successfully",
        type: "success",
      });
      getAffiliateList("INITIAL_PAGE");
      setIsModalOpen(false);
      setFormAction("add");
      affiliateForm.reset()
    }
    else {
      showToast({
        message: response.message,
        type: "error",
      });
    }
    setIsBtnLoading(false);
  }

  // const updatedStatus = async (ChargeId: number, chargeStatus: "active" | "inActive") => {
  //   setIsBtnLoading(true);
  //   const response = await patchData(`${saveFormEndPoints[ChargeService]}${chargeStatus}/${ChargeId}`, {});
  //   if (response.isSuccess) {
  //     showToast({
  //       message: "Form Update Successfully",
  //       type: "success",
  //     });
  //     getAffiliateList(ChargeService, "INITIAL_PAGE");
  //     setIsModalOpen(false);
  //     setFormAction("add");
  //     affiliateForm.reset()
  //   }
  //   else {
  //     showToast({
  //       message: response.message,
  //       type: "error",
  //     });
  //   }
  //   setIsLoadingIndex(undefined);
  //   setIsBtnLoading(false);


  // }

  return {
    allCountryList,
    allCompanyTypes,
    allStateList,
    getAllStateListByCountryId,
    isModalOpen,
    setIsModalOpen,
    isAgreeWithTerm,
    setIsAgreeWithTerm,
    formAction,
    setFormAction,
    formStep,
    setFormStep,
    isLoading,
    isLoadingIndex,
    isBtnLoading,
    affiliateForm,
    postNewAffiliate,
    updatedAffiliate,
    affiliateList,
    handleClickEdit,
    handleChangeStatus,
    getAffiliateList,
    getAllCountryList,
    getAllCompanyTypes,
  };
};

export default useAffiliates;
