import { Button, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ETable } from "../../../../libs/ui";
import { useSuppliers } from "./hook/useSuppliers";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function HotelSuppliers() {

    const {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        suppliersList,
        newSuppliers,
        setNewSuppliers,
        selectedSuppliers,
        setSelectedSuppliers,
        submitNewHotelSuppliers,
        UpdateStatus,
        UpdateSupplier,
        deleteSupplier,
    } = useSuppliers();


    return (
        <PageLayout>
            <EPageTitle
                title="Hotels Supplier"
                end={
                    <div className="flex gap-1">
                        <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton>
                    </div>
                }
            />


            <ETable
            loading={isLoading}
            >
                <thead>
                    <tr>
                        <th>SR. No.</th>
                        <th>Supplier Name</th>
                        <th>Status</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        suppliersList?.map((each, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{each.name}</td>
                                    <td>
                                        <ESwitch
                                            label={each.status ? "Active" : "Inactive"}
                                            loading={isSwitchLoadingIndex === index}
                                            checked={!!each.status}
                                            onChange={() => { each?.id && UpdateStatus(each?.id, !each?.status, index) }}
                                            withConfirmation
                                        />
                                    </td>
                                    <td
                                        className="flex w-full gap-1 items-center justify-end">
                                        <Tooltip label="Edit" position="top" >
                                            <div>
                                                <FaRegEdit
                                                    size={18}
                                                    className="cursor-pointer text-green-400 hover:text-green-600"
                                                    onClick={() => {
                                                        setIsOpenModalValue("Edit")
                                                        setSelectedSuppliers(each)
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        {/* <Tooltip label="Delete">
                                                        <div>
                                                        <MdDeleteForever
                                                            size={22}
                                                            className="cursor-pointer text-orange-400 hover:text-orange-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Delete")
                                                                setSelectedSuppliers(each)
                                                            }}
                                                        />
                                                        </div>
                                                    </Tooltip> */}
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </ETable>


            {/* ADD NEW Modal */}
            <EModal
                title="New Supplier"
                opened={isOpenModalValue === "New"}

                onClose={() => {
                    setNewSuppliers({ name: "", code: "" });
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Supplier Name"
                        placeholder="Enter Name"
                        value={newSuppliers?.name}
                        onChange={(e) => setNewSuppliers({ ...newSuppliers, name: e.target.value })}
                    />
                    <TextInput
                        label="Supplier Code"
                        placeholder="Enter Code"
                        value={newSuppliers?.code}
                        onChange={(e) => setNewSuppliers({ ...newSuppliers, code: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newSuppliers?.name)}
                            loading={isBtnLoading}
                            onClick={() => submitNewHotelSuppliers()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Supplier */}
            <EModal
                title="Edit Supplier"
                opened={isOpenModalValue === "Edit"}

                onClose={() => {
                    setSelectedSuppliers(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                {selectedSuppliers ? <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Supplier Name"
                        placeholder="Enter Name"
                        value={selectedSuppliers?.name}
                        onChange={(e) => setSelectedSuppliers({ ...selectedSuppliers, name: e.target.value })}
                    />
                    <TextInput
                        label="Supplier Code"
                        placeholder="Enter Code"
                        value={selectedSuppliers?.code}
                        onChange={(e) => setSelectedSuppliers({ ...selectedSuppliers, code: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(selectedSuppliers?.name)}
                            loading={isBtnLoading}
                            onClick={() => selectedSuppliers?.id && UpdateSupplier(selectedSuppliers?.id, selectedSuppliers?.name)}
                        >
                            Update
                        </Button>
                    </div>
                </div> : <></>}
            </EModal>

            <EModal
                title="Delete Supplier"
                opened={isOpenModalValue === "Delete"}

                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2">
                    <p>Are you sure you want to delete this Supplier?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedSuppliers(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedSuppliers?.id && deleteSupplier(selectedSuppliers?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
