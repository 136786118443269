import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft, HiHome } from "react-icons/hi2";
import { Image } from "@mantine/core";

import { EButton, EModal, ESectionDivider } from "../..";
import ENavItem from "./components/navItem/ENavItem";

import "./ENavbar.scss";

import { AppContext } from "../../../../AppContext";
import { ALL_ALLOWED_ROUTES } from "./../../../../App";
import { IRoute } from "../../../../types";
import { FiChevronRight } from "react-icons/fi";

type Props = {
  role: string[] | undefined;
};

export function ENavbar() {
  const {
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    handleLogout,
    role,
    userAuthorities,
    user,
    currentRotes,
    setCurrentRoutes,
    activeRoute,
    setActiveRoute,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [logOutModalOpened, setLogOutModalOpened] = useState<boolean>(false);
  const [bredCum, setBredCum] = useState<string>("");


  useEffect(() => {
    activeRoute &&
      navigate(activeRoute?.route?.path);
    if (activeRoute) {
      if(activeRoute.SubMenuIndex?.length){
        let bred = generateBreadcrumb(ALL_ALLOWED_ROUTES, activeRoute?.SubMenuIndex);
          setBredCum(bred?.join(' / '));
      }}
      else{
        setBredCum('');
      }
  }, [activeRoute]);


  function generateBreadcrumb(allRoutes: IRoute[], activeRoute: number[]) {
    let currentRoute = allRoutes[activeRoute[0]];
    const breadcrumb = [currentRoute.label];
  
    for (let i = 1; i < activeRoute.length; i++) {
      const submenuIndex = activeRoute[i];
  
      if (currentRoute.submenu && currentRoute.submenu[submenuIndex]) {
        currentRoute = currentRoute.submenu[submenuIndex];
        breadcrumb.push(currentRoute.label); 
      }
    }
    return breadcrumb;
  }
  
  const subMenuRecursion = (submenu: IRoute[]): IRoute[] => {
    return submenu
      .map((sub) => (sub?.submenu ? subMenuRecursion(sub?.submenu) : sub))
      .flat();
  };

  function getRouteByIndex(routes: IRoute[], indices: number[]) {
    let currentRoutes = routes;
    for (let i = 0; i < indices.length; i++) {
      const index = indices[i];
      if (
        currentRoutes &&
        Array.isArray(currentRoutes) &&
        currentRoutes[index]
      ) {
        currentRoutes = currentRoutes[index].submenu as IRoute[];
      } else {
        return []; // Invalid index or route does not exist
      }
    }
    return currentRoutes;
  }

  const activeRouteArray = useMemo(() => {
    if (activeRoute?.SubMenuIndex?.length) {
      let nestedRoute: IRoute[] | undefined = ALL_ALLOWED_ROUTES;

      nestedRoute = getRouteByIndex(
        nestedRoute,
        activeRoute?.SubMenuIndex
      )?.filter((each) => each.icon);
      if (nestedRoute?.[0]?.path) {
        navigate(nestedRoute[0].path);
      }
      return nestedRoute;
    } else {
      // navigate("/");
      return ALL_ALLOWED_ROUTES;
    }
  }, [activeRoute?.SubMenuIndex]);

  return (
    <>
      <div className="head relative">
        {/* <div
          className={`flex justify-center overflow-hidden ${isSidebarCollapsed ? "w-0 duration-500" : "w-full duration-700 "}`}
        >
        <div className="absolute right-3 top-3">
          <HiBars3BottomLeft
            size={28}
            className="cursor-pointer"
            onClick={() => setIsSidebarCollapsed((prev) => !prev)}
          />
        </div>
        </div> */}
        <div className="flex justify-center overflow-hidden">
          <Image
            maw={120}
            h={120}
            fit="contain"
            className="logo"
            src={require("../../../../assets/image/PMLLogo.png")}
            alt="PML Logo"
          />
        </div>

        <div className="avatar-group mt-8">
          <div className="flex items-center gap-3 overflow-hidden ">
            <div
              className={`overflow-hidden ${isSidebarCollapsed
                  ? "w-0 duration-700 delay-200"
                  : "w-full duration-700 "
                }`}
            >
              <div className={`opacity-50 text-sm font-medium`}>
                You are logged in as,
              </div>
              <div className="text-base font-semibold"></div>
            </div>
          </div>
          {/* <div
            className="flex flex-col items-end cursor-pointer"
            onClick={() => setLogOutModalOpened(true)}
          >
            <FiLogOut size={20} />
          </div> */}
        </div>
      </div>

      {/* BACK BUTTON FOR ACTIVE SUBMENU */}

      {activeRoute?.SubMenuIndex?.length ? (
        <div className="mt-6">
          <div
            className="w-fit rounded-sm bg-white/10 p-3 py-2 pr-4 mx-3 flex gap-1 items-center font-semibold text-base cursor-pointer hover:bg-white/20 border-t border-white/10"
            onClick={() => {
              let nestedIndexes: number[] = JSON.parse(
                JSON.stringify(activeRoute?.SubMenuIndex)
              );
              const newNestedIndex = nestedIndexes.pop();
              newNestedIndex !== undefined &&
                setActiveRoute({
                  route: getRouteByIndex(ALL_ALLOWED_ROUTES, nestedIndexes)[0],
                  SubMenuIndex: nestedIndexes,
                });
            }}
          >
            <HiChevronLeft size={24} fontWeight={700} />
            <span
              className={`overflow-hidden ${isSidebarCollapsed
                  ? "w-0 duration-300"
                  : "w-10/12 duration-700 delay-200"
                }`}
            >
              Back
            </span>
          </div>
          <div className="text-base ml-3 mt-2 pb-2 border-b border-white/10 font-semibold"></div>
          {/* <ESectionDivider title={ bredCum + " "} titleStyle="text-sm ml-3 mt-2 pb-2" /> */}
          <div className={`overflow-hidden ${isSidebarCollapsed
                  ? "w-0 duration-300"
                  : "w-10/12 duration-700 delay-200"
                }`}>
          <p className="text-sm ml-3 mt-6 pb-2 flex items-center gap-1">{bredCum + " "} <FiChevronRight/> </p>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="body">
        <div className="nav-group grid gap-2 my-3">
          {activeRouteArray &&
            activeRouteArray.length > 0 &&
            activeRouteArray
              ?.filter((each) => each.icon !== undefined)
              .map((route: IRoute, index: number) => {
                return (
                  <ENavItem
                    key={"route" + index}
                    parentIndex={index}
                    icon={<route.icon /> || <HiHome />}
                    label={
                      <span className="flex items-center">
                        <span>{route?.label}</span>
                      </span>
                    }
                    handleClick={() => {
                      navigate(route.path);
                      setActiveRoute({
                        ...activeRoute,
                        route: route,
                        SubMenuIndex: activeRoute?.SubMenuIndex,
                      });
                      //   }
                    }}
                    isActive={activeRoute?.route?.path === route.path}
                    hasSubMenu={route.isSubmenu}
                    // subMenu={route.submenu}
                    setHoverIndex={(e) => {
                      let prev = activeRoute?.SubMenuIndex || [];
                      setActiveRoute({
                        route: getRouteByIndex(
                          ALL_ALLOWED_ROUTES,
                          prev.concat(e)
                        )[0],
                        SubMenuIndex: [...prev.concat(e)],
                      });
                    }}
                  />
                );
              })}
        </div>
      </div>

      {/* <div className="foot">
          <div className="border--1"></div>
          <ENavItem icon={<AdjustmentsIcon />} label="Help & Support" />
        <ENavItem icon={<BellIcon />} label="Notification" />
        </div> */}

      {/* <div
        className="flex flex-col items-end cursor-pointer"
        onClick={() => setLogOutModalOpened(true)}
      >
        <FiLogOut
          size={20}
        />
      </div> */}

      <EModal
        opened={logOutModalOpened}
        onClose={() => setLogOutModalOpened(false)}
        title="Are you sure you want to logout?"

      >
        <div className="flex justify-end">
          <EButton
            size="sm"
            className="btn-light"
            onClick={() => {
              handleLogout();
              setLogOutModalOpened(false);
            }}
          >
            Yes, Logout
          </EButton>
        </div>
      </EModal>
    </>
  );
}

export default ENavbar;
