import "@mantine/dates/styles.css";
import PageLayout from "PageLayout";
import { IoMdAdd } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import useMarkup_B2C from "./hook/useMarkup_B2C";
import { useNavigate } from "react-router-dom";
import { EButton, EDateInput, EPageTitle, ETable } from "libs/ui";
import { Select, Space, Tooltip } from "@mantine/core";
import ETableTooltipData from "libs/ui/src/table/ETableTooltipData";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function Markup_B2C() {
  const {
    isLoading,
    isLoadingIndex,
    filter,
    markupList,
    supplierList,
    serviceTypes,
    setFilter,
    getMarkupList,
    handleFilterChange,
    handleChangeStatus,
  } = useMarkup_B2C();

  const navigate = useNavigate();
  const { service, suppliers, endDate, startDate } = filter;

  return (
    <PageLayout>

      <EPageTitle
        title="Markup B2C"
        end={
          <EButton
            rightSection={<IoMdAdd />}
            onClick={() => {
              navigate("/admin-transaction/create-markup-b2c", {
                replace: false,
                state: { service },
              });
            }}
          >
            Create New
          </EButton>
        }
      />

      {/* Filter */}
      <div className="filter-container">
        <div className="w-full grid grid-cols-4 gap-3">
          <Select
            label="Service"
            size="md"
            placeholder="Select"
            data={serviceTypes || []}
            value={service}
            onChange={(value) => handleFilterChange("service", value)}
          />
          <Select
            label="Suppliers"
            size="md"
            placeholder="Select"
            data={supplierList || []}
            value={suppliers}
            onChange={(value) => handleFilterChange("suppliers", value)}
          />
          <EDateInput
            clearable
            label="Start Date"
            placeholder="Pick date"
            minDate={new Date()}
            value={startDate ? new Date(startDate) : null}
            onChange={(value) => {
              handleFilterChange("endDate", null);
              handleFilterChange("startDate", value?.toString() || null);
            }}
          />
          <EDateInput
            label="End Date"
            placeholder="Pick date"
            disabled={!startDate}
            value={endDate ? new Date(endDate) : null}
            minDate={startDate ? new Date(startDate) : new Date()}
            onChange={(value) =>
              handleFilterChange("endDate", value?.toString() || null)
            }
          />
        </div>
        <div className="flex w-full justify-end gap-2">
          <EButton
            onClick={() => {
              setFilter({
                service: "FLIGHT",
                suppliers: "",
                startDate: null,
                endDate: null,
              });
            }}
            color="gray"
          >
            Cancel
          </EButton>
          <EButton onClick={() => getMarkupList("INITIAL_PAGE")}>Search</EButton>
        </div>
      </div>

      <Space h="xl" />


      {/* Table */}
      <ETable
        loading={isLoading}
        pagination={true}
        onChangePagination={(e) => getMarkupList(e)}
      >

        <thead className="whitespace-nowrap thead">
          <tr>
            <th> Start Date</th>
            <th> End Date</th>
            <th hidden={service === "BUS"}> Markup Type</th>
            <th hidden={service === "BUS"}> Apply For</th>
            <th hidden={service !== "FLIGHT"}> Fare Type</th>
            <th> Price From</th>
            <th> Price To</th>
            <th> Fee</th>
            <th> Origin</th>
            <th> Destination</th>
            <th> Supplier</th>
            <th hidden={service !== "FLIGHT"}> Airline</th>
            <th> Status</th>
            <th className="flex justify-end w-full whitespace-normal"> </th>
          </tr>
        </thead>
        {
          markupList?.length ? (
            <tbody>
              {markupList.map((markup, index) => {
                const {
                  id,
                  status,
                  endDate,
                  startDate,
                  bookingType,
                  flightFareType,
                  maximumGrossPrice,
                  minimumGrossPrice,
                  chargeApplyForType,
                  supplierResponses,
                  flightAirlineResponses,
                  destinationCountryResponses,
                  originCountryResponses,
                  placeResponses,
                  countryResponses,
                  hotelBookingSupplierResponses,
                } = markup;

                const origins = {
                  FLIGHT: originCountryResponses,
                  HOTEL: countryResponses,
                  BUS: [],
                };
                const destinations = {
                  FLIGHT: destinationCountryResponses,
                  HOTEL: placeResponses,
                  BUS: [],
                };
                const suppliers = {
                  FLIGHT: supplierResponses,
                  HOTEL: hotelBookingSupplierResponses,
                  BUS: [],
                };

                const formatResponseNames = (responses: any) =>
                  responses
                    ?.map((response: any) => <p>{response?.name}</p>) || "";

                return (
                  <tr key={index}>
                    <td>{startDate}</td>
                    <td>{endDate}</td>
                    <td hidden={service === "BUS"}>
                      {bookingType?.name || ""}
                    </td>
                    <td hidden={service === "BUS"}>
                      {chargeApplyForType?.name || ""}
                    </td>
                    <td hidden={service !== "FLIGHT"}>
                      {flightFareType?.name || ""}
                    </td>
                    <td>{minimumGrossPrice}</td>
                    <td>{maximumGrossPrice}</td>
                    <td>
                      <div className="flex flex-col">
                        {
                          markup?.travelServiceFormFeeResponses
                            ?.map((fee: any) =>
                              <p>{fee?.name + ": " + fee?.feeAmount + ""}</p>
                            )}
                      </div>
                    </td>
                    <td>
                      {/* {formatResponseNames(origins[service])} */}
                      {ETableTooltipData(origins[service]?.map((e: any) => e?.name))}

                    </td>
                    <td>
                      {ETableTooltipData(destinations[service]?.map((e: any) => e?.name))}
                      {/* {formatResponseNames(destinations[service])} */}
                    </td>
                    <td>
                      {formatResponseNames(suppliers[service])}
                    </td>
                    <td hidden={service !== "FLIGHT"}>
                      {formatResponseNames(flightAirlineResponses)}
                    </td>
                    <td>
                      <ESwitch
                        label={status ? "Active" : "In-Active"}
                        checked={status}
                        onChange={(e) => handleChangeStatus(e, id, index)}
                        withConfirmation
                        loading={isLoadingIndex === index}
                        confirmationTitle="Confirmation"
                        confirmationDescription="Are you sure you want to change status?"
                      />
                    </td>
                    <td className="flex justify-end w-full whitespace-normal  gap-1">
                      <div className="flex h-10">
                        <Tooltip label="Edit" position="top">
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(`/markup_and_commission/edit-markup-b2c`, {
                                replace: false,
                                state: { service, markup: markup },
                              });
                            }}
                          >
                            <FaRegEdit
                              size={23}
                              className="cursor-pointer text-green-400  h-full self-center"
                            />
                          </div>
                        </Tooltip>

                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <></>
          )}
      </ETable>


    </PageLayout>
  );
}
