import { useEffect, useState } from "react";
import useMaster from "hook/useMaster";
import { showToast } from "utils/utility";
import { IMarkupB2CFilter, TPaginationStatus } from "types";
import { useApi } from "libs/data-access/src/api/useApi";
import { PAGINATION_ITEM_LIMIT } from "libs/data-access/constant";
import moment from "moment";
import { IFlightBookingList } from "libs/data-access/types";
import { useLocation } from "react-router-dom";

export default function useMarkup_B2C() {

  const { state } = useLocation();
  const { service } = state || {};


  const { postData, patchData } = useApi();
  const { 
    getServiceTypes, 
    serviceTypes, 
    supplierList, 
    getSupplierList 
  } = useMaster();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingIndex, setIsLoadingIndex] = useState<number | undefined>(undefined);
  const [markupList, setMarkupList] = useState<IFlightBookingList[]>([]);
  
  const [filter, setFilter] = useState<IMarkupB2CFilter>({
    service: service ? service : "FLIGHT",
    suppliers: "",
    startDate: null,
    endDate: null,
  });

  const markupListEndpoints = {
    FLIGHT: "flight-markup-form",
    HOTEL: "hotel-markup-form",
    BUS: "bus-markup-form",
  };

  useEffect(() => {
    if (filter.service) {
      getServiceTypes();
      getMarkupList("INITIAL_PAGE");
      getSupplierList(filter.service);
    }
  }, [filter.service])
  // Handler Function

  const handleFilterChange = (
    key: keyof IMarkupB2CFilter | "default",
    value: string | null
  ) => {
    if (key === "default") {
      setFilter({} as IMarkupB2CFilter);
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleChangeStatus = (status: boolean, id: number, index?: number) => {
    setIsLoadingIndex(index);
    updatedStatus(id, status ? "active" : "inActive");
  }

  // API Function
  const getMarkupList = async (paginationAction: TPaginationStatus) => {
    const { suppliers, startDate, endDate } = filter;
    const sDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    const eDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    setIsLoading(true);
    const body = {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        sortingOrder: "DESC",
        id: paginationAction === "NEXT_PAGE" ? markupList[markupList.length - 1]?.id : paginationAction === "PREVIOUS_PAGE" ? markupList[0]?.id : null,
      },
      supplierId: suppliers?.length ? suppliers : null,
      startDate: sDate,
      endDate: eDate,
      userType: "CUSTOMER",
    };
    setMarkupList([]);
    const response = await postData(markupListEndpoints[filter.service] + "/get-all", body);
    console.log("respono of getting markup list ", response);
    if (response.isSuccess) {
      setMarkupList(response.data.data);
    } else {
      setMarkupList([]);
      showToast({
        type: "error",
        message: response.message ?? "Something went wrong",
      });
    }
    setIsLoading(false);
  };

  const updatedStatus = async (id: number, status: string) => {
    const response = await patchData(`${markupListEndpoints[filter.service]}/${status}/${id}`, {})
    if (response.isSuccess) {
      showToast({
        type: "success",
        message: "Markup status updated successfully",
      })
      getMarkupList("INITIAL_PAGE");
    }
    else {
      showToast({
        type: "error",
        message: response.message ?? "Something went wrong",
      })
    }
    setIsLoadingIndex(undefined);
  }


  return {
    isLoading,
    isLoadingIndex,
    getMarkupList,
    setIsLoading,
    markupList,
    supplierList,
    serviceTypes,
    filter,
    setFilter,
    handleFilterChange,
    handleChangeStatus,
  };
}
