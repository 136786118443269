import { useContext } from "react";
import { ROUTES } from "../Routes/routes";
import { IUserAuthorities } from "../libs/data-access/types";
import { AppContext } from "../AppContext";
import { IRoute } from "../types";

/**
 * It takes a path, finds the route that matches that path, and returns the route
 * @param {string} path - string =&gt; the path that you want to match
 * @returns The return type is IRoute.
 */

const getToken = () => {
  let tkn = localStorage.getItem("token");
  return tkn;
};
const getIsLoggedIn = () => {
  let tkn = localStorage.getItem("isLoggedIn");
  return tkn;
};
const getRole = () => {
  let data = localStorage.getItem("role");
  let tkn = JSON.parse(data as string);
  return tkn;
};

const getUserAuthorities = () => {
  let tkn = localStorage.getItem("userAuthorities");
  let ParsAuth = JSON.parse(tkn as string);
  return ParsAuth || [];
};

export const getActiveRoute = () => {
  let tkn = localStorage.getItem('activeRoute');
  let ParsAuth = JSON.parse(tkn as string);
  return ParsAuth || [];
};

let userAuthoritiesSlugList: string[] = getUserAuthorities()?.map((e: any) => e.name);


const subMenuRecursion = (submenu: IRoute[]): IRoute[] => {
  return (
    submenu.map(sub =>
      sub?.submenu ?
        subMenuRecursion(sub?.submenu) :
        sub
    ).flat()
  )
};

export const matchRoute = (path: string): IRoute => {
  const route = ROUTES.ALL_ROUTES;
  const allRoutes = route.map(r => r.submenu ? subMenuRecursion(r.submenu) : r).flat(2);
  return allRoutes.find((route) => route.path === path) as IRoute;
};

/**
 * It checks if the user has the role to access the route
 * @param {string[] | undefined} roles - string[] | undefined =&gt; this is the roles that the user has
 * @param {string[]} routeRoles - ['department', 'district']
 * @returns returns a boolean.
 */
export const checkRouteBasedOnRoles = (
  roles: string[] | undefined,
  routeRoles: string[]
) => {
  function findCommonRoles(roles: string[] | undefined, routeRoles: string[]) {
    return roles?.some(item => routeRoles?.includes(item))
  };
  let isPresent = findCommonRoles(roles, routeRoles);
  return isPresent;
};


export const filterRoutesByRoles = (
  routes: IRoute[],
): IRoute[] => {
  let filteredRoutes = routes.map((route) => {
    if (checkRouteBasedOnRoles(getRole(), route?.rolesAllowed)) {
      if (route.submenu) {
        const filteredSubmenu = filterRoutesByRoles(route.submenu);
        return { ...route, submenu: filteredSubmenu };
      }
      return { ...route };
    } else if (route.submenu) {
      const filteredSubmenu = filterRoutesByRoles(route.submenu);
      if (filteredSubmenu.length > 0) {
        return { ...route, submenu: filteredSubmenu };
      }
    }
    return null;
  })
    .filter(route => route !== null) as IRoute[];

  return filteredRoutes;
};

export const checkRouteBasedOnSlugs = (
  RoutesSlug: string
) => {
  let isPresent = userAuthoritiesSlugList?.findIndex(item => item === RoutesSlug)
  if (isPresent === -1) {
    return false
  }
  else {
    userAuthoritiesSlugList.splice(isPresent, 1);
    return true
  }
};

export const checkRouteAuthority = (
  route: IRoute,
  authorities: IUserAuthorities[]
) => {
  function findCommonRoles(route: IRoute, authorities: IUserAuthorities[]) {
    return authorities?.some(authority => route.slug === authority.name);
  };
  let isPresent = findCommonRoles(route, authorities);
  return isPresent;
};



const subMenuRecursionForSlug = (submenu: IRoute[]): IRoute[] => {
  return (
    submenu.map(sub => {
      if (!sub?.submenu) {
        let value: IRoute = {} as IRoute;
        if (checkRouteBasedOnSlugs(sub?.slug)) {
          value = { ...sub };
        }
        else {
          value = {} as IRoute
        }
        return value
      }
      else {
        const subMenu = subMenuRecursionForSlug(sub?.submenu)?.filter((sub) => sub?.path)
        let value: IRoute | undefined = {} as IRoute;
        if (subMenu?.length > 0) {
          value = { ...sub, submenu: subMenu }
        }
        else {
          value = {} as IRoute
        }
        return value
      }
    }
    )
  )
};

export const filterRoutesByUserAuth = () => {
  let filteredRoutes = ROUTES.ALL_ROUTES.map((route) => {
    let CurrentRoute: IRoute = {} as IRoute;
    if (route?.slug === "home") {
      CurrentRoute = { ...route };
    }
    else if (!route.submenu) {
      let value: IRoute = {} as IRoute
      if (checkRouteBasedOnSlugs(route?.slug)) {
        value = { ...route };
      }
      else {
        value = {} as IRoute
      }
      CurrentRoute = value
    }
    else {
      const filteredSubmenu: IRoute[] = subMenuRecursionForSlug(route?.submenu)?.filter((sub) => sub?.path)
      let value: IRoute | undefined = {} as IRoute
       if(filteredSubmenu?.length > 0){
        value = { ...route, submenu: filteredSubmenu } }
        else{
          value = {} as IRoute
        }
        CurrentRoute = value
    }
    return CurrentRoute
  });
  return filteredRoutes?.filter((sub) => sub?.path);
};


