import { useState, useEffect, useRef } from 'react'
import { useApi } from '../../../../../libs/data-access/src/api/useApi';
import { TPaginationStatus } from '../../../../../types';
import { PAGINATION_ITEM_LIMIT } from '../../../../../libs/data-access/constant';
import { showToast } from '../../../../../utils/utility';
import { IBookingTypeList } from '../../../../../libs/data-access/types';
import { useReactToPrint } from "react-to-print";

const defaultNewBookingType : IBookingTypeList ={
    name: '',
    code: '',
    flightTicketSupplierId: 0
}
export  const useMarkupType = () => {
    
    const {
        postData,
        patchData,
        putData
    } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isSwitchLoadingIndex, setIsSwitchLoadingIndex] = useState<number | undefined>(undefined);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isOpenModalValue, setIsOpenModalValue] = useState<"New" | "Edit" | "Delete" | undefined >(undefined);
    const [searchValue, setSearchValue] = useState("");
    const [bookingTypeList, setBookingTypeList] = useState<IBookingTypeList[]>([]);
    const [selectedBookingType, setSelectedBookingType] = useState<IBookingTypeList | undefined>(undefined);
    const [newBookingType, setNewBookingType] = useState<IBookingTypeList>(defaultNewBookingType);

    const tableRef = useRef(null);


    useEffect(() => {
        if(searchValue?.length > 2 || searchValue === ""){
        getBookingTypeList("INITIAL_PAGE");
    }
    }, [searchValue])

    // HANDLER FUNCTION

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        documentTitle: "Booking Types"
      });


    // API FUNCTION
    const getBookingTypeList = async (paginationAction: TPaginationStatus, id?: number) => {
        setIsLoading(true);
        const response = await postData(`booking-type/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: paginationAction,
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id: id ?? null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setBookingTypeList(response.data.data)
        } else {
            setBookingTypeList([])
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            });
        };
    }


    const submitNewBookingType = async () => {
        setIsBtnLoading(true);
        const response = await postData(`booking-type/save`, newBookingType);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Booking type added successfully",
            })
            getBookingTypeList("INITIAL_PAGE");
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const UpdateStatus = async (id: number, status: boolean, index: number) => {
        setIsSwitchLoadingIndex(index);
        const response = await patchData(`booking-type/${status ? "active-all" :  "inActive-all" }?ids=${[id]}`,{});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Booking type status updated successfully",
            })
            getBookingTypeList("INITIAL_PAGE");
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsSwitchLoadingIndex(undefined);
    }

    const UpdateMarkup = async (id: number, name: string) => {
        const response = await putData(`booking-type/update`, {id: id, name: name});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Booking type updated successfully",
            })
            getBookingTypeList("INITIAL_PAGE");
            setSelectedBookingType(undefined);
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
    }

    const deleteMarkup = async (id: number) => {
       const isConfirm = window.confirm("Are you sure you want to delete this booking type?");
        if(!isConfirm) return;
        else{
            setIsBtnLoading(true);
            showToast({
                type:"success",
                title:"Ready to delete",
                message:"Add api to delete Markup Type"
            })

        }
        // setIsBtnLoading(true);
        // const response = await putData(`booking-type/delete`, {id: id});
        // if (response.isSuccess) {
        //     showToast({
        //         type: "success",
        //         message: "Booking type deleted successfully",
        //     })
        //     getBookingTypeList("INITIAL_PAGE");
        //     setSelectedBookingType(undefined);
        //     setIsOpenModalValue(undefined);
        // } else {
        //     showToast({
        //         type: "error",
        //         message: response.message ?? "Something went wrong",
        //     })
        // };
        // setIsBtnLoading(false);
    }

    return {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        bookingTypeList,
        searchValue,
        setSearchValue,
        selectedBookingType,
        setSelectedBookingType,
        newBookingType,
        setNewBookingType,
        submitNewBookingType,
        UpdateStatus,
        UpdateMarkup,
        deleteMarkup,
        tableRef,
        handlePrint,
        defaultNewBookingType
    }
}