import PageLayout from "PageLayout";
import useAffiliates from "./hook/useAffiliates";
import { MdError, MdManageAccounts, MdRefresh } from "react-icons/md";
import ExportDataActions from "libs/ui/src/Export/ExportDataActions";
import {
  EButton,
  ESelect,
  EPageTitle,
  ETextInput,
  ESectionDivider,
  ETable,
  ELabelView,
} from "libs/ui";
import { Space, Checkbox, Tooltip } from "@mantine/core";
import { BsArrowLeft, BsArrowRight, } from "react-icons/bs";
import ENumberInput from "libs/ui/src/ENumberInput/ENumberInput";
import EDropzone from "libs/ui/src/EDropZone/EDropzone";
import ESwitch from "libs/ui/src/Switch/ESwitch";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { EDialog } from "libs/ui/src/Dialog/EDialog";

export default function Affiliates() {
  const navigate = useNavigate()
  const {
    allCountryList,
    allCompanyTypes,
    allStateList,
    getAllStateListByCountryId,
    isModalOpen,
    setIsModalOpen,
    formAction,
    setFormAction,
    formStep,
    setFormStep,
    isBtnLoading,
    isLoadingIndex,
    handleClickEdit,
    affiliateForm,
    affiliateList,
    postNewAffiliate,
    updatedAffiliate,
    handleChangeStatus,
    isAgreeWithTerm,
    setIsAgreeWithTerm,
  } = useAffiliates();

  const formValues = affiliateForm.getValues();

  return (
    <form
    onSubmit={affiliateForm.onSubmit(
      () => {
        formAction === "add" ?
          postNewAffiliate() :
          updatedAffiliate()
      }
    )}
  >
    <PageLayout
     hideFooter={!!formAction}
     footer={
      <div className="flex justify-between items-center">
                {formStep < 2 ?
                  <>
                    <div className=" flex gap-3 items-center justify-end w-full">
                      {formAction === "add" ? <EButton onClick={() => affiliateForm.reset()} variant="outline" leftSection={<MdRefresh />} > Reset</EButton> : <></>}
                      <EButton onClick={() => {
                        affiliateForm.reset();
                        setIsModalOpen(false)
                        setFormAction("add");
                      }}
                        variant="outline"
                      > Cancel </EButton>
                      <EButton onClick={async () => {
                        const valid = await affiliateForm.validate();
                        console.log("Valid: ", valid);
                        if (valid.hasErrors) {
                          EDialog({
                            title: "Please fill all required fields",
                            children: Object.values(valid.errors).map(e=>
                              <p className="flex gap-2 items-center"> <MdError color="red"/> {e}</p>
                            ),
                            withoutIcon: true 
                          })
                        } else {
                          setFormStep(formStep + 1);
                        }
                        // affiliateForm.validate() && setFormStep(formStep + 1)
                      }
                      } rightSection={<BsArrowRight />} >Next</EButton>
                    </div>
                  </> :
                  <>
                    <Checkbox
                      label="I agree to the privacy policy and Terms & Conditions of PML Holidays."
                      checked={isAgreeWithTerm}
                      onChange={(e) => setIsAgreeWithTerm(e.currentTarget.checked)}
                    />
                    <div className=" flex gap-3 items-center justify-end">
                      <EButton variant="outline" leftSection={<BsArrowLeft />} onClick={() => setFormStep(formStep - 1)} > Back</EButton>
                      <EButton onClick={() => {
                        affiliateForm.reset();
                        setIsModalOpen(false)
                        setFormAction("add");
                      }}
                        variant="outline"
                      > Cancel </EButton>
                      <EButton
                        type="submit"
                        loading={isBtnLoading}
                        disabled={!isAgreeWithTerm}
                        onClick={()=> affiliateForm.onSubmit }
                      >{formAction === "add" ? "Save" : "Update"}</EButton>
                    </div>
                  </>
                }
              </div>
     }
    >
      {
        !isModalOpen ?
          <>
            <EPageTitle
              title="Affiliates"
              end={
                <div className="flex gap-2">
                  <EButton onClick={() => setIsModalOpen(!isModalOpen)}>{" Add New"}</EButton>
                </div>
              }
            />

            <Space h="md" />

            {/*TABLE DATA VIEW  */}

            <ETable >
              <thead>
                <tr>
                  <th>Company info</th>
                  <th>Name</th>
                  <th>Balance information group</th>
                  <th>Group</th>
                  <th>Country</th>
                  <th>State & city</th>
                  <th>Status</th>
                  <th className="flex justify-end">Manage</th>
                </tr>

              </thead>
              <tbody>
                {
                  affiliateList?.map((item, index) =>
                    <tr>
                      <td>
                        <div>
                          <ELabelView label="Name" value={item.companyName ?? ""} horizontal />
                          <ELabelView label="Website" value={item.website ?? ""} horizontal />
                          <ELabelView label="Company Type" value={item.companyType?.toLowerCase().replaceAll("_", " ")} horizontal />
                          <ELabelView label="Address" value={item.firstAddress ?? ""} horizontal />
                        </div>
                      </td>
                      <td>
                        <div>
                          <p className="font-semibold text-slate-600">{item.firstName + " " + (item?.lastName ?? "") ?? ""}</p>
                          <ELabelView label="Phone" value={item.contactNumber ?? ""} horizontal />
                          <ELabelView label="Mobile" value={item.mobileNumber ?? ""} horizontal />
                          <ELabelView label="Email" value={item.emailId ?? ""} horizontal />
                        </div>
                      </td>
                      <td>
                        <div>
                          <ELabelView label="Remaining Credits" value={item.remainingCredit ? item.remainingCredit + "" : "N/A"} horizontal />
                          <ELabelView label="Credit Limit" value={item.creditLimit ? item.creditLimit + "" : "N/A"} horizontal />
                          <ELabelView label="Validity" value={item.validity ? item.validity + "" : "N/A"} horizontal />
                        </div>
                      </td>
                      <td>
                        {item.affiliateGroupName ?? "N/A"}
                      </td>
                      <td>
                        {item.countryName ?? ""}
                      </td>
                      <td>
                        <p>{item.stateName ?? ""}</p>
                        <p>{item.city ?? ""}</p>
                      </td>
                      <td>
                        <ESwitch
                          checked={item.affiliateStatus}
                          label={item.affiliateStatus ? "Active" : "In-active"}
                          loading={isLoadingIndex === index}
                          onChange={(e) => handleChangeStatus(e, index)} />
                      </td>
                      <td>
                        <div className="flex flex-col gap-3 items-center">
                          <Tooltip label={"Edit"} >
                            <div>
                              <FiEdit size={20} className="text-green-500 cursor-pointer" onClick={() => handleClickEdit(item)} />
                            </div>
                          </Tooltip>
                          <Tooltip label={"Manage"} >
                            <div>
                              <MdManageAccounts size={26} className="text-sky-500 cursor-pointer" onClick={() => navigate(`/affiliate/manage-affiliates`, { state: item })} />
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </ETable>
          </>
          :
          <>
            <EPageTitle title={formAction === "add" ? "Add New Affiliate" : "Edit Affiliate"} />
           
              <div className="grid grid-cols-3 gap-4 px-8 pb-0 pt-8 min-h-screen">
                {formStep === 1 ? <>
                  <ETextInput
                    label="Company Name"
                    placeholder="Enter Name"
                    key={affiliateForm.key('companyName')}
                    {...affiliateForm.getInputProps('companyName')}
                  />
                  <ETextInput
                    label="Website"
                    placeholder="Enter Website Name"
                    key={affiliateForm.key('website')}
                    {...affiliateForm.getInputProps('website')}
                  />
                  <ETextInput
                    label="Address 1"
                    placeholder="Enter Address"
                    key={affiliateForm.key('firstAddress')}
                    {...affiliateForm.getInputProps('firstAddress')}
                  />
                  <ETextInput
                    label="Address 2"
                    placeholder="Enter Address"
                    key={affiliateForm.key('secondAddress')}
                    {...affiliateForm.getInputProps('secondAddress')}
                  />
                  <ESelect
                    label="Country"
                    placeholder="Enter Country"
                    data={allCountryList}
                    key={affiliateForm.key('countryId')}
                    {...affiliateForm.getInputProps('countryId')}
                    onChange={(e) => {
                      if (e) {
                        getAllStateListByCountryId(+e)
                      }
                      affiliateForm.setFieldValue('countryId', e ?? "")
                      affiliateForm.setFieldValue('stateId', '')
                      affiliateForm.setFieldValue('city', '')
                    }}
                  />
                  <ESelect
                    label="State"
                    placeholder="Select"
                    data={allStateList}
                    key={affiliateForm.key('stateId')}
                    {...affiliateForm.getInputProps('stateId')}
                    onChange={(e) => affiliateForm.setFieldValue('stateId', e ?? "")}
                  />
                  <ETextInput
                    label="City"
                    placeholder="Enter City"
                    key={affiliateForm.key('city')}
                    {...affiliateForm.getInputProps('city')}
                  />
                  <ENumberInput
                    label="Pin Code"
                    placeholder="Enter Pin Code"
                    key={affiliateForm.key('pinCode')}
                    {...affiliateForm.getInputProps('pinCode')}
                  />
                  <div></div>
                  <div className="col-span-3">
                    <ESectionDivider title="Company Type" />
                  </div>
                  <ESelect
                    label="Company Type"
                    placeholder="Enter Company Type"
                    data={allCompanyTypes?.length ? allCompanyTypes : [{ label: "Other", value: "Other" }]}
                    key={affiliateForm.key('companyType')}
                    {...affiliateForm.getInputProps('companyType')}
                  />
                  <div></div>
                  <div></div>
                  <div className="col-span-3">
                    <ESectionDivider title="Basic Settings" />
                  </div>
                  <ETextInput
                    label="PAN Number"
                    placeholder="Enter PAN Number"
                    key={affiliateForm.key('panNumber')}
                    {...affiliateForm.getInputProps('panNumber')}
                  />
                  <ETextInput
                    label="TDS Exemption Number"
                    placeholder="Enter TDS Exemption Number"
                    key={affiliateForm.key('tdsExemptionNumber')}
                    {...affiliateForm.getInputProps('tdsExemptionNumber')}
                  />
                  <ENumberInput
                    label="TDS Exemption Limit"
                    placeholder="Enter TDS Exemption Limit"
                    key={affiliateForm.key('tdsExemptionLimit')}
                    {...affiliateForm.getInputProps('tdsExemptionLimit')}
                  />
                  <ETextInput
                    label="TDS Exemption Rate"
                    placeholder="Enter TDS Exemption Rate"
                    key={affiliateForm.key('tdsExemptionRate')}
                    {...affiliateForm.getInputProps('tdsExemptionRate')}
                  />
                  <ETextInput
                    label="TDS Exemption Code"
                    placeholder="Enter TDS Exemption Code"
                    key={affiliateForm.key('tdsExemptionCode')}
                    {...affiliateForm.getInputProps('tdsExemptionCode')}
                  />
                  <ETextInput
                    label="XL Code"
                    placeholder="Enter XL Code"
                    key={affiliateForm.key('xlCode')}
                    {...affiliateForm.getInputProps('xlCode')}
                  />
                  <ENumberInput
                    label="Look to Book Ratio Search"
                    placeholder="Enter Look to Book Ratio Search"
                    key={affiliateForm.key('lookToBookRatioSearch')}
                    {...affiliateForm.getInputProps('lookToBookRatioSearch')}
                  />
                  <ENumberInput
                    label="Look to Book Ratio Book"
                    placeholder="Enter Look to Book Ratio Book"
                    key={affiliateForm.key('lookToBookRatioBook')}
                    {...affiliateForm.getInputProps('lookToBookRatioBook')}
                  />
                  <div></div>
                  <div className="col-span-3 flex gap-8">
                    <Checkbox
                      label="Is Logo Advice"
                      key={affiliateForm.key('isLogoAdvice')}
                      {...affiliateForm.getInputProps('isLogoAdvice', { type: 'checkbox' })}
                    />
                    <Checkbox
                      label="Is Corporate"
                      key={affiliateForm.key('isCorporate')}
                      {...affiliateForm.getInputProps('isCorporate', { type: 'checkbox' })}
                    />
                    <Checkbox
                      label="Is Supplier Promo Code"
                      key={affiliateForm.key('isSupplierPromoCode')}
                      {...affiliateForm.getInputProps('isSupplierPromoCode', { type: 'checkbox' })}
                    />
                    <Checkbox
                      label="PLM Affiliation"
                      key={affiliateForm.key('pmlAffiliate')}
                      {...affiliateForm.getInputProps('pmlAffiliate', { type: 'checkbox' })}
                    />
                  </div>
                  <div className="col-span-3">
                    <ESectionDivider title="Contact Details" />
                  </div>
                  <ESelect
                    label="Title"
                    placeholder="Enter Title"
                    data={[{ label: "Mr", value: "MR" }, { label: "Miss", value: "MISS" }, { label: "Mrs", value: "MRS" }]}
                    key={affiliateForm.key('title')}
                    {...affiliateForm.getInputProps('title')}
                  />
                  <ETextInput
                    label="First Name"
                    placeholder="Enter First Name"
                    key={affiliateForm.key('firstName')}
                    {...affiliateForm.getInputProps('firstName')}
                  />
                  <ETextInput
                    label="Last Name"
                    placeholder="Enter Last Name"
                    key={affiliateForm.key('lastName')}
                    {...affiliateForm.getInputProps('lastName')}
                  />
                  <ETextInput
                    label="Email"
                    placeholder="Enter Email"
                    key={affiliateForm.key('email')}
                    {...affiliateForm.getInputProps('email')}
                  />
                  <ETextInput
                    label="Contact Number"
                    placeholder="Enter Contact Number"
                    key={affiliateForm.key('contactNumber')}
                    {...affiliateForm.getInputProps('contactNumber')}
                  />
                  <ETextInput
                    label="Mobile Number"
                    placeholder="Enter Mobile Number"
                    key={affiliateForm.key('mobileNumber')}
                    {...affiliateForm.getInputProps('mobileNumber')}
                  />
                  
                </>
                  :
                  // DOCUMENT DETAILS ---------------------
                  <>
                    <div className="col-span-3">
                      <ESectionDivider title="Documents" />
                    </div>
                    <EDropzone
                      label="Aadhaar Card ID"
                      placeholder="Enter Aadhaar Card ID"
                      key={affiliateForm.key('aadhaarCardId')}
                      filesId={formValues.aadhaarCardId ? [formValues.aadhaarCardId] : []}
                      {...affiliateForm.getInputProps('aadhaarCardId')}
                      onChange={(value) => affiliateForm.setFieldValue('aadhaarCardId', value[0])}
                    />
                    <EDropzone
                      label="PAN Card ID"
                      placeholder="Enter PAN Card ID"
                      key={affiliateForm.key('panCardId')}
                      filesId={formValues.panCardId ? [formValues.panCardId] : []}
                      {...affiliateForm.getInputProps('panCardId')}
                      onChange={(value) => affiliateForm.setFieldValue('panCardId', value[0])}
                    />
                    <div></div>
                    <EDropzone
                      label="GST ID"
                      key={affiliateForm.key('gstId')}
                      filesId={formValues.gstId ? [formValues.gstId] : []}
                      {...affiliateForm.getInputProps('gstId')}
                      onChange={(value) => affiliateForm.setFieldValue('gstId', value[0])}
                    />
                    <EDropzone
                      label="Driving License"
                      key={affiliateForm.key('drivingLicenseId')}
                      filesId={formValues.drivingLicenseId ? [formValues.drivingLicenseId] : []}
                      {...affiliateForm.getInputProps('drivingLicenseId')}
                      onChange={(value) => affiliateForm.setFieldValue('drivingLicenseId', value[0])}
                    />
                    <div></div>
                    <ETextInput
                    label="GST Number"
                    placeholder="Enter GST Number"
                    key={affiliateForm.key('gstNumber')}
                    {...affiliateForm.getInputProps('gstNumber')}
                  />
                   <div></div>
                   <div></div>
                    <div className="col-span-3">
                      <ESectionDivider title="Company Logo" />
                    </div>
                    <EDropzone
                      label="Company Logo ID"
                      key={affiliateForm.key('companyLogoId')}
                      filesId={formValues.companyLogoId ? [formValues.companyLogoId] : []}
                      {...affiliateForm.getInputProps('companyLogoId')}
                      onChange={(value) => affiliateForm.setFieldValue('companyLogoId', value[0])}
                    />

                  </>}
              </div>
              <Space h={"md"} />
              <Space h={"md"} />

              <div className="sticky bottom-0 flex justify-between items-center bg-neutral-50 border-t border-slate-500 py-4 px-10 ">
                {formStep < 2 ?
                  <>
                    <div className=" flex gap-3 items-center justify-end w-full">
                      {formAction === "add" ? <EButton onClick={() => affiliateForm.reset()} variant="outline" leftSection={<MdRefresh />} > Reset</EButton> : <></>}
                      <EButton onClick={() => {
                        affiliateForm.reset();
                        setIsModalOpen(false)
                        setFormAction("add");
                      }}
                        variant="outline"
                      > Cancel </EButton>
                      <EButton onClick={async () => {
                        const valid = await affiliateForm.validate();
                        console.log("Valid: ", valid);
                        if (valid.hasErrors) {
                          EDialog({
                            title: "Please fill all required fields",
                            children: Object.values(valid.errors).map(e=>
                              <p className="flex gap-2 items-center"> <MdError color="red"/> {e}</p>
                            ),
                            withoutIcon: true 
                          })
                        } else {
                          setFormStep(formStep + 1);
                        }
                        // affiliateForm.validate() && setFormStep(formStep + 1)
                      }
                      } rightSection={<BsArrowRight />} >Next</EButton>
                    </div>
                  </> :
                  <>
                    <Checkbox
                      label="I agree to the privacy policy and Terms & Conditions of PML Holidays."
                      checked={isAgreeWithTerm}
                      onChange={(e) => setIsAgreeWithTerm(e.currentTarget.checked)}
                    />
                    <div className=" flex gap-3 items-center justify-end">
                      <EButton variant="outline" leftSection={<BsArrowLeft />} onClick={() => setFormStep(formStep - 1)} > Back</EButton>
                      <EButton onClick={() => {
                        affiliateForm.reset();
                        setIsModalOpen(false)
                        setFormAction("add");
                      }}
                        variant="outline"
                      > Cancel </EButton>
                      <EButton
                        type="submit"
                        loading={isBtnLoading}
                        disabled={!isAgreeWithTerm}
                      >{formAction === "add" ? "Save" : "Update"}</EButton>
                    </div>
                  </>
                }
              </div>
          </>
      }


    </PageLayout>
      </form>
  );
}
