import { useState, useEffect, useRef } from 'react'
import { useApi } from '../../../../../libs/data-access/src/api/useApi';
import { INewMarkupFee, TPaginationStatus } from '../../../../../types';
import { PAGINATION_ITEM_LIMIT } from '../../../../../libs/data-access/constant';
import { showToast } from '../../../../../utils/utility';
import { IMarkupFee } from '../../../../../libs/data-access/types';
import { useReactToPrint } from "react-to-print";
export  const useMarkupFee = () => {
    
    const {
        postData,
        patchData,
        putData
    } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isSwitchLoadingIndex, setIsSwitchLoadingIndex] = useState<number>();
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isOpenModalValue, setIsOpenModalValue] = useState<"New" | "Edit" | "Delete" | undefined >(undefined);
    const [searchValue, setSearchValue] = useState("");
    const [markupFeeList, setMarkupFeeList] = useState<IMarkupFee[]>([]);
    const [selectedMarkupFee, setSelectedMarkupFee] = useState<IMarkupFee | undefined>(undefined);
    const [newMarkupFee, setNewMarkupFee] = useState<INewMarkupFee>({} as INewMarkupFee );
    const [feeTypeList, setFeeTypeList] = useState<any[]>([]);

    useEffect(() => {
        if(searchValue?.length > 2 || searchValue === ""){
        getMarkupFeeList("INITIAL_PAGE");
        getFeeTypeList();
    }
    }, [searchValue])

    // API FUNCTION

    const getFeeTypeList = async () =>{
        setIsLoading(true);
        const response = await postData(`fee-type/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: "INITIAL_PAGE",
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id:  null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setFeeTypeList(response.data.data)
        } else {
            setFeeTypeList([])
           console.log("Error while getting fee type list: ", response.message);
        };
    }
    
    const getMarkupFeeList = async (paginationAction: TPaginationStatus, id?: number) => {
        setIsLoading(true);
        const response = await postData(`travel-way-form-fee-activity/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: paginationAction,
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id: id ? id : paginationAction === "INITIAL_PAGE" ? null : paginationAction === "NEXT_PAGE" ? markupFeeList[PAGINATION_ITEM_LIMIT - 1]?.id : paginationAction === "PREVIOUS_PAGE" ? markupFeeList[0]?.id : null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setMarkupFeeList(response.data.data)
        } else {
            setMarkupFeeList([])
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            });
        };
    }


    const submitNewMarkupFee = async () => {
        setIsBtnLoading(true);
        const response = await postData(`travel-way-form-fee-activity/save`, newMarkupFee);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Markup fee added successfully",
            })
            getMarkupFeeList("INITIAL_PAGE");
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const UpdateStatus = async (id: number, status: boolean, index: number) => {
        setIsSwitchLoadingIndex(index);
        const response = await patchData(`travel-way-form-fee-activity/${status ? "active-all" :  "inActive-all" }?ids=${[id]}`,{});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Markup fee status updated successfully",
            })
            getMarkupFeeList("INITIAL_PAGE");
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsSwitchLoadingIndex(undefined);
    }

    const UpdateMarkupFee = async (id: number, name: string) => {
        setIsBtnLoading(true);
        const response = await putData(`travel-way-form-fee-activity/update`, selectedMarkupFee);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Markup fee updated successfully",
            })
            getMarkupFeeList("INITIAL_PAGE");
            setSelectedMarkupFee(undefined);
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const deleteMarkupFee = async (id: number) => {
       const isConfirm = window.confirm("Are you sure you want to delete this Markup fee?");
        if(!isConfirm) return;
        else{
            setIsBtnLoading(true);
            showToast({
                type:"success",
                title:"Ready to delete",
                message:"Add api to delete Markup fee"
            })

        }
        // setIsBtnLoading(true);
        // const response = await putData(`travel-way-form-fee-activity/delete`, {id: id});
        // if (response.isSuccess) {
        //     showToast({
        //         type: "success",
        //         message: "Markup fee deleted successfully",
        //     })
        //     getMarkupFeeList("INITIAL_PAGE");
        //     setSelectedMarkupFee(undefined);
        // } else {
        //     showToast({
        //         type: "error",
        //         message: response.message ?? "Something went wrong",
        //     })
        // };
        // setIsBtnLoading(false);
    }

    return {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        markupFeeList,
        searchValue,
        setSearchValue,
        selectedMarkupFee,
        setSelectedMarkupFee,
        newMarkupFee,
        setNewMarkupFee,
        feeTypeList,
        submitNewMarkupFee,
        UpdateStatus,
        UpdateMarkupFee,
        deleteMarkupFee,
        getMarkupFeeList
    }
}