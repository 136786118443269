import React from "react";

interface IProps {
  title: string;
  classes?: string;
  titleStyle?: string;
  end?: React.ReactNode;
  hidden?: boolean;
}

export function ESectionDivider({
  title,
  classes,
  titleStyle,
  end,
  hidden,
}: IProps) {
  return (
    <div
      className={` flex items-center justify-between mb-4 ${
        classes && classes
      } ${hidden && "hidden"}
      }`}
    >
      <div
        className={` capitalize ${
          titleStyle ? titleStyle : "text-xl font-bold mr-4"
        }`}
      >
        {title}
      </div>
      <div className="flex-1 h-[1px] bg-slate-200"></div>
      {end && <div className="pl-3">{end}</div>}
    </div>
  );
}
