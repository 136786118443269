import { Select, SelectProps } from "@mantine/core";

export function ESelect({ ...props }: SelectProps) {
  return (
    <Select
      radius={"md"}
      size={"md"}
      {...props}
      comboboxProps={{
        transitionProps: { transition: "fade-down", duration: 200 },
      }}
    />
  );
}
