/* eslint-disable react-hooks/exhaustive-deps */
import useMaster from "hook/useMaster";
import { PAGINATION_ITEM_LIMIT } from "libs/data-access/constant";
import { useApi } from "libs/data-access/src/api/useApi";
import { useEffect, useState } from "react";
import { IAffiliateGroupList, INewAffiliateGroup, TPaginationStatus } from "types";
import { showToast } from "utils/utility";

const DefaultGroup: INewAffiliateGroup = {
  name: "",
  affiliateUsers: []
}

const useManageGroupB2B = () => {
  const {
    affiliateUsers,
    getAffiliateUsers
  } = useMaster();
  const {
    postData,
    putData,
    patchData
  } = useApi();

  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingIndex, setIsLoadingIndex] = useState<number | undefined>(undefined);

  const [modalType, setModalType] = useState<"CREATE" | "UPDATE">();

  const [groupList, setGroupList] = useState<IAffiliateGroupList[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<IAffiliateGroupList>();
  const [newGroup, setNewGroup] = useState<INewAffiliateGroup>(DefaultGroup);

  useEffect(() => {
    getAffiliateUsers();
    getGroupList("INITIAL_PAGE");
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      setNewGroup({
        name: selectedGroup.name,
        affiliateUsers: selectedGroup.affiliateUsers?.map(e=>e?.id)
      });
    }
    else{
      setNewGroup(DefaultGroup);
    }
  }, [selectedGroup]);

  const getGroupList = async (paginationAction: TPaginationStatus) => {
    const body = {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        id: null,
        sortingOrder: "ASC",
      },
      supplierId: 0,
    };
    const response = await postData(`affiliate-group/get-all`, body);
    if (response.isSuccess) {
      setGroupList(response.data.data);
    }
    console.log("response of getting all group list", response);
  };

  const createNewGroup = async () => {
    setLoading(true);
    const response = await postData(`affiliate-group/save`, newGroup);
    if (response.isSuccess) {
      showToast({
        type: "success",
        message: "Group created successfully",
      });
    }
    setLoading(false);
    setModalType(undefined);
  };

  const activeInactiveGroup = async (status: boolean, id: number, index: number) => {
    setIsLoadingIndex(index);
    const endpoint = status
      ? "affiliate-group/active-all"
      : "affiliate-group/inActive-all";
    const response = await patchData(`${endpoint}?ids=${id}`, {});
    if (response.isSuccess) {
      getGroupList("INITIAL_PAGE");
    }
    console.log("response of activeInactiveGroup", response);
    setIsLoadingIndex(undefined);
  };

  const updateAffiliates = async () => {
    setLoading(true);
   

    const response = await putData(
      `affiliate-group/update/${selectedGroup?.id}`,
      {
        name: newGroup.name,
        userIds: newGroup.affiliateUsers  
      }
    );
    if (response.isSuccess) {
      getGroupList("INITIAL_PAGE");
      showToast({
        type: "success",
        message: "Group updated successfully",
      });
    }
    setLoading(false);
    setSelectedGroup(undefined)
    setModalType(undefined);
  };

  return {
    loading,
    isLoadingIndex,
    groupList,
    modalType,
    affiliateUsers,
    setModalType,
    createNewGroup,
    updateAffiliates,
    activeInactiveGroup,
    DefaultGroup,
    newGroup,
    setNewGroup,
    selectedGroup,
    setSelectedGroup,

  };
};

export default useManageGroupB2B;
