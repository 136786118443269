import PageLayout from "PageLayout";
import { IoMdAdd } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { EPageTitle, ESelect, EButton, EDateInput, ETable } from "libs/ui";
import { Grid, GridCol, Table, Tooltip } from "@mantine/core";
import useViewMarkupB2B from "./hook/useViewMarkupB2B";
import ETableTooltipData from "libs/ui/src/table/ETableTooltipData";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function ViewMarkupB2B() {
  const {
    loading,
    isLoadingIndex,
    markupList,
    groupTypes,
    serviceTypes,
    filterOption,
    getMarkupList,
    setFilterOption,
    handleFilterOption,
    updateMarkupB2BStatus
  } = useViewMarkupB2B();

  const navigate  = useNavigate();
  const { service, group, startDate, endDate } = filterOption;
  
  return (
    <PageLayout>

      <EPageTitle
        title="View Markup B2B"
        end={
          <EButton
            rightSection={<IoMdAdd />}
            onClick={() => {
              navigate("/group-settings/create-markup-b2b");
            }}
          >
            Create New
          </EButton>
        }
      />

      {/* Filter */}
      <div className="filter-container"> 
      <Grid>
        <GridCol span={3}>
          <ESelect
            label="Services"
            placeholder="Select"
            data={serviceTypes}
            value={service || ""}
            onChange={(value) => {
              handleFilterOption("service", value || "");
            }}
          />
        </GridCol>
        <GridCol span={3}>
          <ESelect
            label="Group"
            data={groupTypes}
            placeholder="Select"
            value={group}
            onChange={(value) => {
              handleFilterOption("group", value || "");
            }}
          />
        </GridCol>
        <GridCol span={3}>
          <EDateInput
            label="Valid From"
            placeholder="Start Date"
            valueFormat="YYYY-MM-DD"
            value={startDate}
            onChange={(value) => {
              handleFilterOption("startDate", value);
            }}
          />
        </GridCol>
        <GridCol span={3}>
          <EDateInput
            label="Valid Till"
            placeholder="End Date"
            valueFormat="YYYY-MM-DD"
            value={endDate}
            onChange={(value) => {
              handleFilterOption("endDate", value);
            }}
          />
        </GridCol>
      </Grid>
      <div className="flex gap-2 my-3 w-full justify-end">
        <EButton
          onClick={() => {
            setFilterOption({
              service: "FLIGHT",
              group: null,
              startDate: null,
              endDate: null,
            });
          }}
          color="gray"
        >
          Cancel
        </EButton>
        <EButton
          onClick={() => {
            getMarkupList("INITIAL_PAGE");
          }}
        >
          Search
        </EButton>
      </div>
      </div>
    
     <div className="mt-5"> </div>
    
    {/* Table */}
        <ETable
         loading={loading}
         pagination={true}
         onChangePagination={(e) => getMarkupList(e)}
        >
          <thead className="whitespace-nowrap">
            <tr>
              <th>Group</th>
              <th>Start date</th>
              <th>End date</th>
              <th hidden={service === "BUS"}>Markup type</th>
              <th hidden={service === "BUS"}>Apply for</th>
              <th>Price from</th>
              <th>Price to</th>
              <th>Fee</th>
              <th>Origin</th>
              <th>Destination</th>
              <th>Suppliers</th>
              <th hidden={service !== "FLIGHT"}>Airlines</th>
              <th>Status</th>
              <th className="flex justify-end w-full whitespace-normal">Manage</th>
            </tr>

          </thead>
          <tbody>
            {
            markupList?.map((item, index) => {
              const {
                status,
                startDate,
                endDate,
                bookingType,
                affiliateGroup,
                chargeApplyForType,
                minimumGrossPrice,
                maximumGrossPrice,
                countryResponses,
                placeResponses,
                supplierResponses,
                travelFormFeeResponses,
                originCountryResponses,
                flightAirlineResponses,
                destinationCountryResponses,
                hotelBookingSupplierResponses,
                travelServiceFormFeeResponses,
              } = item;
              const origins = {
                FLIGHT: originCountryResponses,
                HOTEL: countryResponses,
                BUS: [],
              };
              const destinations = {
                FLIGHT: destinationCountryResponses,
                HOTEL: placeResponses,
                BUS: [],
              };
              const suppliers = {
                FLIGHT: supplierResponses,
                HOTEL: hotelBookingSupplierResponses,
                BUS: [],
              };

              const travelFees = {
                FLIGHT: travelServiceFormFeeResponses,
                HOTEL: travelFormFeeResponses,
                BUS: [],
              };
              // const getFeeAmount = (id: any) => {
              //   const amount =
              //     travelFees[filterOption.service]?.find(
              //       (fee: any) => fee?.id === id
              //     ) || "";

              //   const { feeType } = amount || {};
              //   return feeType === "FIXED"
              //     ? amount?.feeAmount
              //     : `${amount.feeAmount}%`;
              // };


              return (
                <tr key={index}>
                  <td>{affiliateGroup?.name || ""}</td>
                  <td>{startDate}</td>
                  <td>{endDate}</td>
                  <td hidden={service === "BUS"}>
                    {bookingType?.name || ""}
                  </td>
                  <td hidden={service === "BUS"}>
                    {chargeApplyForType?.name || ""}
                  </td>
                  <td>{minimumGrossPrice}</td>
                  <td>{maximumGrossPrice}</td>
                  <td>
                    <div className="flex flex-col">
                     { 
                      travelFees[filterOption.service]?.map((e:any) => (
                        <p>{e?.name +": " + e?.feeAmount}{ e?.feeType === "PERCENTAGE" ? " %" : ""}</p>
                      ))
                     }
                    </div>
                  </td>
                  <td>{ ETableTooltipData(origins[service]?.map((e:any) => e?.name) ?? [])}</td>
                  <td>
                    {ETableTooltipData(destinations[service]?.map((e:any) => e?.name) ?? [])}
                  </td>
                  <td>{ETableTooltipData(suppliers[service]?.map((e:any) => e?.name) ?? [])}</td>
                  <td hidden={service !== "FLIGHT"}>
                    {ETableTooltipData(flightAirlineResponses?.map((e:any) => e?.name) ?? [])}
                  </td>
                  <td>{
                  <ESwitch
                      label={status ? "Active" : "In-Active"} 
                      checked={!!status}
                      loading={isLoadingIndex === index} 
                      onChange={(e) => {
                        updateMarkupB2BStatus(item?.id, e, index);
                      }}     
                      withConfirmation             
                  />
                  }</td>
                  <td >
                    <div className="flex justify-end h-10">
                      <Tooltip label="Edit" position="top">
                        <div
                        className="cursor-pointer"
                        onClick={() => { 
                          navigate("/group-settings/create-markup-b2b",
                            { 
                             replace: false,
                             state:{service, item: item}
                          });
                        }}>
                          <FaRegEdit
                            size={23}
                            className="cursor-pointer text-green-400  h-full self-center"
                            
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ETable>
    </PageLayout>
  );
}
