import useMaster from "hook/useMaster";
import { PAGINATION_ITEM_LIMIT } from "libs/data-access/constant";
import { useApi } from "libs/data-access/src/api/useApi";
import { useEffect, useState } from "react";
import { TPaginationStatus } from "types";
import { showToast } from "utils/utility";

interface IManageSubChargeTypes {
  id: number;
  name: string;
  status: boolean
  additionalChargeTypeId: string;
  additionalChargeTypeName: string;
}
const useManaeSubChargeTypes = () => {
  const { postData, patchData, putData } = useApi();
  const [additionalChargeTypesList, setAdditionalChargeTypesList] = useState<
    Array<any>[]
  >([]);
  const { additionalChargeTypes, getAdditionalChargeTyps } = useMaster();
  const [loading, setloading] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [subChargeName, setSubChargeName] = useState<string>("");
  const [chargeType, setChargeType] = useState<string | null>("");
  const [isOpenAddNewModal, setIsOpenAddNewModal] = useState<boolean>(false);
  const [selectedSubCharge, setSelectedSubCharge] = useState<IManageSubChargeTypes | undefined>(undefined);

  useEffect(() => {
    getAdditionalChargeTyps();
    getAdditionalSubChargeTypsList("INITIAL_PAGE");
  }, []);

  const getAdditionalSubChargeTypsList = async (
    paginationAction: TPaginationStatus
  ) => {
    const body = {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        id: null,
        sortingOrder: "ASC",
      },
    };
    const response = await postData("additional-sub-charge-type/get-all", body);
    if (response.isSuccess && response?.data?.data?.length) {
      setAdditionalChargeTypesList(response.data.data);
    }
    console.log("response of getting sub charge type list", response);
  };

  const createSubChargeType = async () => {
    setIsBtnLoading(true);
    const body = {
      name: subChargeName,
      additionalChargeTypeId: chargeType,
    };
    const response = await postData("additional-sub-charge-type/save", body);
    if (response.isSuccess) {
      showToast({
        type: "success",
        message: "Sub Charge Type created successfully",
      });
      getAdditionalSubChargeTypsList("INITIAL_PAGE");
      setSubChargeName("");
      setChargeType("");
      setIsOpenAddNewModal(false);
    }
    setIsBtnLoading(false);
  };
  
  const updateSubChargeType = async () => {
    setIsBtnLoading(true);
    if(selectedSubCharge) {
    const response = await putData(`additional-sub-charge-type/update/${selectedSubCharge?.id}`, {
      "name": selectedSubCharge?.name,
      "additionalChargeTypeId": +selectedSubCharge?.additionalChargeTypeId
    });
    if (response.isSuccess) {
      showToast({
        type: "success",
        message: "Sub Charge Type updated successfully",
      });
      getAdditionalSubChargeTypsList("INITIAL_PAGE");
      setSubChargeName("");
      setChargeType("");
      setIsOpenAddNewModal(false);
      setSelectedSubCharge(undefined);
    }
  }
  setIsBtnLoading(false);
  };

  const activeInActiveSubChargeType = async (
    status: boolean,
    subChargeTypeId: string
  ) => {
    const endpoint = (status
      ? "additional-sub-charge-type/active-all"
      : "additional-sub-charge-type/inActive-all");
    const response = await patchData(`${endpoint}?ids=${subChargeTypeId}`, {});
    if (response.isSuccess) {
      getAdditionalSubChargeTypsList("INITIAL_PAGE");
    }
    console.log("response of activeInactive charge type", response);
  };

  return {
    loading,
    isBtnLoading,
    chargeType,
    subChargeName,
    isOpenAddNewModal,
    additionalChargeTypes,
    additionalChargeTypesList,
    selectedSubCharge,
    setloading,
    setChargeType,
    setSubChargeName,
    createSubChargeType,
    setIsOpenAddNewModal,
    getAdditionalSubChargeTypsList,
    activeInActiveSubChargeType,
    setSelectedSubCharge,
    updateSubChargeType
  };
};

export default useManaeSubChargeTypes;
