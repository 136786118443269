import React, { useEffect } from 'react'
import useOnlineBooking from './hooks/useOnlineBooking'
import PageLayout from 'PageLayout';
import { EPageTitle, ETable } from 'libs/ui';
import BookingFilter from './Components/BookingFilter';
import { FaRegEdit } from 'react-icons/fa';
import  EBadge  from 'libs/ui/src/EBadge/EBadge';

export default function OnlineHotelsBooking() {
    const {
        loading,
        isBtnLoading,
        handleChangeFilter,
        hotelBookingList,
        filterState,
        setFilterState,
        handleSubmitFilter,
        getHotelBookingList
    } = useOnlineBooking();

    useEffect(() => {
      getHotelBookingList();
    },[]);

  return (
    <PageLayout>
        <EPageTitle title="Hotel Booking List" />
        {/* <BookingFilter/> */}
        <BookingFilter
        filter={filterState}
        setFilter={setFilterState}
        onChange={(key, value) => handleChangeFilter(key, value as string)}
        onSubmit={() => handleSubmitFilter()}
        onReset={() => handleChangeFilter("default", "")}
      />
      
       <div className='mt-5'></div>
      <ETable >
        <thead>
          <tr>
            <th>User Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Invoice</th>
            <th>Ref. No.</th>
            <th>PNR. No.</th>
            <th>Hotel Name</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {hotelBookingList?.map((item) => (
            <tr key={item.id}>
              <td>{item.user?.name?.firstName + (item.user?.name?.lastName ? item.user?.name?.lastName : "")}</td>
              <td>{item.user?.emailId}</td>
              <td>{item?.user?.phoneNumber}</td>
              <td>{item?.invoiceNo}</td>
              <td>{item?.referenceNo}</td>
              <td>{item?.pnr}</td>
              <td>{item?.paymentOrder?.hotel?.name}</td>
              <td>{item?.paymentOrder?.hotelTotalAmount}</td>
              <td>{
               <EBadge
                 label={item?.bookingStatus}
               />
              }</td>
              </tr> ))}
              </tbody>
      </ETable>

    </PageLayout>
  )
}
