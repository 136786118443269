import React from "react";
import "./EBadge.scss";
import { Badge } from "@mantine/core";

interface IProps {
  label: string;
  color?: string; // Tailwind color, eg: green-600
}

const EBadge = (props: IProps) => {

  const color :{ [key:string] : { text: string , bg: string}} = {
    "pending": {
      text: "text-white",
      bg: "bg-orange-500"
    },
    "approved": {
      text: "text-white",
      bg: "bg-green-500"
    },
    "declined": {
      text: "text-white",
      bg: "bg-red-500"
    },
    "paid": {
      text: "text-white",
      bg: "bg-blue-500"
    },
    "unpaid": {
      text: "text-white",
      bg: "bg-red-500"
    },
    "active": {
      text: "text-white",
      bg: "bg-blue-500"
    },
    "inactive": {
      text: "text-white",
      bg: "bg-red-500"
    },
    "success": {
      text: "text-white",
      bg: "bg-green-500"
    },
    "warning": {
      text: "text-white",
      bg: "bg-yellow-500"
    },
    "danger": {
      text: "text-white",
      bg: "bg-red-500"
    },
    "info": {
      text: "text-white",
      bg: "bg-blue-500"
    },
  }

  const { text, bg } = color[props.color || "info"];
  return (
    <div>  
 <Badge color={props.color} >{props.label}</Badge>
    </div>
  );
}

export default EBadge;

