import { Button, ButtonProps } from '@mantine/core';
import './EButton.scss';

interface IProps extends ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "submit" | "button" | "reset";
};

interface IProps {
  color?: string;
  // children: React.ReactNode;
}

export function EButton({ ...props }: IProps) {
  return <Button radius={'sm'} size="sm" {...props} />;
}
