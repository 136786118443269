import { useState, useEffect } from 'react';
import { useApi } from '../../../../../libs/data-access/src/api/useApi';
import { TPaginationStatus } from '../../../../../types';
import { PAGINATION_ITEM_LIMIT } from '../../../../../libs/data-access/constant';
import { showToast } from '../../../../../utils/utility';
import { IApplyType } from '../../../../../libs/data-access/types';
export  const useApplyType = () => {
    
    const {
        postData,
        patchData,
        putData
    } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isSwitchLoadingIndex, setIsSwitchLoadingIndex] = useState<number>();
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isOpenModalValue, setIsOpenModalValue] = useState<"New" | "Edit" | "Delete" | undefined >(undefined);
    const [searchValue, setSearchValue] = useState("");
    const [applyTypeList, setApplyTypeList] = useState<IApplyType[]>([]);
    const [selectedApplyType, setSelectedApplyType] = useState<IApplyType | undefined>(undefined);
    const [newApplyType, setNewApplyType] = useState<IApplyType>({name: ""});


    useEffect(() => {
        if(searchValue?.length > 2 || searchValue === ""){
        getApplyTypeList("INITIAL_PAGE");
    }
    }, [searchValue])


    // API FUNCTION
    const getApplyTypeList = async (paginationAction: TPaginationStatus, id?: number) => {
        setIsLoading(true);
        const response = await postData(`flight-apply/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: paginationAction,
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id: id ?? null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setApplyTypeList(response.data.data)
        } else {
            setApplyTypeList([])
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            });
        };
    }


    const submitNewApplyType = async () => {
        setIsBtnLoading(true);
        const response = await postData(`flight-apply/create`, newApplyType);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Apply Type added successfully",
            })
            getApplyTypeList("INITIAL_PAGE");
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const UpdateStatus = async (id: number, status: boolean, index: number) => {
        setIsSwitchLoadingIndex(index);
        const response = await patchData(`flight-apply/${status ? "active-all" :  "inActive-all" }?ids=${[id]}`,{});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Apply Type status updated successfully",
            })
            getApplyTypeList("INITIAL_PAGE");
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsSwitchLoadingIndex(undefined);
    }

    const UpdateApplyType = async (id: number, name: string) => {
        setIsBtnLoading(true);
        const response = await putData(`flight-apply/update`, {id: id, name: name});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Apply Type updated successfully",
            })
            getApplyTypeList("INITIAL_PAGE");
            setSelectedApplyType(undefined);
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const deleteApplyType = async (id: number) => {
       const isConfirm = window.confirm("Are you sure you want to delete this Apply Type?");
        if(!isConfirm) return;
        else{
            setIsBtnLoading(true);
            showToast({
                type:"success",
                title:"Ready to delete",
                message:"Add api to delete Apply Type"
            })

        }
        // setIsBtnLoading(true);
        // const response = await putData(`flight-apply/delete`, {id: id});
        // if (response.isSuccess) {
        //     showToast({
        //         type: "success",
        //         message: "Apply Type deleted successfully",
        //     })
        //     getApplyTypeList("INITIAL_PAGE");
        //     setSelectedApplyType(undefined);
        // } else {
        //     showToast({
        //         type: "error",
        //         message: response.message ?? "Something went wrong",
        //     })
        // };
        // setIsBtnLoading(false);
    }

    return {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        applyTypeList,
        searchValue,
        setSearchValue,
        selectedApplyType,
        setSelectedApplyType,
        newApplyType,
        setNewApplyType,
        submitNewApplyType,
        UpdateStatus,
        UpdateApplyType,
        deleteApplyType,
    }
}