import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IRoute, IUser } from "../../types";
import { initialUser } from "../../initialStates";
import { IUserAuthorities } from "../../libs/data-access/types";
import { getActiveRoute } from "../../utils/routeUtility";
import { ALL_ALLOWED_ROUTES } from "../../App";

export default function useApp() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>(initialUser);
  const [userAuthorities, setUserAuthorities] = useState<IUserAuthorities[]>(
    []
  );
  const [token, setToken] = useState<string | undefined>(undefined);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    undefined
  );
  const [role, setRole] = useState<string[] | undefined>(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<
    boolean | undefined
  >(false);
  const [currentRotes, setCurrentRoutes] = useState<IRoute[]>([]);
  const [scrollTrigger, setScrollTrigger] = useState<boolean>(false);
  const [activeRoute, setActiveRoute] = useState<{
    route: IRoute;
    SubMenuIndex: number[];
  }>({
    route: getActiveRoute().route || ALL_ALLOWED_ROUTES[0],
    SubMenuIndex: getActiveRoute().SubMenuIndex || [],
  });

  /* HANDLER FUNCTIONS */
  const handleUser = (value: IUser) => {
    setUser((prev) => ({
      ...prev,
      value,
    }));
  };

  const handleContextStateUpdateOnMount = () => {
    localStorage.getItem("isSidebarCollapsed") &&
      setIsSidebarCollapsed(
        JSON.parse(localStorage.getItem("isSidebarCollapsed") as string)
      );

    localStorage.getItem("token") &&
      setToken(localStorage.getItem("token") as string);

    localStorage.getItem("refreshToken") &&
      setRefreshToken(localStorage.getItem("refreshToken") as string);

    localStorage.getItem("isLoggedIn") &&
      setIsLoggedIn(JSON.parse(localStorage.getItem("isLoggedIn") as string));

    localStorage.getItem("role") &&
      setRole(JSON.parse(localStorage.getItem("role") as string));

    localStorage.getItem("user") &&
      setUser(JSON.parse(localStorage.getItem("user") as string));

    localStorage.getItem("userAuthorities") &&
      setUserAuthorities(
        JSON.parse(localStorage.getItem("userAuthorities") as string)
      );
    localStorage.getItem("activeRoute") &&
      setActiveRoute(
        JSON.parse(localStorage.getItem("activeRoute") as string)
      );
  };

  const handleContextStateUpdateOnStateChange = () => {
    if (isSidebarCollapsed !== undefined) {
      localStorage.setItem(
        "isSidebarCollapsed",
        JSON.stringify(isSidebarCollapsed)
      );
    }

    if (token) {
      setIsLoggedIn(true);
      localStorage.setItem("token", token);
    }
    if (refreshToken) {
      setIsLoggedIn(true);
      localStorage.setItem("refreshToken", refreshToken);
    }

    if (isLoggedIn) {
      localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
    }

    if (role) {
      localStorage.setItem("role", JSON.stringify(role));
    }

    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }

    if (userAuthorities) {
      localStorage.setItem("userAuthorities", JSON.stringify(userAuthorities));
    }
    if (activeRoute) {
      localStorage.setItem("activeRoute", JSON.stringify(activeRoute));
    }
  };

  const handleLogout = () => {
    setUser(initialUser);
    setUserAuthorities([]);
    setIsSidebarCollapsed(false);
    setIsLoggedIn(false);
    setToken(undefined);
    setRefreshToken(undefined);
    setRole(undefined);
    localStorage.clear();
    navigate("/");
  };

  return {
    user,
    loader,
    setLoader,
    handleUser,
    userAuthorities,
    setUserAuthorities,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    isLoggedIn,
    setIsLoggedIn,
    token,
    setToken,
    refreshToken,
    setRefreshToken,
    role,
    setRole,
    handleLogout,
    handleContextStateUpdateOnMount,
    handleContextStateUpdateOnStateChange,
    currentRotes,
    setCurrentRoutes,
    scrollTrigger,
    setScrollTrigger,
    activeRoute,
    setActiveRoute,
  };
}
