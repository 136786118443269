import { useEffect } from 'react';
import useOnlineBooking from './hooks/useOnlineBooking'
import PageLayout from 'PageLayout';
import { EPageTitle, ETable } from 'libs/ui';
import BookingFilter from './Components/BookingFilter';
import EBadge from 'libs/ui/src/EBadge/EBadge';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { NumberFormatter } from '@mantine/core';

export default function OnlineFlightBookingList() {

  const navigate = useNavigate();
  const {
    loading,
    isBtnLoading,
    filterState,
    setFilterState,
    handleChangeFilter,
    handleSubmitFilter,
    flightBookingList,
    getFlightBookingList
  } = useOnlineBooking();

  useEffect(() => {
    getFlightBookingList();
  }, []);


  return (
    <PageLayout>
      <EPageTitle title="Flight Booking List" />

      <BookingFilter
        filter={filterState}
        setFilter={setFilterState}
        btnLoading={isBtnLoading}
        onChange={(key, value) => handleChangeFilter(key, value as string)}
        onSubmit={() => handleSubmitFilter()}
        onReset={() => handleChangeFilter("default", "")}
      />
       <div className='mt-5'></div>
      <ETable
        loading={loading}>
        <thead>
          <tr>
            <th>Ticket Supplier</th>
            <th>Route</th>
            <th>Booking Date</th>
            <th>Booked By</th>
            <th>Travel Date</th>
            <th>PNR. No.</th>
            {/* <th>Booking Ref. No.</th> */}
            <th>Details</th>
            <th>Status</th>
            <th>Booking Amount</th>
          </tr>
        </thead>
        <tbody>
          {flightBookingList?.map((trip, index) =>
            <tr key={"trip" + index}
              onClick={() => {
                navigate(`/online-booking/Flight/1G`, { state: { filterState, trip } })

              }}
              className='cursor-pointer'
            >
              <td>{trip?.bookingResponses?.map((item) => <p className='capitalize'>{item?.flightSupplierName}</p>)}</td>
              <td>
                {
                  trip?.bookingResponses?.map((item) => (
                    item?.flightSupplierId === 3 ?
                      <>
                        {item?.galileoBookingDetailResponses && item?.galileoBookingDetailResponses?.map(eachFlight => (
                          <p className='text-sm text-slate-700'>{eachFlight?.origin} - {eachFlight?.destination}</p>
                        ))}
                      </>
                      :
                      <>
                        {item?.navitaireBookingDetailResponses && item?.navitaireBookingDetailResponses?.map(eachFlight => (
                          <p className='text-sm text-slate-700'>{eachFlight?.origin} - {eachFlight?.destination}</p>
                        ))}
                      </>
                  ))

                }
              </td>
              <td>
                {trip?.bookingResponses[0]?.bookingDate ? moment(trip?.bookingResponses[0]?.bookingDate).format("DD-MM-YYYY") : "--/--/----"}
              </td>
              <td className='capitalize'>{trip?.bookingResponses[0]?.bookedBy ? trip?.bookingResponses[0]?.bookedBy.toLowerCase().replaceAll("_", " ") : ""}</td>
              <td>
                {
                 trip?.bookingResponses[0]?.flightSupplierId === 3 ?
                  <>
                    {trip?.bookingResponses[0]?.galileoBookingDetailResponses && trip?.bookingResponses[0]?.galileoBookingDetailResponses[0]?.flightBookingSegments[0]?.departureTime
                      ?
                      moment(trip?.bookingResponses[0]?.galileoBookingDetailResponses[0]?.flightBookingSegments[0]?.departureTime).format("DD-MM-YYYY")
                      :
                      "--/--/----"}
                  </>
                  :
                  <>
                    {trip?.bookingResponses[0]?.navitaireBookingDetailResponses && trip?.bookingResponses[0]?.navitaireBookingDetailResponses[0]?.navitaireBookingDetailSegments[0]?.originDateTime
                      ?
                      moment(trip?.bookingResponses[0]?.navitaireBookingDetailResponses[0]?.navitaireBookingDetailSegments[0]?.originDateTime).format("DD-MM-YYYY")
                      :
                      "--/--/----"}
                  </>
                }
              </td>
              <td>{trip?.bookingResponses[0]?.pnr}</td>
              {/* <td>{trip?.bookingResponses[0]?.referenceNo}</td> */}
              <td>
                <p className='text-base'><span className='text-slate-400 text-sm'>Invoice No. </span>{trip?.bookingResponses[0]?.invoiceNo}</p>
                <p className='text-base'><span className='text-slate-400 text-sm'>Ref. No.    </span>{trip?.bookingResponses[0]?.referenceNo}</p>
              </td>
              <td>{<EBadge label={trip?.bookingResponses[0]?.bookingStatus?.replaceAll("_", " ")} />}</td>


              <td> <NumberFormatter
                className="text-lg"
                thousandSeparator
                prefix="₹ "
                value={

                  (trip?.paymentOrder?.galelioFlightOrderDetail?.flightBasePrice || 0)
                  +
                  (trip?.paymentOrder?.naviatorOrderDetail?.indigoBasePrice || 0)
                  +
                  (trip?.paymentOrder?.galelioFlightOrderDetail?.flightTaxPrice || 0)
                  +
                  (trip?.paymentOrder?.naviatorOrderDetail?.indigoTaxPrice || 0)
                  +
                  (trip?.paymentOrder?.baseCharge || 0)
                  +
                  (trip?.paymentOrder?.serviceCharge || 0)
                  +
                  (trip?.paymentOrder?.bookingCharge || 0)
                  +
                  (trip?.paymentOrder?.otherCharge || 0)
                  +
                  (trip?.paymentOrder?.totalGst || 0)} />
              </td>
            </tr>
          )}
        </tbody>
      </ETable>

    </PageLayout>
  )
}