import PageLayout from "PageLayout";
import { FaRegEdit } from "react-icons/fa";
import { EPageTitle, ETextInput, EButton, EModal, EMultiSelect, ETable } from "libs/ui";
import { Space, Tooltip } from "@mantine/core";
import useManageGroupB2B from "./hook/useManageGroupB2B";
import { IoMdAdd } from "react-icons/io";
import ETableTooltipData from "libs/ui/src/table/ETableTooltipData";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function ManageGroupB2B() {
  const {
    loading,
    isLoadingIndex,
    groupList,
    modalType,
    setModalType,
    affiliateUsers,
    createNewGroup,
    updateAffiliates,
    activeInactiveGroup,
    DefaultGroup,
    newGroup,
    setNewGroup,
    selectedGroup,
    setSelectedGroup
  } = useManageGroupB2B();

  return (
    <PageLayout>
      <EPageTitle
        title="Manage Group B2B"
        end={
          <EButton
            rightSection={<IoMdAdd />}
            onClick={() => {
              setModalType("CREATE");
            }}
          >
            Create New
          </EButton>
        }
      />


      <ETable
       loading={loading}
      >
        <thead>
          <tr>
            <th>Group Name</th>
            <th>Affiliate</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
          groupList?.map((group, index) => {
            const { name, status, affiliateUsers, id } = group;
            return (
              <tr>
                <td>{name}</td>
                <td>
                  {affiliateUsers?.length ? ETableTooltipData(affiliateUsers?.map((e: any) => e?.name)) : "N/A"}
                </td>
                <td  >
                  <ESwitch
                    size="sm"
                    label={status ? "Active" : "Inactive"}
                    checked={status}
                    loading={isLoadingIndex === index}
                    onChange={(event) =>
                      activeInactiveGroup(event, id, index)
                    }
                    withConfirmation
                  />
                </td>
                <td>
                  <div className=" flex justify-end">
                    <Tooltip label="Edit">
                      <div
                        onClick={() => {
                          setModalType("UPDATE");
                          setSelectedGroup(group);
                        }}
                      >
                        <FaRegEdit
                          size={23}
                          className="cursor-pointer text-green-400"
                          onClick={() => { }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </ETable>

      <EModal
        title={"Create New Group"}
        opened={modalType === "CREATE"}
        onClose={() => {
          setNewGroup(DefaultGroup);
          setModalType(undefined);
        }}
      >
        <div className="w-full flex flex-col gap-2">
          <ETextInput
            label="Group Name"
            placeholder="Select"
            value={newGroup?.name}
            onChange={(event) => setNewGroup({ ...newGroup, name: event.target.value })}
          />
          <Space h={"1px"} />
          <EMultiSelect
            label="Affiliate"
            placeholder="Select"
            data={affiliateUsers}
            value={newGroup?.affiliateUsers?.map(e=>e+"")}
            onChange={(value) => setNewGroup({ ...newGroup, affiliateUsers: value ? value.map((e) => (+e)) : [] })}
          />
          <div className="flex justify-end mt-2">
            <EButton
              disabled={ !newGroup?.name || !newGroup?.affiliateUsers?.length}
              loading={loading}
              onClick={() => {
                  createNewGroup();
              }}
            >
              {"Create"}
            </EButton>
          </div>
        </div>
      </EModal>


      <EModal
        title={"Update Affiliate Group"}
        opened={modalType === "UPDATE"}
        onClose={() => {
          setSelectedGroup(undefined);
          setModalType(undefined);
        }}
      >
       {selectedGroup && <div className="w-full flex flex-col gap-2">
          <ETextInput
            label="Group Name"
            placeholder="Select"
            value={newGroup?.name}
            onChange={(event) => setNewGroup({ ...newGroup, name: event.target.value })}
          />
          <Space h={"1px"} />
          <EMultiSelect
            label="Affiliate"
            placeholder="Select"
            data={affiliateUsers}
            value={newGroup?.affiliateUsers?.map(e=>e+"")}
            onChange={(value) => {
               setNewGroup({ ...newGroup, affiliateUsers: value ? value.map((e) => (+e)) : [] })
            }}
          />
          <div className="flex justify-end mt-2">
            <EButton
              loading={loading}
              disabled={ !newGroup?.name || !newGroup?.affiliateUsers?.length}
              onClick={() => {
                  updateAffiliates();
              }}
            >
              Update
            </EButton>
          </div>
        </div>}
      </EModal>
    </PageLayout>
  );
}
