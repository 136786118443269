import { useContext, useState } from 'react';

import useApp from '../../Hooks/useApp';
import { AppContext } from '../../../AppContext';
import { useAuth } from '../../../libs/data-access';
import { showToast } from './../../../utils/utility';
import { ILoginParams } from '../../../types';
import { handleValidateLoginParams } from '../../../utils/validators';
import { ALL_ALLOWED_ROUTES } from '../../../App';

export default function useAuthentication() {

    const {
        setRole,
        setRefreshToken,
        setToken,
        setIsLoggedIn,
        handleUser,
        setUserAuthorities,
        setActiveRoute
    } = useContext(AppContext);

    const {
        handleLogin,
    } = useAuth();
    const {
        handleContextStateUpdateOnMount
    } = useApp();

    const [loginParams, setLoginParams] = useState<ILoginParams>({
        userName: "",
        password: "",
        deviceToken: ""
    });

    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<"admin" | "agent">("admin");

    /* HANDLER FUNCTIONS */
    const handleLoginPayloadChange = (
        key: keyof ILoginParams,
        value: string
    ) => {
        setLoginParams(prev => ({ ...prev, [key]: value }));
    };


    /* API CALLS */
    const handleUserLogin = async () => {

        const isValidated = handleValidateLoginParams(loginParams);
        if (isValidated) {
            setIsLoggingIn(true);
            const payload: ILoginParams = {
                userName: loginParams.userName.trim(),
                password: loginParams.password.split("/").join(""),
                deviceToken: "gdgdgd"
            };

            const response = await handleLogin(payload);
            if (response.isSuccess) {

                // console.log("Response for login: ", response.data);
                // console.log("Extra data: ", response?.data?.extraDetails);
                const resData = response.data.data;
                const user = response.data.data.user;
                const othersDetails = response?.data?.extraDetails;
                const userAuthorities = response?.data?.extraDetails?.authorities;

                handleUser(user);
                setUserAuthorities(userAuthorities);
                setRole(othersDetails.role);
                setToken(resData?.accessToken);
                setRefreshToken(resData?.refreshToken);
                setIsLoggedIn(true);
                setActiveRoute({ route: ALL_ALLOWED_ROUTES[0], SubMenuIndex: [] });



                localStorage.setItem("userAuthorities", JSON.stringify(userAuthorities));
                localStorage.setItem("role", JSON.stringify(othersDetails.role));
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("refreshToken", resData?.refreshToken);
                localStorage.setItem("token", resData?.accessToken);
                localStorage.setItem("isLoggedIn", JSON.stringify(true));
                localStorage.setItem("activeRoute", JSON.stringify({ route: ALL_ALLOWED_ROUTES[0], SubMenuIndex: [] }));
                // filterRoutesByRoles(ALL_ROUTES)
                window.location.reload();

            } else {
                console.error("Error while login: ", response.error?.response?.status);
                showToast({
                    type: "error",
                    title: response.message || "Something Went Wrong!",
                    message: `Could not process login ${response.error?.response?.status ?? ""}`
                });

            };
            setIsLoggingIn(false);
        };
    };

    return {
        activeTab,
        setActiveTab,
        loginParams,
        isLoggingIn,
        handleLoginPayloadChange,
        handleUserLogin
    };
}
