import { Button, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ETable } from "../../../../libs/ui";
import { useApplyType } from "./hook/useApplyType";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function ApplyType() {

    const {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        applyTypeList,
        newApplyType,
        setNewApplyType,
        selectedApplyType,
        setSelectedApplyType,
        submitNewApplyType,
        UpdateStatus,
        UpdateApplyType,
        deleteApplyType,
    } = useApplyType();


    return (
        <PageLayout>
            <EPageTitle
                title="Flight Apply Type"
                end={
                    <div className="flex gap-1">
                        <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton>
                    </div>
                }
            />
            {/* <div className="flex gap-2 items-start">
                <TextInput
                    placeholder="Search"
                    leftSection={
                        <BsSearch />
                    }
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <div
                    className="grow">
                </div>
                
                <PrintableTable
                    title="Apply Type"
                    data={applyTypeList.map((e: IApplyType) => ({ name: e?.name, status: e?.status ? "Active" : "Inactive" }))}
                />

            </div> */}


            <ETable
            loading={isLoading}
            >
                <thead>
                    <tr>
                        <th>SR. No.</th>
                        <th>Apply Type Name</th>
                        <th>Status</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        applyTypeList?.map((each, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{each.name}</td>
                                    <td>
                                        <ESwitch
                                            label={each.status ? "Active" : "Inactive"}
                                            loading={isSwitchLoadingIndex === index}
                                            checked={!!each.status}
                                            onChange={() => { each?.id && UpdateStatus(each?.id, !each?.status, index) }}
                                            withConfirmation
                                        />
                                    </td>
                                    <td
                                        className="flex w-full gap-1 items-center justify-end">
                                        <Tooltip label="Edit" position="top" >
                                            <div>
                                                <FaRegEdit
                                                    size={18}
                                                    className="cursor-pointer text-green-400 hover:text-green-600"
                                                    onClick={() => {
                                                        setIsOpenModalValue("Edit")
                                                        setSelectedApplyType(each)
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        {/* <Tooltip label="Delete">
                                                        <div>
                                                        <MdDeleteForever
                                                            size={22}
                                                            className="cursor-pointer text-orange-400 hover:text-orange-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Delete")
                                                                setSelectedApplyType(each)
                                                            }}
                                                        />
                                                        </div>
                                                    </Tooltip> */}
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>

            </ETable>
            {/* ADD NEW Modal */}
            <EModal
                title="New Apply Type"
                opened={isOpenModalValue === "New"}

                onClose={() => {
                    setNewApplyType({ name: "" });
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Apply Name"
                        placeholder="Enter Name"
                        value={newApplyType?.name}
                        onChange={(e) => setNewApplyType({ ...newApplyType, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newApplyType?.name)}
                            loading={isBtnLoading}
                            onClick={() => submitNewApplyType()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Apply Type */}
            <EModal
                title="Edit Apply Type"
                opened={isOpenModalValue === "Edit"}

                onClose={() => {
                    setSelectedApplyType(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Apply Name"
                        placeholder="Enter Name"
                        value={selectedApplyType?.name}
                        onChange={(e) => setSelectedApplyType({ ...selectedApplyType, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(selectedApplyType?.name)}
                            loading={isBtnLoading}
                            onClick={() => selectedApplyType?.id && UpdateApplyType(selectedApplyType?.id, selectedApplyType?.name)}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </EModal>

            <EModal
                title="Delete Apply Type"
                opened={isOpenModalValue === "Delete"}

                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2">
                    <p>Are you sure you want to delete this Apply type?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedApplyType(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedApplyType?.id && deleteApplyType(selectedApplyType?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
