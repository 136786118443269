import React from 'react';
import ReactDOM from 'react-dom';
import { BsCrop, BsInfoCircle } from 'react-icons/bs';
import { FaCross } from 'react-icons/fa';
import { MdClose, MdDelete } from 'react-icons/md';

interface IProps {
    title: string;
    children?: React.ReactNode;
    variant?: "info" | "delete";
    withoutIcon?: boolean;
    buttonText?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const EDialogPopOver: React.FC<IProps> = ({
    title,
    children,
    variant = "info",
    withoutIcon = false,
    onConfirm,
    onCancel,
    buttonText
}) => {
    const alertType = {
        info: {
            icon: <BsInfoCircle />, // Using a simple icon as a placeholder
            color: "blue",
            buttonText: "Yes, Continue"
        },
        delete: {
            icon: <MdDelete />, // Using a simple icon as a placeholder
            color: "red",
            buttonText: "Yes, Delete"
        }
    };

    return (
        <div className="fixed inset-0 flex justify-end items-end bg-sky-600  bg-opacity-5 duration-800" style={{ zIndex: 300 }}
         onClick={onCancel}
        >
            <div className='m-6 mr-12 w-fit' >
                <div className="bg-white shadow-lg rounded-lg p-4 pb-1  w-full">
                    <div className="flex gap-5">
                        {!withoutIcon && (
                            <div style={{ color: alertType[variant]?.color }} className='text-xl mt-1'>
                                {alertType[variant]?.icon}
                            </div>
                        )}
                        <div>
                            <p className="text-xl ">
                                {title}
                            </p>
                        </div>
                        <div className='grow flex justify-end'>
                            <div className='cursor-pointer'
                             onClick={onCancel}
                            >
                            <MdClose size={24}  />
                            </div>
                        </div>
                    </div>
                            <div className="text-normal p-2">
                                {children}
                            </div>
                </div>
            </div>
        </div>
    );
};

export function EDialog({
    title,
    children,
    variant,
    withoutIcon,
    buttonText,
}: Omit<IProps, 'onConfirm' | 'onCancel'>): Promise<boolean> {
    return new Promise((resolve) => {
        const div = document.createElement('div');
        document.body.appendChild(div);

        const handleConfirm = () => {
            cleanup();
            resolve(true);
        };

        const handleCancel = () => {
            cleanup();
            resolve(false);
        };

        const cleanup = () => {
            ReactDOM.unmountComponentAtNode(div);
            div.remove();
        };

        ReactDOM.render(
            <EDialogPopOver
                title={title}
                variant={variant}
                withoutIcon={withoutIcon}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                buttonText={buttonText}
            >
                {children}
            </EDialogPopOver>,
            div
        );
    });
}
