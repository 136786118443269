import { useForm } from "@mantine/form";
import useMaster from "hook/useMaster";
import { PAGINATION_ITEM_LIMIT } from "libs/data-access/constant";
import { useApi } from "libs/data-access/src/api/useApi";
import { useEffect, useState } from "react";
import { IServices, TBookingChargeForm, TBookingChargeList, TPaginationStatus } from "types";
import { showToast } from "utils/utility"

const initialFormValues: TBookingChargeForm = {
  service: "FLIGHT",
  bookingChargeCategory: "",
  userCategory: "",
  groupIds: [],
  affiliateIds: [],
  paymentGatewayIds: "",
  paymentModeIds: [],
  domesticAmount: 0,
  domesticAmountType: "",
  domesticChargeApplyForTypeId: "",
  domesticGst: false,
  internationalAmount: 0,
  internationalAmountType: "",
  internationalChargeApplyForTypeId: "",
  internationalGst: false
}

const useMyCharges = () => {
  const {
    serviceTypes,
    groupTypes,
    affiliates,
    allFeeTypes,
    applyForTypes,
    userCategoryType,
    bookingChargeCategories,
    paymentGateway,
    paymentMode,
    getServiceTypes,
    getAllGroupTypes,
    getUserCategoryTypes,
    getAffiliatedByGroup,
    getAllFeeTypes,
    getApplyForTypes,
    getBookingChargeCategories,
    getPaymentGateway,
    getPaymentModeBasedOnGateway
  } = useMaster();

  const { postData, putData, patchData } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isLoadingIndex, setIsLoadingIndex] = useState<number | undefined>(undefined);
  const [serviceName, setServiceName] = useState<IServices>("FLIGHT");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formAction, setFormAction] = useState<"add" | "edit">("add");
  const [myChargesList, setMyChargesList] = useState<TBookingChargeList[]>([]);
  const saveFormEndPoints = {
    FLIGHT: "flight-booking-charge-form/",
    HOTEL: "hotel-booking-charge-form/",
    BUS: "/bus-booking-charge-form/",
  };

  const bookingChargeForm = useForm({
    mode: "uncontrolled",
    initialValues: initialFormValues,
    validate: {
      service: (value) => (value ? null : "Service is required"),
      userCategory: (value) => (value ? null : "User Category is required"),
      bookingChargeCategory: (value) => (value ? null : "Booking Charge Category is required"),
      groupIds: (value) => {if(!value?.length && bookingChargeForm?.getValues()?.userCategory === "AFFILIATE") { return "Group is required" }  return null},
      affiliateIds: (value) => {if(!value?.length && bookingChargeForm?.getValues()?.userCategory === "AFFILIATE") { return "Affiliate is required" }  return null},
      paymentGatewayIds: (value) => { if(value || bookingChargeForm?.getValues()?.bookingChargeCategory === "CANCELLATION_CHARGES") { return null; } else{ return "Payment Gateway is required" }},
      paymentModeIds: (value) => { if(value || bookingChargeForm?.getValues()?.bookingChargeCategory === "CANCELLATION_CHARGES") { return null; } else{ return "Payment Mode is required" }},
      domesticAmount: (value) => (value ? null : "Domestic Amount is required"),
      domesticAmountType: (value) => (value ? null : "Domestic Amount Type is required"),
      domesticChargeApplyForTypeId: (value) => (value ? null : "Domestic Charge Apply For Type is required"),
      internationalAmount: (value) => (value ? null : "International Amount is required"),
      internationalChargeApplyForTypeId: (value) => (value ? null : "International Charge Apply For Type is required"),
      internationalAmountType: (value) => (value ? null : "International Amount Type is required"),
    },
    //  Form transform is used to update values before sending it to the API by using form.getTransformValue()
    transformValues: (values) => (
      {
        ...values,
        groupIds: values.groupIds?.map((id) => Number(id)),
        affiliateIds: values.affiliateIds?.map((id) => Number(id)),
        paymentGatewayIds: values.paymentGatewayIds ? [+values.paymentGatewayIds] : [],
        paymentModeIds: values.paymentModeIds?.map((id) => Number(id)),
        domesticAmount: values.domesticAmount ? Number(values.domesticAmount) : 0,
        domesticChargeApplyForTypeId: Number(values.domesticChargeApplyForTypeId),
        internationalAmount: values.internationalAmount ? Number(values.internationalAmount) : 0,
        internationalChargeApplyForTypeId: Number(values.internationalChargeApplyForTypeId),
      })
  });

  useEffect(() => {
    getServiceTypes();
    getAllGroupTypes();
    getAllFeeTypes();
    getUserCategoryTypes();
    getBookingChargeCategories();
    getPaymentGateway();
  }, []);

  useEffect(() => {
    getApplyForTypes(serviceName, "BOOKING_CHARGE");
    getMyChargesList(serviceName, "INITIAL_PAGE");
  }, [serviceName]);

  // HANDLER FUNCTION

  const handleClickEdit = (item: TBookingChargeList) => {
    let FormValue: TBookingChargeForm = {
      id: item.id,
      bookingChargeCategory: item.bookingChargeCategory,
      userCategory: item.userCategory,
      groupIds: item.affiliateGroupCommonResponses?.map((each) => each.id+""),
      affiliateIds: item.affiliateCommonResponses?.map((id) => id.id+""),
      paymentGatewayIds: item.paymentGatewayCommonResponses?.map((id) => id.id+"")[0],
      paymentModeIds: item.paymentModeCommonResponses?.map((id) => id.id+""),
      domesticChargeApplyForTypeId: item.domesticChargeApplyForType?.id+"",
      internationalChargeApplyForTypeId: item.internationalChargeApplyForType?.id + "",
      service: serviceName,
      domesticAmount: item.domesticAmount,
      domesticAmountType: item.domesticAmountType,
      domesticGst: item.domesticGst,
      internationalAmount: item.internationalAmount,
      internationalAmountType: item.internationalAmountType,
      internationalGst: item.internationalGst
    }
    bookingChargeForm.setValues(FormValue);
    setIsModalOpen(true);
    setFormAction("edit");
  }

  const handleChangeStatus = (status: boolean, id: number, index?: number) => {
      if (status) {
        setIsLoadingIndex(index);
        updatedStatus(serviceName, id, "active");
      }
      else {
        updatedStatus(serviceName, id, "inActive");
      }
  }

  //  API FUNCTION
  const getMyChargesList = async (service: "FLIGHT" | "HOTEL" | "BUS", paginationAction: TPaginationStatus) => {
    setIsLoading(true);
    const body = {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        sortingOrder: "ASC",
      }
    };
    const res = await postData(saveFormEndPoints[service] + "get-all", body);
    if (res.isSuccess) {
      setMyChargesList(res.data.data);
    }
    else {
      showToast({
        message: res.message,
        type: "error",
      });
      setMyChargesList([])
    }
    setIsLoading(false);
  }

  const postMyCharges = async () => {
    setIsBtnLoading(true);
    const formValues = bookingChargeForm.getTransformedValues();
    const { service } = formValues;

    const response = await postData(saveFormEndPoints[service] + "save", formValues);
    if (response.isSuccess) {
      showToast({
        message: "Saved Successfully",
        type: "success",
      });
      getMyChargesList(service, "INITIAL_PAGE");
      setIsModalOpen(false);
      bookingChargeForm.reset()
    }
    else {
      showToast({
        message: response.message,
        type: "error",
      });
    }
    setIsBtnLoading(false);
  };

  const updatedMyCharges = async () => {
    setIsBtnLoading(true);
    const formValues = bookingChargeForm.getTransformedValues();
    const { id, service } = formValues;
    const response = await putData(`${saveFormEndPoints[service]}update/${id}`, formValues);
    if (response.isSuccess) {
      showToast({
        message: "Status Update Successfully",
        type: "success",
      });
      getMyChargesList(service, "INITIAL_PAGE");
      setIsModalOpen(false);
      setFormAction("add");
      bookingChargeForm.reset()
    }
    else {
      showToast({
        message: response.message,
        type: "error",
      });
    }
    setIsBtnLoading(false);
  }

  const updatedStatus = async (ChargeService: IServices, ChargeId: number, chargeStatus: "active" | "inActive") => {
    setIsBtnLoading(true);
    const response = await patchData(`${saveFormEndPoints[ChargeService]}${chargeStatus}/${ChargeId}`, {});
    if (response.isSuccess) {
      showToast({
        message: "Form Update Successfully",
        type: "success",
      });
      getMyChargesList(ChargeService, "INITIAL_PAGE");
      setIsModalOpen(false);
      setFormAction("add");
      bookingChargeForm.reset()
    }
    else {
      showToast({
        message: response.message,
        type: "error",
      });
    }
    setIsLoadingIndex(undefined);
    setIsBtnLoading(false);


  }

  return {
    isLoading,
    isModalOpen,
    setIsModalOpen,
    formAction,
    setFormAction,
    isBtnLoading,
    isLoadingIndex,
    groupTypes,
    affiliates,
    allFeeTypes,
    serviceTypes,
    serviceName,
    applyForTypes,
    userCategoryType,
    bookingChargeForm,
    bookingChargeCategories,
    paymentGateway,
    paymentMode,
    getServiceTypes,
    setServiceName,
    getUserCategoryTypes,
    getAffiliatedByGroup,
    postMyCharges,
    updatedMyCharges,
    getPaymentModeBasedOnGateway,
    myChargesList,
    handleClickEdit,
    handleChangeStatus,
  };
};

export default useMyCharges;
