import { useContext, useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { useApi } from "libs/data-access/src/api/useApi";
import { CommonValidations, validationFormFields } from "./contants";
import useMaster from "hook/useMaster";
import { AppContext } from "AppContext";
import {
  IDropdonwn,
  IMarkupBusDiscountB2C,
  IMarkupFlightDiscountB2C,
  IMarkupHotelDiscountB2C,
  TMarkupDiscountForm,
} from "types";
import moment from "moment";
import { showToast } from "utils/utility";
import { useNavigate, useLocation } from "react-router-dom";

export default function useCreateDiscount_B2C() {
  const { state } = useLocation();
  const { service, id } = state || {};
  const { getData, postData } = useApi();
  const navigate = useNavigate();
  const { setLoader } = useContext(AppContext);
  const [serviceName, setServiceName] = useState<"FLIGHT" | "HOTEL" | "BUS">(
    "FLIGHT"
  );
  const [discountCodes, setDiscountCodes] = useState<IDropdonwn[]>([]);
  const [discountSearchValue, setDiscountSearchValue] = useState<string>("");

  const {
    amountTypes,
    applyForTypes,
    serviceTypes,
    markupTypes,
    discountTypes,
    customerTypes,
    allCountryList,
    supplierList,
    airlinesList,
    hotelPlaces,
    busSeatTypes,
    busCategoryTypes,
    busDestinations,
    hotelCategories,
    flightJourneyTypes,
    airportByCoutries,
    discountCategoryTypes,
    getAllCountryList,
    getMarkupTypes,
    getServiceTypes,
    getDicountTypes,
    getCustomerTypes,
    getBusSeatTypes,
    getApplyForTypes,
    getSupplierList,
    getAmountTypes,
    getBusDestinations,
    getHotelCategories,
    getBusCategoryTypes,
    getFlightJourrneyTypes,
    getAirlinesBySuppliers,
    getAllAirportByCountries,
    getHotelPlacesByCountries,
    getDicountCategoryTypes,
  } = useMaster();
  const [flightSpecific, setFlightSpecific] = useState<boolean>(false);
  const markupDiscountForm = useForm({
    mode: "uncontrolled",
    initialValues: {
      service: "FLIGHT",
      flightSpecific: false,
    } as TMarkupDiscountForm,
    validate: { ...CommonValidations, ...validationFormFields[serviceName] },
  });

  useEffect(() => {
    getServiceTypes();
    getMarkupTypes();
    getDicountTypes();
    getCustomerTypes();
    getAllCountryList();
    getAmountTypes();
    getBusSeatTypes();
    getBusCategoryTypes();
    getFlightJourrneyTypes();
    getDicountCategoryTypes();
  }, []);

  useEffect(() => {
    if (service) {
      setServiceName(service);
    }
  }, []);

  useEffect(() => {
    const formValue = markupDiscountForm.getValues();
    const {
      service,
      discountType,
      uniqueCodesCount,
      discountCategory,
      customerType,
      name,
      description,
      code,
      startDate,
      endDate,
      perCustomerUsage,
      amount,
      amountType,
      fromRange,
      toRange,
      minimumLimit,
      maximumLimit,
    } = formValue;
    getSupplierList(serviceName);
    getApplyForTypes(serviceName, "DISCOUNT");
    if (serviceName === "HOTEL") {
      getHotelCategories();
    }
    if (serviceName === "BUS") {
      getBusCategoryTypes();
      getBusSeatTypes();
      getBusDestinations();
    }
    markupDiscountForm.reset();
    markupDiscountForm.setValues({
      service,
      discountType,
      uniqueCodesCount,
      discountCategory,
      customerType,
      name,
      description,
      code,
      startDate,
      endDate,
      perCustomerUsage,
      amount,
      amountType,
      fromRange,
      toRange,
      minimumLimit,
      maximumLimit,
    });
  }, [serviceName]);

  // API Function

  const createDiscountMarkupFromB2C = async (
    data:
      | IMarkupFlightDiscountB2C
      | IMarkupHotelDiscountB2C
      | IMarkupBusDiscountB2C
  ) => {
    setLoader(true);
    const body: any = { ...data };
    const saveFormEndPoints = {
      FLIGHT: "flight-discount-form/save",
      HOTEL: "hotel-discount-form/save",
      BUS: "bus-discount-form/save",
    };
    body.startDate = moment(data.startDate).format("YYYY-MM-DD");
    body.endDate = moment(data.endDate).format("YYYY-MM-DD");
    body["code"] = data.code.split(",");
    if (serviceName === "HOTEL" || serviceName === "BUS") {
      delete body.flightSpecific;
    }
    delete body.service;
    console.log("flight body", body);
    const response = await postData(saveFormEndPoints[serviceName], body);
    if (response.isSuccess) {
      showToast({
        type: "success",
        title: "Success",
        message: "Markup Discount Created Successfully",
      });
      navigate(-1);
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: response.message,
      });
    }
    setLoader(false);
    console.log("response of save form data ", response);
  };

  return {
    id,
    amountTypes,
    markupTypes,
    hotelPlaces,
    applyForTypes,
    serviceTypes,
    discountTypes,
    customerTypes,
    flightSpecific,
    allCountryList,
    supplierList,
    airlinesList,
    discountCodes,
    serviceName,
    busSeatTypes,
    busDestinations,
    hotelCategories,
    discountSearchValue,
    flightJourneyTypes,
    airportByCoutries,
    markupDiscountForm,
    discountCategoryTypes,
    busCategoryTypes,
    setServiceName,
    setDiscountCodes,
    setFlightSpecific,
    setDiscountSearchValue,
    getAirlinesBySuppliers,
    getAllAirportByCountries,
    createDiscountMarkupFromB2C,
    getHotelPlacesByCountries,
  };
}
