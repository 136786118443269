import { useApi } from 'libs/data-access/src/api/useApi';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IAffiliateAddCredits, IAffiliateList } from 'types';
import { showToast } from 'utils/utility';

const defaultCreditLimit: IAffiliateAddCredits = {
    affiliateId: null,
    creditLimit: null,
    validity: ''
}

const UseManageAffiliates = () => {

    const {
        patchData
    } = useApi();

    const { state } = useLocation();

     const [isBtnLoading, setIsBtnLoading] = React.useState(false);
     const [editAccountCredit, setEditAccountCredit] = useState <IAffiliateAddCredits>(defaultCreditLimit);
     const [selectedAffiliate, setSelectedAffiliate] = useState<IAffiliateList>(state);

     useEffect(() => {
        setEditAccountCredit({
            affiliateId: selectedAffiliate.id,
            creditLimit: selectedAffiliate.creditLimit,
            validity: selectedAffiliate.validity
        })
    }, [])


     const handleUpdateAffiliateWithCredits = async () => {
        setIsBtnLoading(true); 
         const response = await patchData('affiliate/add-credit-details', editAccountCredit);
         if (response.isSuccess) {
            showToast({
                message: "Credits Update Successfully",
                type: "success",
            })
            setSelectedAffiliate(prev=>({...prev, creditLimit: +editAccountCredit ?? 0 , validity: editAccountCredit.validity}));
        }
        else{
            showToast({
                message: response.message,
                type: "error",
            })
        }
        setIsBtnLoading(false);
     }
    return {
      isBtnLoading,
      selectedAffiliate,
      editAccountCredit,
      setEditAccountCredit,
      handleUpdateAffiliateWithCredits
    }
}

export default UseManageAffiliates;
