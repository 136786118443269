import { promises } from 'dns';
import useMaster from 'hook/useMaster';
import { useApi } from 'libs/data-access/src/api/useApi';
import { IFlightOnlineBookingList, IHotelOnlineBookingList } from 'libs/data-access/types';
import Affiliates from 'Pages/Affiliates/Affiliates';
import React, { useEffect } from 'react'
import { IFilterState, TFilterBookedBy, TFilterBookingStatus, TFilterDateType } from 'types';

type IOnlineBookingFilter = {
   service: string; 
   startDate: string;
   endDate: string; 
   bookingBy: string;
   bookingStatus: string;
   lastName: string;
   pnr: string;
   bookingRefNo: string;
   ticketNo: string;
}
// filter: {
//     [key: string] : {
//       value : string;
//       type : "Text" | "Number" | "Multiple" | "Single" | "CheckBox" | "Date";
//       option?: {label:string; value: string}[];
//     } 

export default function useOnlineBooking() {

    const {
      serviceTypes,
      getServiceTypes,
      getAllGroupTypes,
      getAffiliatedByGroup
    } = useMaster();

    const { 
       getData,
       postData
    } = useApi();

    const dateTypeOptions: {label: string; value:TFilterDateType}[] =[{
      label: "Booking Date",
      value: "BOOKING_DATE"
    },
    {
      label: "Travel Date",
      value: "TRAVEL_DATE"
    }
  ]
    const bookedByOptions: {label: string; value:TFilterBookedBy}[] =[{
      label: "Customer",
      value: "NORMAL_USER"
    },
    {
      label: "Affiliate",
      value: "AFFILIATE"
    }
  ]

   const filterBookingStatusList :{label: string; value:TFilterBookingStatus | ""}[]  = [{
     label: "All",
     value: ""
   },
   {
     label: "Pending",
     value: "PENDING"
   },
   {
     label: "Success",
     value: "SUCCESS"
   },
   {
     label: "Failed",
     value: "FAILED"
   },
   {
     label: "Cancelled",
     value: "CANCELED"
   },
   {
     label: "Refunded",
     value: "REFUNDED"
   },
   {
     label: "To be Canceled",
     value: "TO_BE_CANCELED"
   },
   {
     label: "To be Refunded",
     value: "TO_BE_REFUNDED"
   }
  ]

    const DefaultFilter: IFilterState = {
     dateType: { label: "Date Type", value: "", type: "Single" , option:dateTypeOptions},
     startDate: { label: "Start Date", value: "", type: "Date" },
     endDate: { label: "End Date", value: "", type: "Date" },
     bookedBy: { label: "Booked By", value: "", type: "Single", option:bookedByOptions },
    //  affiliateGroup : { label: "Affiliate Group", value: "", type: "Single", relationChild:["affiliate"], getDropDownListFunction: getAllGroupTypes },
    //  affiliate: { label: "Affiliate", value: "", type: "Multiple", getDropDownListByIdFunction: (id) => id  ? getAffiliatedByGroup(id) : Promise.reject("Invalid ID provided") },
     bookingStatus: { label: "Booking Status", value: "", type: "Single", option:filterBookingStatusList , additionalProps: { defaultValue: ""} },
     lastName: { label: "Last Name", value: "", type: "Text" },
     pnr: { label: "PNR ", value: "", type: "Text" },
     referenceNo: { label: "Booking Ref No.", value: "", type: "Text" },
     ticketNo: { label: "Ticket No.", value: "", type: "Text" },
   }

    const [loading, setLoading] = React.useState(false);
    const [isBtnLoading, setIsBtnLoading] = React.useState(false);
    const [hotelBookingList, setHotelBookingList] = React.useState<IHotelOnlineBookingList[]>([]);
    const [flightBookingList, setFlightBookingList] = React.useState<IFlightOnlineBookingList[]>([]);
  
    const [filterState, setFilterState] = React.useState<IFilterState>(DefaultFilter);
 

  
    // HANDLER FUNCTION 
    
    const handleChangeFilter = (key: keyof IFilterState | "default", value: string) => {
        if(key === "default") {
            setFilterState(DefaultFilter);
        }
        else{
            let filter = JSON.parse(JSON.stringify(filterState));
            if(key === "startDate") {
                filter["endDate"].value = "";
                filter[key].value = value;
                setFilterState(filter);
            }
            // if(key === "affiliateGroup" ){
            //     filter["affiliate"].value = "";
            //     filter[key].value = value;
            //     getAffiliatedByGroup([value]).then((res) => {
            //        filter["affiliate"].option = res ?? [];
            //        setFilterState(filter);
            //     });
            // }
            else{
                filter[key].value = value;
                setFilterState(filter);
            }
           console.log("Filter value after change filter", filter) 
        }
    }

    const handleSubmitFilter = () => {
        getFlightBookingList();
    }

    // API FUNCTION 

    const getFlightBookingList = async () => {
      setLoading(true);
      const response = await postData(`flight/get-all-bookings`, 
        {
          "filterRequest": {
            "paginationAction": "INITIAL_PAGE",
            "maxLimit": 50,
            "id": 1,
            "sortingOrder": "DESC"
          },
          "dateType": filterState?.dateType?.value || null,
          "bookedBy": filterState?.bookedBy?.value || null,
          "pnr": filterState?.pnr?.value || null,
          "lastName": filterState?.lastName?.value || null,
          "referenceNo": filterState?.referenceNo?.value || null,
          "ticketNo": filterState?.ticketNo?.value || null,
          "bookingStatus": filterState?.bookingStatus?.value || null,
          "startDate": filterState?.startDate?.value || null,
          "endDate": filterState?.endDate?.value || null,
        });
     if(response.isSuccess){
        setFlightBookingList(response.data?.data)
     }
     else{
        setFlightBookingList([])
     }
     setLoading(false);
    }
    
    const getHotelBookingList = async () => {
      setLoading(true);
      const response = await postData(`hotel/get-all-bookings`, 
        {
          "filterRequest": {
            "paginationAction": "INITIAL_PAGE",
            "maxLimit": 50,
            "id": 1,
            "sortingOrder": "DESC"
          },
          "dateType": filterState?.dateType?.value || null,
          "bookedBy": filterState?.bookedBy?.value || null,
          "pnr": filterState?.pnr?.value || null,
          "lastName": filterState?.lastName?.value || null,
          "referenceNo": filterState?.referenceNo?.value || null,
          "ticketNo": filterState?.ticketNo?.value || null,
          "bookingStatus": filterState?.bookingStatus?.value || null,
          "startDate": filterState?.startDate?.value || null,
          "endDate": filterState?.endDate?.value || null,
        });
     if(response.isSuccess){
      setHotelBookingList(response.data?.data)
     }
     else{
      setHotelBookingList([])
     }
     setLoading(false);
    }

  return {
   loading,
   isBtnLoading,
   filterState,
   setFilterState,
   handleChangeFilter,
   handleSubmitFilter,
   hotelBookingList,
   flightBookingList,
   getFlightBookingList,
   getHotelBookingList,
  }
}
