import { useContext, useEffect, useState } from "react";
import { IServices, TCommissionFormB2B } from "../../../../types";
import { useApi } from "../../../../libs/data-access/src/api/useApi";
import useMaster from "hook/useMaster";
import { useForm } from "@mantine/form";
import { commonValidations, validationFormFields } from "./contants";
import moment from "moment";
import { AppContext } from "AppContext";
import { showToast } from "utils/utility";

export default function useCreateCommission() {
  const { getData, postData } = useApi();
  const {
    fareTypes,
    ruleTypes,
    groupTypes,
    serviceTypes,
    affiliateUsers,
    hotelCategories,
    markupTypes,
    searchTypes,
    allFeeTypes,
    supplierList,
    allCountryList,
    airportByCoutries,
    airlinesList,
    flightAllClassTypes,
    flightCabinClassTypes,
    hotelPlaces,
    busCategoryTypes,
    busDestinations,
    busSeatTypes,
    travelServiceCategories,
    airportByDestinationCoutries,
    getRuleTypes,
    getFareTypes,
    getMarkupTypes,
    getServiceTypes,
    getAllGroupTypes,
    getAffiliateUsers,
    getSearchTypes,
    getSupplierList,
    getAllCountryList,
    getHotelCategories,
    getAllFeeTypes,
    getTravelServiceCategories,
    getAirlinesBySuppliers,
    getAllAirportByCountries,
    getFlightAllClassTypes,
    getFlightCabinClassTypes,
    getHotelPlacesByCountries,
    getBusCategoryTypes,
    getBusDestinations,
    getBusSeatTypes,
    getAllAirportByDestinationCountries,
  } = useMaster();

  const { setLoader } = useContext(AppContext);
  const [serviceName, setServiceName] = useState<IServices>("FLIGHT");
  const commissionB2BForm = useForm({
    mode: "uncontrolled",
    initialValues: {
      service: "FLIGHT",
    } as TCommissionFormB2B,
    validate: { ...validationFormFields[serviceName] },
  });

  useEffect(() => {
    getRuleTypes();
    getServiceTypes();
    getAllGroupTypes();
    getAffiliateUsers();
    getMarkupTypes();
    getFareTypes();
    getSearchTypes();
    getAllFeeTypes();
    getAllCountryList();
    getFlightAllClassTypes();
    getFlightCabinClassTypes();
  }, []);

  useEffect(() => {
    getSupplierList(serviceName);
    getTravelServiceCategories(serviceName, "COMMISSION");

    if (serviceName === "HOTEL") {
      getHotelCategories();
    }
    if (serviceName === "BUS") {
      getBusCategoryTypes();
      getBusSeatTypes();
      getBusDestinations();
    }
  }, [serviceName]);

  useEffect(() => {
    commissionB2BForm.setInitialValues({} as TCommissionFormB2B);
    commissionB2BForm.setValues((prev) => ({
      service: serviceName,
      travelFormFeeRequests: travelServiceCategories,
    }));
  }, [travelServiceCategories]);

  // Handler Function

  // API Function

  const createCommissionB2B = async (data: any) => {
    setLoader(true);
    const body: any = { ...data };

    const saveFormEndPoints = {
      FLIGHT: "flight-commission-form/save",
      HOTEL: "hotel-commission-form/save",
      BUS: "bus-commission-form/save",
    };
    body["affiliateGroupValid"] = true;
    body.startDate = moment(data.startDate).format("YYYY-MM-DD");
    body.endDate = moment(data.endDate).format("YYYY-MM-DD");
    body["travelCommissionFeeRequests"] = data.travelFormFeeRequests.map(
      (data: any) => {
        const { id, feeType, fee } = data;
        return {
          fee,
          criteriaType: feeType,
          travelFormFeeId: id,
        };
      }
    );
    delete body.travelFormFeeRequests;
    console.log("body sent", body);
    const response = await postData(saveFormEndPoints[serviceName], body);
    if (response.isSuccess) {
      showToast({
        type: "success",
        title: "Success",
        message: "Markup Created Successfully",
      });
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: response.message,
      });
    }
    setLoader(false);
    console.log("response of save commission form data ", response);
  };

  return {
    fareTypes,
    ruleTypes,
    groupTypes,
    serviceTypes,
    searchTypes,
    markupTypes,
    serviceName,
    affiliateUsers,
    allCountryList,
    hotelPlaces,
    commissionB2BForm,
    airportByCoutries,
    supplierList,
    airlinesList,
    hotelCategories,
    flightAllClassTypes,
    flightCabinClassTypes,
    busCategoryTypes,
    busDestinations,
    busSeatTypes,
    allFeeTypes,
    travelServiceCategories,
    airportByDestinationCoutries,
    setServiceName,
    createCommissionB2B,
    getHotelPlacesByCountries,
    getAirlinesBySuppliers,
    getAllAirportByCountries,
    getAllAirportByDestinationCountries,
  };
}
