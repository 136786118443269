import { DatePickerInput, DatePickerInputProps } from '@mantine/dates';
import React from 'react';

const EDatePicker = ({ ...props }: DatePickerInputProps) => {
    return (
        <DatePickerInput
            radius="md"
            size="md"
            {...props}
            minDate={props.minDate ? new Date(props.minDate) : new Date()}
            value={props.value ? new Date(props.value) : undefined}
            onChange={(date) =>props.onChange && props.onChange(date as any)}
        />
    );
}

export default EDatePicker;
