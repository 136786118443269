import { I1GPenalty } from "types";

export type TNavitaireSupplier = "6E" | "SG";
export type TPaxType = "ADT" | "CHD" | "INF";

/* API RESPONSE BODY */
export interface IResponse<T> {
    status: boolean;
    message: string;
    response: T;
};

export interface IResponseFormat<T> {
    isSuccess: boolean;
    data: T;
    error: T;
    message?: string;
};

export const res: IResponseFormat<undefined> = {
    isSuccess: false,
    data: undefined,
    error: undefined,
    message: '',
};

/* LOGIN RESPONSE */
export interface IUserAuthorities {
    name: string;
    actionAllowed: {
        read: boolean;
        print: boolean;
        create: boolean;
        delete: boolean;
        update: boolean;
        download: boolean;
    }
};


export interface IMasterData {
    id?: number;
    name: string;
};

/* ADMIN MASTER */
export interface IGstList {
    id?: number;
    travelServiceCategory: string;
    gst: number;
}
export interface IApplyFor {
    id?: number;
    name: string;
    travelServiceCategory: string;
    formType: string;
    status?: boolean;
}
export interface IFlightMarkupList {
    id?: number;
    name: string;
    travelServiceCategory: string;
    formType: string;
}

export interface IFareType {
    id?: number,
    name: string
    status?: boolean
}
export interface IApplyOn {
    id?: number;
    name: string;
    status?: boolean;
}
export interface ISearchType {
    id?: number;
    name: string;
    status?: boolean;
}
export interface IApplyType {
    id?: number;
    name: string;
    status?: boolean;
}
export interface IOriginCountry {
    id?: number;
    name: string;
    status?: boolean;
}
export interface ISupplier {
    id?: number;
    name: string;
    code: string;
    status?: boolean;
}

export interface IMarkupFee {
    id?: number;
    travelServiceCategory: string;
    formType: string;
    feeType: {
        id?: number;
        name: string;
    },
    status?: boolean
}

export interface IBookingTypeList {
    id?: number;
    name: string;
    code: string;
    flightTicketSupplierId: number;
    supplierId?: number;
    supplierName?: string;
    supplierCode?: string;
    supplierStatus?: boolean
    status?: boolean;
}

export interface IHotelsCategory {
    id?: number;
    name: string;
    status?: boolean
}



type ResponseType = {
    id: number;
    name: string;
};

type TravelFormFeeResponse = {
    id: number;
    feeAmount: number;
    feeType: 'FIXED' | 'PERCENTAGE';
};

export interface IFlightBookingList {
    id: number;
    affiliateGroup: {
        id: number | null;
        name: string | null;
    };
    chargeApplyForType: {
        id: number;
        name: string;
    };
    bookingType: {
        id: number;
        name: string;
    };
    startDate: string; // or Date if you prefer Date objects
    endDate: string;   // or Date if you prefer Date objects
    minimumGrossPrice: number;
    maximumGrossPrice: number;
    userType: string;
    gst: boolean;
    status: boolean;

    // flight booking list data

    flightAirlineResponses: {
        id: number;
        name: string;
    }[];
    originCountryResponses: {
        id: number;
        name: string;
    }[];
    destinationCountryResponses: {
        id: number;
        name: string;
    }[];
    originAirportResponses: {
        id: number | null;
        name: string | null;
    }[];
    destinationAirportResponses: {
        id: number | null;
        name: string | null;
    }[];
    travelServiceFormFeeResponses: {
        id: number;
        feeAmount: number;
        feeType: 'FIXED' | 'PERCENTAGE';
        name: string
    }[];
    supplierResponses: {
        id: number;
        name: string;
    }[];
    chargeApplyOnType: {
        id: number;
        name: string;
    };
    flightFareType: {
        id: number;
        name: string;
    };
    flightJourneyType: {
        id: number;
        name: string;
    };
    flightApplyType: {
        id: number;
        name: string;
    };
    flightClass: string[];
    flightCabin: string[];

    // Hotel Booking List Data

    countryResponses: ResponseType[];
    placeResponses: ResponseType[];
    travelFormFeeResponses: TravelFormFeeResponse[];
    hotelBookingSupplierResponses: ResponseType[];
    hotelCategoryResponses: ResponseType[];
};


//    ONLINE BOOKING LIST HOTEL | FLIGHT | BUS
export interface IHotelOnlineBookingList {
    id: number;
    bookingRooms: {
        guestList: {
            salutation: string;
            firstName: string;
            lastName: string;
            countryId: number;
            panCardNo: string;
            passPortNumber: string;
            dateOfIssue: string | null;
            dateOfExpiry: string | null;
        }[];
        roomName: string;
        roomId: string | null;
        roomRateKey: {
            cancellationPolicies: {
                amount: string;
                from: string;
            }[];
            promotions: {
                code: string;
                name: string;
                remark: string;
            }[];
            rateKey: string | null;
        };
        wildCards: string[];
        hotelBookingFacilities: {
            name: string;
            feeApplied: boolean;
            mandatory: boolean;
            facilityGroup: string;
        }[];
        roomImageUrl: string[];
        user: string | null;
    }[];
    referenceNo: string;
    invoiceNo: string;
    pnr: string;
    user: {
        id: number;
        name: {
            firstName: string;
            middleName: string | null;
            lastName: string;
        };
        emailId: string | null;
        phoneNumber: string | null;
        address: string | null;
        city: string | null;
        pinCode: string | null;
    };
    paymentOrder: {
        id: string | null;
        orderId: string | null;
        paymentSessionId: string;
        travelServiceCategory: string;
        hotelOrderDetail: {
            totalRoomCharge: number;
            checkIn: string;
            checkOut: string;
        };
        hotel: {
            id: number;
            name: string;
            description: string;
            address: string;
            postalCode: string;
            email: string;
            phones: Array<{
                phoneNumber: string;
                phoneType: string;
            }>;
            city: string;
            place: {
                id: number;
                name: string;
                status: boolean;
            };
        };
        otherCharge: number;
        totalGst: number;
        baseCharge: number;
        serviceCharge: number;
        bookingCharge: number;
        paymentCharge: number;
        discount: number;
        paymentStatus: string;
        hotelTotalAmount: number;
    };
    bookingStatus: string;
    bookingMode: string;
};
export interface IFlightOnlineBookingPaymentOrder {
    id: string | null;
    orderId: string | null;
    paymentSessionId: string;
    travelServiceCategory: string;
    naviatorOrderDetail: {
        indigoBasePrice: number,
        indigoTaxPrice: number
    };
    galelioFlightOrderDetail: {
        flightBasePrice: number,
        flightTaxPrice: number
    },
    otherCharge: number;
    totalGst: number;
    baseCharge: number;
    serviceCharge: number;
    bookingCharge: number;
    paymentCharge: number;
    discount: number;
    paymentStatus: string;
};

export interface IFlightOnlineBookingResponseList {
    id: number;
        galileoBookingDetailResponses: {
            origin: string;
            destination: string;
            flightBookingSegments: {
                group: number;
                distance: number;
                participantLevel: string;
                linkAvailability: boolean;
                polledAvailabilityOption: string;
                optionalServicesIndicator: boolean;
                availabilitySource: string;
                availabilityDisplayType: string;
                carrier: string;
                changeOfPlane: boolean;
                equipment: string;
                flightNumber: string;
                flightTime: number;
                fareInfoRef: string;
                origin: string;
                destination: string;
                departureTime: string;
                arrivalTime: string;
                originTerminal: string | null;
                destinationTerminal: string | null;
                cabin: string;
                maxWeight: number;
                unit: string;
                numberOfPieces: number | null;
                brandName: string | null;
                flightBookingImages: string[];
                originAirportId: number | null;
                destinationAirportId: number | null;
                originPlaceId: number | null;
                destinationPlaceId: number | null;
                eticketability: string;
            }[];
            bookingChangePenalty: I1GPenalty[] | null;
            bookingCancelPenalty: I1GPenalty[] | null;
            bookingFareRules: string[];
        }[] | null;
        navitaireBookingDetailResponses: [
            {
                origin: string;
                destination: string;
                navitaireBookingDetailSegments:
                {
                    origin: string;
                    destination: string;
                    originDateTime: string;
                    destinationDateTime: string;
                    carrierCode: string;
                    flightNumber: string;
                    segmentSellKey: string;
                    international: boolean;
                    adjustedCapacity: number;
                    originTerminal: string;
                    destinationTerminal: string;
                    arrvLTV: number;
                    backMoveDays: number;
                    capacity: number;
                    changeOfDirection: boolean;
                    deptLTV: number;
                    equipmentType: string;
                    equipmentTypeSuffix: string;
                    flifoUpdated: boolean;
                    irop: boolean;
                    actualOffBlockTime: string;
                    actualOnBlockTime: string;
                    actualTouchDownTime: string;
                    airborneTime: string;
                    arrivalGate: string;
                    arrivalNote: string;
                    arrivalStatus: string;
                    baggageClaim: string;
                    departureGate: string;
                    departureNote: string;
                    departureStatus: string;
                    eta: string;
                    etd: string;
                    tailNumber: string;
                    originAirportId: string;
                    destinationAirportId: string;
                    originPlaceId: string;
                    destinationPlaceId: string;
                    eticket: boolean;
                    onTime: string;
                }[]
            }
        ] | null;
        navitairePassengerDetailResponses: {
            title: string;
            firstName: string;
            lastName: string;
            gender: string;
            dateOfBirth: string;
        }[] | null;
        galileoPassengerDetailResponses: {
            title: string;
            firstName: string;
            lastName: string;
            gender: string;
            dateOfBirth: string;
        }[] | null;
        referenceNo: string;
        invoiceNo: string;
        pnr: string;
        user: {
            id: number;
            name: {
                firstName: string;
                middleName: string;
                lastName: string;
            },
            emailId: string;
            phoneNumber: string;
            address: string;
            city: string;
            pinCode: string;
        };
        bookingStatus: string;
        bookingMode: string;
        flightSupplierId: number;
        flightSupplierName: string;
        bookedBy: string;
        bookingDate: string;
    
}
export interface IFlightOnlineBookingList {
    uuid: string;
    bookingResponses: IFlightOnlineBookingResponseList[];
    paymentOrder: IFlightOnlineBookingPaymentOrder;
};

export interface INavitaireFlightJourneyResponseList {
    fareSellKey: string;
    journeySellKey: string;
    markupId: number | null;
    totalPrice: number;
    flightSegmentResponseList: INavitaireFlightSegmentResponseList[];
};

// flight segment
export interface INavitaireFlightSegmentResponseList {
    origin: string;
    destination: string;
    originDateTime: string;
    destinationDateTime: string;
    carrierCode: TNavitaireSupplier;
    flightNumber: string;
    segmentSellKey: string;
    international: boolean;
    adjustedCapacity: number;
    originTerminal: string | null;
    destinationTerminal: string | null;
    arrvLTV: number | null;
    backMoveDays: number | null;
    capacity: number | null;
    changeOfDirection: false,
    deptLTV: number | null;
    equipmentType: string;
    equipmentTypeSuffix: string | null;
    flifoUpdated: boolean;
    irop: boolean;
    actualOffBlockTime: any;
    actualOnBlockTime: any;
    actualTouchDownTime: any;
    airborneTime: any;
    arrivalGate: any;
    arrivalNote: any;
    arrivalStatus: any;
    baggageClaim: any;
    departureGate: any;
    departureNote: any;
    departureStatus: any;
    eta: any;
    etd: any;
    tailNumber: any;
    eticket: boolean;
    onTime: string | null;
};


export type THotelsMarkupB2B = {
    id: number;
    countryResponses: {
        id: number;
        name: string;
    }[];
    placeResponses: {
        id: number;
        name: string;
    }[];
    travelFormFeeResponses: {
        id: number;
        feeAmount: number;
        name: string;
        feeType: "FIXED" | "PERCENTAGE";

    }[];
    affiliateGroup: {
        id: number;
        name: string;
    };
    hotelBookingSupplierResponses: {
        id: number;
        name: string;
    }[];
    chargeApplyForType: {
        id: number;
        name: string;
    };
    bookingType: {
        id: number;
        name: string;
    };
    startDate: string;  // ISO string date format
    endDate: string;    // ISO string date format
    minimumGrossPrice: number;
    maximumGrossPrice: number;
    gst: boolean;
    hotelCategoryResponses: {
        id: number;
        name: string;
    }[];
    userType: "CUSTOMER" | "ADMIN" | "AFFILIATE";  // Assuming possible values
    status: boolean;
};
