import React from 'react';
import ReactDOM from 'react-dom';
import { BsInfoCircle } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';

interface IProps {
    title: string;
    children?: React.ReactNode;
    variant?: "info" | "delete";
    withoutIcon?: boolean;
    buttonText?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const EAlert: React.FC<IProps> = ({
    title,
    children,
    variant = "info",
    withoutIcon = false,
    onConfirm,
    onCancel,
    buttonText
}) => {
    const alertType = {
        info: {
            icon: <BsInfoCircle />, // Using a simple icon as a placeholder
            color: "blue",
            buttonText: "Yes, Continue"
        },
        delete: {
            icon: <MdDelete />, // Using a simple icon as a placeholder
            color: "red",
            buttonText: "Yes, Delete"
        }
    };

    return (
        <div className="fixed inset-0 flex justify-center bg-black bg-opacity-50" style={{ zIndex: 300 }}>
            <div className='mt-20 w-1/3' >
                <div className="bg-white rounded-lg p-6  w-full">
                    <div className="flex gap-3">
                        {!withoutIcon && (
                            <div style={{ color: alertType[variant]?.color }} className='text-xl mt-1'>
                                {alertType[variant]?.icon}
                            </div>
                        )}
                        <div>
                            <h2 className="text-xl font-semibold">
                                {title}
                            </h2>
                            <div className="text-normal mt-1">
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex justify-end gap-2">
                        <button
                            onClick={onCancel}
                            className="border border-gray-200 bg-gray-50 hover:bg-gray-100 text-gray-700 px-4 py-2 font-semibold rounded"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onConfirm}
                            className={`text-white px-4 py-2 font-semibold rounded ${alertType[variant]?.color === "blue" ? "bg-blue-500 hover:bg-blue-600" : "bg-red-500 hover:bg-red-600"}`}
                        >
                            {buttonText ? buttonText : alertType[variant]?.buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export function EConfirm({
    title,
    children,
    variant,
    withoutIcon,
    buttonText,
}: Omit<IProps, 'onConfirm' | 'onCancel'>): Promise<boolean> {
    return new Promise((resolve) => {
        const div = document.createElement('div');
        document.body.appendChild(div);

        const handleConfirm = () => {
            cleanup();
            resolve(true);
        };

        const handleCancel = () => {
            cleanup();
            resolve(false);
        };

        const cleanup = () => {
            ReactDOM.unmountComponentAtNode(div);
            div.remove();
        };

        ReactDOM.render(
            <EAlert
                title={title}
                variant={variant}
                withoutIcon={withoutIcon}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                buttonText={buttonText}
            >
                {children}
            </EAlert>,
            div
        );
    });
}
