import { useState, useEffect, useRef } from "react";
import { useApi } from "../../../../../libs/data-access/src/api/useApi";
import { TPaginationStatus } from "../../../../../types";
import { PAGINATION_ITEM_LIMIT } from "../../../../../libs/data-access/constant";
import { showToast } from "../../../../../utils/utility";
import { IFareType } from "../../../../../libs/data-access/types";
import { useReactToPrint } from "react-to-print";
export const useFareType = () => {
  const { postData, patchData, putData } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [isSwitchLoading, setIsSwitchLoading] = useState<boolean>(false);
  const [isSwitchLoadingIndex, setIsSwitchLoadingIndex] = useState<number | undefined>(undefined);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isOpenModalValue, setIsOpenModalValue] = useState<"New" | "Edit" | "Delete" | null>();
  const [searchValue, setSearchValue] = useState("");
  const [fareTypeList, setFareTypeList] = useState<IFareType[]>([]);
  const [selectedFareType, setSelectedFareType] = useState<
    IFareType | undefined
  >(undefined);
  const [newFareType, setNewFareType] = useState<IFareType>({ name: "" });

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue === "") {
      getFareTypeList("INITIAL_PAGE");
    }
  }, [searchValue]);

  // API FUNCTION
  const getFareTypeList = async (
    paginationAction: TPaginationStatus,
    id?: number
  ) => {
    setIsLoading(true);
    const response = await postData(`flight-fare/get-all`, {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        id: id ?? null,
        sortingOrder: "ASC",
      },
      search: searchValue ?? "",
    });
    setIsLoading(false);

    if (response.isSuccess) {
      setFareTypeList(response.data.data);
    } else {
      setFareTypeList([]);
      showToast({
        type: "error",
        message: response.message ?? "Something went wrong",
      });
    }
  };

  const submitNewFareType = async () => {
    setIsBtnLoading(true);
    const response = await postData(`flight-fare/create`, newFareType);
    if (response.isSuccess) {
      showToast({
        type: "success",
        message: "Flight fare type added successfully",
      });
      getFareTypeList("INITIAL_PAGE");
      setIsOpenModalValue(null);
    } else {
      showToast({
        type: "error",
        message: response.message ?? "Something went wrong",
      });
    }
    setIsBtnLoading(false);
  };

  const UpdateStatus = async (id: number, status: boolean, index: number) => {
    setIsSwitchLoadingIndex(index);
    const response = await patchData(
      `flight-fare/${status ? "active-all" : "inActive-all"}?ids=${[id]}`,
      {}
    );
    if (response.isSuccess) {
      showToast({
        type: "success",
        message: "Flight fare type status updated successfully",
      });
      setIsOpenModalValue(null);
      getFareTypeList("INITIAL_PAGE");
    } else {
      showToast({
        type: "error",
        message: response.message ?? "Something went wrong",
      });
    }
    setIsSwitchLoadingIndex(undefined);
  };

  const UpdateFareType = async (id: number, name: string) => {
    setIsSwitchLoading(true);
    const response = await putData(`flight-fare/update`, {
      id: id,
      name: name,
    })
    if (response.isSuccess) {
      setSelectedFareType(undefined);
      setIsOpenModalValue(null);
      getFareTypeList("INITIAL_PAGE");
      showToast({
        type: "success",
        message: "Flight fare type updated successfully",
      });
    } else {
      showToast({
        type: "error",
        message: response.message ?? "Something went wrong",
      });
    }
    setIsSwitchLoading(false);
  };

  const deleteFareType = async (id: number) => {
    const isConfirm = window.confirm(
      "Are you sure you want to delete this Flight fare type?"
    );
    if (!isConfirm) return;
    else {
      setIsBtnLoading(true);
      showToast({
        type: "success",
        title: "Ready to delete",
        message: "Add api to delete Markup Type",
      });
    }
    // setIsBtnLoading(true);
    // const response = await putData(`flight-fare/delete`, {id: id});
    // if (response.isSuccess) {
    //     showToast({
    //         type: "success",
    //         message: "Flight fare type deleted successfully",
    //     })
    //     getFareTypeList("INITIAL_PAGE");
    //     setSelectedFareType(null);
    // } else {
    //     showToast({
    //         type: "error",
    //         message: response.message ?? "Something went wrong",
    //     })
    // };
    // setIsBtnLoading(false);
  };

  return {
    isLoading,
    isSwitchLoading,
    isSwitchLoadingIndex,
    isBtnLoading,
    isOpenModalValue,
    setIsOpenModalValue,
    fareTypeList,
    searchValue,
    setSearchValue,
    selectedFareType,
    setSelectedFareType,
    newFareType,
    setNewFareType,
    submitNewFareType,
    UpdateStatus,
    UpdateFareType,
    deleteFareType,
  };
};
