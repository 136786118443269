import { isNotEmpty } from "@mantine/form";
import {
  travelFormFeeRequestsValidation,
  validateArrayField,
} from "utils/utility";

const commonValidations = {
  service: isNotEmpty("Please select Service"),
  affiliateGroupId: isNotEmpty("Please select Group"),
  ruleType: isNotEmpty("Please select Rule Type"),
  affiliateIds: isNotEmpty("Please select Affiliates"),
  startDate: isNotEmpty("Please select Start Date"),
  endDate: isNotEmpty("Please select End Date"),
  minimumPrice: isNotEmpty("Please enter From Price"),
  maximumPrice: (value: any, values: any) => {
    if (!value) {
      return "Please enter Price To";
    } else if (parseInt(value) < parseInt(values.minimumPrice)) {
      return "Maximum Price cannot be less than Minimum Price";
    }
  },
  travelFormFeeRequests: {
    fee: travelFormFeeRequestsValidation,
    feeType: isNotEmpty("Please enter Fee Type"),
  },
};

const flightValidaitons = {
  ...commonValidations,
  bookingTypeIds: isNotEmpty("Please select Flight Type"),
  flightJourneyTypes: isNotEmpty("Please select Search Type"),
  flightOriginCountryIds: validateArrayField("Please select Origin Country"),
  flightDestinationCountryIds: validateArrayField(
    "Please select Destination Country"
  ),
  originAirportIds: validateArrayField("Please select Route Origin"),
  destinationAirportIds: validateArrayField("Please select Route Destination"),
  flightTicketSupplierIds: validateArrayField("Please select Suppliers"),
  flightAirlineIds: validateArrayField("Please select Airlines"),
  flightFareTypeId: isNotEmpty("Please select Fare Types"),
  flightClass: validateArrayField("Please select Flight Class"),
  flightCabin: validateArrayField("Please select Flight Cabin Class"),
};

const hptelValidations = {
  ...commonValidations,
  countryIds: validateArrayField("Please select Country"),
  placeIds: validateArrayField("Please select Destination"),
  supplierIds: validateArrayField("Please select Suppliers"),
  bookingTypeIds: isNotEmpty("Please select Hotel Type"),
  hotelCategoryIds: validateArrayField("Please select Hotel Category"),
  hotelName: isNotEmpty("Please enter Hotel Name"),
  hotelCode: isNotEmpty("Please enter Hotel Code"),
};

const busValidaitons = {
  ...commonValidations,
  originPlaceIds: validateArrayField("Please select Origin"),
  destinationPlaceIds: validateArrayField("Please select Destination"),
  busTicketSupplierIds: validateArrayField("Please select Suppliers"),
  busCategories: validateArrayField("Please select Categories"),
  busSeatCategories: validateArrayField("Please select Seat Categories"),
};

const validationFormFields = {
  FLIGHT: flightValidaitons,
  HOTEL: hptelValidations,
  BUS: busValidaitons,
};

export { commonValidations, validationFormFields };
