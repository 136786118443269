import PageLayout from "PageLayout";
import { FaRegEdit } from "react-icons/fa";
import useMyCharges from "./hook/useMyCharges";
import { MdFlight, MdHotel } from "react-icons/md";
import SectionLabel from "libs/ui/src/SectionLabel";
import {
  EButton,
  ESelect,
  EPageTitle,
  ETextInput,
  ESectionDivider,
  EMultiSelect,
  ETable,
} from "libs/ui";
import { Grid, Space, GridCol, Checkbox, Tabs, Tooltip } from "@mantine/core";
import ESwitch from "libs/ui/src/Switch/ESwitch";
import { BsBusFront } from "react-icons/bs";
import { IServices } from "types";

export default function MyCharges() {
  const {
    isLoading,
    isModalOpen,
    setIsModalOpen,
    formAction,
    setFormAction,
    isBtnLoading,
    groupTypes,
    affiliates,
    allFeeTypes,
    serviceName,
    serviceTypes,
    applyForTypes,
    userCategoryType,
    paymentGateway,
    paymentMode,
    bookingChargeCategories,
    bookingChargeForm,
    myChargesList,
    isLoadingIndex,
    setServiceName,
    getAffiliatedByGroup,
    postMyCharges,
    updatedMyCharges,
    getPaymentModeBasedOnGateway,
    handleClickEdit,
    handleChangeStatus,
  } = useMyCharges();

  const formValues = bookingChargeForm.getValues();
  const { service } = formValues;

  return (
    <PageLayout>
      {

        !isModalOpen ?
          <>
            <EPageTitle
              title="My Charges"
              end={
                <div className="flex gap-2">
                  <EButton onClick={() => setIsModalOpen(!isModalOpen)}>{" Add New"}</EButton>
                </div>
              }
            />

            <div className="flex w-full justify-between items-center gap-4 mt-8 my-5">
              <Tabs
                defaultValue="flight"
                value={serviceName}
                className="grow"
                onChange={(value) => setServiceName(value as IServices)}
              >
                <Tabs.List>
                  <Tabs.Tab value="FLIGHT" leftSection={<MdFlight />}>
                    Flight
                  </Tabs.Tab>
                  <Tabs.Tab value="HOTEL" leftSection={<MdHotel />}>
                    Hotel
                  </Tabs.Tab>
                  <Tabs.Tab value="BUS" disabled leftSection={<BsBusFront />}>
                    Bus
                  </Tabs.Tab>
                </Tabs.List>
              </Tabs>

              <div></div>
            </div>
            <Space h="md" />

            {/*TABLE DATA VIEW  */}

            <ETable 
            loading={isLoading}
            >
              <thead>
                <tr>
                  <th>User type</th>
                  <th>Affiliate</th>
                  <th>Affiliate group</th>
                  <th>Category</th>
                  <th>Domestic amount</th>
                  <th>International amount</th>
                  <th>Status</th>
                  <th className="flex justify-end">Manage</th>
                </tr>
              </thead>
              <tbody>
                {
                  !myChargesList?.length ?
                    <>  </>
                    :
                    myChargesList?.map((item, index) =>
                      <tr>
                        <td>{item?.userCategory?.toLowerCase()}</td>
                        <td>
                          {item?.affiliateCommonResponses?.length ?
                            <Tooltip label={item?.affiliateCommonResponses?.map((item, index) => ((index > 0 ? ", " : "") + item?.fullName?.toLowerCase()))} >
                              <div>{item?.affiliateCommonResponses[0].fullName} <span className="text-gray-400 font-bold">{item?.affiliateCommonResponses?.length > 1 ? ` +${item?.affiliateCommonResponses?.length - 1}` : ""}</span></div>
                            </Tooltip>
                            : <></>
                          }
                        </td>
                        <td>{item?.affiliateGroupCommonResponses?.map((item, index) => ((index > 0 ? ", " : "") + item?.name ? item?.name?.toLowerCase() : "_"))}</td>
                        <td>{item?.bookingChargeCategory?.toLowerCase()?.replaceAll("_", " ")}</td>
                        <td>{item?.domesticAmount + " (" + item?.domesticAmountType.toLowerCase() + ")" + " / " + item?.domesticChargeApplyForType?.name?.toLowerCase()}</td>
                        <td>{item?.internationalAmount + " (" + item?.internationalAmountType.toLowerCase() + ")" + " / " + item?.internationalChargeApplyForType?.name?.toLowerCase()} </td>
                        <td>
                          <ESwitch
                            label={item?.status ? "Active" : "In-Active"}
                            checked={item?.status}
                            loading={isLoadingIndex === index}
                            onChange={(e) => handleChangeStatus(e, item?.id, index)}
                            withConfirmation
                            confirmationTitle="Confirmation"
                            confirmationDescription="Are you sure you want to change status?"
                          />
                        </td>
                        <td className="flex gap-2 w-full justify-end">
                          <FaRegEdit
                            size={20}
                            className="cursor-pointer text-green-400 hover:text-green-500"
                            onClick={() => {
                              handleClickEdit(item)
                            }
                            }
                          />
                        </td>
                      </tr>
                    )}
              </tbody>
            </ETable>
          </>
          :
          // ADD NEW AND EDIT 
          <>
            <EPageTitle
              title={formAction === "add" ? "Add New Charge" : "Edit Charge"}
            />
            <form
              onSubmit={bookingChargeForm.onSubmit(
                () => {
                  formAction === "add" ?
                    postMyCharges() :
                    updatedMyCharges()
                }
              )}
            >
              <div className=" p-8 pt-6">
                <Grid>
                  <GridCol span={4}>
                    <ESelect
                      label="Select Service"
                      placeholder="Select"
                      data={serviceTypes}
                      disabled={formAction === "edit"}
                      required
                      value={service}
                      {...bookingChargeForm.getInputProps("service")}
                      onChange={(value: any) => {
                        setServiceName(value);
                        bookingChargeForm.reset();
                        bookingChargeForm.setFieldValue("service", value);
                      }}
                    />
                  </GridCol>

                  <GridCol span={4}>
                    <ESelect
                      label="User Type"
                      placeholder="Select"
                      required
                      data={userCategoryType}
                      key={bookingChargeForm.key("userCategory")}
                      {...bookingChargeForm.getInputProps("userCategory")}
                      onChange={(value: any) => {
                        bookingChargeForm.setFieldValue("userCategory", value);
                        bookingChargeForm.setFieldValue("bookingChargeCategory", "");
                        bookingChargeForm.setFieldValue("groupIds", []);
                        bookingChargeForm.setFieldValue("affiliateIds", []);
                      }}
                    />
                  </GridCol>

                  <GridCol span={4}>
                    <ESelect
                      placeholder="Select"
                      label="Select Category"
                      required
                      data={bookingChargeCategories}
                      key={bookingChargeForm.key("bookingChargeCategory")}
                      {...bookingChargeForm.getInputProps("bookingChargeCategory")}
                      onChange={(value: any) => {
                        bookingChargeForm.setFieldValue("bookingChargeCategory", value);
                        bookingChargeForm.setFieldValue("paymentGatewayIds", "");
                        bookingChargeForm.setFieldValue("paymentModeIds", []);
                      }}
                    />
                  </GridCol>

                  <GridCol span={4}>
                    <EMultiSelect
                      data={groupTypes}
                      label="Select Group"
                      disabled={bookingChargeForm?.getValues()?.userCategory?.toLowerCase() === "affiliate" ? false : true}
                      placeholder="Select"
                      required={bookingChargeForm?.getValues()?.userCategory?.toLowerCase() === "affiliate" ? true : false}
                      key={bookingChargeForm.key("groupIds")}
                      {...bookingChargeForm.getInputProps("groupIds")}
                      onChange={(value: Array<string>) => {
                        getAffiliatedByGroup(value.join(","));
                        bookingChargeForm.setFieldValue("groupIds", value);
                        bookingChargeForm.setFieldValue("affiliateIds", []);
                      }}
                    />
                  </GridCol>

                  <GridCol span={4}>
                    <EMultiSelect
                      placeholder="Select"
                      label="Select Affiliate"
                      required={bookingChargeForm?.getValues()?.userCategory?.toLowerCase() === "affiliate" ? true : false}
                      disabled={bookingChargeForm?.getValues()?.userCategory?.toLowerCase() === "affiliate" ? false : true}
                      data={affiliates}
                      key={bookingChargeForm.key("affiliateIds")}
                      {...bookingChargeForm.getInputProps("affiliateIds")}
                      onChange={(value: Array<string>) => {
                        bookingChargeForm.setFieldValue("affiliateIds", value);
                      }}
                    />
                  </GridCol>
                  <GridCol span={4}>
                  </GridCol>
                  <GridCol span={4}>
                    <ESelect
                      placeholder="Select"
                      label="Select Payment Gateway"
                      data={paymentGateway}
                      required={bookingChargeForm?.getValues()?.bookingChargeCategory === "CANCELLATION_CHARGES" ? false : true}
                      disabled={bookingChargeForm?.getValues()?.bookingChargeCategory === "CANCELLATION_CHARGES" ? true : false}
                      key={bookingChargeForm.key("paymentGatewayIds")}
                      {...bookingChargeForm.getInputProps("paymentGatewayIds")}
                      onChange={(value: any) => {
                        if (value) { getPaymentModeBasedOnGateway(+value); }
                        bookingChargeForm.setFieldValue("paymentGatewayIds", value);
                        bookingChargeForm.setFieldValue("paymentModeIds", []);
                      }}
                    />
                  </GridCol>
                  <GridCol span={4}>
                    <EMultiSelect
                      placeholder="Select"
                      label="Select Payment Mode"
                      required={bookingChargeForm?.getValues()?.bookingChargeCategory === "CANCELLATION_CHARGES" ? false : true}
                      disabled={bookingChargeForm?.getValues()?.bookingChargeCategory === "CANCELLATION_CHARGES" ? true : false}
                      data={paymentMode}
                      key={bookingChargeForm.key("paymentModeIds")}
                      {...bookingChargeForm.getInputProps("paymentModeIds")}
                      onChange={(value: Array<string>) => {
                        bookingChargeForm.setFieldValue("paymentModeIds", value);
                      }}
                    />
                  </GridCol>

                </Grid>

                <Space h={"lg"} />
                <ESectionDivider title="Parameters" />
                <h1 className="text-sm text-gray-600">
                  Enter Amount 0 if not applicable
                </h1>

                <SectionLabel label="Domestic" />
                <Grid>
                  <GridCol span={4}>
                    <ETextInput
                      label="Amount"
                      placeholder="Enter Amount"
                      required
                      key={bookingChargeForm.key("domesticAmount")}
                      {...bookingChargeForm.getInputProps("domesticAmount")}
                    />
                  </GridCol>
                  <GridCol span={4}>
                    <ESelect
                      label="Amount Type"
                      placeholder="Select"
                      data={allFeeTypes}
                      required
                      key={bookingChargeForm.key("domesticAmountType")}
                      {...bookingChargeForm.getInputProps("domesticAmountType")}

                    />
                  </GridCol>
                  <GridCol span={4}>
                    <ESelect
                      label="Apply For"
                      placeholder="Select"
                      data={applyForTypes}
                      required
                      key={bookingChargeForm.key("domesticChargeApplyForTypeId")}
                      {...bookingChargeForm.getInputProps("domesticChargeApplyForTypeId")}
                    />
                  </GridCol>
                  <GridCol span={4}>
                    <Checkbox
                      label={"Inclusive GST"}
                      labelPosition="left"
                      className="mt-1"
                      checked={formValues?.domesticGst}
                      key={bookingChargeForm.key("domesticGst")}
                      {...bookingChargeForm.getInputProps("domesticGst")}
                      onChange={(e) =>
                        bookingChargeForm.setFieldValue("domesticGst", e?.target.checked)
                      }
                    />
                  </GridCol>
                </Grid>

                <Space h={"md"} />
                <SectionLabel label="International" />
                <Grid>
                  <GridCol span={4}>
                    <ETextInput
                      label="Amount"
                      required
                      placeholder="Enter Amount"
                      key={bookingChargeForm.key("internationalAmount")}
                      {...bookingChargeForm.getInputProps("internationalAmount")}
                    />
                  </GridCol>
                  <GridCol span={4}>
                    <ESelect
                      label="Amount Type"
                      placeholder="Select"
                      required
                      data={allFeeTypes}
                      key={bookingChargeForm.key("internationalAmountType")}
                      {...bookingChargeForm.getInputProps("internationalAmountType")}
                    />
                  </GridCol>
                  <GridCol span={4}>
                    <ESelect
                      label="Apply For"
                      placeholder="Select"
                      required
                      data={applyForTypes}
                      key={bookingChargeForm.key("internationalChargeApplyForTypeId")}
                      {...bookingChargeForm.getInputProps("internationalChargeApplyForTypeId")}
                    />
                  </GridCol>
                  <GridCol span={4}>
                    <Checkbox
                      label={"Inclusive GST"}
                      labelPosition="left"
                      checked={formValues?.internationalGst}
                      className="mt-1"
                      key={bookingChargeForm.key("internationalGst")}
                      {...bookingChargeForm.getInputProps("internationalGst")}
                      onChange={(e) =>
                        bookingChargeForm.setFieldValue("internationalGst", e?.target.checked)
                      }
                    />
                  </GridCol>
                </Grid>
              </div>
              <Space h={"md"} />
              <div className=" flex gap-3 items-center justify-end">
                {formAction === "add" ?
                  <EButton onClick={() => bookingChargeForm.reset()} variant="outline" > Reset</EButton>
                  : <></>
                }
                <EButton onClick={() => {
                  bookingChargeForm.reset();
                  setIsModalOpen(false)
                  setFormAction("add");
                }}
                  variant="outline"
                > Cancel </EButton>
                <EButton type="submit" loading={isBtnLoading} >{formAction === "add" ? "Save" : "Update"}</EButton>
              </div>
            </form>
            <Space h={"md"} />
          </>}

      {/* ADD OR EDIT MODAL */}

    </PageLayout>
  );
}
