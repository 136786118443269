import { Image, PasswordInput, Tabs } from "@mantine/core";

import "./Login.scss";

import { EButton, ETextInput } from "../../../../libs/ui";

import useAuthentication from "../../Hooks/useAuthentication";

export default function Login() {
  const {
    activeTab,
    setActiveTab,
    loginParams,
    isLoggingIn,
    handleLoginPayloadChange,
    handleUserLogin,
  } = useAuthentication();

  return (
    <div className="page-login">
      <div className="background"></div>
      <div className="body">
        <div className="inner">
          <div className="w-full flex items-end gap-2">
            <Image
              w={50}
              h={50}
              radius="md"
              className=" shadow-green-300 shadow"
              src={require("../../../../assets/image/PMLLogo.png")}
              alt="PLM_logo"
            />
            <p className="text-slate-400 font-semibold">PLM Travel Portal</p>
          </div>
          <div className="w-full px-6 mt-10">
            <Tabs
              value={activeTab}
              onChange={(value: any) => setActiveTab(value ?? "admin")}
              variant="default"
              className="bg-slate-50 rounded"
            >
              <Tabs.List grow>
                <Tabs.Tab value="admin">Admin</Tabs.Tab>
                <Tabs.Tab value="agent">Agent</Tabs.Tab>
              </Tabs.List>
            </Tabs>
            <div className="flex flex-col gap-2 border-t-0 mt-5">
              <div>
                <h3 className="capitalize"> {activeTab} Sign in</h3>
                <p className="text-sm opacity-50">
                  Please enter your{" "}
                  <span className="font-semibold opacity-90 ">{"user"}</span>{" "}
                  credentials below to login
                </p>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="signin-form "
              >
                <ETextInput
                  required
                  size="md"
                  variant="unstyled"
                  placeholder={"Enter Username"}
                  className="border-b px-2"
                  value={loginParams.userName}
                  onChange={(e) => {
                    const { value } = e.target;
                    handleLoginPayloadChange("userName", value);
                  }}
                />

                <PasswordInput
                  required
                  size="md"
                  variant="unstyled"
                  className="border-b px-2"
                  placeholder={"Enter Password (########)"}
                  value={loginParams.password}
                  onChange={(e) => {
                    const { value } = e.target;
                    handleLoginPayloadChange("password", value);
                  }}
                />

                <EButton
                  fullWidth
                  type="submit"
                  size="md"
                  className="btn-blue mt-5"
                  loading={isLoggingIn}
                  onClick={() => {
                    handleUserLogin();
                  }}
                >
                  {"Sign in"}
                </EButton>
              </form>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
