import { Button, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ETable } from "../../../../libs/ui";
import {useFareType} from "./hook/useFareType";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function FareType() {

    const {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        fareTypeList,
        newFareType,
        setNewFareType,
        selectedFareType,
        setSelectedFareType,
        submitNewFareType,
        UpdateStatus,
        UpdateFareType,
        deleteFareType,
    } = useFareType();

    return (
        <PageLayout>
            <EPageTitle
                title="Flight Fare Type"
                end={
                    <div className="flex gap-1">
                        <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton>
                    </div>
                }
            />
            {/* <div className="flex gap-2 items-start">
                <TextInput
                    placeholder="Search"
                    leftSection={
                        <BsSearch />
                    }
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <div
                    className="grow">
                </div>
                
                <PrintableTable
                    title="Flight Fare Type"
                    data={fareTypeList.map((e: IFareType) => ({ name: e?.name, status: e?.status ? "Active" : "Inactive" }))}
                />

            </div> */}


            <ETable
             loading={isLoading}
            >
                <thead>
                    <tr>
                        <th>SR. No.</th>
                        <th>Fare Type</th>
                        <th>Status</th>
                        <th ></th>
                    </tr>
                </thead>
                {
                        fareTypeList?.length === 0
                            ?
                            <></>
                            :
                            <tbody>
                                {
                                    fareTypeList?.map((each, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{each.name}</td>
                                                <td>
                                                    <ESwitch
                                                        label={each.status ? "Active" : "Inactive"}
                                                        loading={isSwitchLoadingIndex === index}
                                                        checked={!!each.status}
                                                        onChange={() => { each?.id && UpdateStatus(each?.id, !each?.status, index) }}
                                                        withConfirmation
                                                    />
                                                </td>
                                                <td
                                                    className="flex w-full gap-1 items-center justify-end">
                                                    <Tooltip label="Edit" position="top" >
                                                        <div>
                                                        <FaRegEdit
                                                            size={18}
                                                            className="cursor-pointer text-green-400 hover:text-green-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Edit")
                                                                setSelectedFareType(each)
                                                            }}
                                                        />
                                                            </div>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                }

            </ETable>
          
            {/* ADD NEW Modal */}
            <EModal
                title="New Fare Type"
                opened={isOpenModalValue === "New"}
                 
                onClose={() => {
                    setNewFareType({ name: "" });
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Fare Name"
                        placeholder="Enter Name"
                        value={newFareType?.name}
                        onChange={(e) => setNewFareType({ ...newFareType, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newFareType?.name)}
                            loading={isBtnLoading}
                            onClick={() => submitNewFareType()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Fare Type */}
            <EModal
                title="Edit Fare Type"
                opened={isOpenModalValue === "Edit" ? true : false} 
                onClose={() => {
                    setSelectedFareType(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Fare Name"
                        placeholder="Enter Name"
                        value={selectedFareType?.name}
                        onChange={(e) => setSelectedFareType({ ...selectedFareType, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(selectedFareType?.name)}
                            loading={isBtnLoading}
                            onClick={() => selectedFareType?.id && UpdateFareType(selectedFareType?.id, selectedFareType?.name)}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </EModal>

            <EModal
                title="Delete Fare Type"
                opened={isOpenModalValue === "Delete"}
                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2">
                    <p>Are you sure you want to delete this Fare Type?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedFareType(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedFareType?.id && deleteFareType(selectedFareType?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
