import { FaRegEdit } from "react-icons/fa";
import { Button, NumberInput, Select } from "@mantine/core";

import PageLayout from "../../../PageLayout";
import { useManageGST } from "./hook/useManageGST";
import { EButton, EModal, EPageTitle, ETable } from "../../../libs/ui";
import { IoMdAdd } from "react-icons/io";

export default function ManageGST() {
  const {
    isLoading,
    isBtnLoading,
    isOpenAddNewModal,
    setIsOpenAddNewModal,
    gstList,
    allServiceList,
    newGst,
    setNewGst,
    submitNewGst,
    selectedGst,
    setSelectedGst,
    updateGst
  } = useManageGST();

  return (
    <PageLayout>
      <EPageTitle
        title="Manage GST"
        end={
          <EButton
            rightSection={<IoMdAdd />}
            onClick={() => {
              setIsOpenAddNewModal(true);
            }}
          >
            Add New
          </EButton>
        }
      />
      {/* <div className="flex gap-2 items-start">
        <TextInput
          placeholder="Search"
          leftSection={<BsSearch />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div className="grow"> </div>
        <PrintableTable title="GST List" data={gstList} />
      </div> */}
      <ETable
       loading={isLoading}
      >
        <thead>
          <tr>
            <th>SR. No.</th>
            <th>Service Name</th>
            <th>GST Value</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {gstList?.length === 0 ? (
            <></>
          ) : (
            gstList?.map((each, index) => (
              <tr>
                <td>{index+1}</td>
                <td>{each.travelServiceCategory}</td>
                <td>{each?.gst}</td>
                <td className="flex gap-2 w-full justify-end">
                  <FaRegEdit
                    className="cursor-pointer text-green-400 hover:text-green-500"
                    onClick={() => {setSelectedGst(each)}}
                    size={21}
                  />
                  {/* <MdDeleteForever
                    className="cursor-pointer text-orange-500 hover:text-orange-600"
                    size={24}
                  /> */}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </ETable>
     
      {/* ADD NEW Modal */}
      <EModal
        title="Add New GST"
        opened={isOpenAddNewModal}
         
        onClose={() => {
          setIsOpenAddNewModal(false);
        }}
      >
        <div className="w-full flex flex-col gap-2">
          <Select
            label="Service Name"
            placeholder="Select"
            data={allServiceList?.map((e) => ({
              label: e?.key,
              value: e?.value,
            }))}
            value={newGst?.travelServiceCategory}
            onChange={(value) =>
              setNewGst({ ...newGst, travelServiceCategory: value ?? "" })
            }
          />
          <NumberInput
            label="GST Value"
            placeholder="Enter GST Value"
            hideControls
            value={newGst?.gst ? newGst?.gst : ""}
            onChange={(e) => setNewGst({ ...newGst, gst: +e })}
          />
          <div className="flex justify-end mt-2">
            <Button
              disabled={!(newGst?.gst && newGst?.travelServiceCategory)}
              loading={isBtnLoading}
              onClick={() => submitNewGst()}
            >
              Save
            </Button>
          </div>
        </div>
      </EModal>

      <EModal
        title={`Manage ${selectedGst?.travelServiceCategory.toLowerCase()} gst`}
        opened={selectedGst ? true : false}
         
        onClose={() => {
          setSelectedGst(undefined);
        }}
      >
        { selectedGst && <div className="w-full flex flex-col gap-2">
          <NumberInput
            label="GST Value"
            placeholder="Enter GST Value"
            hideControls
            value={selectedGst?.gst ? selectedGst?.gst : ""}
            onChange={(e) => setSelectedGst({ ...selectedGst, gst: +e })}
          />
          <div className="flex justify-end mt-2">
            <Button
              disabled={!(selectedGst?.gst && selectedGst?.travelServiceCategory)}
              loading={isBtnLoading}
              onClick={() => {updateGst()}}
            >
              Update
            </Button>
          </div>
        </div>}
      </EModal>
    </PageLayout>
  );
}
