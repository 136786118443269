import React from "react";
import PageLayout from "PageLayout";
import { IoMdAdd } from "react-icons/io";
import { FaEdit, FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import useManageTypes from "./hook/useManageType";
import { Space, Switch, Table, TableThead } from "@mantine/core";
import { EButton, EEmpty, EModal, EPageTitle, ETable, ETextInput } from "libs/ui";
import ExportDataActions from "libs/ui/src/Export/ExportDataActions";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function ManageChargeTypes() {
  const {
    loading,
    isBtnLoading,
    isLoadingIndex,
    setIsLoadingIndex,
    chargeTypeList,
    isOpenAddNewModal,
    chargeTypeName,
    selectedType,
    setChargeTypeName,
    setIsOpenAddNewModal,
    createChargeType,
    activeInActiveChargeType,
    setSelectedType,
    updateChargeType
  } = useManageTypes();
  return (
    <PageLayout>
      <EPageTitle
        title="Manage Charge Types"
        end={
          <EButton
            rightSection={<IoMdAdd />}
            onClick={() => {
              setIsOpenAddNewModal(true);
            }}
          >
            Create New
          </EButton>
        }
      />
      {/* <div className="flex w-full h-20 justify-end">
        <ExportDataActions
          pdf
          print
          csv
          pdfColor="red"
          csvColor="green"
          size={30}
          onCSV={() => { }}
          onPrint={() => { }}
          onPDF={() => { }}
        />
      </div> */}
      <ETable
       loading={loading}
      >
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Name</th>
            <th>Status</th>
            <th className="flex justify-end">Manage</th>
          </tr>
        </thead>
        <tbody>
          {chargeTypeList?.map((chargeType, index) => {
            const { name, status, id } = chargeType;
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{name}</td>
                <td>
                  <ESwitch
                    size="sm"
                    checked={status}
                    loading={isLoadingIndex === index}
                    label={status ? "Active" : "In-Active"}
                    onChange={(event) => {
                      activeInActiveChargeType(
                        event,
                        id + ""
                      );
                    }}
                    withConfirmation
                    confirmationTitle="Are you sure?"
                    confirmationDescription="You want to change the status of this charge type?"
                  />
                </td>

                <td>
                  <div className="w-full flex justify-end items-center text-green-400 hover:text-green-500">
                    <FaEdit onClick={() => setSelectedType(chargeType)} className="cursor-pointer" size={24} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </ETable>
     
      <EModal
        title={"Create Charge Type"}
        opened={isOpenAddNewModal}

        onClose={() => {
          setIsOpenAddNewModal(false);
          setChargeTypeName("");
        }}
      >
        <div className="w-full flex flex-col gap-2">
          <ETextInput
            label="Charge Type Name"
            placeholder="Select"
            value={chargeTypeName}
            onChange={(event) => setChargeTypeName(event.target.value)}
          />
          <Space h={"1px"} />
          <div className="flex justify-end mt-2">
            <EButton
              disabled={!chargeTypeName}
              loading={isBtnLoading}
              onClick={() => {
                createChargeType();
              }}
            >
              {"Create"}
            </EButton>
          </div>
        </div>
      </EModal>

      <EModal
        title={"Edit Charge Type"}
        opened={!!selectedType}
        onClose={() => {
          setSelectedType(undefined);
        }}
      >
        <div className="w-full flex flex-col gap-2">
          <ETextInput
            label="Charge Type Name"
            placeholder="Select"
            value={selectedType?.name}
            onChange={(event) => setSelectedType((prev) => (prev && { ...prev, name: event.target.value }))}
          />
          <Space h={"1px"} />
          <div className="flex justify-end mt-2">
            <EButton
              disabled={!selectedType?.name}
              loading={loading}
              onClick={() => {
                updateChargeType();
              }}
            >
              {"Update"}
            </EButton>
          </div>
        </div>
      </EModal>
    </PageLayout>
  );
}
