import { EButton, ELabelView, EPageTitle, ESectionDivider } from 'libs/ui';
import EDatePicker from 'libs/ui/src/EDateInput/EDatePicker';
import PageLayout from 'PageLayout';
import UseManageAffiliates from './hook/useManageAffiliates';
import ENumberInput from 'libs/ui/src/ENumberInput/ENumberInput';
import moment from 'moment';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ManageAffiliates = () => {

    const navigate = useNavigate();

    const {
        isBtnLoading,
        selectedAffiliate,
        editAccountCredit,
        setEditAccountCredit,
        handleUpdateAffiliateWithCredits
    } = UseManageAffiliates();

    return (
        <PageLayout>
            <EPageTitle title='Manage Affiliates' />
            <div className='filter-container'>

                <div className='grid grid-cols-3 w-full gap-4 mt-4'>
                    <ELabelView
                        label='Booking Till Date'
                        value={'--'}
                        card
                    />
                    <ELabelView
                        label='Look to Book Ratio Search'
                        value={selectedAffiliate.searchBookingRatio ? selectedAffiliate?.searchBookingRatio + "" : "00"}
                        card
                    />
                    <ELabelView
                        label='Look to Book Ratio Book'
                        value={selectedAffiliate.bookingRatio ? selectedAffiliate?.bookingRatio + "" : "00"}
                        card
                    />

                    <ELabelView
                        label='Total Credit (INR)'
                        value={selectedAffiliate?.creditLimit ? selectedAffiliate?.creditLimit + "" : "00.00"}
                        card
                    />
                    <ELabelView
                        label='Remaining Credit (INR)'
                        value={selectedAffiliate?.remainingCredit ? selectedAffiliate?.remainingCredit + "" : "00.00"}
                        card
                    />
                    <ELabelView
                        label='Credit Limit '
                        value={selectedAffiliate?.creditLimit ? selectedAffiliate?.creditLimit + "" : "00.00"}
                        card
                    />
                </div>

                <div className='grid grid-cols-3 w-full mt-10 gap-14 gap-y-2'>

                    <div>
                        <ESectionDivider title="Company Details" />
                        <div className='flex flex-col gap-3'>
                            <ELabelView label="Company Name" value={selectedAffiliate?.companyName ?? ""} horizontal />
                            <ELabelView label="Address 1" value={selectedAffiliate?.firstAddress ?? ""} horizontal />
                            <ELabelView label="Address 2" value={selectedAffiliate?.secondAddress} horizontal />
                            <ELabelView label="Country" value={selectedAffiliate.countryName ? selectedAffiliate?.countryName + "" : ""} horizontal />
                            <ELabelView label="State" value={selectedAffiliate.stateName ? selectedAffiliate?.stateName + "" : ""} horizontal />
                            <ELabelView label="City" value={selectedAffiliate.city ? selectedAffiliate?.city + "" : ""} horizontal />
                            <ELabelView label="PinCode" value={selectedAffiliate.pinCode ? selectedAffiliate?.pinCode + "" : ""} horizontal />
                            <ELabelView label="Company Type" value={selectedAffiliate.companyType ? selectedAffiliate?.companyType + "" : ""} horizontal />
                            <ELabelView label="Pan" value={selectedAffiliate?.panCardMediaId ? selectedAffiliate?.panCardMediaId + "" : ""} horizontal />
                            {/* <ELabelView label="Pan Holder Name" value={"--"} horizontal /> */}
                            <ELabelView label="Look to Book ratio" value={selectedAffiliate?.bookingRatio ? selectedAffiliate?.bookingRatio + "" : ""} horizontal />
                            <ELabelView label="TDS Exemption Number" value={selectedAffiliate?.tdsExemptionNumber ? selectedAffiliate?.tdsExemptionNumber + "" : ""} horizontal />
                            <ELabelView label="TDS Exemption Limit" value={selectedAffiliate?.tdsExemptionLimit ? selectedAffiliate?.tdsExemptionLimit + "" : ""} horizontal />
                            <ELabelView label="TDS Exemption Rate" value={selectedAffiliate?.tdsExemptionRate ? selectedAffiliate?.tdsExemptionRate + "" : ""} horizontal />
                            <ELabelView label="TDS Exemption Code" value={selectedAffiliate?.tdsExemptionCode ? selectedAffiliate?.tdsExemptionCode + "" : ""} horizontal />
                            <ELabelView label="Logo Invoice" value={selectedAffiliate?.logoInvoice ? "Yes" : "No"} horizontal />
                        </div>
                    </div>
                    <div>
                        <ESectionDivider title="Contact Details" />
                        <div className='flex flex-col gap-3'>
                            <ELabelView label="First Name" value={selectedAffiliate?.firstName ?? ""} horizontal />
                            <ELabelView label="Last Name" value={selectedAffiliate?.lastName ?? ""} horizontal />
                            <ELabelView label="Email Id" value={selectedAffiliate?.emailId ?? ""} horizontal />
                            <ELabelView label="Phone" value={selectedAffiliate?.contactNumber ?? ""} horizontal />
                            <ELabelView label="Mobile" value={selectedAffiliate?.mobileNumber ?? ""} horizontal />
                        </div>
                        <div className='mt-12'></div>
                        <ESectionDivider title="Group Details"
                            end={
                                <p
                                    className='flex justify-center items-center gap-1 text-sm cursor-pointer text-blue-500'
                                    onClick={() => navigate(`/group-settings/manage-group-b2b`)}
                                >
                                    Manage Group <MdOutlineOpenInNew />
                                </p>
                            }
                        />
                        <ELabelView label="Group Name" value={selectedAffiliate?.affiliateGroupName ?? ""} horizontal />

                    </div>
                    <div>
                        <ESectionDivider title="Edit Credits" />

                        <ENumberInput
                            label="Credits Limits"
                            placeholder='Enter Credits'
                            className='mb-3'
                            value={editAccountCredit?.creditLimit ? editAccountCredit?.creditLimit + "" : "00.00"}
                            onChange={(e) => setEditAccountCredit({ ...editAccountCredit, creditLimit: +e ?? null })}
                        />

                        <EDatePicker
                            label="Validity"
                            placeholder='Select Validity Date'
                            value={editAccountCredit?.validity ? new Date(editAccountCredit?.validity) : undefined}
                            onChange={(date) => setEditAccountCredit({ ...editAccountCredit, validity: date ? moment(date).format("YYYY-MM-DD") + "" : "" })}
                        />
                        <div className='mt-6 flex justify-end'>
                            <EButton
                                onClick={handleUpdateAffiliateWithCredits}
                                loading={isBtnLoading}
                            >
                                Update Credits
                            </EButton>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
}

export default ManageAffiliates;
