import { Checkbox } from '@mantine/core';
import { IMasterData } from 'libs/data-access/types';
import { EButton, EDateInput, EMultiSelect, ESelect, ETextInput } from 'libs/ui';
import ENumberInput from 'libs/ui/src/ENumberInput/ENumberInput';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { IFilterState } from 'types';

interface IFilterProps {
  btnLoading?: boolean;
  filter: IFilterState;
  setFilter: (filter: IFilterState) => void;
  onChange: (key: string, value: string | number | boolean | string[] | number[]) => void;
  onReset?: () => void
  onSubmit?: () => void;
}

export default function BookingFilter(props: IFilterProps) {
  const filterKeys: string[] = Object.keys(props.filter) ?? []

  const [filterOptionWithKey, setFilterOptionWithKey] = useState<{ [key: keyof IFilterState]: IMasterData[] }[]>([])

  useEffect(() => {
    filterKeys?.map(async (eachFilter, index) => {
      if (props.filter[eachFilter]?.getDropDownListFunction) {
        props.filter[eachFilter]?.getDropDownListFunction?.().then((response) => {
          setFilterOptionWithKey(prev => ({ ...prev, [eachFilter]: response ?? [] }))
        })
      }
      else {
        console.log("Please provide getDropDownListFunction")
      }
    })
  }, [])

  return (
    <div className="filter-container">
      <div className='grid grid-cols-4 gap-4 w-full'>
        {filterKeys?.map((eachFilter) => {
          switch (props.filter[eachFilter]?.type) {
            case "Text":
              return (
                <ETextInput
                  label={props.filter[eachFilter].label}
                  placeholder={"Enter " + props.filter[eachFilter].label}
                  value={props.filter[eachFilter]?.value}
                  onChange={(e) => {
                    props.onChange(eachFilter, e.target.value);
                  }}
                  {...props.filter[eachFilter]?.additionalProps}
                />
              );
            case "Number":
              return (
                <ENumberInput
                  label={props.filter[eachFilter].label}
                  placeholder={"Enter " + props.filter[eachFilter].label}
                  value={props.filter[eachFilter]?.value as string}
                  onChange={(e) => {
                    props.onChange(eachFilter, e);
                  }}
                  {...props.filter[eachFilter]?.additionalProps}
                />
              );
            case "Multiple":
              return (
                <EMultiSelect
                  label={props.filter[eachFilter].label}
                  placeholder="Select"
                  value={props.filter[eachFilter]?.value ? props.filter[eachFilter]?.value?.split(",") : []}
                  data={props.filter[eachFilter]?.option ?? []}
                  onChange={(e) => {
                    props.onChange(eachFilter, e ? e.join(",") : "");
                  }}

                  {...props.filter[eachFilter]?.additionalProps}
                />
              )
            case "Single":
              return (
                <ESelect
                  label={props.filter[eachFilter].label}
                  placeholder="Select"
                  value={props.filter[eachFilter]?.value || null}
                  data={props.filter[eachFilter]?.option ? props.filter[eachFilter]?.option : filterOptionWithKey[eachFilter as any] ?? []}
                  onChange={(e) => {
                    if (props.filter[eachFilter]?.relationChild?.length) {
                       alert("Function is in Child ")
                      props.filter[eachFilter]?.relationChild?.map((child) => {
                        props.filter[child].value = ""
                        props.filter[eachFilter].value = e ?? ""
                        props.setFilter(props.filter)
                        if (e) {
                          alert("FUNCTION is in Chiel valid e ")
                          props.filter[child]?.getDropDownListByIdFunction?.(e).then((response) => {
                            setFilterOptionWithKey(prev => ({ ...prev, [eachFilter]: response ?? [] }))
                          }).catch((error) => {
                            console.log("error", error)
                            setFilterOptionWithKey(prev => ({ ...prev, [eachFilter]: [] }))
                            props.filter[child].option = []
                          })
                        } 
                      })
                    }
                    else {
                      props.onChange(eachFilter, e ?? "");
                    }
                  }}
                  {...props.filter[eachFilter]?.additionalProps}
                />
              )
            case "CheckBox":
              return (
                <Checkbox
                  label={props.filter[eachFilter].label}
                  checked={props.filter[eachFilter]?.value === "true"}
                  onChange={(e) => {
                    props.onChange(eachFilter, e.target.checked);
                  }}
                  {...props.filter[eachFilter]?.additionalProps}
                />
              );
            case "Date":
              return (
                <EDateInput
                  label={props.filter[eachFilter].label}
                  placeholder={"Select"}
                  value={props.filter[eachFilter]?.value ? new Date(props.filter[eachFilter]?.value) : undefined}
                  onChange={(e) => {
                    props.onChange(eachFilter, e ? moment(e).format("YYYY-MM-DD") : "");
                  }}
                  {...props.filter[eachFilter]?.additionalProps}
                />
              );
            default:
              return (
                <ETextInput
                  label={props.filter[eachFilter].label}
                  placeholder={"Enter " + props.filter[eachFilter].label}
                  value={props.filter[eachFilter]?.value}
                  onChange={(e) => {
                    props.onChange(eachFilter, e.target.value);
                  }}
                  {...props.filter[eachFilter]?.additionalProps}
                />
              );
          }

        })}
        <div className={`col-span-${(4 - (filterKeys?.length % 4))}`} >
          <div className=" flex gap-2 h-full w-full justify-end items-end " >
            <EButton onClick={() => { props.onReset?.() }} variant="outline">Reset</EButton>
            <EButton loading={props.btnLoading} onClick={() => { props.onSubmit?.() }} >Search</EButton>
          </div>
        </div>
      </div>
    </div>
  )
}
