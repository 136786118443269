import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip, Text } from "@mantine/core";
import { FiLogOut } from "react-icons/fi";
import { HiBars3BottomLeft } from "react-icons/hi2";

import { EModal } from "../Modal/EModal";
import { EButton } from "../Button/EButton";

import { useAuth } from "../../../data-access";
import { AppContext } from "../../../../AppContext";
import { showToast } from "../../../../utils/utility";
import { matchRoute } from "../../../../utils/routeUtility";

export function ETopbar() {

  const location = useLocation();
  const {
    token,
    refreshToken,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    role,
    user,
    handleLogout
  } = useContext(AppContext);

  const {
    logout
  } = useAuth();


  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logOutModalOpened, setLogOutModalOpened] = useState<boolean>(false);


  // API CALLS
  const performLogout = async () => {

    if (!token || !refreshToken) {
      handleLogout();
      setLogOutModalOpened(false);
      return
    };

    setIsLoading(true);
    const payload = {
      accessToken: token,
      refreshToken: refreshToken
    };

    const response = await logout(payload);
    if (response.isSuccess) {

      // console.log("Logged out successfully: ", response.data);
      handleLogout();
      setLogOutModalOpened(false);

    } else {

      console.log("Error while logging out: ", response.error);
      showToast({
        type: "error",
        title: "Something went wrong"!,
        message: response.message
      });

    };
    setIsLoading(false);

  };



  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center">
        <HiBars3BottomLeft
          size={24}
          className="cursor-pointer"
          onClick={() => setIsSidebarCollapsed((prev) => !prev)}
        />
        <p className="font-semibold ml-2">PML Travel Dashboard</p>
      </div>

      {/* {location.pathname == "/" ? ( */}
      <div className="flex items-center justify-between">
        <div className="px-4">
          {/* <p className="text-xs">{location.pathname}</p> */}
          {/* <p className="text-3xl font-extrabold tracking-tight text-blue-950">{matchRoute(location.pathname)?.label}</p> */}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <Tooltip
            withArrow
            position="left-start"
            label="Logout"
            transitionProps={{ transition: 'pop', duration: 300 }}
          >
            <div
              className="cursor-pointer"
              onClick={() => setLogOutModalOpened(true)}
            >
              <FiLogOut size={20} />
            </div>
          </Tooltip>
        </div>
      </div>
      {/* ) : (
        <></>
      )} */}

      {/* <div className="breadcrumbs font-medium">
                <Breadcrumbs separator={<ChevronRightIcon className="w-3" />}>
                    {
                        breadCrumbData.map((path, index) => (
                            index !== 0 ? <Anchor
                                key={index}
                                onClick={() => index !== breadCrumbData.length - 1 && navigate(breadCrumbData.slice(0, index + 1).join('/').replace(" ", "-"))}
                            >
                                <span
                                    className={`capitalize ${index === breadCrumbData.length - 1 && "text-slate-400"}`}
                                >
                                    {path.replace("-", " ")}
                                </span>
                            </Anchor> : <></>
                        ))
                    }
                </Breadcrumbs>
            </div> */}

      {/* <div className="flex gap-6">
                <div className="icon-link">
                    <InboxIcon />
                </div>
                <div className="icon-link">
                    <BellIcon />
                </div>
            </div> */}

      <EModal
        opened={logOutModalOpened}
        onClose={() => setLogOutModalOpened(false)}
        title="Are you sure you want to logout?"
      >
        <div className="flex justify-end">
          <EButton
            size="sm"
            className="btn-light"
            loading={isLoading}
            onClick={performLogout}
          >
            Yes, Logout
          </EButton>
        </div>
      </EModal>
    </div>
  );
}
