import { Button, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ETable } from "../../../../libs/ui";
import {useApplyOn} from "./hook/useApplyOn";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function ApplyOn() {

    const {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        applyOnList,
        newApplyOn,
        setNewApplyOn,
        selectedApplyOn,
        setSelectedApplyOn,
        submitNewApplyOn,
        UpdateStatus,
        UpdateApplyOn,
        deleteApplyOn,
    } = useApplyOn();


    return (
        <PageLayout>
            <EPageTitle
                title="Flight Apply On"
                end={
                    <div className="flex gap-1">
                        <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton>
                    </div>
                }
            />
            
            <ETable
            loading={isLoading}
            >
                <thead>
                    <tr>
                        <th>SR. No.</th>
                        <th>Apply On Name</th>
                        <th>Status</th>
                        <th ></th>
                    </tr>
                </thead>
                {
                  applyOnList?.length === 0
                            ?
                            <></>
                            :
                            <tbody>
                                {
                                    applyOnList?.map((each, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{each.name}</td>
                                                <td>
                                                    <ESwitch
                                                        label={each.status ? "Active" : "Inactive"}
                                                        loading={isSwitchLoadingIndex === index}
                                                        checked={!!each.status}
                                                        onChange={() => { each?.id && UpdateStatus(each?.id, !each?.status, index) }}
                                                        withConfirmation
                                                    />
                                                </td>
                                                <td
                                                    className="flex w-full gap-1 items-center justify-end">
                                                    <Tooltip label="Edit" position="top" >
                                                        <div>
                                                        <FaRegEdit
                                                            size={18}
                                                            className="cursor-pointer text-green-400 hover:text-green-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Edit")
                                                                setSelectedApplyOn(each)
                                                            }}
                                                        />
                                                            </div>
                                                    </Tooltip>
                                                    {/* <Tooltip label="Delete">
                                                        <div>
                                                        <MdDeleteForever
                                                            size={22}
                                                            className="cursor-pointer text-orange-400 hover:text-orange-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Delete")
                                                                setSelectedApplyOn(each)
                                                            }}
                                                        />
                                                        </div>
                                                    </Tooltip> */}
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                }

            </ETable>
        

            {/* ADD NEW Modal */}
            <EModal
                title="New Apply On Type"
                opened={isOpenModalValue === "New"}
                 
                onClose={() => {
                    setNewApplyOn({ name: "" });
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Apply On Name"
                        placeholder="Enter Name"
                        value={newApplyOn?.name}
                        onChange={(e) => setNewApplyOn({ ...newApplyOn, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newApplyOn?.name)}
                            loading={isBtnLoading}
                            onClick={() => submitNewApplyOn()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Apply On Type */}
            <EModal
                title="Edit Apply On Type"
                opened={isOpenModalValue === "Edit"}
                 
                onClose={() => {
                    setSelectedApplyOn(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Apply On Name"
                        placeholder="Enter Name"
                        value={selectedApplyOn?.name}
                        onChange={(e) => setSelectedApplyOn({ ...selectedApplyOn, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(selectedApplyOn?.name)}
                            loading={isBtnLoading}
                            onClick={() => selectedApplyOn?.id && UpdateApplyOn(selectedApplyOn?.id, selectedApplyOn?.name)}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </EModal>

            <EModal
                title="Delete Apply On Type"
                opened={isOpenModalValue === "Delete"}
                 
                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2">
                    <p>Are you sure you want to delete this Apply On type?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedApplyOn(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedApplyOn?.id && deleteApplyOn(selectedApplyOn?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
