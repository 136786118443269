import { useState, useEffect } from 'react'
import { useApi } from '../../../../libs/data-access/src/api/useApi';
import { TPaginationStatus } from '../../../../types';
import { PAGINATION_ITEM_LIMIT } from '../../../../libs/data-access/constant';
import { showToast } from '../../../../utils/utility';
import { IGstList } from '../../../../libs/data-access/types';
export const useManageGST = () => {

    const {
        getData,
        postData,
        putData
    } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isOpenAddNewModal, setIsOpenAddNewModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [gstList, setGstList] = useState<IGstList[]>([]);
    const [selectedGst, setSelectedGst] = useState<IGstList>();
    const [newGst, setNewGst] = useState<IGstList>({ travelServiceCategory: "", gst: 0 });

    const [allServiceList, setAllServiceList] = useState<{ key: string; value: string }[]>([]);


    useEffect(() => {
        getGstList("INITIAL_PAGE");
        getAllServiceList();
    }, [])

    useEffect(() => {
        if(searchValue?.length > 2){
        getGstList("INITIAL_PAGE");}
    }, [searchValue])


    // API FUNCTION
    const getGstList = async (paginationAction: TPaginationStatus, id?: number) => {
        setIsLoading(true);
        const response = await postData(`travel-way-gst/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: paginationAction,
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id: id ?? null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setGstList(response.data.data)
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            });
        };
    }

    const getAllServiceList = async () => {
        setIsLoading(true);
        const response = await getData(`static/travel-service-all-types`)
        setIsLoading(false);
        if (response.isSuccess) {
            console.log("Response for all services: ", response.data.data);
            setAllServiceList(response.data.data)
        } else {
            console.log("Fail to get all Service List for modal dropdown")
        };
    };

    const submitNewGst = async () => {
        setIsBtnLoading(true);
        const response = await postData(`travel-way-gst/create`, newGst);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "GST added successfully",
            })
            getGstList("INITIAL_PAGE");
            setIsOpenAddNewModal(false);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const updateGst = async () => {
        setIsBtnLoading(true);
        const response = await putData(`travel-way-gst/update`, selectedGst);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "GST updated successfully",
            })
            getGstList("INITIAL_PAGE");
            setSelectedGst(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    return {
        gstList,
        isLoading,
        isBtnLoading,
        isOpenAddNewModal,
        setIsOpenAddNewModal,
        searchValue,
        setSearchValue,
        allServiceList,
        newGst,
        setNewGst,
        submitNewGst,
        selectedGst,
        setSelectedGst,
        updateGst
    }
}