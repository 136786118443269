import React from "react";
import { MultiSelect, MultiSelectProps } from "@mantine/core";

export function EMultiSelect({ ...props }: MultiSelectProps) {
  return (
    <MultiSelect
      className={`${props?.data?.length && props?.data?.length > 15 ? `col-span-2` : ``}`}
      radius={"md"}
      size={"md"}
      maxDropdownHeight={220}
      mah={400}
      scrollAreaProps={{ scrollHideDelay: 0 }}
      searchable
      {...props}
      data={props?.data ? [{ label: "All", value: "all" }, ...props.data] : []}
      comboboxProps={{
        transitionProps: { transition: "fade-down", duration: 200 },
      }}
      clearable
      onChange={(value) => {
        console.log("value", value);
        console.log("data", props?.data);
        console.log("is include all", value?.includes("all") );
        const isAll = value?.includes("all");
        if (props?.onChange) {
          isAll ?
            props.onChange(
              props?.data ?
                props?.data?.map((each: any) => each?.value as string)
                : [])
              :
            props?.onChange(value);
        }
      }
      }
    />
  );
}
