import React from "react";
import { Space } from "@mantine/core";

import "./App.scss";

interface IProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  hideFooter?: boolean;
  pageModal?: React.ReactNode;
};

export default function PageLayout({ title, children, footer, pageModal , hideFooter }: IProps) {
  return (
    <div className="page-layout">
      {/* {title ? <div className="top-bar">{title}</div> : <></>} */}
      <div>
      <div className={` ${footer ? `layout-body-footer`: `layout-body`}`}>{pageModal  ? pageModal :children}</div>
      </div>
      <Space h={20} />
    {hideFooter ? <></> : footer ? <div className="footer border--top">{footer}</div> : <></>}
    </div>
  );
}
