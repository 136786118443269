import { createContext, Dispatch, SetStateAction } from "react";

import { IRoute, IUser } from "./types";
import { initialUser } from "./initialStates";
import { IUserAuthorities } from "./libs/data-access/types";

export interface IAuthContext {
  user: IUser;
  loader: boolean;
  setLoader: Dispatch<SetStateAction<boolean>>;
  handleUser: (value: IUser) => void;
  userAuthorities: IUserAuthorities[];
  setUserAuthorities: Dispatch<SetStateAction<IUserAuthorities[]>>;
  isSidebarCollapsed: boolean | undefined;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean | undefined>>;
  isLoggedIn: boolean | undefined;
  setIsLoggedIn: Dispatch<SetStateAction<boolean | undefined>>;
  token: string | undefined;
  setToken: Dispatch<SetStateAction<undefined | string>>;
  refreshToken: string | undefined;
  setRefreshToken: Dispatch<SetStateAction<undefined | string>>;
  role: string[] | undefined;
  setRole: Dispatch<SetStateAction<string[] | undefined>>;
  handleLogout: () => void;
  currentRotes: IRoute[] | undefined;
  setCurrentRoutes: Dispatch<SetStateAction<IRoute[]>>;
  targetRef: React.MutableRefObject<HTMLDivElement | null>;
  scrollTrigger: boolean;
  setScrollTrigger: Dispatch<SetStateAction<boolean>>;
  activeRoute: {
    route: IRoute;
    SubMenuIndex: number[];
  };
  setActiveRoute: Dispatch<
    SetStateAction<{
      route: IRoute;
      SubMenuIndex: number[];
    }>
  >;
  // setActiveRoute: (route: IRoute, SubMenuIndex : number[]) => void;
}

export const AppContext = createContext<IAuthContext>({
  user: initialUser,
  handleUser: () => {},
  loader: false,
  setLoader: () => {},
  userAuthorities: [],
  setUserAuthorities: () => {},
  isSidebarCollapsed: true,
  setIsSidebarCollapsed: () => {},
  isLoggedIn: true,
  setIsLoggedIn: () => {},
  token: undefined,
  setToken: () => {},
  refreshToken: undefined,
  setRefreshToken: () => {},
  role: undefined,
  setRole: () => {},
  handleLogout: () => {},
  currentRotes: undefined,
  setCurrentRoutes: () => {},
  targetRef: { current: null },
  scrollTrigger: false,
  setScrollTrigger: () => {},
  activeRoute: {
    route: {} as IRoute,
    SubMenuIndex: [],
  },
  setActiveRoute: () => {},
});
