import { useEffect, useRef } from "react";
import axios from "axios";
import { Notifications } from "@mantine/notifications";
import { LoadingOverlay, MantineProvider, createTheme } from "@mantine/core";

import { EAppShell, ENavbar, ETopbar } from "./libs/ui";
import ErrorBoundaryWrapper from "./ErrorBoundaryWrapper";

import "./App.scss";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

import { AppContext } from "./AppContext";
import useApp from "./Pages/Hooks/useApp";
import RootRoutes from "./Routes/RootRoutes";
import { filterRoutesByUserAuth } from "./utils/routeUtility";
import { useLocation } from "react-router-dom";

export const ALL_ALLOWED_ROUTES = filterRoutesByUserAuth();

console.log("ALL_ALLOWED_ROUTES---------------", ALL_ALLOWED_ROUTES);

function App() {
  const targetRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const {
    user,
    loader,
    setLoader,
    handleUser,
    userAuthorities,
    setUserAuthorities,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    isLoggedIn,
    setIsLoggedIn,
    token,
    setToken,
    refreshToken,
    setRefreshToken,
    role,
    setRole,
    handleLogout,
    handleContextStateUpdateOnMount,
    handleContextStateUpdateOnStateChange,
    currentRotes,
    setCurrentRoutes,
    scrollTrigger,
    setScrollTrigger,
    activeRoute,
    setActiveRoute,
  } = useApp();

  useEffect(() => {
    handleContextStateUpdateOnMount();
  }, []);

  useEffect(() => {
    handleContextStateUpdateOnStateChange();
  }, [
    user,
    userAuthorities,
    isSidebarCollapsed,
    isLoggedIn,
    token,
    role,
    activeRoute,
  ]);

  const getToken = () => {
    let tkn = localStorage.getItem("token");
    return tkn || token;
  };
  const getIsLoggedIn = () => {
    let tkn = localStorage.getItem("isLoggedIn");
    return tkn || isLoggedIn;
  };

  const getUserAuthorities = () => {
    let auth = localStorage.getItem("userAuthorities");
    let parsAuth = JSON.parse(auth as string);
    return parsAuth || userAuthorities;
  };

  // AXIOS INTERCEPTOR START
  // -- interceptor for each requests --
  axios.interceptors.request.use(
    (request: any) => {
      if (request.url.includes("auth")) {
      } else if (request.url.includes("unsecure")) {
        request.headers["X-TENANT-ID"] = "pml";
      } else {
        request.headers["Authorization"] = `Bearer ${getToken()}`;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // -- interceptor for each responce --
  axios.interceptors.response.use(
    undefined,
    function axiosRetryIntercptor(err) {
      if (err?.response?.status === 401) {
        console.error("Oops...!", "You are unauthorized");
        console.log(err.response);
      }
      return Promise.reject(err);
    }
  );
  // AXIOS INTERCEPTOR ENDS

  return (
    // AppContext Wrapper
    <AppContext.Provider
      value={{
        user,
        loader,
        setLoader,
        handleUser,
        userAuthorities,
        setUserAuthorities,
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isLoggedIn,
        setIsLoggedIn,
        token,
        setToken,
        refreshToken,
        setRefreshToken,
        handleLogout,
        role,
        setRole,
        currentRotes,
        setCurrentRoutes,
        targetRef,
        scrollTrigger,
        setScrollTrigger,
        activeRoute,
        setActiveRoute,
      }}
    >
      {/* Mantine Wrapper */}
      <MantineProvider theme={CUSTOM_MANTINE_THEME}>
        {/* Mantine Notification Wrapper */}
        <Notifications
          position="top-right"
          style={{ marginTop: "8px", marginBottom: "8px", height: "auto" }}
        />
        {/* App Level Error Boundary */}
        <ErrorBoundaryWrapper>
          {getIsLoggedIn() && getToken() ? (
            !ALL_ALLOWED_ROUTES.length ? (
              <>{window.location.reload()} </>
            ) : (
              <EAppShell
                navbar={<ENavbar />}
                topbar={<ETopbar />}
                // footer={}
              >
                {/* <Home/> */}
                <RootRoutes />
              </EAppShell>
            )
          ) : (
            <RootRoutes />
          )}
          <LoadingOverlay
            visible={loader}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
        </ErrorBoundaryWrapper>
      </MantineProvider>
    </AppContext.Provider>
  );
}

export default App;

const CUSTOM_MANTINE_THEME = createTheme({
  colors: {
    // Add your color
    deepBlue: [
      "#eef3ff",
      "#dce4f5",
      "#b9c7e2",
      "#94a8d0",
      "#748dc1",
      "#5f7cb8",
      "#5474b4",
      "#44639f",
      "#39588f",
      "#2d4b81",
    ],
    // or replace default theme color
    blue: [
      "#eef3ff",
      "#dee2f2",
      "#bdc2de",
      "#98a0ca",
      "#7a84ba",
      "#6672b0",
      "#5c68ac",
      "#4c5897",
      "#424e88",
      "#364379",
    ],
  },

  // shadows: {
  //   md: '1px 1px 3px rgba(0, 0, 0, .25)',
  //   xl: '5px 5px 3px rgba(0, 0, 0, .25)',
  // },

  // headings: {
  //   fontFamily: 'Roboto, sans-serif',
  //   sizes: {
  //     h1: { fontSize: rem(36) },
  //   },
  // },

  // Font sizes in px, other units are not supported
  fontFamily: "Inter, sans-serif",
  fontFamilyMonospace: "Monaco, Courier, monospace",
  fontSizes: {
    xs: "12",
    sm: "14",
    md: "16",
    lg: "18",
    xl: "22",
  },
});
