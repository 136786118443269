import { DateValue } from "@mantine/dates";
import moment from "moment";
import useMaster from "hook/useMaster";
import { showToast } from "utils/utility";
import { useEffect, useState } from "react";
import { useApi } from "libs/data-access/src/api/useApi";
import { IMarkupB2BFilter, TPaginationStatus } from "types";
import { PAGINATION_ITEM_LIMIT } from "libs/data-access/constant";
import { useLocation } from "react-router-dom";

const useViewMarkupB2B = () => {

  const { state } = useLocation();
  const { service } = state || {};

  const { postData, patchData } = useApi();
  const {  
    serviceTypes, 
    groupTypes, 
    getServiceTypes, 
    getAllGroupTypes 
  } = useMaster();

  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingIndex, setIsLoadingIndex] = useState<number| undefined>(undefined);
  const [markupList, setMarkupList] = useState<Array<any>>();

  const [filterOption, setFilterOption] = useState<IMarkupB2BFilter>({
    service: service ? service : "HOTEL",
  } as IMarkupB2BFilter);

  const markupListEndpoints = {
      FLIGHT: "flight-markup-form/get-all",
      HOTEL: "hotel-markup-form/get-all",
      BUS: "bus-markup-form/get-all",
    };

  useEffect(() => {
    getServiceTypes();
    getAllGroupTypes();
  }, []);

  useEffect(() => {
    if (filterOption.service) {
      getMarkupList("INITIAL_PAGE");
    }
  }, [filterOption.service]);

  const handleFilterOption = (
    key: keyof IMarkupB2BFilter,
    value: string | DateValue
  ) => {
    setFilterOption((prev) => ({ ...prev, [key]: value }));
  };

  // API Function
  const getMarkupList = async (paginationAction: TPaginationStatus) => {
    setLoading(true);
    const { startDate, endDate } = filterOption;
    const sDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    const eDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    const body = {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        sortingOrder: "ASC",
      },
      affiliateGroupId: filterOption?.group || null,
      startDate: sDate,
      endDate: eDate,
      userType: "AFFILIATE",
    };

    const response = await postData(
      markupListEndpoints[filterOption.service],
      body
    );
    if (response.isSuccess) {
      setMarkupList(response.data.data);
    } else {
      setMarkupList([]);
      showToast({
        type: "error",
        message: response.message ?? "Something went wrong",
      });
    }
    setLoading(false);
  };

  const updateMarkupB2BStatus = async (
    id: number,
    status: boolean,
    index: number
  ) => {
    setIsLoadingIndex(index);
    const saveFormEndPoints = {
      FLIGHT: "flight-markup-form/",
      HOTEL: "hotel-markup-form/",
      BUS: "bus-markup-form/",
    };

    const response = await patchData(`${saveFormEndPoints[filterOption.service]}${status ? `active`: `inActive`}/${id}`, {});
    if (response.isSuccess) {
      showToast({
        type: "success",
        title: "Success",
        message: "Markup Updated Successfully",
      });
      const markupListCopy = [...(markupList || [])];
      markupListCopy[index].status = status;
      setMarkupList(markupListCopy)
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: response.message,
      });
    }
    setIsLoadingIndex(undefined);
    
  }
  
  return {
    loading,
    isLoadingIndex,
    serviceTypes,
    groupTypes,
    filterOption,
    markupList,
    getMarkupList,
    handleFilterOption,
    getServiceTypes,
    getAllGroupTypes,
    setFilterOption,
    updateMarkupB2BStatus
  };
};

export default useViewMarkupB2B;
