import { Button, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ESelect, ETable } from "../../../../libs/ui";
import { useApplyFor } from "./hook/useApplyFor";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function ApplyFor() {

    const {
        isLoading,
        isBtnLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        applyForList,
        newApplyFor,
        setNewApplyFor,
        selectedApplyFor,
        setSelectedApplyFor,
        submitNewApplyFor,
        UpdateStatus,
        UpdateFlightFor,
        deleteFlightFor,
        getApplyForList
    } = useApplyFor();

    return (
        <PageLayout>
            <EPageTitle
                title="Flight Apply For"
                end={
                    <div className="flex gap-1">
                        <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton>
                    </div>
                }
            />
            {/* <div className="flex gap-2 items-start">
                <TextInput
                    placeholder="Search"
                    leftSection={
                        <BsSearch />
                    }
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <div
                    className="grow">
                </div>

                <PrintableTable
                    title="Flight Apply For"
                    data={applyForList.map((e: IApplyFor) => ({ name: e?.name, travelServiceCategory: e?.travelServiceCategory, formType: e?.formType, status: e?.status ? "Active" : "Inactive" }))}
                />

            </div> */}


            <ETable
             loading={isLoading}
             pagination
             onChangePagination={(e) => getApplyForList(e) }
             >
                <thead>
                    <tr>
                        {/* <th> SR. No.</th> */}
                        <th> Apply For</th>
                        <th> Travel Service Category</th>
                        <th> Form Type</th>
                        <th> Status</th>
                        <th> </th>
                    </tr>
                </thead>
                {
                        applyForList?.length === 0
                            ?
                            <></>
                            :
                            <tbody>
                                {
                                    applyForList?.map((each, index) => {
                                        return (
                                            <tr>
                                                {/* <td>{index + 1}</td> */}
                                                <td>{each?.name ?? ""}</td>
                                                <td>{each?.travelServiceCategory ?? ""}</td>
                                                <td>{each?.formType?.replaceAll("_", " ") ?? ""}</td>
                                                <td>
                                                    <ESwitch
                                                        label={each.status ? "Active" : "Inactive"}
                                                        loading={isBtnLoadingIndex === index}
                                                        checked={!!each?.status}
                                                        onChange={() => {     
                                                            each?.id && UpdateStatus(each?.id, !each?.status, index ) 
                                                        }}
                                                        withConfirmation
                                                        confirmationTitle="Are you sure?"
                                                        confirmationDescription="Do you want to change the status?"
                                                    />
                                                </td>
                                                <td
                                                    className="flex w-full gap-1 items-center justify-end">
                                                    <Tooltip label="Edit" position="top" >
                                                        <div>
                                                            <FaRegEdit
                                                                size={18}
                                                                className="cursor-pointer text-green-400 hover:text-green-600"
                                                                onClick={() => {
                                                                    setIsOpenModalValue("Edit")
                                                                    setSelectedApplyFor(each)
                                                                }}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                    {/* <Tooltip label="Delete">
                                                        <div>
                                                            <MdDeleteForever
                                                                size={22}
                                                                className="cursor-pointer text-orange-400 hover:text-orange-600"
                                                                onClick={() => {
                                                                    setIsOpenModalValue("Delete")
                                                                    setSelectedApplyFor(each)
                                                                }}
                                                            />
                                                        </div>
                                                    </Tooltip> */}
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                }

            </ETable>
           

            {/* ADD NEW Modal */}
            <EModal
                title="New Flight Apply For"
                opened={isOpenModalValue === "New"}

                onClose={() => {
                    setNewApplyFor({ name: "", travelServiceCategory: "", formType: "" });
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Flight Apply For Name"
                        placeholder="Enter Name"
                        value={newApplyFor?.name}
                        onChange={(e) => setNewApplyFor({ ...newApplyFor, name: e.target.value })}
                    />
                    <ESelect
                        label="Travel Service Category"
                        placeholder="Select"
                        data={["FLIGHT", "HOTEL"]}
                        value={newApplyFor?.travelServiceCategory}
                        onChange={(e) => setNewApplyFor({ ...newApplyFor, travelServiceCategory: e ?? '' })}
                    />

                    <ESelect
                        label="Form Type"
                        placeholder="Select"
                        data={["COMMISSION"]}
                        value={newApplyFor?.formType}
                        onChange={(e) => setNewApplyFor({ ...newApplyFor, formType: e ?? '' })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newApplyFor?.name && newApplyFor?.travelServiceCategory && newApplyFor?.formType)}
                            loading={isBtnLoading}
                            onClick={() => submitNewApplyFor()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Flight Apply For */}
            <EModal
                title="Edit Flight Apply For"
                opened={!!(isOpenModalValue === "Edit")}

                onClose={() => {
                    setSelectedApplyFor(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                {
                    selectedApplyFor &&
                    <div className="w-full flex flex-col gap-2 ">
                        <TextInput
                            label="Flight Apply For Name"
                            placeholder="Enter Name"
                            value={selectedApplyFor?.name}
                            onChange={(e) => setSelectedApplyFor({ ...selectedApplyFor, name: e.target.value })}
                        />
                        <ESelect
                            label="Travel Service Category"
                            placeholder="Select"
                            data={["FLIGHT", "HOTEL"]}
                            value={selectedApplyFor?.travelServiceCategory}
                            onChange={(e) => setSelectedApplyFor({ ...selectedApplyFor, travelServiceCategory: e ?? '' })}
                        />

                        <ESelect
                            label="Form Type"
                            placeholder="Select"
                            data={["COMMISSION"]}
                            value={selectedApplyFor?.formType}
                            onChange={(e) => setSelectedApplyFor({ ...selectedApplyFor, formType: e ?? '' })}
                        />


                        <div className="flex justify-end mt-2">
                            <Button
                                disabled={!(selectedApplyFor?.name && selectedApplyFor?.travelServiceCategory && selectedApplyFor?.formType)}
                                loading={isBtnLoading}
                                onClick={() => selectedApplyFor?.id && UpdateFlightFor()}
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                }
            </EModal>

            <EModal
                title="Delete Flight Apply For"
                opened={isOpenModalValue === "Delete"}
                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2 ">
                    <p>Are you sure you want to delete this Flight Apply For?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedApplyFor(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedApplyFor?.id && deleteFlightFor(selectedApplyFor?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
