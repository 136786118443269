import { useStyles } from "@mantine/core";
import { useApi } from "libs/data-access/src/api/useApi";
import { useState } from "react";
import {
  IDropdonwn,
  IServices,
  TFormTypes,
  TravelFormFeeRequest,
  TTravelServiceCategory,
} from "types";
import { mapDropdownData } from "utils/utility";

const useMaster = () => {
  const { getData, postData } = useApi();
  const [travelServiceCategories, setTravelServiceCategories] =
    useState<Array<TravelFormFeeRequest>>();
  const [markupfeeNameIds, setMarkupFeeNameIds] =
    useState<Array<{ id: number; name: string }>>();
  const [airportByCoutries, setAirportsByCountries] =
    useState<Array<IDropdonwn>>();
  const [airportByDestinationCoutries, setAirportsByDestinationCountries] =
    useState<Array<IDropdonwn>>();
  const [flightJourneyTypes, setFlightJourneyTypes] =
    useState<Array<IDropdonwn>>();
  const [discountCategoryTypes, setDiscountCategoryTypes] =
    useState<Array<IDropdonwn>>();
  const [additionalChargeTypes, setAdditionalChargeTypes] =
    useState<Array<IDropdonwn>>();
  const [additionalSubChargeTypes, setAdditionalSubChargeTypes] =
    useState<Array<IDropdonwn>>();
  const [userCategoryType, setUserCategoryTypes] =
    useState<Array<IDropdonwn>>();
  const [bookingChargeCategories, setBookingChargeCategories] =
    useState<Array<IDropdonwn>>();
  const [affiliates, setAffiliates] = useState<Array<IDropdonwn>>();
  const [ruleTypes, setRuleTypes] = useState<Array<IDropdonwn>>();
  const [affiliateUsers, setAffiliateUsers] = useState<Array<any>>();
  const [customerTypes, setCustomerTypes] = useState<Array<IDropdonwn>>();
  const [groupTypes, setGroupTypes] = useState<Array<IDropdonwn>>();
  const [amountTypes, setAmountTypes] = useState<Array<IDropdonwn>>();
  const [discountTypes, setDiscountTypes] = useState<Array<IDropdonwn>>();
  const [busDestinations, setBusDestination] = useState<Array<IDropdonwn>>();
  const [busCategoryTypes, setBusCategoryTypes] = useState<Array<IDropdonwn>>();
  const [busSeatTypes, setBusSeatTypes] = useState<Array<IDropdonwn>>();
  const [hotelPlaces, setHotelPlaces] = useState<Array<IDropdonwn>>();
  const [hotelCategories, setHotelCategories] = useState<Array<IDropdonwn>>();
  const [serviceTypes, setServiceTypes] = useState<Array<IDropdonwn>>();
  const [applyForTypes, setApplyForTypes] = useState<Array<IDropdonwn>>();
  const [markupTypes, setMarkupTypes] = useState<Array<IDropdonwn>>();
  const [fareTypes, setFareTypes] = useState<Array<IDropdonwn>>();
  const [applyOnTypes, setApplyOnTypes] = useState<Array<IDropdonwn>>();
  const [applyTypes, setApplyTypes] = useState<Array<IDropdonwn>>();
  const [searchTypes, setSearchTypes] = useState<Array<IDropdonwn>>([]);
  const [allCountryList, setAllCountryList] = useState<Array<IDropdonwn>>();
  const [allStateList, setAllStateList] = useState<Array<IDropdonwn>>();
  const [supplierList, setSupplierList] = useState<Array<IDropdonwn>>();
  const [airlinesList, setAirlinesList] = useState<Array<IDropdonwn>>();
  const [allFeeTypes, setAllFeeTypes] = useState<Array<IDropdonwn>>();
  const [paymentGateway, setPaymentGateway] = useState<Array<IDropdonwn>>();
  const [paymentMode, setPaymentMode] = useState<Array<IDropdonwn>>();
  const [allCompanyTypes, setAllCompanyTypes] = useState<Array<IDropdonwn>>();

  const [flightAllClassTypes, setFlightAlltypes] =
    useState<Array<IDropdonwn>>();
  const [flightCabinClassTypes, setFlightCabinClassTypes] =
    useState<Array<IDropdonwn>>();

  const getServiceTypes = async () => {
    const response = await getData(`static/travel-service-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setServiceTypes(types);
      return types;
    } else {
      console.log("Fail to get all Service List for modal dropdown", response);
    }
  };

  const getApplyForTypes = async (
    travelServiceCategory: TTravelServiceCategory,
    formType: TFormTypes
  ) => {
    const response = await postData(
      `charge-apply-for-type/get-charge-apply-for-by-type?travelServiceCategory=${travelServiceCategory}&formType=${formType}`,
      {}
    );
    if (response.isSuccess && response.data?.data?.length) {
const types = mapDropdownData(response.data.data);
      setApplyForTypes(types);
    } else {
      console.log("Fail to get all apply for type modal dropdown", response);
    }
  };

  const getMarkupTypes = async () => {
    const response = await getData(
      `booking-type/form/to-show/all-booking-types`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setMarkupTypes(types);
    } else {
      console.log("Fail to get markup types", response);
    }
  };

  const getFareTypes = async () => {
    const response = await getData(
      `flight-fare/form/to-show/get-all-fare-types`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setFareTypes(types);
    } else {
      console.log("Fail to get fare types", response);
    }
  };

  const getApplyOnTypes = async () => {
    const response = await getData(
      `charge-apply-on-type/form/to-show/get-all-charge-apply-on-types`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setApplyOnTypes(types);
    } else {
      console.log("Fail to get apply on types", response);
    }
  };

  const getApplyTypes = async () => {
    const response = await getData(
      `flight-apply/form/to-show/get-all-flight-apply-types`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setApplyTypes(types);
    } else {
      console.log("Fail to get apply types", response);
    }
  };

  const getSearchTypes = async () => {
    const response = await getData(
      `flight-journey/form/to-show/get-all-flight-journey-types`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setSearchTypes(types);
    } else {
      console.log("Fail to get search types", response);
    }
  };

  const getAllCountryList = async () => {
    const response = await getData(`country/form/get-all-country`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setAllCountryList(types);
    } else {
      console.log("Fail to get country list", response);
    }
  };

  const getAllStateListByCountryId = async (countryId: number) => {
    const response = await getData(`state/get-states-by-country-id?countryId=${countryId}`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setAllStateList(types);
    } else {
      console.log("Fail to get country list", response);
    }
  };

  const getFlightAllClassTypes = async () => {
    const response = await getData(`static/flight-class-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setFlightAlltypes(types);
    } else {
      console.log("Fail to get flight all types", response);
    }
  };

  const getFlightCabinClassTypes = async () => {
    const response = await getData(`static/flight-cabin-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setFlightCabinClassTypes(types);
    } else {
      console.log("Fail to get flight cabin class types", response);
    }
  };

  const getSupplierList = async (formType: "FLIGHT" | "HOTEL" | "BUS") => {
    const endpoints = {
      FLIGHT: "flight-ticket-supplier/form/get-all-ticket-supplier",
      HOTEL:
        "hotel-booking-supplier/form/to-show/get-all-hotel-booking-suppliers",
      BUS: "bus-ticket-supplier/get-all-supplier",
    };
    const response = await getData(endpoints[formType]);
    if (response.isSuccess) {
      const suppliers = mapDropdownData(response.data.data);
      setSupplierList(suppliers);
    } else {
      console.log("Fail to get suppliers list", response);
    }
  };

  const getAirlinesBySuppliers = async (supplierIds: string[]) => {
    const response = await postData(
      `flight-airline/get-airline-by-supplier-ids?ids=${supplierIds.join(",")}`,
      {}
    );
    if (response.isSuccess) {
      const suppliers = mapDropdownData(response.data.data);
      setAirlinesList(suppliers);
    } else {
      console.log("Fail to get airlines list", response);
      setAirlinesList([])
    }
  };

  const getAllFeeTypes = async () => {
    const response = await getData(`static/fee-all-types`);
    if (response.isSuccess) {
      const suppliers = mapDropdownData(response.data.data);
      setAllFeeTypes(suppliers);
    } else {
      console.log("Fail to get all fee types list", response);
    }
  };
  const getMarkupFeeNameIds = async () => {
    const body = {
      paginationFilterRequest: {
        paginationAction: "INITIAL_PAGE",
        id: null,
        sortingOrder: "ASC",
      },
    };
    const response = await postData(`fee-type/get-all`, body);
    if (response.isSuccess && response?.data?.data?.length) {
      setMarkupFeeNameIds(response.data.data);
      } else {
      console.log("Fail to get markup fee name list", response);
    }
  };

  const getHotelCategories = async () => {
    const body = {
      paginationFilterRequest: {
        paginationAction: "INITIAL_PAGE",
        id: null,
        sortingOrder: "ASC",
      },
    };
    const response = await postData("hotel-category/get-all", body);
    if (response.isSuccess && response?.data?.data?.length) {
      const types = mapDropdownData(response.data.data);
      setHotelCategories(types);
    } else {
      console.log("Fail to get hotel categories", response);
    }
  };

  const getHotelPlacesByCountries = async (countryIds: string[]) => {
    const response = await getData(
      `place/get-places-by-country-ids?ids=${countryIds.join(",")}`
    );
    if (response.isSuccess && response?.data?.data?.length) {
      const types = mapDropdownData(response.data.data);
      setHotelPlaces(types);
    } else {
      console.log("Fail to get hotel places by countries", response);
      setHotelPlaces([])
    }
  };

  const getBusSeatTypes = async () => {
    const response = await getData(`static/bus-seat-category-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setBusSeatTypes(types);
    } else {
      console.log("Fail to get bus seat types", response);
    }
  };

  const getBusCategoryTypes = async () => {
    const response = await getData(`static/bus-category-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setBusCategoryTypes(types);
    } else {
      console.log("Fail to get bus categories types", response);
    }
  };

  const getBusDestinations = async () => {
    const body = {
      paginationFilterRequest: {
        paginationAction: "INITIAL_PAGE",
        id: null,
        sortingOrder: "ASC",
      },
      fetchStaticDestination: true,
    };
    const response = await postData(`place/admin/get-all-places`, body);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setBusDestination(types);
    } else {
      console.log("Fail to get bus destinations types", response);
    }
  };

  const getDicountTypes = async () => {
    const response = await getData(`static/discount-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setDiscountTypes(types);
    } else {
      console.log("Fail to get dicount  types", response);
    }
  };

  const getCustomerTypes = async () => {
    const response = await getData(`static/customer-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setCustomerTypes(types);
    } else {
      console.log("Fail to get customer types", response);
    }
  };

  const getAllAirportByCountries = async (countries: Array<string>) => {
    const response = await getData(
      `airport/get-all-airports-by-country-ids?countryIds=${countries.join(
        ","
      )}`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data);
      setAirportsByCountries(types);
    } else {
      setAirportsByCountries([])
      console.log("Fail to get airpots by countries", response);
    }
  };

  const getAllAirportByDestinationCountries = async (
    countries: Array<string>
  ) => {
    const response = await getData(
      `airport/get-all-airports-by-country-ids?countryIds=${countries.join(
        ","
      )}`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data);
      setAirportsByDestinationCountries(types);
    } else {
      setAirportsByDestinationCountries([])
      console.log("Fail to get airpots by destination countries", response);
    }
  };

  const getFlightJourrneyTypes = async () => {
    const response = await getData(
      `flight-journey/form/to-show/get-all-flight-journey-types`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setFlightJourneyTypes(types);
    } else {
      setFlightJourneyTypes([])
      console.log("Fail to get flight journey types", response);
    }
  };

  const getAmountTypes = async () => {
    const response = await getData(`static/amount-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setAmountTypes(types);
    } else {
      console.log("Fail to get amount types", response);
    }
  };

  const getDicountCategoryTypes = async () => {
    const response = await getData(`static/discount-category-all-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setDiscountCategoryTypes(types);
    } else {
      console.log("Fail to get discount category types", response);
    }
  };

  const getAllGroupTypes = async () => {
    const response = await getData(`affiliate-group/form/get-all-group`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setGroupTypes(types);
      return types
    } else {
      console.log("Fail to get group category types", response);
    }
  };

  const getAffiliateUsers = async () => {
    const response = await getData(`affiliate/get-all-affiliate-users`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setAffiliateUsers(types);
    } else {
      console.log("Fail to get affiliate users", response);
    }
  };

  const getAdditionalChargeTyps = async () => {
    const response = await getData(
      `additional-charge-type/get-all-charge-types`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data);
      setAdditionalChargeTypes(types);
    } else {
      console.log("Fail to get additional charge types", response);
    }
  };

  const getAdditionalSubChargeTypesByCharge = async (chargeId: string) => {
    const response = await postData(
      `additional-sub-charge-type/get-all-sub-charge-types-from-charge-type-id?chargeTypeId=${chargeId}`,{}
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data);
      setAdditionalSubChargeTypes(types);
    } else {
      console.log("Fail to get additional charge types", response);
      setAdditionalSubChargeTypes([])
    }
  };

  const getRuleTypes = async () => {
    const response = await getData(`static/rule-types`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setRuleTypes(types);
    } else {
      console.log("Fail to get rule types", response);
    }
  };

  const getTravelServiceCategories = async (
    travelServiceCategory: IServices,
    formType: TFormTypes
  ) => {
    const response = await postData(
      `travel-way-form-fee-activity/form/travel-service-form-fee-type/get-all?travelServiceCategory=${travelServiceCategory}&formType=${formType}`,
      {}
    );
    if (response.isSuccess) {
      const categories = response.data.data.map((item: any) => {
        const obj = { ...item };
        obj["fee"] = "";
        obj["feeType"] = "";
        return obj;
      });
      setTravelServiceCategories(categories);
    } else {
      console.log("Fail to get travel service category types", response);
    }
  };

  const getUserCategoryTypes = async () => {
    const response = await getData(`static/user-category`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setUserCategoryTypes(types);
    } else {
      console.log("Fail to get user category types", response);
    }
  };

  const getBookingChargeCategories = async () => {
    const response = await getData(`static/booking-charge-category`);
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setBookingChargeCategories(types);
    } else {
      console.log("Fail to get booking charge categories", response);
    }
  };

  const  getAffiliatedByGroup = async (groupId: string) => {
    const response = await getData(
      `affiliate-group/get-affiliate-by-affiliate-group-id?affiliateGroupIds=${groupId}`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setAffiliates(types);
      return types
    } else {
      console.log("Fail to get affiliate by group", response);
      setAffiliates([])
    }
  };

  const  getPaymentGateway = async () => {
    const response = await getData(
      `payment-gateway/get-all-payment-gateway`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setPaymentGateway(types);
    } else {
      console.log("Fail to get affiliate by group", response);
      setPaymentGateway([])
    }
  };
  const  getPaymentModeBasedOnGateway = async (paymentGatewayID: number) => {
    const response = await postData(
      `payment-mode/get-all-by-payment-gateway-id?paymentGatewayId=${paymentGatewayID}`,{}
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setPaymentMode(types);
    } else {
      console.log("Fail to get affiliate by group", response);
      setPaymentMode([])
    }
  };
  const  getAllCompanyTypes = async () => {
    const response = await getData(
      `static/company-type`
    );
    if (response.isSuccess) {
      const types = mapDropdownData(response.data.data);
      setAllCompanyTypes(types);
    } else {
      console.log("Fail to get affiliate by group", response);
      setAllCompanyTypes([])
    }
  };

  return {
    fareTypes,
    ruleTypes,
    applyTypes,
    hotelPlaces,
    markupTypes,
    serviceTypes,
    searchTypes,
    groupTypes,
    amountTypes,
    applyForTypes,
    affiliateUsers,
    applyOnTypes,
    allCountryList,
    allStateList,
    supplierList,
    airlinesList,
    allFeeTypes,
    busSeatTypes,
    discountTypes,
    hotelCategories,
    markupfeeNameIds,
    busCategoryTypes,
    busDestinations,
    customerTypes,
    affiliates,
    discountCategoryTypes,
    flightJourneyTypes,
    additionalChargeTypes,
    additionalSubChargeTypes,
    airportByCoutries,
    flightAllClassTypes,
    flightCabinClassTypes,
    userCategoryType,
    bookingChargeCategories,
    travelServiceCategories,
    airportByDestinationCoutries,
    paymentGateway,
    paymentMode,
    allCompanyTypes,
    getRuleTypes,
    getAllGroupTypes,
    getFareTypes,
    getApplyTypes,
    getServiceTypes,
    getAllCountryList,
    getAllStateListByCountryId,
    getApplyForTypes,
    getMarkupTypes,
    getSearchTypes,
    getApplyOnTypes,
    getSupplierList,
    getAllFeeTypes,
    getAmountTypes,
    getBusSeatTypes,
    getAffiliateUsers,
    getBusDestinations,
    getMarkupFeeNameIds,
    getBusCategoryTypes,
    getAirlinesBySuppliers,
    getFlightAllClassTypes,
    getFlightCabinClassTypes,
    getHotelCategories,
    getDicountTypes,
    getCustomerTypes,
    getAdditionalChargeTyps,
    getAdditionalSubChargeTypesByCharge,
    getDicountCategoryTypes,
    getFlightJourrneyTypes,
    getAllAirportByCountries,
    getHotelPlacesByCountries,
    getTravelServiceCategories,
    getUserCategoryTypes,
     getAffiliatedByGroup,
    getBookingChargeCategories,
    getAllAirportByDestinationCountries,
    getPaymentGateway,
    getPaymentModeBasedOnGateway,
    getAllCompanyTypes,
  };
};

export default useMaster;
