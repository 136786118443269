import { Badge, Divider, NumberFormatter, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useApi } from 'libs/data-access/src/api/useApi';
import { IFlightOnlineBookingList } from 'libs/data-access/types';
import { EModal, ETable } from 'libs/ui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LuBaggageClaim } from 'react-icons/lu';
import { PiAirplaneInFlight } from 'react-icons/pi';
import { I1GPenalty, IAirportDetails } from 'types';
import { convertToArray, getAirLineData } from 'utils/utility';

const FlightDetails = ({ flight }: { flight: IFlightOnlineBookingList }) => {

    const { postData } = useApi();
    const [airportDetails, setAirportDetails] = useState<IAirportDetails[]>([]);

    const [showFareRules, { open: OpenFareRules, close: closeFareRules }] = useDisclosure(false);

    const fetchAirportInformation = async () => {
        let allAirportCode:any[] = [];
        flight?.bookingResponses?.forEach((trip) => {
            trip?.galileoBookingDetailResponses?.forEach((journey) => {
                journey?.flightBookingSegments?.forEach((segment) => {
                    allAirportCode = [...allAirportCode, segment.origin, segment.destination]
                })
            })
            trip?.navitaireBookingDetailResponses?.forEach((journey) => {
                journey?.navitaireBookingDetailSegments?.forEach((segment) => {
                    allAirportCode = [...allAirportCode, segment.origin, segment.destination]
                })
            })
        })

        const response = await postData(`unsecure/flight/one-g/get-airport-response?codes=${allAirportCode}`, null);
        if (response.isSuccess) {
            // console.log('Response:', response.data);
            setAirportDetails(response.data?.data);
        } else {
            console.error('Error occurred while requesting for Air Pricing:', response);
        };
    };

    useEffect(() => {
        fetchAirportInformation();
    }, []);


    return (
        <div>
            <div>
                {/* Flight Details Galileo */}
                {
                    flight?.bookingResponses?.map((trip) => trip?.galileoBookingDetailResponses?.map((props, index) =>
                    (
                        <div key={flight.uuid + "_flight_details"} className="mb-5 p-3">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center gap-5">
                                    {/* ORIGIN/FROM */}
                                    <div className="text-lg">
                                        {
                                            airportDetails?.find(each => each.airportCode === props?.origin)?.placeName
                                        }
                                    </div>
                                    <PiAirplaneInFlight size={24} />
                                    {/* DESTINATION/TO */}
                                    <div className="text-lg">
                                        {
                                            airportDetails?.find(each => each.airportCode === props?.destination)?.placeName
                                        }
                                    </div>
                                </div>

                                <div>
                                    <p
                                        className="text-sm text-blue-500 cursor-pointer hover:underline"
                                        onClick={OpenFareRules}
                                    >View Fare Rules</p>
                                </div>
                            </div>

                            {
                                props?.flightBookingSegments?.map((segment, i) => (
                                    <div key={flight.uuid + "__segment_details" + i}>
                                        <div>
                                            <div className="mt-8">
                                                <div className="grid grid-cols-12">
                                                    <div className="col-span-5">
                                                        {/* ORIGIN/FROM */}
                                                        <div className="flex flex-col">
                                                            <p className="text-lg font-semibold">{moment(segment.departureTime).format("HH:mm")}</p>
                                                            <p>{moment(segment.departureTime).format("dddd, MMM D")}</p>
                                                            <p className="font-semibold">{airportDetails?.find(each => each.airportCode === segment.origin)?.placeName} ({segment.origin})</p>
                                                            <p className="text-navy-blue text-xs">
                                                                {
                                                                    airportDetails?.find(each => each.airportCode === segment.origin)?.airportName
                                                                } {segment?.originTerminal ? `(T${segment?.originTerminal})` : "(N/A)"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <div className="h-full flex items-center justify-center">
                                                            <div className="border-[1.5px] w-full rounded-md border-green-600" />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-5">
                                                        {/* DESTINATION/TO */}
                                                        <div className="flex flex-col items-end w-full">
                                                            <p className="text-lg font-semibold">{moment(segment.arrivalTime).format("HH:mm")}</p>
                                                            <p>{moment(segment.arrivalTime).format("dddd, MMM D")}</p>
                                                            <p className="font-semibold">{airportDetails?.find(each => each.airportCode === segment.destination)?.placeName} ({segment.destination})</p>
                                                            <p className="text-navy-blue text-xs">
                                                                {
                                                                    airportDetails?.find(each => each.airportCode === segment.destination)?.airportName
                                                                } {segment?.destinationTerminal ? `(T${segment?.destinationTerminal})` : "(N/A)"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-8 flex gap-3">
                                                <Badge autoContrast color="red" variant="light">{moment(segment.departureTime).format("dddd, MMM D")}</Badge>
                                                <Badge autoContrast color="orange" variant="light">{segment.cabin}</Badge>
                                                {segment?.cabin ? <Badge autoContrast color="blue" variant="light">Cabin Class: {segment.cabin}</Badge> : <></>}
                                            </div>

                                            <div className="mt-5 flex items-center gap-12">
                                                <div className="h-10 w-10 rounded-md">
                                                    <img
                                                        src={getAirLineData(segment.carrier).logo}
                                                        alt="flight logo"
                                                        loading="lazy"
                                                        className="h-10 w-10 object-cover rounded-lg"
                                                    />
                                                </div>

                                                <div className="flex items-center gap-3">
                                                    <p className="font-semibold">
                                                        {getAirLineData(segment.carrier).name}
                                                    </p>
                                                    <div>{`${segment.carrier}-${segment.flightNumber}`}</div>
                                                </div>

                                                <div className="flex items-center gap-1">
                                                    <LuBaggageClaim size={24} />
                                                    <span>Baggage: <span className="font-semibold">
                                                        {
                                                            segment.maxWeight
                                                                ?
                                                                `${segment.maxWeight} ${segment.unit === "Kilograms" ? "Kg" : "Lbs"}`
                                                                :
                                                                "N/A"
                                                        }
                                                    </span></span>
                                                </div>

                                                {/* <div className="flex items-center gap-1">
                                                            <LuBaggageClaim size={24} />
                                                            <span>Cabin Baggage: <span className="font-semibold">{"7 kg"}</span></span>
                                                        </div> */}
                                            </div>
                                        </div>
                                        <Divider variant="dashed" my="lg" />
                                    </div>
                                ))
                            }
                        </div>
                    )))}
                {/* FLIGHT DETAILS NAVITAIRE */}
                {flight?.bookingResponses?.map((trip) => trip?.navitaireBookingDetailResponses?.map((props, index) => (
                    <div key={flight.uuid + "_flight_details"} className="mb-5 p-3">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-5">
                                {/* ORIGIN/FROM */}
                                <div className="text-lg">
                                    {
                                        airportDetails?.find(each => each.airportCode === props?.origin)?.placeName
                                    }
                                </div>
                                <PiAirplaneInFlight size={24} />
                                {/* DESTINATION/TO */}
                                <div className="text-lg">
                                    {
                                        airportDetails?.find(each => each.airportCode === props?.destination)?.placeName
                                    }
                                </div>
                            </div>

                            <div>
                                {/* <p
                                                    className="text-sm text-blue-500 cursor-pointer hover:underline"
                                                    onClick={OpenFareRules}
                                                >View Fare Rules</p> */}
                            </div>
                        </div>

                        {
                            props?.navitaireBookingDetailSegments.map((segment, i) => (
                                <div key={flight + "__segment_details" + i}>
                                    <div>
                                        <div className="mt-8 w-[75%]">
                                            <div className="grid grid-cols-12">
                                                <div className="col-span-5">
                                                    {/* ORIGIN/FROM */}
                                                    <div className="flex flex-col">
                                                        <p className="text-lg font-semibold">{moment(segment.originDateTime).format("HH:mm")}</p>
                                                        <p>{moment(segment.originDateTime).format("dddd, MMM D")}</p>
                                                        <p className="font-semibold">{airportDetails.find(each => each.airportCode === segment.origin)?.placeName} ({segment.origin})</p>
                                                        <p className="text-navy-blue text-xs">
                                                            {
                                                                airportDetails.find(each => each.airportCode === segment.origin)?.airportName
                                                            } {segment?.originTerminal ? `(T${segment?.originTerminal})` : "(N/A)"}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-span-2">
                                                    <div className="h-full flex items-center justify-center">
                                                        <div className="border-[1.5px] w-full rounded-md border-green-600" />
                                                    </div>
                                                </div>
                                                <div className="col-span-5">
                                                    {/* DESTINATION/TO */}
                                                    <div className="flex flex-col items-end">
                                                        <p className="text-lg font-semibold">{moment(segment.destinationDateTime).format("HH:mm")}</p>
                                                        <p>{moment(segment.destinationDateTime).format("dddd, MMM D")}</p>
                                                        <p className="font-semibold">{airportDetails.find(each => each.airportCode === segment.destination)?.placeName} ({segment.destination})</p>
                                                        <p className="text-navy-blue text-xs">
                                                            {
                                                                airportDetails.find(each => each.airportCode === segment.destination)?.airportName
                                                            } {segment?.destinationTerminal ? `(T${segment?.destinationTerminal})` : "(N/A)"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-8 flex gap-3">
                                            <Badge autoContrast color="red" variant="light">{moment(segment.originDateTime).format("dddd, MMM D")}</Badge>
                                            {/* <Badge autoContrast color="orange" variant="light">{segment.cabin}</Badge> */}
                                            {/* {segment?.cabinClass ? <Badge autoContrast color="blue" variant="light">Cabin Class: {segment.cabin}</Badge> : <></>} */}
                                        </div>

                                        <div className="mt-5 flex items-center gap-12">
                                            <div className="h-10 w-10 rounded-md">
                                                <img
                                                    src={getAirLineData(segment.carrierCode).logo}
                                                    alt="flight logo"
                                                    loading="lazy"
                                                    className="h-10 w-10 object-cover rounded-lg"
                                                />
                                            </div>

                                            <div className="flex items-center gap-3">
                                                <p className="font-semibold">
                                                    {getAirLineData(segment.carrierCode).name}
                                                </p>
                                                <div>{`${segment.carrierCode}-${segment.flightNumber}`}</div>
                                            </div>

                                            {/* <div className="flex items-center gap-1">
                                                            <LuBaggageClaim size={24} />
                                                            <span>Baggage: <span className="font-semibold">
                                                                {
                                                                    segment.maxWeight
                                                                        ?
                                                                        `${segment.maxWeight} ${segment.unit === "Kilograms" ? "Kg" : "Lbs"}`
                                                                        :
                                                                        "N/A"
                                                                }
                                                            </span></span>
                                                        </div> */}
                                        </div>
                                    </div>
                                    <Divider variant="dashed" my="lg" />
                                </div>
                            ))
                        }
                    </div>
                )))}

            </div>
            {/* FARE RULES (CANCELLATION & DATE CHANGE) */}
            <EModal
                opened={showFareRules}
                onClose={closeFareRules}
                size={"xl"}
                title="Fare Rules"
            >
                <Tabs color="teal" defaultValue="cancellation_charges">
                    <Tabs.List>
                        <Tabs.Tab value="cancellation_charges">Cancellation Charges</Tabs.Tab>
                        <Tabs.Tab value="date_change_charges" color="blue">Date Change Charges</Tabs.Tab>
                    </Tabs.List>

                    {/* CANCELLATION CHARGES */}
                    <Tabs.Panel value="cancellation_charges" pt="xs">
                        {
                            flight?.bookingResponses?.map((trip) => trip?.galileoBookingDetailResponses?.map((paxPriceInfo, index) => (
                                <div key={index + "_cancellation_charges"} className="mb-5 flex flex-col gap-2">
                                    {/* <p className="font-semibold text-sm">
                                  For {
                                      paxPriceInfo.passengerType.code === "ADT"
                                          ?
                                          "Adult"
                                          :
                                          paxPriceInfo.passengerType.code === "CHD" || paxPriceInfo.passengerType.code === "CNN"
                                              ?
                                              "Child"
                                              :
                                              "Infant"
                                  }
                              </p> */}
                                    <ETable>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">Time frame</p>
                                                        <p className="text-xs">(From Scheduled flight departure)</p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">Airline Fee + Platform Fee</p>
                                                        <p className="text-xs">(Per passenger)</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                convertToArray(paxPriceInfo?.bookingCancelPenalty)?.map(
                                                    (item: I1GPenalty, i: number) => (
                                                        <tr key={i + "_" + i + "_penalty"}>
                                                            <td>{item.penaltyApplies}</td>
                                                            <td>
                                                                <NumberFormatter
                                                                    thousandSeparator
                                                                    prefix="₹ "
                                                                    value={item.amount}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </ETable>
                                </div>
                            )))
                        }
                    </Tabs.Panel>

                    {/* DATE CHANGE CHARGES */}
                    <Tabs.Panel value="date_change_charges" pt="xs">
                        {
                            flight?.bookingResponses?.map((trip) => trip?.galileoBookingDetailResponses?.map((paxPriceInfo, index) => (
                                <div key={index + "_date_change_charges"} className="mb-5 flex flex-col gap-2">
                                    {/* <p className="font-semibold text-sm">
                                  For {
                                      paxPriceInfo.passengerType.code === "ADT"
                                          ?
                                          "Adult"
                                          :
                                          paxPriceInfo.passengerType.code === "CHD" || paxPriceInfo.passengerType.code === "CNN"
                                              ?
                                              "Child"
                                              :
                                              "Infant"
                                  }
                              </p> */}
                                    <ETable>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">Time frame</p>
                                                        <p className="text-xs">(From Scheduled flight departure)</p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">Airline Fee + Platform Fee</p>
                                                        <p className="text-xs">(Per passenger)</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                convertToArray(paxPriceInfo?.bookingChangePenalty)?.map((item: I1GPenalty, i: number) => (
                                                    <tr key={i + "_" + i + "_penalty"}>
                                                        <td>{item.penaltyApplies}</td>
                                                        <td>
                                                            <NumberFormatter
                                                                thousandSeparator
                                                                prefix="₹ "
                                                                value={item.amount}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </ETable>
                                </div>
                            )))
                        }
                    </Tabs.Panel>
                </Tabs>
            </EModal>
        </div>
    );
}

export default FlightDetails;
