import React from "react";
import PageLayout from "PageLayout";
import { IoMdAdd } from "react-icons/io";
import { FaEdit, FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { Space, Switch, Table, TableThead } from "@mantine/core";
import ExportDataActions from "libs/ui/src/Export/ExportDataActions";
import useManaeSubChargeTypes from "./hook/useManageSubChargeTypes";
import { EButton, EModal, EPageTitle, ESelect, ETable, ETextInput } from "libs/ui";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function ManageSubChargeTypes() {
  const {
    loading,
    isBtnLoading,
    chargeType,
    subChargeName,
    isOpenAddNewModal,
    additionalChargeTypes,
    additionalChargeTypesList,
    selectedSubCharge,
    setloading,
    setChargeType,
    setSubChargeName,
    createSubChargeType,
    setIsOpenAddNewModal,
    activeInActiveSubChargeType,
    setSelectedSubCharge,
    updateSubChargeType
  } = useManaeSubChargeTypes();

  return (
    <PageLayout>
      <EPageTitle
        title="Manage Sub Charge Types"
        end={
          <EButton
            rightSection={<IoMdAdd />}
            onClick={() => {
              setIsOpenAddNewModal(true);
            }}
          >
            Create New
          </EButton>
        }
      />
      {/* <div className="flex  w-full h-20 justify-end">
        <ExportDataActions
          pdf
          print
          csv
          pdfColor="red"
          csvColor="green"
          onCSV={() => {}}
          onPrint={() => {}}
          onPDF={() => {}}
        />
      </div> */}
       <ETable
        loading={loading}
       >
           <thead>
            <tr>
              <th>S. No.</th>
              <th>Change Name</th>
              <th>Sub Charge Name</th>
              <th>Status</th>
              <th className="flex justify-end">Manage</th>
            </tr>
            </thead>
          <tbody>
            {additionalChargeTypesList?.map((data: any, index: number) => {
              const { id, name, additionalChargeTypeName, status } = data;
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{additionalChargeTypeName}</td>
                  <td>{name}</td>
                  <td>
                      <ESwitch
                        size="sm"
                        label={status ? "Active" : "In-Active"}
                        checked={status}
                        onChange={(value) => {
                          activeInActiveSubChargeType(
                            value,
                            id
                          );  
                        }}
                        withConfirmation
                      />
                  </td>
                  <td>
                   <div className="w-full flex justify-end items-center text-green-400 hover:text-green-500">
                     <FaEdit onClick={() => setSelectedSubCharge(data)} className="cursor-pointer" size={20}/>
                     </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
          </ETable>
      <EModal
        title={"Create Sub Charge Type"}
        opened={isOpenAddNewModal}
         
        onClose={() => {
          setIsOpenAddNewModal(false);
          setChargeType(null)
          setSubChargeName("")
        }}
      >
        <div className="w-full flex flex-col gap-2">
          <ESelect
            label={"Select Charge Type"}
            placeholder="Select"
            data={additionalChargeTypes}
            value={chargeType}
            onChange={(value) => setChargeType(value)}
          />
          <Space h={"2px"} />
          <ETextInput
            withAsterisk
            label="Sub Charge Name"
            placeholder="Select"
            value={subChargeName}
            onChange={(event) => setSubChargeName(event.target.value)}
          />
          <div className="flex justify-end mt-2">
            <EButton
              disabled={!chargeType || !subChargeName}
              loading={isBtnLoading}
              onClick={() => {
                createSubChargeType();
              }}
            >
              {"Create"}
            </EButton>
          </div>
        </div>
      </EModal>
     
      <EModal
        title={"Update Sub Charge Type"}
        opened={!!selectedSubCharge}
         
        onClose={() => {
          setSelectedSubCharge(undefined);
        }}
      >
        
        <div className="w-full flex flex-col gap-2">
          <ESelect
            label={"Select Charge Type"}
            placeholder="Select"
            data={additionalChargeTypes}
            value={selectedSubCharge?.additionalChargeTypeId ? selectedSubCharge?.additionalChargeTypeId+"" : ""}
            onChange={(value) => setSelectedSubCharge(prev=>(prev && {...prev, additionalChargeTypeId: value ?? ""}))}
          />
          <Space h={"2px"} />
          <ETextInput
            withAsterisk
            label="Sub Charge Name"
            placeholder="Select"
            value={selectedSubCharge?.name}
            onChange={(event) => setSelectedSubCharge(prev=>(prev && {...prev, name:event.target.value}))}
          />
          <div className="flex justify-end mt-2">
            <EButton
              disabled={!selectedSubCharge?.name}
              loading={isBtnLoading}
              onClick={() => {
                updateSubChargeType();
              }}
            >
              {"Update"}
            </EButton>
          </div>
        </div>
      </EModal>
    </PageLayout>
  );
}
