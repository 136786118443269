import PageLayout from "PageLayout";
import "@mantine/dates/styles.css";
import { Checkbox, Grid, GridCol, Space } from "@mantine/core";
import useCreateDiscount_B2C from "./hook/useCreateMarkup_B2C";
import {
  ESelect,
  EButton,
  EPageTitle,
  ETextInput,
  EDateInput,
  EMultiSelect,
  ESectionDivider,
} from "libs/ui";

export default function CreateDiscount_B2C() {
  const {
    markupTypes,
    serviceTypes,
    discountTypes,
    applyForTypes,
    flightSpecific,
    customerTypes,
    allCountryList,
    supplierList,
    airlinesList,
    amountTypes,
    hotelPlaces,
    serviceName,
    busSeatTypes,
    hotelCategories,
    busDestinations,
    discountCategoryTypes,
    airportByCoutries,
    markupDiscountForm,
    flightJourneyTypes,
    busCategoryTypes,
    setServiceName,
    setFlightSpecific,
    getHotelPlacesByCountries,
    getAirlinesBySuppliers,
    getAllAirportByCountries,
    createDiscountMarkupFromB2C,
  } = useCreateDiscount_B2C();
  const formValues = markupDiscountForm.getValues();
  const { startDate, amountType, discountType, service } = formValues;
  return (
    <PageLayout>
      <form
        onSubmit={markupDiscountForm.onSubmit(createDiscountMarkupFromB2C)}
        // onSubmit={markupDiscountForm.onSubmit(
        //   (values, event) => {
        //     console.log("no errors", values);
        //   },
        //   (validationErrors, values, event) => {
        //     console.log("errors", validationErrors, values, event);
        //   }
        // )}
      >
        <EPageTitle
          title="Create Discount B2C"
          end={
            <div className="flex gap-2">
              <EButton onClick={() => {}} color="gray">
                Cancel
              </EButton>
              <EButton type="submit" onClick={() => {}}>
                Save
              </EButton>
            </div>
          }
        />
        <ESectionDivider title="Discount Type" />
        <Grid>
          <Grid.Col span={4}>
            <ESelect
              searchable
              withAsterisk
              label="Discount Type"
              placeholder="Select"
              data={discountTypes || []}
              key={markupDiscountForm.key("discountType")}
              {...markupDiscountForm.getInputProps("discountType")}
              onChange={(value: any) => {
                markupDiscountForm.setFieldValue("discountType", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={discountType === "UNIQUE_CODE"}>
            <ESelect
              searchable
              withAsterisk
              label="Customer Type"
              placeholder="Select"
              data={customerTypes || []}
              key={markupDiscountForm.key("customerType")}
              {...markupDiscountForm.getInputProps("customerType")}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={discountType !== "UNIQUE_CODE"}>
            <ETextInput
              withAsterisk
              label="No. Of Unique Codes"
              placeholder="Enter code"
              key={markupDiscountForm.key("uniqueCodesCount")}
              {...markupDiscountForm.getInputProps("uniqueCodesCount")}
            />
          </Grid.Col>
        </Grid>
        <Space h="xl" />
        <ESectionDivider title="Discount Details" />
        <Grid>
          <GridCol span={4}>
            <ETextInput
              withAsterisk
              label="Discount Name"
              placeholder="Enter Discount Name"
              key={markupDiscountForm.key("name")}
              {...markupDiscountForm.getInputProps("name")}
            />
          </GridCol>
          <GridCol span={4}>
            <ETextInput
              withAsterisk
              label="Discount Description"
              placeholder="Enter Discount Description"
              key={markupDiscountForm.key("description")}
              {...markupDiscountForm.getInputProps("description")}
            />
          </GridCol>
          <GridCol span={4}>
            <ETextInput
              withAsterisk
              label="Discount Code"
              placeholder="Enter Discount Code"
              key={markupDiscountForm.key("code")}
              {...markupDiscountForm.getInputProps("code")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName === "BUS"}>
            <ESelect
              searchable
              withAsterisk
              label="Apply For"
              placeholder="Select"
              data={applyForTypes || []}
              key={markupDiscountForm.key("chargeApplyForTypeId")}
              {...markupDiscountForm.getInputProps("chargeApplyForTypeId")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "BUS"}>
            <ESelect
              searchable
              withAsterisk
              label="Apply For"
              placeholder="Select"
              data={applyForTypes || []}
              display={serviceName === "BUS" ? "block" : "none"}
              key={markupDiscountForm.key("discountApplyFor")}
              {...markupDiscountForm.getInputProps("discountApplyFor")}
            />
          </GridCol>
          <GridCol span={4}>
            <EDateInput
              withAsterisk
              label="Valid From Date"
              placeholder="Start Date"
              minDate={new Date()}
              valueFormat="YYYY-MM-DD"
              key={markupDiscountForm.key("startDate")}
              {...markupDiscountForm.getInputProps("startDate")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("startDate", value);
                markupDiscountForm.setFieldValue("endDate", null);
              }}
            />
          </GridCol>
          <GridCol span={4}>
            <EDateInput
              withAsterisk
              disabled={!startDate}
              label="Valid To Date"
              placeholder="End Date"
              valueFormat="YYYY-MM-DD"
              minDate={startDate ? new Date(startDate) : new Date()}
              key={markupDiscountForm.key("endDate")}
              {...markupDiscountForm.getInputProps("endDate")}
            />
          </GridCol>
          <GridCol span={4}>
            <ETextInput
              withAsterisk
              label="Maximum Usage"
              placeholder="Enter Maximum Usage"
              key={markupDiscountForm.key("perCustomerUsage")}
              {...markupDiscountForm.getInputProps("perCustomerUsage")}
            />
          </GridCol>
          <GridCol span={4}>
            <ETextInput
              withAsterisk
              label="Customer Limit"
              placeholder="Enter Customer Limit"
              key={markupDiscountForm.key("customerLimit")}
              {...markupDiscountForm.getInputProps("customerLimit")}
            />
          </GridCol>
          <GridCol span={4}>
            <ESelect
              searchable
              withAsterisk
              label="Can Be Used"
              placeholder="Select"
              data={discountCategoryTypes || []}
              key={markupDiscountForm.key("discountCategory")}
              {...markupDiscountForm.getInputProps("discountCategory")}
            />
          </GridCol>
        </Grid>
        <Space h="xl" />
        <ESectionDivider title="Service" />
        <Grid>
          <Grid.Col span={4}>
            <ESelect
              searchable
              withAsterisk
              label="Select Service"
              placeholder="Select"
              value={service}
              data={serviceTypes || []}
              key={markupDiscountForm.key("service")}
              {...markupDiscountForm.getInputProps("service")}
              onChange={(value: any) => {
                setServiceName(value);
                markupDiscountForm.setFieldValue("service", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              searchable
              withAsterisk
              label="Origin"
              placeholder="Select"
              data={allCountryList || []}
              key={markupDiscountForm.key("originPlaceIds")}
              {...markupDiscountForm.getInputProps("originPlaceIds")}
              onChange={(value: Array<string>) => {
                markupDiscountForm.setFieldValue("originPlaceIds", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              searchable
              withAsterisk
              label="Destination"
              placeholder="Select"
              data={busDestinations || []}
              key={markupDiscountForm.key("destinationPlaceIds")}
              {...markupDiscountForm.getInputProps("destinationPlaceIds")}
              onChange={(value: Array<string>) => {
                markupDiscountForm.setFieldValue("destinationPlaceIds", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              searchable
              withAsterisk
              label="Category Type"
              placeholder="Select"
              data={busCategoryTypes || []}
              key={markupDiscountForm.key("busCategories")}
              {...markupDiscountForm.getInputProps("busCategories")}
              onChange={(value: Array<string>) => {
                markupDiscountForm.setFieldValue("busCategories", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              searchable
              withAsterisk
              label="Suppliers"
              placeholder="Select"
              data={supplierList || []}
              key={markupDiscountForm.key("ticketSupplierIds")}
              {...markupDiscountForm.getInputProps("ticketSupplierIds")}
              onChange={(value: Array<string>) => {
                markupDiscountForm.setFieldValue("ticketSupplierIds", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              searchable
              withAsterisk
              label="Seat Type"
              placeholder="Select"
              data={busSeatTypes || []}
              key={markupDiscountForm.key("busSeatCategories")}
              {...markupDiscountForm.getInputProps("busSeatCategories")}
              onChange={(value: Array<string>) => {
                markupDiscountForm.setFieldValue("busSeatCategories", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              searchable
              label="Origin Country"
              placeholder="Select"
              data={allCountryList || []}
              key={markupDiscountForm.key("countryIds")}
              {...markupDiscountForm.getInputProps("countryIds")}
              onChange={(value) => {
                if (value?.length) {
                  markupDiscountForm.setFieldValue("countryIds", value);
                  getAllAirportByCountries(value);
                }
              }}
            />
          </Grid.Col>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              searchable
              label="Route Origin"
              placeholder="Select"
              data={airportByCoutries || []}
              key={markupDiscountForm.key("originAirportIds")}
              {...markupDiscountForm.getInputProps("originAirportIds")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("originAirportIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              searchable
              withAsterisk
              label="Route Destination"
              placeholder="Select"
              data={airportByCoutries || []}
              key={markupDiscountForm.key("destinationAirportIds")}
              {...markupDiscountForm.getInputProps("destinationAirportIds")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("destinationAirportIds", value);
              }}
            />
          </GridCol>
          <GridCol
            span={4}
            hidden={serviceName !== "FLIGHT" || flightSpecific === false}
          >
            <ETextInput
              withAsterisk
              label="Airline Code"
              placeholder="Enter Airline Code"
              key={markupDiscountForm.key("airlineCode")}
              {...markupDiscountForm.getInputProps("airlineCode")}
            />
          </GridCol>
          <GridCol
            span={4}
            hidden={serviceName !== "FLIGHT" || flightSpecific === false}
          >
            <ETextInput
              withAsterisk
              label="Flight No"
              placeholder="Enter Flight No"
              key={markupDiscountForm.key("flightNo")}
              {...markupDiscountForm.getInputProps("flightNo")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "HOTEL"}>
            <EMultiSelect
              withAsterisk
              searchable
              label="Country"
              placeholder="Select"
              data={allCountryList || []}
              key={markupDiscountForm.key("countryIds")}
              {...markupDiscountForm.getInputProps("countryIds")}
              onChange={(value) => {
                if (value?.length) {
                  markupDiscountForm.setFieldValue("countryIds", value);
                  getHotelPlacesByCountries(value);
                }
              }}
            />
          </GridCol>
          <Grid.Col span={4} hidden={serviceName !== "HOTEL"}>
            <EMultiSelect
              withAsterisk
              searchable
              label="Destination"
              placeholder="Select"
              data={hotelPlaces || []}
              key={markupDiscountForm.key("placeIds")}
              {...markupDiscountForm.getInputProps("placeIds")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("placeIds", value);
              }}
            />
          </Grid.Col>
          <Grid.Col
            span={4}
            hidden={serviceName == "BUS" || serviceName == "HOTEL"}
          >
            <Checkbox
              className="mt-1"
              label="Flight Specific"
              checked={flightSpecific}
              onClick={() => {
                markupDiscountForm.setValues({
                  ...markupDiscountForm.values,
                  bookingTypeId: null,
                  journeyTypeIds: [],
                  ticketSupplierIds: [],
                  flightAirlineIds: [],
                  airlineCode: null,
                  flightNo: null,
                  flightSpecific: !flightSpecific,
                });

                setFlightSpecific(!flightSpecific);
              }}
            />
          </Grid.Col>
          <Grid.Col
            span={4}
            hidden={flightSpecific || serviceName !== "FLIGHT"}
          >
            <ESelect
              searchable
              withAsterisk
              label="Flight Type"
              placeholder="Select"
              data={markupTypes || []}
              key={markupDiscountForm.key("bookingTypeId")}
              {...markupDiscountForm.getInputProps("bookingTypeId")}
            />
          </Grid.Col>
          <Grid.Col
            span={4}
            hidden={flightSpecific || serviceName !== "FLIGHT"}
          >
            <EMultiSelect
              searchable
              withAsterisk
              label="Trip Type"
              placeholder="Select"
              data={flightJourneyTypes || []}
              key={markupDiscountForm.key("journeyTypeIds")}
              {...markupDiscountForm.getInputProps("journeyTypeIds")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("journeyTypeIds", value);
              }}
            />
          </Grid.Col>
          <Grid.Col
            span={4}
            hidden={flightSpecific || serviceName !== "FLIGHT"}
          >
            <EMultiSelect
              searchable
              withAsterisk
              label="Suppliers"
              placeholder="Select"
              data={supplierList || []}
              key={markupDiscountForm.key("ticketSupplierIds")}
              {...markupDiscountForm.getInputProps("ticketSupplierIds")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("ticketSupplierIds", value);
                if (value?.length) {
                  getAirlinesBySuppliers(value);
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "HOTEL"}>
            <ESelect
              searchable
              withAsterisk
              label="Hotel Type"
              placeholder="Select"
              data={markupTypes || []}
              key={markupDiscountForm.key("bookingTypeId")}
              {...markupDiscountForm.getInputProps("bookingTypeId")}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "HOTEL"}>
            <EMultiSelect
              searchable
              withAsterisk
              label="Suppliers"
              placeholder="Select"
              data={supplierList || []}
              key={markupDiscountForm.key("bookingSupplierIds")}
              {...markupDiscountForm.getInputProps("bookingSupplierIds")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("bookingSupplierIds", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "HOTEL"}>
            <EMultiSelect
              searchable
              withAsterisk
              label="Category Type "
              placeholder="Select"
              data={hotelCategories || []}
              key={markupDiscountForm.key("hotelCategoryIds")}
              {...markupDiscountForm.getInputProps("hotelCategoryIds")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("hotelCategoryIds", value);
              }}
            />
          </Grid.Col>
          <Grid.Col
            span={4}
            hidden={flightSpecific || serviceName !== "FLIGHT"}
          >
            <EMultiSelect
              searchable
              withAsterisk
              label="Airlines"
              placeholder="Select"
              data={airlinesList || []}
              key={markupDiscountForm.key("flightAirlineIds")}
              {...markupDiscountForm.getInputProps("flightAirlineIds")}
              onChange={(value) => {
                markupDiscountForm.setFieldValue("flightAirlineIds", value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "HOTEL"}>
            <ETextInput
              withAsterisk
              label="Hotel Name"
              placeholder="Enter Hotel Name"
              key={markupDiscountForm.key("hotelName")}
              {...markupDiscountForm.getInputProps("hotelName")}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={serviceName !== "HOTEL"}>
            <ETextInput
              withAsterisk
              label="Hotel Code"
              placeholder="Enter Hotel Code"
              key={markupDiscountForm.key("hotelCode")}
              {...markupDiscountForm.getInputProps("hotelCode")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <ETextInput
              withAsterisk
              label="Amount"
              placeholder="Enter Amount"
              key={markupDiscountForm.key("amount")}
              {...markupDiscountForm.getInputProps("amount")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <ESelect
              searchable
              withAsterisk
              label="Amount Type"
              placeholder="Select"
              data={amountTypes || []}
              key={markupDiscountForm.key("amountType")}
              {...markupDiscountForm.getInputProps("amountType")}
              onChange={(value) => {
                if (value) {
                  markupDiscountForm.setFieldValue("amountType", value);
                  if (value === "FIXED") {
                    markupDiscountForm.setValues({
                      ...markupDiscountForm.values,
                      minimumLimit: null,
                      maximumLimit: null,
                    });
                  }
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <ETextInput
              withAsterisk
              type="number"
              label="From Price"
              placeholder="Enter Range From"
              key={markupDiscountForm.key("fromRange")}
              {...markupDiscountForm.getInputProps("fromRange")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <ETextInput
              withAsterisk
              label="To Price"
              type="number"
              placeholder="Enter Range To"
              key={markupDiscountForm.key("toRange")}
              {...markupDiscountForm.getInputProps("toRange")}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={amountType && amountType === "FIXED"}>
            <ETextInput
              withAsterisk
              type="number"
              label="Min Limit"
              placeholder="From price"
              key={markupDiscountForm.key("minimumLimit")}
              {...markupDiscountForm.getInputProps("minimumLimit")}
            />
          </Grid.Col>
          <Grid.Col span={4} hidden={amountType && amountType === "FIXED"}>
            <ETextInput
              withAsterisk
              label="Max Limit"
              type="number"
              placeholder="Price To"
              key={markupDiscountForm.key("maximumLimit")}
              {...markupDiscountForm.getInputProps("maximumLimit")}
            />
          </Grid.Col>
        </Grid>
      </form>
    </PageLayout>
  );
}
