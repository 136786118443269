
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDisclosure } from '@mantine/hooks';
import { Badge, Divider, Grid, Tabs } from '@mantine/core';
import { PiAirplaneInFlight } from 'react-icons/pi';

import { IFlightOnlineBookingResponseList } from 'libs/data-access/types';
import { EButton, EModal, ETable } from 'libs/ui';
import { getAirLineData } from 'utils/utility';
import { IAirportDetails } from 'types';
import { useApi } from 'libs/data-access/src/api/useApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import FareSummary from 'libs/ui/src/FareSummary/FareSummary';

interface IProps {
    flight: IFlightOnlineBookingResponseList
}

export default function FlightBookingDetailsNavitaire() {

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const flight: IFlightOnlineBookingResponseList = state.item;
    const { postData } = useApi();
    const [showFareRules, { open: OpenFareRules, close: closeFareRules }] = useDisclosure(false);
    const [airportDetails, setAirportDetails] = useState<IAirportDetails[]>([]);

    const fetchAirportInformation = async () => {
        const originList = flight?.navitaireBookingDetailResponses && flight?.navitaireBookingDetailResponses.map(journey => journey.navitaireBookingDetailSegments.map(segment => segment.origin)).flat();
        const destinationList = flight?.navitaireBookingDetailResponses && flight?.navitaireBookingDetailResponses.map(journey => journey.navitaireBookingDetailSegments.map(segment => segment.destination)).flat();
        const query = Array.from(new Set([...(originList ?? []), ...(destinationList ?? [])])).join(',');

        const response = await postData(`unsecure/flight/one-g/get-airport-response?codes=${query}`, null);
        if (response.isSuccess) {
            setAirportDetails(response.data?.data);
        } else {
            console.error('Error occurred while requesting for Air Pricing:', response);
        };
    };

    useEffect(() => {
        fetchAirportInformation();
    }, []);

    return (
        <>
            <div className='mt-2'>
                <EButton
                    leftSection={<BsArrowLeft />}
                    onClick={() =>
                        navigate('/online-booking/Flight', { state: location.state })
                    }
                >Back</EButton>

                {/* Passenger Details And Fare Summery */}
                <Grid className='mt-9'>
                    <Grid.Col span={8}>
                        <div className="w-full border-[1px] border-gray-50 bg-white rounded-lg shadow-md">
                            <div className="h-20 px-4 rounded-t-lg border-b-[1px] bg-blue-50 flex items-center shadow-sm">
                                <p className="text-lg text-navy-blue font-semibold">Passenger Details</p>
                            </div>
                            <div className="p-4">
                                <ETable>
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Title</th>
                                            <th>Name</th>
                                            <th>Gender</th>
                                            <th>D.O.B.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {flight?.navitairePassengerDetailResponses?.map((eachPassenger, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{eachPassenger?.title}</td>
                                                <td>{eachPassenger?.firstName} {eachPassenger?.lastName}</td>
                                                <td>{eachPassenger?.gender}</td>
                                                <td>{eachPassenger?.dateOfBirth ? moment(eachPassenger?.dateOfBirth).format('DD-MM-YYYY') : ''}</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </ETable>
                            </div>
                        </div>

                        <div className="border-[1px] border-gray-50 bg-white rounded-lg shadow-md mt-12">
                            <div className="h-20 px-4 rounded-t-lg border-b-[1px] bg-blue-50 flex items-center shadow-sm">
                                <p className="text-lg text-navy-blue font-semibold">Flight Details</p>
                            </div>
                            <div className="p-4">
                                {!flight?.navitaireBookingDetailResponses  ? <>
                                </> : flight?.navitaireBookingDetailResponses.map((props, index) => (
                                    <div key={flight.id + "_flight_details"} className="mb-5 p-3">
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center gap-5">
                                                {/* ORIGIN/FROM */}
                                                <div className="text-lg">
                                                    {
                                                        airportDetails?.find(each => each.airportCode === props?.origin)?.placeName
                                                    }
                                                </div>
                                                <PiAirplaneInFlight size={24} />
                                                {/* DESTINATION/TO */}
                                                <div className="text-lg">
                                                    {
                                                        airportDetails?.find(each => each.airportCode === props?.destination)?.placeName
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                {/* <p
                                                    className="text-sm text-blue-500 cursor-pointer hover:underline"
                                                    onClick={OpenFareRules}
                                                >View Fare Rules</p> */}
                                            </div>
                                        </div>

                                        {
                                        props?.navitaireBookingDetailSegments.map((segment, i) => (
                                            <div key={flight + "__segment_details" + i}>
                                                <div>
                                                    <div className="mt-8 w-[75%]">
                                                        <div className="grid grid-cols-12">
                                                            <div className="col-span-5">
                                                                {/* ORIGIN/FROM */}
                                                                <div className="flex flex-col">
                                                                    <p className="text-lg font-semibold">{moment(segment.originDateTime).format("HH:mm")}</p>
                                                                    <p>{moment(segment.originDateTime).format("dddd, MMM D")}</p>
                                                                    <p className="font-semibold">{airportDetails.find(each => each.airportCode === segment.origin)?.placeName} ({segment.origin})</p>
                                                                    <p className="text-navy-blue text-xs">
                                                                        {
                                                                            airportDetails.find(each => each.airportCode === segment.origin)?.airportName
                                                                        } {segment?.originTerminal ? `(T${segment?.originTerminal})` : "(N/A)"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-2">
                                                                <div className="h-full flex items-center justify-center">
                                                                    <div className="border-[1.5px] w-full rounded-md border-green-600" />
                                                                </div>
                                                            </div>
                                                            <div className="col-span-5">
                                                                {/* DESTINATION/TO */}
                                                                <div className="flex flex-col items-end">
                                                                    <p className="text-lg font-semibold">{moment(segment.destinationDateTime).format("HH:mm")}</p>
                                                                    <p>{moment(segment.destinationDateTime).format("dddd, MMM D")}</p>
                                                                    <p className="font-semibold">{airportDetails.find(each => each.airportCode === segment.destination)?.placeName} ({segment.destination})</p>
                                                                    <p className="text-navy-blue text-xs">
                                                                        {
                                                                            airportDetails.find(each => each.airportCode === segment.destination)?.airportName
                                                                        } {segment?.destinationTerminal ? `(T${segment?.destinationTerminal})` : "(N/A)"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-8 flex gap-3">
                                                        <Badge autoContrast color="red" variant="light">{moment(segment.originDateTime).format("dddd, MMM D")}</Badge>
                                                        {/* <Badge autoContrast color="orange" variant="light">{segment.cabin}</Badge> */}
                                                        {/* {segment?.cabinClass ? <Badge autoContrast color="blue" variant="light">Cabin Class: {segment.cabin}</Badge> : <></>} */}
                                                    </div>

                                                    <div className="mt-5 flex items-center gap-12">
                                                        <div className="h-10 w-10 rounded-md">
                                                            <img
                                                                src={getAirLineData(segment.carrierCode).logo}
                                                                alt="flight image"
                                                                loading="lazy"
                                                                className="h-10 w-10 object-cover rounded-lg"
                                                            />
                                                        </div>

                                                        <div className="flex items-center gap-3">
                                                            <p className="font-semibold">
                                                                {getAirLineData(segment.carrierCode).name}
                                                            </p>
                                                            <div>{`${segment.carrierCode}-${segment.flightNumber}`}</div>
                                                        </div>

                                                        {/* <div className="flex items-center gap-1">
                                                            <LuBaggageClaim size={24} />
                                                            <span>Baggage: <span className="font-semibold">
                                                                {
                                                                    segment.maxWeight
                                                                        ?
                                                                        `${segment.maxWeight} ${segment.unit === "Kilograms" ? "Kg" : "Lbs"}`
                                                                        :
                                                                        "N/A"
                                                                }
                                                            </span></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <Divider variant="dashed" my="lg" />
                                            </div>
                                        ))
                                    }
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        {/* <FareSummary supplierId={flight?.flightSupplierId} airPriceJourneyResponseList={} /> */}
                    </Grid.Col>
                </Grid>


            </div>

            {/* FARE RULES (CANCELLATION & DATE CHANGE) */}
            <EModal
                opened={showFareRules}
                onClose={closeFareRules}
                size={"xl"}
                title="Fare Rules"
            >
                <Tabs color="teal" defaultValue="cancellation_charges">
                    <Tabs.List>
                        <Tabs.Tab value="cancellation_charges">Cancellation Charges</Tabs.Tab>
                        <Tabs.Tab value="date_change_charges" color="blue">Date Change Charges</Tabs.Tab>
                    </Tabs.List>

                    {/* CANCELLATION CHARGES */}
                    <Tabs.Panel value="cancellation_charges" pt="xs">
                        { 
                         !flight.navitaireBookingDetailResponses ? <></> :
                            flight.navitaireBookingDetailResponses?.map((paxPriceInfo, index) => (
                                <div key={index + "_cancellation_charges"} className="mb-5 flex flex-col gap-2">
                                    {/* <p className="font-semibold text-sm">
                                        For {
                                            paxPriceInfo.passengerType.code === "ADT"
                                                ?
                                                "Adult"
                                                :
                                                paxPriceInfo.passengerType.code === "CHD" || paxPriceInfo.passengerType.code === "CNN"
                                                    ?
                                                    "Child"
                                                    :
                                                    "Infant"
                                        }
                                    </p> */}
                                    <ETable>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">Time frame</p>
                                                        <p className="text-xs">(From Scheduled flight departure)</p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">Airline Fee + Platform Fee</p>
                                                        <p className="text-xs">(Per passenger)</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        {/* <tbody>
                                            {
                                                convertToArray(paxPriceInfo?.bookingCancelPenalty)?.map(
                                                    (item: I1GPenalty, i: number) => (
                                                        <tr key={i + "_" + i + "_penalty"}>
                                                            <td>{item.penaltyApplies}</td>
                                                            <td>
                                                                <NumberFormatter
                                                                    thousandSeparator
                                                                    prefix="₹ "
                                                                    value={item.amount}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody> */}
                                    </ETable>
                                </div>
                            ))
                        }
                    </Tabs.Panel>

                    {/* DATE CHANGE CHARGES */}
                    <Tabs.Panel value="date_change_charges" pt="xs">
                        {
                            flight?.navitaireBookingDetailResponses?.map((paxPriceInfo, index) => (
                                <div key={index + "_date_change_charges"} className="mb-5 flex flex-col gap-2">
                                    {/* <p className="font-semibold text-sm">
                                        For {
                                            paxPriceInfo.passengerType.code === "ADT"
                                                ?
                                                "Adult"
                                                :
                                                paxPriceInfo.passengerType.code === "CHD" || paxPriceInfo.passengerType.code === "CNN"
                                                    ?
                                                    "Child"
                                                    :
                                                    "Infant"
                                        }
                                    </p> */}
                                    <ETable>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">Time frame</p>
                                                        <p className="text-xs">(From Scheduled flight departure)</p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">Airline Fee + Platform Fee</p>
                                                        <p className="text-xs">(Per passenger)</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        {/* <tbody>
                                            {
                                                convertToArray(paxPriceInfo?.bookingChangePenalty)?.map((item: I1GPenalty, i: number) => (
                                                    <tr key={i + "_" + i + "_penalty"}>
                                                        <td>{item.penaltyApplies}</td>
                                                        <td>
                                                            <NumberFormatter
                                                                thousandSeparator
                                                                prefix="₹ "
                                                                value={item.amount}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody> */}
                                    </ETable>
                                </div>
                            ))
                        }
                    </Tabs.Panel>
                </Tabs>
            </EModal>
        </>
    )
}
