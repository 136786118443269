import { HiHome, HiTicket } from "react-icons/hi2";
import { FiSettings } from "react-icons/fi";
import { BsTicket, BsTruck, BsTruckFront } from "react-icons/bs";

import Home from "Pages/Home";
import ManageGST from "Pages/AdminMaster/ManageGST/ManageGST";
import ApplyFor from "Pages/AdminMaster/Flight/ApplyFor/ApplyFor";
import FlightMarkupType from "Pages/AdminMaster/Flight/MarupType/FlightMarkupType";
import FareType from "Pages/AdminMaster/Flight/FareType/FareType";
import ApplyOn from "Pages/AdminMaster/Flight/ApplyOn/ApplyOn";
import { MdFlight, MdHotel, MdPages } from "react-icons/md";
import { IRoute } from "types";
import MarkupFee from "Pages/AdminMaster/Flight/MarkupFee/MarkupFee";
import Airlines from "Pages/AdminMaster/Flight/Airlines/Airlines";
import Suppliers from "Pages/AdminMaster/Flight/Suppliers/Suppliers";
import DestinationCountry from "Pages/AdminMaster/Flight/DestinationCountry/DestinationCountry";
import OriginCountry from "Pages/AdminMaster/Flight/OriginCountry/OriginCountry";
import SearchType from "Pages/AdminMaster/Flight/SearchType/SearchType";
import ApplyType from "Pages/AdminMaster/Flight/ApplyType/ApplyType";
import HotelSuppliers from "Pages/AdminMaster/Hotels/Suppliers/HotelSuppliers";
import HotelCategory from "Pages/AdminMaster/Hotels/Suppliers copy/HotelCategory";
import BusSuppliers from "Pages/AdminMaster/BusSupplier/BusSupplier";
import HoldBookingConf from "Pages/AdminMaster/HoldBookingConf/HoldBookingConf";
import ManageHoldBooking from "Pages/AdminMaster/ManageHoldBooking/ManageHoldBooking";
import EnableCouponFeature from "Pages/AdminMaster/EnableCouponFeature/EnableCouponFeature";
import Markup_B2C from "Pages/AdminTransactions/Markup_B2C/Markup_B2C";
import Discount_B2C from "Pages/AdminTransactions/Discount-B2C/Discount_B2C";
import CreateMarkup_B2C from "Pages/AdminTransactions/CreateMarkup-B2C/CreateMarkup_B2C";
import CreateDiscount_B2C from "Pages/AdminTransactions/CreateDiscount-B2C/CreateDiscount-B2C";
import ManageGroupB2B from "Pages/GroupSettings/ManageGroup-B2B/ManageGroup-B2B";
import GroupSettingsB2B from "Pages/GroupSettings/GroupSettings-B2B/GroupSettings-B2B";
import ViewMarkupB2B from "Pages/GroupSettings/ViewMarkup-B2B/ViewMarkup-B2B";
import CreateMarkupB2B from "Pages/GroupSettings/CreateMarkup-B2B/CreateMarkup-B2B";
import ViewCommissionB2B from "Pages/GroupSettings/ViewCommission-B2B/ViewCommission-B2B";
import CreateCommissionB2C from "Pages/GroupSettings/CreateCommission-B2B/CreateCommission-B2B";
import ManageChargeTypes from "Pages/TaxesAndCharges/ManageChargeTypes/ManageChargeTypes";
import ManageSubChargeTypes from "Pages/TaxesAndCharges/ManageSubChargeTypes/ManageSubChargeTypes";
import MyCharges from "Pages/TaxesAndCharges/MyCharges/MyCharges";
import OtherCharges from "Pages/TaxesAndCharges/OtherCharges/OtherCharges";
import Affiliates from "Pages/Affiliates/Affiliates";
import OnlineFlightBookingList from "Pages/Booking/OnlineBooking/OnlineFlightBookingList";
import OnlineHotelsBooking from "Pages/Booking/OnlineBooking/OnlineHotelsBooking";
import FlightBookingDetails1G from "Pages/Booking/OnlineBooking/Components/FlightBookingDetails1G";
import FlightBookingDetailsNavitaire from "Pages/Booking/OnlineBooking/Components/FlightBookingDetailsNavitaire";
import ManageAffiliates from "Pages/Affiliates/ManageAffiliates";
import RoleManagement from "Pages/UserManagement/RoleManagement";
import UserManagement from "Pages/UserManagement/UserManagement";

export const ROLES = {
  ADMIN: "ADMIN",
  AFFILIATE: "AFFILIATE",
};

export const ROUTES: {
  ALL_ROUTES: IRoute[];
} = {
  ALL_ROUTES: [
    {
      path: "/",
      label: "Home",
      slug: "home",
      element: Home,
      icon: HiHome,
      rolesAllowed: [ROLES.ADMIN],
    },
    // {
    //   path: "/user-management",
    //   label: "User Management",
    //   slug: "ROLE_MANAGEMENT",
    //   element: null,
    //   icon: FiSettings,
    //   rolesAllowed: [ROLES.ADMIN],
    //   isSubmenu: true,
    //   submenu: [
    //     {
    //       path: "/user-management/role-management",
    //       label: "Role Management",
    //       slug: "ROLE_MANAGEMENT__ROLE_MANAGEMENT",
    //       element: RoleManagement,
    //       icon: BsTicket,
    //       rolesAllowed: [ROLES.ADMIN],
    //     },
    //     {
    //       path: "/user-management/user-access",
    //       label: "User Access",
    //       slug: "ROLE_MANAGEMENT__USER_MANAGEMENT",
    //       element: UserManagement,
    //       icon: BsTicket,
    //       rolesAllowed: [ROLES.ADMIN],
    //     },
    //   ]
    // },
    {
      path: "/admin-master",
      label: "Admin Master",
      slug: "ADMIN_MASTER",
      element: null,
      icon: FiSettings,
      rolesAllowed: [ROLES.ADMIN],
      isSubmenu: true,
      submenu: [
        {
          path: "/admin-master/manage-gst",
          label: "Manage GST",
          slug: "ADMIN_MASTER__MANAGE_GST",
          element: ManageGST,
          icon: BsTicket,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/admin-master/flight",
          label: "Flight",
          slug: "",
          element: null,
          icon: MdFlight,
          rolesAllowed: [ROLES.ADMIN],
          isSubmenu: true,
          submenu: [
            {
              path: "/admin-master/flight/apply-for",
              label: "Apply For",
              slug: "ADMIN_MASTER__FLIGHT_APPLY_FOR",
              element: ApplyFor,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/flight/markup-type",
              label: "Markup Type",
              slug: "ADMIN_MASTER__FLIGHT_MARKUP_TYPE",
              element: FlightMarkupType,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/flight/fare-type",
              label: "Fare Type",
              slug: "ADMIN_MASTER__FLIGHT_FARE_TYPE",
              element: FareType,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/flight/apply-on",
              label: "Apply On",
              slug: "ADMIN_MASTER__FLIGHT_APPLY_ON",
              element: ApplyOn,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/flight/search-type",
              label: "Search Type",
              slug: "ADMIN_MASTER__FLIGHT_SEARCH_TYPE",
              element: SearchType,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/flight/apply-type",
              label: "Apply Type",
              slug: "ADMIN_MASTER__FLIGHT_APPLY_TYPE",
              element: ApplyType,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/flight/country",
              label: "Country",
              slug: "ADMIN_MASTER__FLIGHT_ORIGIN_COUNTRY",
              element: OriginCountry,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            // {
            //   path: "/flight/destination-country",
            //   label: "Destination Country",
            //   slug: "ADMIN_MASTER__FLIGHT_DESTINATION_COUNTRY",
            //   element: DestinationCountry,
            //   icon: MdPages,
            //   rolesAllowed: [ROLES.ADMIN],
            // },
            {
              path: "/admin-master/flight/Suppliers",
              label: "Suppliers",
              slug: "ADMIN_MASTER__FLIGHT_SUPPLIERS",
              element: Suppliers,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/flight/Airlines",
              label: "Airlines",
              slug: "ADMIN_MASTER__FLIGHT_AIRLINES",
              element: Airlines,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/flight/Markup-fee",
              label: "Markup Fee",
              slug: "ADMIN_MASTER__FLIGHT_MARKUP_FEE",
              element: MarkupFee,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
          ],
        },
        {
          path: "/admin-master/hotel",
          label: "Hotel",
          slug: "",
          element: null,
          icon: MdHotel,
          rolesAllowed: [ROLES.ADMIN],
          isSubmenu: true,
          submenu: [
            {
              path: "/admin-master/hotel/supplier",
              label: "Supplier",
              slug: "ADMIN_MASTER__HOTEL_SUPPLIERS",
              element: HotelSuppliers,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
            {
              path: "/admin-master/hotel/category",
              label: "Hotel Category",
              slug: "ADMIN_MASTER__HOTEL_CATEGORY",
              element: HotelCategory,
              icon: MdPages,
              rolesAllowed: [ROLES.ADMIN],
            },
          ],
        },
        // {
        //   path: "/admin-master/bus-supplier",
        //   label: "Bus Supplier",
        //   slug: "ADMIN_MASTER__BUS_SUPPLIERS",
        //   element: BusSuppliers,
        //   icon: BsTruckFront,
        //   rolesAllowed: [ROLES.ADMIN],
        // },
        // {
        // {
        //   path: "/admin-master/hold-booking-config",
        //   label: "Hold Booking Config",
        //   slug: "home",
        //   element: HoldBookingConf,
        //   icon: MdPages,
        //   rolesAllowed: [ROLES.ADMIN],
        // },
        // {
        //   path: "/admin-master/manage-hold-booking",
        //   label: "Manage Hold Booking",
        //   slug: "home",
        //   element: ManageHoldBooking,
        //   icon: MdPages,
        //   rolesAllowed: [ROLES.ADMIN],
        // },
        // {
        //   path: "/admin-master/enable-coupon-feature",
        //   label: "Enable Coupon Feature",
        //   slug: "home",
        //   element: EnableCouponFeature,
        //   icon: MdPages,
        //   rolesAllowed: [ROLES.ADMIN],
        // },
      ],
    },
    
    {
      path: "/group-settings",
      label: "Group Settings",
      slug: "",
      element: null,
      icon: FiSettings,
      rolesAllowed: [ROLES.ADMIN],
      isSubmenu: true,
      submenu: [
        {
          path: "/group-settings/manage-group-b2b",
          label: "Manage Group B2B",
          slug: "GROUP_SETTINGS__MANAGE_GROUP_B2B",
          element: ManageGroupB2B,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
        // {
        //   path: "/group-settings/group-settings-b2b",
        //   label: "Group Setting B2B",
        //   slug: "GROUP_SETTINGS__GROUP_SETTINGS_B2B",
        //   element: GroupSettingsB2B,
        //   icon: MdPages,
        //   rolesAllowed: [ROLES.ADMIN],
        // },
        {
          path: "/affiliate/affiliates-list",
          label: "Affiliates",
          slug: "AFFILIATES__VIEW_AFFILIATES",
          element: Affiliates,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/affiliate/manage-affiliates",
          label: "Manage Affiliates",
          slug: "AFFILIATES__CREATE_AFFILIATES",
          element: ManageAffiliates,
          icon: null,
          rolesAllowed: [ROLES.ADMIN],
        }
      ],
    },
    {
      path: "/markup_and_commission",
      label: "Markup & Commission",
      slug: "",
      element: null,
      icon: FiSettings,
      rolesAllowed: [ROLES.ADMIN],
      isSubmenu: true,
      submenu: [
        {
          path: "/markup_and_commission/markup-b2c",
          label: "Markup B2C",
          slug: "ADMIN_TRANSACTIONS__VIEW_MARKUP_B2C",
          element: Markup_B2C,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
        // {
        //   path: "/markup_and_commission/discount-b2c",
        //   label: "Discount B2C",
        //   slug: "ADMIN_TRANSACTIONS__VIEW_DISCOUNT_B2C",
        //   element: Discount_B2C,
        //   icon: MdPages,
        //   rolesAllowed: [ROLES.ADMIN],
        // },
        // {
        //   path: "/markup_and_commission/create-markup-b2c",
        //   label: "Admin Transaction",
        //   slug: "ADMIN_TRANSACTIONS__VIEW_MARKUP_B2C",
        //   element: CreateMarkup_B2C,
        //   icon: undefined,
        //   rolesAllowed: [ROLES.ADMIN],
        // },
        {
          path: "/markup_and_commission/edit-markup-b2c",
          label: "Admin Transaction",
          slug: "ADMIN_TRANSACTIONS__CREATE_MARKUP_B2C",
          element: CreateMarkup_B2C,
          icon: undefined,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/markup_and_commission/create-discount-b2c",
          label: "Admin Transaction",
          slug: "ADMIN_TRANSACTIONS__CREATE_DISCOUNT_B2C",
          element: CreateDiscount_B2C,
          icon: undefined,
          rolesAllowed: [ROLES.ADMIN],
        },

        //  Markup and Commission B2B
        
        {
          path: "/group-settings/view-markup-b2b",
          label: "Markup B2B",
          slug: "GROUP_SETTINGS__VIEW_MARKUP_B2B",
          element: ViewMarkupB2B,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/group-settings/create-markup-b2b",
          label: "Markup B2B",
          slug: "GROUP_SETTINGS__CREATE_MARKUP_B2B",
          element: CreateMarkupB2B,
          icon: undefined,
          rolesAllowed: [ROLES.ADMIN],
        },
        // {
        //   path: "/group-settings/view-commission-b2b",
        //   label: "Commission B2B",
        //   slug: "GROUP_SETTINGS__VIEW_COMMISSION_B2B",
        //   element: ViewCommissionB2B,
        //   icon: MdPages,
        //   rolesAllowed: [ROLES.ADMIN],
        // },
        {
          path: "/group-settings/create-commission-b2b",
          label: "Commission B2B",
          slug: "GROUP_SETTINGS__CREATE_COMMISSION_B2B",
          element: CreateCommissionB2C,
          icon: undefined,
          rolesAllowed: [ROLES.ADMIN],
        },
      ],
    },
    {
      path: "/taxes-and-charges",
      label: "Taxes & Charges",
      slug: "",
      element: null,
      icon: FiSettings,
      rolesAllowed: [ROLES.ADMIN],
      isSubmenu: true,
      submenu: [
        {
          path: "/taxes-and-charges/manage-charge-types",
          label: "Manage Charge Types",
          slug: "TAXES_CHARGES__MANAGE_CHARGE_TYPES",
          element: ManageChargeTypes,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/taxes-and-charges/manage-sub-charge-types",
          label: "Manage Sub-Charge Types",
          slug: "TAXES_CHARGES__MANAGE_SUB_CHARGE_TYPES",
          element: ManageSubChargeTypes,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/taxes-and-charges/my-charge",
          label: "My Charges",
          slug: "TAXES_CHARGES__MY_CHARGES",
          element: MyCharges,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/taxes-and-charges/other-charge",
          label: "Other Charges",
          slug: "TAXES_CHARGES__OTHER_CHARGES",
          element: OtherCharges,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
      ],
    },
    {
      path: "/online-booking",
      label: "Online Booking",
      slug: "ONLINE_BOOKING__TICKETD_BOOKING_DETAILS_B2B",
      element: null,
      icon: FiSettings,
      rolesAllowed: [ROLES.ADMIN],
      isSubmenu: true,
      submenu: [
        {
          path: "/online-booking/Flight",
          label: "Flight Booking",
          slug: "ONLINE_BOOKING__SUPPLIER_PROMO_CODE",
          element: OnlineFlightBookingList,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/online-booking/Flight/1G",
          label: "Online Booking",
          slug: "ONLINE_BOOKING__MANAGE_AFFILIATES",
          element: FlightBookingDetails1G,
          icon: undefined,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/online-booking/Flight/navitaire",
          label: "Online Booking",
          slug: "ONLINE_BOOKING__MANAGE_PAYMENT_GATEWAY",
          element: FlightBookingDetailsNavitaire,
          icon: undefined,
          rolesAllowed: [ROLES.ADMIN],
        },
        {
          path: "/online-booking/Hotels",
          label: "Hotels bookings",
          slug: "Online_Booking",
          element: OnlineHotelsBooking,
          icon: MdPages,
          rolesAllowed: [ROLES.ADMIN],
        },
      ],
    },
  ],
};

export default ROUTES;
