import { useApi } from "libs/data-access/src/api/useApi";
import React, { useEffect, useRef, useState } from "react";
import { showToast } from "utils/utility";
import { EConfirm } from "../../Confirm/EConfirm";

type TFileList = {
  id: number;
  mediaType: string;
  originalFileName: string;
}
export default function useFileSelect({ filesId }: { filesId: number[] }) {

  const {
    getData,
    postData
  } = useApi();

  const [loading, setLoading] = useState(false);
  const [loadingDeleteIcon, setLoadingDeleteIcon] = useState<
    number | undefined
  >(undefined);
  const [isFileDragOver, setIsFileDragOver] = useState(false);

  const [dropError, setDropError] = useState<string | undefined>(undefined);
  const [allFiles, setAllFiles] = useState<{ old: number[]; new: number[] }>({
    old: filesId,
    new: [],
  });

  const [fileList, setFileList] = useState<any[]>([]);
  useEffect(() => {
    getAllFiles()
  }, []);

  const arraysEqual = (a: number[], b: number[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };


  const handleChangePayload = (
    key: "old" | "new",
    value: number[],
    action: "add" | "remove"
  ) => {
    let previousData = JSON.parse(JSON.stringify(allFiles));
    if (action === "add") {
      previousData[key] = previousData[key]?.length ? [...previousData[key], ...value] : value
    }
    else {
      previousData[key] = previousData[key]?.filter((e: number) => !value?.includes(e))
    }
    setAllFiles(previousData);
  };

  const handleDropFile = (acceptedFiles: any) => {
    const payload = new FormData();
    acceptedFiles.forEach((acceptedFiles: any) => {
      payload.append('file', acceptedFiles);
    });
    handleUploadDroppedFile(payload);
  };


  //   API FUNCTION

  const getDetailById = async (id: number) => {
    try {
      const response = await getData(`get-file?id=${id}`)
      return response.data;
    } catch (error) {
      console.error(`Error fetching details for ID ${id}:`, error);
      throw error;
    }

  };
  const getAllFiles = async () => {
    setLoading(true);
    try {
      const oldDetails = await Promise.all(filesId.map(getDetailById));
      setFileList(oldDetails);
    }
    catch (error) {
      console.error('Error fetching details:', error);
    }
    setLoading(false);
  };

  const handleUploadDroppedFile = async (payload: any) => {
    setLoading(true);
    const response = await postData("save-image", payload);
    if (response?.isSuccess) {
      handleChangePayload("new", [response?.data?.mediaId], "add");
    } else {
      showToast({
        title: "Error",
        message: response?.message,
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleDeleteMediaFile = async (id: number, section: "new" | "old") => {
    if (
      await EConfirm({
        title: "Delete File",
        children: "Are you sure you want to delete this file?",
        variant: "delete",
      })
    ) {

      if (section === "new") {
        handleChangePayload("new", [id], "remove");
      }
      else {
        handleChangePayload("old", [id], "remove");
      }
    }
    else {
      
    }
    setLoadingDeleteIcon(undefined);
  };

  return {
    loading,
    loadingDeleteIcon,
    setLoadingDeleteIcon,
    isFileDragOver,
    setIsFileDragOver,
    dropError,
    setDropError,
    allFiles,
    setAllFiles,
    handleChangePayload,
    handleDropFile,
    handleDeleteMediaFile
  };
}
