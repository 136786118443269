import { Skeleton, Table } from '@mantine/core';
import './ETable.scss';
import { TPaginationStatus } from 'types';
import { useEffect, useRef, useState } from 'react';
import { EButton } from '../Button/EButton';
import { PAGINATION_ITEM_LIMIT } from 'libs/data-access/constant';
import { EEmpty } from '../Empty/EEmpty';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { now } from 'moment';

type Props = {
  hasEndButtons?: boolean;
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
  pagination?: boolean;
  onChangePagination?: (action: TPaginationStatus) => void
};

export function ETable({ children, hasEndButtons, pagination, onChangePagination, loading }: Props) {
  const [activePage, setActivePage] = useState(1);
  const tableRef = useRef(null);
  const [rowCount, setRowCount] = useState(0);
  const [tdCount, setTdCount] = useState(0);

  useEffect(() => {
    if (tableRef.current) {
      // @ts-ignore
      const rows = tableRef.current.querySelectorAll('tr');
      // @ts-ignore
      const firstRowThs = tableRef.current.querySelectorAll('th');
      const thCount = firstRowThs.length;
      setRowCount(rows.length - 1)
      setTdCount(thCount);
      // @ts-ignore
    }
  }, [children]); // Runs whenever 'children' changes

  return (
    <div className='w-full overflow-x-scroll overflow-y-visible scrollbar-hide'>
      <Table
        ref={tableRef}
        className={'e-table ' + (hasEndButtons ? 'end-btns' : '') + `${loading ? `min-h-28` : ``} w-96`}
        highlightOnHover
        striped
        withRowBorders
      >
        {children}
        {
          loading ?
            [1, 2, 3, 4]?.map(e => <tr key={"Loading" + e + now()}>
              {Array.from({ length: tdCount }, (_, index) => (
                <td>
                  <div className='p-1 h-full'>
                    <Skeleton height={40} />
                  </div>
                </td>
              ))}
            </tr>
            ) : <></>}
      </Table>
      {rowCount === 0 && !loading ? <EEmpty title={'No Data Available'} /> : <></>}

      {

        pagination ?
          ((rowCount < PAGINATION_ITEM_LIMIT) && activePage === 1) ?
            <></> :
            <div className='flex justify-end pr-6 items-center gap-1 mt-6'>
              {
                activePage === 1
                  ? <></>
                  :
                  <EButton
                    variant="subtle"
                    onClick={() => {
                      setActivePage(activePage - 1)
                      onChangePagination && onChangePagination('PREVIOUS_PAGE');
                    }}
                  >
                    <FaChevronLeft />
                  </EButton>
              }
              <p>{activePage}</p>
              {
                rowCount < PAGINATION_ITEM_LIMIT ? <></> :
                  <EButton
                    variant='subtle'
                    onClick={() => {
                      setActivePage(activePage + 1)
                      onChangePagination && onChangePagination('NEXT_PAGE');
                    }}
                  > <FaChevronRight />
                  </EButton>
              }
            </div>
          : <></>
      }
    </div>
  );
}
