import PageLayout from "PageLayout";
import "@mantine/dates/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, GridCol, Radio, Space } from "@mantine/core";
import useCreateMarkup_B2C from "./hook/useCreateMarkup_B2C";
import { EButton, ESelect, EPageTitle, EDateInput, EMultiSelect, ESectionDivider } from "libs/ui";
import ENumberInput from "libs/ui/src/ENumberInput/ENumberInput";

const CountryOriginFiledKeys = {
  FLIGHT: "flightOriginCountryIds",
  HOTEL: "countryIds",
  BUS: "originPlaceIds",
};

const CountryDestinationFiledKeys = {
  FLIGHT: "flightDestinationCountryIds",
  HOTEL: "placeIds",
  BUS: "destinationPlaceIds",
};

const SuppliersFiledKeys = {
  FLIGHT: "flightTicketSupplierIds",
  HOTEL: "hotelBookingSupplierIds",
  BUS: "busTicketSupplierIds",
};

export default function CreateMarkup_B2C() {
  
  const {
    markupId,
    busDestinations,
    busCategoryTypes,
    busSeatTypes,
    serviceName,
    markupB2CForm,
    fareTypes,
    allFeeTypes,
    applyTypes,
    hotelPlaces,
    markupTypes,
    applyOnTypes,
    supplierList,
    airlinesList,
    applyForTypes,
    searchTypes,
    hotelCategories,
    allCountryList,
    flightAllClassTypes,
    flightCabinClassTypes,
    travelServiceCategories,
    editMarkupFromB2C,
    createMarkupFromB2C,
    getAirlinesBySuppliers,
    getHotelPlacesByCountries,
  } = useCreateMarkup_B2C();
  
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location

  const formValues = markupB2CForm.getValues();

  const { 
      gst,
      service, 
      startDate, 
      countryIds, 
      flightTicketSupplierIds
     } = formValues;

  return (
    <form
      onSubmit={markupB2CForm.onSubmit(
        markupId ? editMarkupFromB2C : createMarkupFromB2C
      )}
    >
      <PageLayout
        footer={
          <div className="flex justify-end gap-2 ">
            <EButton
              onClick={() => {
                navigate('/markup_and_commission/markup-b2c', 
                  { state: location.state });
              }}
              color="gray"
            >
              Cancel
            </EButton>
            <EButton type="submit" >
              {markupId ? "Update" : "Save"}
            </EButton>
          </div>
        }
      >
        <EPageTitle
          title={markupId ? "Edit Markup B2C" : `Create  ${""+location.state?.service?.toLowerCase()} Markup B2C`}
        />
        <ESectionDivider title="Service" />
        {/* <Grid>
          <GridCol span={4}>
            <ESelect
              disabled={!!markupId}
              withAsterisk
              label="Service"
              placeholder="Select"
              value={service}
              data={serviceTypes || []}
              {...markupB2CForm.getInputProps("service")}
              onChange={(value: any) => {
                markupB2CForm.reset();
                setServiceName(value);
              }}
            />
          </GridCol>
        </Grid> */}
        <Space h="lg" />
        <div className="w-full">
          <Grid hidden={service === "BUS"}>
            <GridCol span={4}>
              <ESelect
                withAsterisk
                searchable
                label="Apply For"
                placeholder="Select"
                key={markupB2CForm.key("chargeApplyForTypeId")}
                data={applyForTypes || []}
                value={formValues.chargeApplyForTypeId}
                {...markupB2CForm.getInputProps("chargeApplyForTypeId")}
                onChange={(value: any) => {
                  markupB2CForm.setFieldValue("chargeApplyForTypeId", value);
                }}
              />
            </GridCol>
            <GridCol span={4}>
              <ESelect
                withAsterisk
                searchable
                label="Markup Types"
                placeholder="Select"
                data={markupTypes || []}
                key={markupB2CForm.key("bookingTypeId")}
                {...markupB2CForm.getInputProps("bookingTypeId")}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <ESelect
                withAsterisk
                searchable
                label="Fare Types"
                placeholder="Select"
                data={fareTypes || []}
                key={markupB2CForm.key("flightFareTypeId")}
                {...markupB2CForm.getInputProps("flightFareTypeId")}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <ESelect
                withAsterisk
                searchable
                label="Apply On"
                placeholder="Select"
                data={applyOnTypes || []}
                key={markupB2CForm.key("chargeApplyOnTypeId")}
                {...markupB2CForm.getInputProps("chargeApplyOnTypeId")}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <ESelect
                withAsterisk
                searchable
                label="Search Type"
                placeholder="Select"
                data={searchTypes || []}
                key={markupB2CForm.key("flightJourneyTypeId")}
                {...markupB2CForm.getInputProps("flightJourneyTypeId")}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <ESelect
                withAsterisk
                searchable
                label="Apply Type"
                placeholder="Select"
                data={applyTypes || []}
                key={markupB2CForm.key("flightApplyTypeId")}
                {...markupB2CForm.getInputProps("flightApplyTypeId")}
              />
            </GridCol>
          </Grid>
        </div>
        <Space h="xl" />
        <div className="w-full">
          <ESectionDivider title="Markup Validity" />
          <Grid>
            <GridCol span={4}>
              <EDateInput
                withAsterisk
                label="Start Date"
                placeholder="Start Date"
                minDate={new Date()}
                valueFormat="YYYY-MM-DD"
                value={markupB2CForm.getValues().startDate ? new Date(markupB2CForm.getValues().startDate) : null}
                key={markupB2CForm.key("startDate")}
                {...markupB2CForm.getInputProps("startDate")}
                onChange={(value) => {
                  markupB2CForm.setFieldValue("startDate", value);
                }}
              />
            </GridCol>
            <GridCol span={4}>
              <EDateInput
                withAsterisk
                size="md"
                label="End Date"
                placeholder="End Date"
                valueFormat="YYYY-MM-DD"
                value={markupB2CForm.getValues().endDate ? new Date(markupB2CForm.getValues().endDate) : null}
                minDate={startDate || new Date()}
                disabled={!startDate}
                key={markupB2CForm.key("endDate")}
                {...markupB2CForm.getInputProps("endDate")}
                onChange={(value) => {
                  markupB2CForm.setFieldValue("endDate", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <ESectionDivider title="Gross Price Range" />
          <Grid>
            <GridCol span={4}>
              <ENumberInput
                withAsterisk
                label="From Price"
                placeholder="Enter price"
                key={markupB2CForm.key("minimumGrossPrice")}
                {...markupB2CForm.getInputProps("minimumGrossPrice")}
              />
            </GridCol>
            <GridCol span={4}>
              <ENumberInput
                withAsterisk
                label="To Price"
                placeholder="Enter price"
                key={markupB2CForm.key("maximumGrossPrice")}
                {...markupB2CForm.getInputProps("maximumGrossPrice")}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <ESectionDivider
            title={
              service === "FLIGHT"
                ? "Country Wise"
                : service === "HOTEL"
                  ? "Destination"
                  : "Destination/Route"
            }
          />
          <Grid>
            <GridCol span={4} hidden={serviceName === "BUS"}>
              <EMultiSelect
                withAsterisk
                searchable
                placeholder="Select"
                label={serviceName === "FLIGHT" ? "Origin Country" : "Country"}
                key={markupB2CForm.key(CountryOriginFiledKeys[serviceName])}
                data={allCountryList || []}
                {...markupB2CForm.getInputProps(
                  CountryOriginFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  if (serviceName === "HOTEL") {
                    getHotelPlacesByCountries(value);
                  }
                  markupB2CForm.setFieldValue(
                    CountryOriginFiledKeys[serviceName],
                    value
                  );
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={serviceName !== "BUS"}>
              <EMultiSelect
                searchable
                withAsterisk
                placeholder="Select"
                label="Country"
                key={markupB2CForm.key(CountryOriginFiledKeys[serviceName])}
                data={busDestinations || []}
                {...markupB2CForm.getInputProps(
                  CountryOriginFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2CForm.setFieldValue(
                    CountryOriginFiledKeys[serviceName],
                    value
                  )
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
              <EMultiSelect
                searchable
                withAsterisk
                placeholder="Select"
                label="Destination Country"
                key={markupB2CForm.key(
                  CountryDestinationFiledKeys[serviceName]
                )}
                data={allCountryList || []}
                {...markupB2CForm.getInputProps(
                  CountryDestinationFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2CForm.setFieldValue(
                    CountryDestinationFiledKeys[serviceName],
                    value
                  )
                }}     
              />
            </GridCol>
            <GridCol span={4} hidden={serviceName !== "HOTEL"}>
              <EMultiSelect
                searchable
                withAsterisk
                disabled={!countryIds?.length}
                placeholder="Select"
                label="Destination"
                // value={markupB2CForm.getValues().CountryDestinationFiledKeys["HOTEL"] ?? null}\\
                key={markupB2CForm.key(
                  CountryDestinationFiledKeys[serviceName]
                )}
                data={hotelPlaces || []}
                {...markupB2CForm.getInputProps(
                  CountryDestinationFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2CForm.setFieldValue(
                    CountryDestinationFiledKeys[serviceName],
                    value
                  )
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={serviceName !== "BUS"}>
              <EMultiSelect
                searchable
                withAsterisk
                placeholder="Select"
                label="Destination"
                key={markupB2CForm.key(
                  CountryDestinationFiledKeys[serviceName]
                )}
                data={busDestinations || []}
                {...markupB2CForm.getInputProps(
                  CountryDestinationFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2CForm.setFieldValue(
                    CountryDestinationFiledKeys[serviceName],
                    value
                  )
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <ESectionDivider
            title="Flight Class / Flight Cabin Class"
            hidden={service !== "FLIGHT"}
          />
          <Grid hidden={service !== "FLIGHT"}>
            <GridCol span={4}>
              <EMultiSelect
                withAsterisk
                label="Flight Class"
                size="md"
                placeholder="Select"
                data={flightAllClassTypes || []}
                key={markupB2CForm.key("flightClass")}
                {...markupB2CForm.getInputProps("flightClass")}
                onChange={(value) => {
                  markupB2CForm.setFieldValue("flightClass", value);
                }}
              />
            </GridCol>
            <GridCol span={4}>
              <EMultiSelect
                withAsterisk
                searchable
                label="Flight Cabin Class"
                size="md"
                placeholder="Select"
                data={flightCabinClassTypes || []}
                key={markupB2CForm.key("flightCabin")}
                {...markupB2CForm.getInputProps("flightCabin")}
                onChange={(value) => {
                  markupB2CForm.setFieldValue("flightCabin", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <ESectionDivider title="Suppliers / Airlines" />
          <Grid>
            <GridCol span={4}>
              <EMultiSelect
                withAsterisk
                searchable
                label="Suppliers"
                placeholder="Select"
                data={supplierList || []}
                key={markupB2CForm.key(SuppliersFiledKeys[serviceName])}
                {...markupB2CForm.getInputProps(
                  SuppliersFiledKeys[serviceName]
                )}
                onChange={(value) => {
                  markupB2CForm.setFieldValue(
                    SuppliersFiledKeys[serviceName],
                    value
                  );
                  if (!value.length && service === "FLIGHT") {
                    markupB2CForm.setFieldValue("flightAirlineIds", []);
                  } else {
                    getAirlinesBySuppliers(value);
                  }
                }}
              />
            </GridCol>
            <GridCol span={4} hidden={service !== "FLIGHT"}>
              <EMultiSelect
                withAsterisk
                searchable
                label="Airlines"
                placeholder="Select"
                data={airlinesList || []}
                disabled={!flightTicketSupplierIds?.length}
                key={markupB2CForm.key("flightAirlineIds")}
                {...markupB2CForm.getInputProps("flightAirlineIds")}
                onChange={(value) => {
                  markupB2CForm.setFieldValue("flightAirlineIds", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" hidden={service !== "HOTEL"} />
          <ESectionDivider title="Category" hidden={service !== "HOTEL"} />
          <Grid hidden={service !== "HOTEL"}>
            <GridCol span={4}>
              <EMultiSelect
                withAsterisk
                searchable
                label="Category Type"
                placeholder="Select"
                data={hotelCategories || []}
                key={markupB2CForm.key("hotelCategoryIds")}
                {...markupB2CForm.getInputProps("hotelCategoryIds")}
                onChange={(value) => {
                  markupB2CForm.setFieldValue("hotelCategoryIds", value);
                }}
              />
            </GridCol>
          </Grid>
          <Space h="xl" />
          <div hidden={service !== "BUS"}>
            <ESectionDivider title="Category" />
            <Grid>
              <GridCol span={4}>
                <EMultiSelect
                  withAsterisk
                  searchable
                  label="Category Type"
                  placeholder="Select"
                  data={busCategoryTypes || []}
                  key={markupB2CForm.key("busCategories")}
                  {...markupB2CForm.getInputProps("busCategories")}
                  onChange={(value) => {
                    markupB2CForm.setFieldValue("busCategories", value);
                  }}
                />
              </GridCol>
            </Grid>
            <Space h="xl" />
            <ESectionDivider title="Seats" />
            <Grid>
              <GridCol span={4}>
                <EMultiSelect
                  withAsterisk
                  searchable
                  label="Category Type"
                  placeholder="Select"
                  data={busSeatTypes || []}
                  key={markupB2CForm.key("busSeatCategories")}
                  {...markupB2CForm.getInputProps("busSeatCategories")}
                  onChange={(value) => {
                    markupB2CForm.setFieldValue("busSeatCategories", value);
                  }}
                />
              </GridCol>
            </Grid>
          </div>
          <Space h="xl" />
          <ESectionDivider title="Markup" />
          <div className="flex gap-4">
            <Radio
              required
              label={"Exclusive GST"}
              checked={!gst}
              onClick={() => {
                markupB2CForm.setFieldValue("gst", false);
              }}
            />
            <Radio
              required
              label={"Inclusive GST"}
              checked={gst}
              onClick={() => {
                markupB2CForm.setFieldValue("gst", true);
              }}
            />
          </div>
          <Space h={"xl"} />
          <Grid>
            {travelServiceCategories?.map((category, index) => {
              const { name } = category;
              return (
                <GridCol span={4}>
                  <h1 className="text-lg text-black font-bold mb-2">{name}</h1>
                  <ENumberInput
                    withAsterisk
                    label="Amount"
                    placeholder="Enter Amount"
                    key={markupB2CForm.key(
                      `travelFormFeeRequests.${index}.fee`
                    )}
                    {...markupB2CForm.getInputProps(
                      `travelFormFeeRequests.${index}.fee`
                    )}
                  />
                  <Space h="sm" />
                  <ESelect
                    withAsterisk
                    searchable
                    label="Fee Type"
                    placeholder="Select"
                    data={allFeeTypes}
                    key={markupB2CForm.key(
                      `travelFormFeeRequests.${index}.feeType`
                    )}
                    {...markupB2CForm.getInputProps(
                      `travelFormFeeRequests.${index}.feeType`
                    )}
                  />
                </GridCol>
              );
            })}
          </Grid>
        </div>
      </PageLayout>
    </form>
  );
}
