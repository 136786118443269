import React, { useContext, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import Login from "../Pages/Auth/Pages/Login/Login";

import { AppContext } from "../AppContext";
import { IRoute } from "../types";
import { ALL_ALLOWED_ROUTES } from "../App";
import ErrorBoundaryWrapper from "ErrorBoundaryWrapper";
import Home from "Pages/Home";
export default function RootRoutes() {
  const { isLoggedIn, token, role, user, userAuthorities } =
    useContext(AppContext);

  const getToken = () => {
    let tkn = localStorage.getItem("token");
    return tkn || token;
  };
  const getIsLoggedIn = () => {
    let tkn = localStorage.getItem("isLoggedIn");
    return tkn || isLoggedIn;
  };
  const getRole = () => {
    let data = localStorage.getItem("role");
    let tkn = JSON.parse(data as string);
    return tkn || role;
  };

  const subMenuRecursion = (submenu: IRoute[]): JSX.Element[] => {
    return submenu.map((sub, i) => {
      return (
        <>
          <Route
            key={"sub-route_" + i}
            path={sub.path}
            element={sub.element ? <sub.element /> : <></>}
          />
          {sub.submenu && subMenuRecursion(sub?.submenu)}
        </>
      );
    });
  };

  return (
    <Routes>
      <>
        {getIsLoggedIn() && getToken() ? (
          <>
            {ALL_ALLOWED_ROUTES.map((each, index) => (
              <React.Fragment key={"route_" + index}>
                <Route path={each.path} element={each.element ? <each.element /> : <ErrorBoundaryWrapper/>} />
                {each.submenu && subMenuRecursion(each.submenu)}
              </React.Fragment>
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </>
    </Routes>
  );
}
