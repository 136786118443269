import axios from "axios";
import React from "react";
import { BASE_URL } from "../../constant";
import {
  handleFormatResponse,
  handleFormatResponseForError,
} from "../../utility";

export const useApi = () => {
  const getData = async (url: string) => {
    try {
      let response = await axios.get(`${BASE_URL}${url}`);
      const formattedResponse = handleFormatResponse(response);
      return formattedResponse;
    } catch (error: any) {
      const formattedError = handleFormatResponseForError(error);
      return formattedError;
    }
  };

  const postData = async (url: string, payload: any | null) => {
    try {
      let response;
      if (payload == null) {
        response = await axios.post(`${BASE_URL}${url}`)
      }
      else {
       response = await axios.post(`${BASE_URL}${url}`, payload)
    }
    const formattedResponse = handleFormatResponse(response);
    return formattedResponse;
  } catch (error: any) {
    const formattedError = handleFormatResponseForError(error);
    return formattedError;
  }
};

const putData = async (url: string, payload: any) => {
  try {
    let response = await axios.put(`${BASE_URL}${url}`, payload);
    const formattedResponse = handleFormatResponse(response);
    return formattedResponse;
  } catch (error: any) {
    const formattedError = handleFormatResponseForError(error);
    return formattedError;
  }
};

const patchData = async (url: string, payload: any) => {
  try {
    let response = await axios.patch(`${BASE_URL}${url}`, payload);
    const formattedResponse = handleFormatResponse(response);
    return formattedResponse;
  } catch (error: any) {
    const formattedError = handleFormatResponseForError(error);
    return formattedError;
  }
};

const deleteData = async (url: string) => {
  try {
    let response = await axios.delete(`${BASE_URL}${url}`);
    const formattedResponse = handleFormatResponse(response);
    return formattedResponse;
  } catch (error: any) {
    const formattedError = handleFormatResponseForError(error);
    return formattedError;
  }
};

return {
  getData,
  postData,
  putData,
  patchData,
  deleteData,
};
};
