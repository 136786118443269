import { showNotification } from "@mantine/notifications";
import { Tooltip } from "@mantine/core";
import axios from "axios";
import moment from "moment";
import { IFlightMarkupB2C, TCommissionFormB2B } from "types";
import { JsxElement } from "typescript";
import AirlineInfo from "../assets/data/AirlineInfo.json";

export const validateArrayField = (message: string) => (value: any[]) => {
  if (!value?.length) {
    return message;
  }
  return null;
};

export const travelFormFeeRequestsValidation = (
  value: any,
  values: any,
  path: any
) => {
  if (!value) {
    return "Please enter Amount";
  }
  const index = path.match(/\d+/g);
  let val = values.travelFormFeeRequests[index[0]];
  if (val.feeType === "PERCENTAGE") {
    const error = validatePercentage(val);
    if (error) {
      return error;
    }
  }
};

export const mapDropdownData = (data: any) => {
  if (!data?.length) {
    return [];
  }
  return data.map(
    (type: { id: number; name: string; key: string; value: string }) => {
      const { name, key, value, id } = type;
      const label = name || value;
      const valueKey = key || id;
      return { label: label, value: valueKey.toString() };
    }
  );
};

export const validatePercentage = (value: any) => {
  if (value.feeType === "PERCENTAGE" && parseInt(value.fee) > 100) {
    return `Percentage cannot be greater than 100`;
  }
  return null;
};

/* Group by key */
export const groupByKey = (array: any[], key: string) => {
  return array?.reduce((temp, obj) => {
    if (!obj[key]) return temp;
    const keyValue = obj[key];
    temp[keyValue] = (temp[keyValue] || []).concat(obj);
    return temp;
  }, {});
};

/* Mantine Toast */
export const showToast = (data: {
  message: string;
  title?: string;
  type?: "error" | "success" | "warning";
  time?: number;
  icon?: JSX.Element;
  className?: string;
  isLoading?: boolean;
  nonClosable?: boolean;
}) => {
  showNotification({
    title: data?.title,
    message: data?.message,
    color:
      data?.type === "error"
        ? "red"
        : data?.type === "success"
          ? "green"
          : data?.type === "warning"
            ? "yellow"
            : "blue",
    autoClose: data?.time ? data?.time : 4000,
    icon: data?.icon,
    className: data?.className,
    loading: data?.isLoading,
    disallowClose: data?.nonClosable,
  });
};

/* validators */
export const hasSpecialChar = (str: string) => {
  // console.log(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?@()._]/g.test(str),str);
  return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?@()._]/g.test(str);
};
export const hasOnlyNumber = (str: string) => {
  return /^\d+$/g.test(str);
};
export const isValidPhone = (str: string) => {
  return str === "+" || /^\+\d+$/.test(str) || /^\d+$/.test(str);
};
export const hasOnlyChars = (str: string) => {
  return /^[a-zA-Z\s]+$/g.test(str);
};
export const isValidEmail = (str: string) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g.test(str);
};

export const isValidAadhaar = (str: string) => {
  return /^[2-9]{1}[0-9]{11}$/g.test(str);
};

export const isValidURL = (url: string) => {
  let expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let match = url.match(expression);
  // console.log(match);
  if (match) return true;
  else return false;
};

export const calculateCurrentMonth = () => {
  let currentMonth = (new Date().getMonth() + 1).toString();

  if (+currentMonth < 10) {
    currentMonth = `0${currentMonth}`;
  }

  return currentMonth;
};
export const calculateCurrentMonthForDashboard = () => {
  let currentMonth = (new Date().getMonth() + 1).toString();
  let todayDate = moment().date();

  if (todayDate < 20) {
    currentMonth = String(currentMonth == "1" ? "12" : +currentMonth - 1);
  }
  if (+currentMonth < 10) {
    currentMonth = `0${currentMonth}`;
  }

  return currentMonth;
};
// export const calculateYearForDashboard = (selectedMonth: number) => {
//   if (selectedMonth > 4) {
//     return moment().subtract(1, "year").format("YYYY")
//   }
//   else {
//     return moment().format("YYYY")
//   }
// };
export const calculateYearForDashboard = (selectedMonth: number) => {
  const currentMonth = parseInt(moment().format("MM"), 10);
  if (selectedMonth > 4 && selectedMonth > currentMonth) {
    // "MM" staunds for current months
    return moment().subtract(1, "year").format("YYYY");
  } else {
    return moment().format("YYYY");
  }
};

export const calculateDefaultSession = (selectedMonth: number) => {
  console.log("selectedMonth", selectedMonth);
  if (selectedMonth < 4) {
    return `Session ${moment()
      .subtract(1, "year")
      .format("YYYY")} - ${moment().format("YYYY")}`;
  } else {
    return `Session ${moment().format("YYYY")} - ${moment()
      .add(1, "year")
      .format("YYYY")}`;
  }
};

// Map Utility function
export let hoverColor = "#00a67e";

export function camelCaseToTitleCase(input: string): string {
  // Split the string by uppercase letters
  const words = input.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word and join them back together
  const titleCase = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return titleCase;
}

export const handleColor = (remarks: number) => {
  if (remarks <= 20) {
    return "#FF0000";
  }
  if (remarks > 20 && remarks <= 40) {
    return "#FFAC27";
  }
  if (remarks > 40 && remarks <= 60) {
    return "#FFFF1B";
  }
  if (remarks > 60 && remarks <= 80) {
    return "#7CFF77";
  }
  if (remarks > 80 && remarks <= 100) {
    return "#00EE33";
  }
  if (remarks == 400) {
    return "#D3D3D3";
  }
  if (remarks > 100) {
    return "#00EE33";
  } else {
    return "#FF0000";
  }
};

export const handleCheckDateRange = (date: string) => {
  var today = moment().format("YYYY-MM-DD");
  const d_date = moment(date).format("YYYY-MM-DD");
  const can_delete = moment(d_date).isAfter(today, "days");

  return can_delete;
};

export const handleCheckEndDateRange = (date: string) => {
  var today = moment().format("YYYY-MM-DD");
  const d_date = moment(date).format("YYYY-MM-DD");
  const can_delete = !moment(d_date).isSameOrAfter(today, "days");

  return can_delete;
};

/* Sorting the appointments array based on the date. */
export const handleSortByOrderDate = ({
  appointments,
  orderByDateKey,
  isAscend,
}: {
  appointments: any[];
  orderByDateKey: string;
  isAscend?: boolean;
}) => {
  return appointments?.sort((a, b) => {
    const date1 = new Date(a[orderByDateKey]);
    const date2 = new Date(b[orderByDateKey]);
    if (isAscend) {
      return date1 > date2 ? 1 : -1;
    } else {
      return date1 < date2 ? 1 : -1;
    }
  });
};

export const handleDownload = (fileUrl: any, title: string) => {
  // Replace with the URL of the downloadable file
  return new Promise((resolve, reject) => {
    axios({
      url: fileUrl,
      method: "GET",
      responseType: "blob", // Specify the response type as a blob
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${title.replaceAll("_", " ")}`; // Specify the filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        resolve(true);
      })
      .catch((error) => {
        console.error("Download failed:", error);
        reject(error);
      });
  });
};

export function convertToArray(obj: any) {
  const ifObject: boolean = isObject(obj);
  if (ifObject) {
    return [obj];
  };
  return obj;
};

export function isObject(value: any) {
  // Check if the value is not null and its type is 'object'
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};

export const getAirLineData = (airlineCode: string) => {
  const data = AirlineInfo;
  const airlineData = data[airlineCode as keyof typeof AirlineInfo];

  if (!airlineData) {
    return {
      iata: airlineCode,
      name: airlineCode,
      organization: "",
      country: "",
      logo: "/logo192.png"
    };
  };

  return airlineData;
};

export const handleUpdateStateOnResponse = (currentState: any[], id: number, response: any, compareKey?: string) => {
  if (currentState?.length) {
    return currentState.map((state) => {
      if ( compareKey && state[compareKey] == id) {
        return response
      }
      if (state.id == id) {
        return response
      }
      return state
    })
  }
  else {
    return []
  }
}