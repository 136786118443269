"use client";

import React, { useEffect, useState } from "react";
import { CiCircleQuestion } from "react-icons/ci";
import useFileSelect from "./hooks/useFileSelect";
import { Group, Loader, Tooltip, Image } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { FaCross, FaUpload } from "react-icons/fa";
import { BsImage } from "react-icons/bs";
import { showToast } from "utils/utility";
import { EConfirm } from "../Confirm/EConfirm";
import { MdDelete } from "react-icons/md";
import { BASE_URL } from "libs/data-access/constant";
import EImage from "../EImage/EImage";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    error?: string;
    viewOnly?: boolean;
    required?: boolean;
    helperText?: string;
    supportText?: string;
    value?: string;
    onChange?: (value: any) => void;
    onDrop?: (value: any) => void;
    fileTypes?: "IMAGE" | "VIDEO" | "DOC";
    multiple?: boolean;
    maxSize?: number;
    minSize?: number;
    filesId?: number[];
}

export default function EDropzone({
    label,
    placeholder,
    className,
    disabled,
    error,
    viewOnly,
    required,
    helperText,
    supportText,
    value,
    id,
    onChange,
    onDrop,
    fileTypes,
    multiple,
    filesId,
    maxSize,
    minSize,
    maxLength = 1,
    ...props
}: IProps) {

    const {
        loading,
        loadingDeleteIcon,
        setLoadingDeleteIcon,
        isFileDragOver,
        setIsFileDragOver,
        dropError,
        setDropError,
        allFiles,
        setAllFiles,
        handleChangePayload,
        handleDropFile,
        handleDeleteMediaFile,
    } = useFileSelect({ filesId: filesId ?? [] });

    useEffect(() => {
        if (onChange) {
            onChange([...allFiles.new, ...allFiles.old] ?? []);
        }
    }, [allFiles])

    const [errorText, setErrorText] = useState<string>("");

    useEffect(() => {
        setErrorText(error ?? "");
    }, [error]);
    return (
        <div
            className={`w-full cursor-pointer flex flex-col py-3 ${className}`}
        >
            {label ? (
                <div className={`flex flex-row items-center `}>
                    <div
                        className={` flex ${viewOnly
                            ? "text-textPlaceholder font-medium text-sm"
                            : "text-medium text-base font-medium text-textPrimary"
                            }`}
                    >
                        {label}
                        {required ? <div className="text-danger">*</div> : <></>}
                    </div>
                    {helperText ? (
                        <Tooltip label={helperText} >
                            <div>
                                <CiCircleQuestion size={18} className="text-textPrimary" />
                            </div>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
            {
                maxLength && (((allFiles?.old?.length) + allFiles?.new?.length) >= maxLength) || viewOnly
                    ?
                    <></>
                    :
                    <div className={disabled ? "cursor-not-allowed" : ""} >
                        <Dropzone
                            onDrop={(files) => {
                                setIsFileDragOver(false)
                                setDropError(undefined)
                                handleDropFile(files)
                            }}
                            onReject={(files) => setDropError(files[0].errors[0].message)}
                            maxSize={maxSize}
                            maxFiles={maxLength ?? 1}
                            onDragEnter={() => {
                                setIsFileDragOver(true)
                            }}
                            onDragLeave={() => {
                                setIsFileDragOver(false)
                            }}

                        >
                            <div className="flex justify-around items-center border-2 border-dashed rounded-md p-8">
                                <Dropzone.Accept>
                                    <FaUpload
                                        size={44}
                                        className="text-green-300"
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <FaCross
                                        size={44}
                                        className="text-red-400"
                                    />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <BsImage
                                        size={44}
                                        className="text-green-300"
                                    />
                                </Dropzone.Idle>
                                <div>
                                    <p className="text-blue-700 font-semibold">Browse or Drop File</p>
                                    <div className="text-textPlaceholder text-xs font-normal capitalize" >{fileTypes ? fileTypes?.toLowerCase() + ": " : "image/video/file"} {fileTypes ? `${Object?.keys(fileTypes)?.join(", ")}`?.replaceAll(`${fileTypes?.toLowerCase()}/`, " ") : ``}{maxSize ? ` (max. ${maxSize}MB)` : " / __mb"}</div>
                                </div>
                            </div>
                        </Dropzone>
                    </div>
                // </div>
            }

            {/* Files List */}

            <div className="w-full flex flex-col gap-2 mt-5">
                {allFiles?.old?.map((file, index) => (
                    <div key={index} className="w-full flex flex-row items-center mt-3 border border-border rounded-md bg-slate-100 px-4 py-3 text-wrap overflow-hidden justify-between">
                        <div className="flex flex-row items-end gap-3">
                            <div className="h-20 w-20 rounded-lg overflow-hidden">
                                <EImage
                                    mediaId={file}
                                />
                            </div>
                            <div className="text-base font-semibold pb-1">{file}</div>
                        </div>
                        {disabled || viewOnly ? <></> : <div
                            className="cursor-pointer text-red-500 hover:text-red-400"
                            onClick={async () => {
                                setLoadingDeleteIcon(index);
                                handleDeleteMediaFile(file, "old");
                            }}
                        >
                            {loadingDeleteIcon === index ? <Loader size={24} /> : <MdDelete size={24} />}
                        </div>}
                    </div>
                ))}
                {allFiles?.new?.map((file, index) => (
                    <div key={index} className="w-full flex flex-row items-center mt-3 border border-border rounded-md px-4 py-3 text-wrap overflow-hidden justify-between">
                        <div className="flex flex-row items-center gap-3">
                            <div className="text-placeholder font-normal"><FaUpload size={40} /></div>
                            <div className="flex flex-col justify-between">
                                <div className="text-base font-semibold-4 truncate">{file}</div>
                                {/* <div className="text-sm font-normal">{file?.mediaType}</div> */}
                            </div>
                        </div>
                        {disabled || viewOnly ? <></> : <div
                            className="cursor-pointer text-red-500 hover:text-red-400"
                            onClick={async () => {
                                if (!loadingDeleteIcon) {
                                    setLoadingDeleteIcon(index);
                                    handleDeleteMediaFile(file, "new");
                                }
                            }}
                        >
                            {viewOnly ? <></> : loadingDeleteIcon === index ? <Loader size={24} /> : <MdDelete size={24} />}
                        </div>}
                    </div>
                ))}

            </div>

            {
                !!dropError ? (
                    <div className="text-danger text-xs font-medium px-1">{dropError}</div>
                ) :
                    errorText ?
                        <div className="text-danger text-xs font-medium px-1">{errorText}</div> :
                        supportText ? (
                            <div className="text-textSecondary text-xs font-medium px-1">
                                {supportText}
                            </div>
                        ) : (
                            <></>
                        )}
        </div>
    );
}
