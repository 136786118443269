import { isNotEmpty } from "@mantine/form";
import { IFlightMarkupB2BPayload, IHotelMarkupB2BPayload } from "types";
import {
  validateArrayField,
  travelFormFeeRequestsValidation,
} from "utils/utility";

const CommonValidation = {
  startDate: isNotEmpty("Please select Start Date"),
  endDate: isNotEmpty("Please select End Date"),
  minimumGrossPrice: isNotEmpty("Please enter From Price"),
  affiliateGroupId: isNotEmpty("Please select Group"),
  maximumGrossPrice(value: any, values: any) {
    if (!value) {
      return "Please enter To Price";
    }
    if (
      parseInt(values.maximumGrossPrice) < parseInt(values.minimumGrossPrice)
    ) {
      return "Maximum Price cannot be less than Minimum Price";
    }
  },
};

const flightValidation = {
  chargeApplyForTypeId: isNotEmpty("Please select Charge Apply For Type"),
  bookingTypeId: isNotEmpty("Please select Markup Type"),
  chargeApplyOnTypeId: isNotEmpty("Please select Apply On"),
  flightFareTypeId: isNotEmpty("Please select Fare Types"),
  flightJourneyTypeId: isNotEmpty("Please select Search Type"),
  flightApplyTypeId: isNotEmpty("Please select Apply Type"),
  flightDestinationCountryIds: validateArrayField(
    "Please select Origin Country"
  ),
  flightOriginCountryIds: validateArrayField(
    "Please select Destination Country"
  ),
  destinationAirportIds: validateArrayField("Please select Route Destination"),
  originAirportIds: validateArrayField("Please select Route Origin"),
  flightClass: validateArrayField("Please select Flight Class"),
  flightCabin: validateArrayField("Please select Flight Cabin Class"),
  flightTicketSupplierIds: validateArrayField("Please select Suppliers"),
  flightAirlineIds: validateArrayField("Please select Airlines"),
};

const hotelValidation = {
  chargeApplyForTypeId: isNotEmpty("Please select Charge Apply For Type"),
  bookingTypeId: isNotEmpty("Please select Markup Type"),
  countryIds: validateArrayField("Please select Country"),
  placeIds: validateArrayField("Please select Place"),
  hotelBookingSupplierIds: validateArrayField(
    "Please select Hotel Booking Suppliers"
  ),
  hotelCategoryIds: validateArrayField("Please select Hotel Category"),
};

const busValidaitons = {
  originPlaceIds: validateArrayField("Please select Origin"),
  destinationPlaceIds: validateArrayField("Please select Destination"),
  busTicketSupplierIds: validateArrayField("Please select Suppliers"),
  busCategories: validateArrayField("Please select Categories"),
  busSeatCategories: validateArrayField("Please select Seat Categories"),
};

const validationFormFields = {
  FLIGHT: flightValidation,
  HOTEL: hotelValidation,
  BUS: busValidaitons,
};

const travelFormFeeRequests = {
  FLIGHT: [
    { fee: "", feeType: "", travelFormFeeId: 1 },
    { fee: "", feeType: "", travelFormFeeId: 2 },
    { fee: "", feeType: "", travelFormFeeId: 3 },
  ],
  HOTEL: [
    { fee: "", feeType: "", travelFormFeeId: 1 },
    { fee: "", feeType: "", travelFormFeeId: 2 },
  ],
  BUS: [
    { fee: "", feeType: "", travelFormFeeId: 1 },
    { fee: "", feeType: "", travelFormFeeId: 2 },
  ],
};

const DefaultFlightMarkupB2C : IFlightMarkupB2BPayload = {} as IFlightMarkupB2BPayload;
const DefaultHotelMarkupB2C : IHotelMarkupB2BPayload = {} as IHotelMarkupB2BPayload;
export { validationFormFields, CommonValidation, travelFormFeeRequests, DefaultFlightMarkupB2C, DefaultHotelMarkupB2C };
