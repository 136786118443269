import { Button, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ETable } from "../../../../libs/ui";
import { useOriginCountry } from "./hook/useOriginCountry";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function OriginCountry() {

    const {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        originCountryList,
        newOriginCountry,
        setNewOriginCountry,
        selectedOriginCountry,
        setSelectedOriginCountry,
        submitNewOriginCountry,
        UpdateStatus,
        UpdateCountry,
        deleteCountry,
        getOriginCountryList
    } = useOriginCountry();


    return (
        <PageLayout>
            <EPageTitle
                title="Country"
                end={
                    <div className="flex gap-1">
                        <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton>
                    </div>
                }
            />
            {/* <div className="flex gap-2 items-start">
                <TextInput
                    placeholder="Search"
                    leftSection={
                        <BsSearch />
                    }
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <div
                    className="grow">
                </div>
                
                <PrintableTable
                    title="Country"
                    data={originCountryList.map((e: IOriginCountry) => ({ name: e?.name, status: e?.status ? "Active" : "Inactive" }))}
                />

            </div> */}


            <ETable
                loading={isLoading}
                pagination
                onChangePagination={(e) => getOriginCountryList(e)}
            >
                <thead>
                    <tr>
                        <th>SR. No.</th>
                        <th>Country Name</th>
                        <th>Status</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        originCountryList?.map((each, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{each.name}</td>
                                    <td>
                                        <ESwitch
                                            label={each.status ? "Active" : "Inactive"}
                                            loading={isSwitchLoadingIndex === index}
                                            checked={!!each.status}
                                            onChange={() => { each?.id && UpdateStatus(each?.id, !each?.status, index) }}
                                            withConfirmation
                                        />
                                    </td>
                                    <td
                                        className="flex w-full gap-1 items-center justify-end">
                                        <Tooltip label="Edit" position="top" >
                                            <div>
                                                <FaRegEdit
                                                    size={18}
                                                    className="cursor-pointer text-green-400 hover:text-green-600"
                                                    onClick={() => {
                                                        setIsOpenModalValue("Edit")
                                                        setSelectedOriginCountry(each)
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        {/* <Tooltip label="Delete">
                                                        <div>
                                                        <MdDeleteForever
                                                            size={22}
                                                            className="cursor-pointer text-orange-400 hover:text-orange-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Delete")
                                                                setSelectedOriginCountry(each)
                                                            }}
                                                        />
                                                        </div>
                                                    </Tooltip> */}
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>

            </ETable>

            {/* ADD NEW Modal */}
            <EModal
                title="New Country"
                opened={isOpenModalValue === "New"}

                onClose={() => {
                    setNewOriginCountry({ name: "" });
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Country Name"
                        placeholder="Enter Name"
                        value={newOriginCountry?.name}
                        onChange={(e) => setNewOriginCountry({ ...newOriginCountry, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newOriginCountry?.name)}
                            loading={isBtnLoading}
                            onClick={() => submitNewOriginCountry()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Country */}
            <EModal
                title="Edit Country"
                opened={isOpenModalValue === "Edit"}

                onClose={() => {
                    setSelectedOriginCountry(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Country Name"
                        placeholder="Enter Name"
                        value={selectedOriginCountry?.name}
                        onChange={(e) => setSelectedOriginCountry({ ...selectedOriginCountry, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(selectedOriginCountry?.name)}
                            loading={isBtnLoading}
                            onClick={() => selectedOriginCountry?.id && UpdateCountry(selectedOriginCountry?.id, selectedOriginCountry?.name)}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </EModal>

            <EModal
                title="Delete Country"
                opened={isOpenModalValue === "Delete"}

                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2">
                    <p>Are you sure you want to delete this Country?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedOriginCountry(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedOriginCountry?.id && deleteCountry(selectedOriginCountry?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
