import { useForm } from "@mantine/form";
import useMaster from "hook/useMaster";
import { PAGINATION_ITEM_LIMIT } from "libs/data-access/constant";
import { useApi } from "libs/data-access/src/api/useApi";
import { useEffect, useState } from "react";
import {
  IServices,
  IAdditionalChargesForm,
  TPaginationStatus,
  IAdditionalChargeList,
} from "types";
import { showToast } from "utils/utility"

const initialFormValues: IAdditionalChargesForm = {
  userCategory: "",
  groupIds: [],
  affiliateIds: [],
  applyForTypeId: "",
  additionalChargeTypeId: "",
  additionalSubChargeTypeId: "",
  bookingTypeId: "",
  amountType: "",
  amount: "",
  startDate: "",
  endDate: "",
  gst: false,
  baseFareIncluded: false,
  minDayDifference: "",
  content: ""
}

const useOtherCharges = () => {
  const {
    serviceTypes,
    groupTypes,
    affiliates,
    allFeeTypes,
    applyForTypes,
    userCategoryType,
    bookingChargeCategories,
    paymentGateway,
    paymentMode,
    markupTypes,
    supplierList,
    airlinesList,
    additionalChargeTypes,
    additionalSubChargeTypes,
    amountTypes,

    getServiceTypes,
    getAllGroupTypes,
    getUserCategoryTypes,
    getAffiliatedByGroup,
    getAllFeeTypes,
    getMarkupTypes,
    getApplyForTypes,
    getBookingChargeCategories,
    getPaymentGateway,
    getPaymentModeBasedOnGateway,
    getSupplierList,
    getAirlinesBySuppliers,
    getAdditionalChargeTyps,
    getAdditionalSubChargeTypesByCharge,
    getAmountTypes,
  } = useMaster();

  const { postData, putData, patchData } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isLoadingIndex, setIsLoadingIndex] = useState<number | undefined>(undefined);
  const [serviceName, setServiceName] = useState<IServices>("FLIGHT");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formAction, setFormAction] = useState<"add" | "edit">("add");
  const [myAdditionalChargesList, setMyAdditionalChargesList] = useState<IAdditionalChargeList[]>([]);

  const formEndPoints = {
    FLIGHT: "flight-additional-charge-form/",
    HOTEL: "hotel-additional-charge-form/",
    BUS: "/bus-additional-charge-form/",
  };

  const bookingAdditionalChargeForm = useForm({
    mode: "uncontrolled",
    initialValues: initialFormValues,
    validate: {
      userCategory: (value) => (value === "" ? "User Category is required" : null),
      bookingTypeId: (value) => (value === "" ? "Booking Type is required" : null),
      applyForTypeId: (value) => (value === "" ? "Apply For Type is required" : null),
      groupIds: (value) => { if(!value.length && bookingAdditionalChargeForm?.getValues().userCategory === "AFFILIATE") { return("Group is required") } return null },
      affiliateIds: (value) => { if(!value.length && bookingAdditionalChargeForm?.getValues().userCategory === "AFFILIATE") { return("Affiliate is required") } return null },
      hotelSupplierIds: (value) => { if(!value?.length && serviceName === "HOTEL") { return("Hotel Supplier is required") } return null },
      flightSupplierIds: (value) => { if(!value?.length && serviceName === "FLIGHT") { return("Flight Supplier is required") } return null },
      airlineIds: (value) => { if(!value?.length && serviceName === "FLIGHT") { return("Air line is required") } return null },
      additionalChargeTypeId: (value) => (value === "" ? "Additional Charge Type is required" : null),
      additionalSubChargeTypeId: (value) => (value === "" ? "Additional Sub Charge Type is required" : null),
      amountType: (value) => (value === "" ? "Amount Type is required" : null),
      amount: (value) => (value === "" ? "Amount is required" : null),
      startDate: (value) => (value === "" ? "Start Date is required" : null),
      endDate: (value) => (value === "" ? "End Date is required" : null),
      minDayDifference: (value) => (value === "" ? "Min Day Difference is required" : null),
      content: (value) => (value === "" ? "Content is required" : null),

    },
    //  Form transform is used to update values before sending it to the API by using form.getTransformValue()
    transformValues: (values) => (
      serviceName === "FLIGHT" ?
        {
          ...values,
          flightSupplierIds: values.flightSupplierIds?.map((id) => Number(id)),
          airlineIds: values.airlineIds?.map((id) => Number(id)),
          affiliateIds: values.affiliateIds?.map((id) => Number(id)),
          groupIds: values.groupIds?.map((id) => Number(id)),
          additionalChargeTypeId: Number(values.additionalChargeTypeId),
          additionalSubChargeTypeId: Number(values.additionalSubChargeTypeId),
          bookingTypeId: Number(values.bookingTypeId),
          applyForTypeId: Number(values.applyForTypeId),
          amount: Number(values.amount),
          minDayDifference: Number(values.minDayDifference),
        } : {
          ...values,
          hotelSupplierIds: values.hotelSupplierIds?.map((id) => Number(id)),
          affiliateIds: values.affiliateIds?.map((id) => Number(id)),
          groupIds: values.groupIds?.map((id) => Number(id)),
          additionalChargeTypeId: Number(values.additionalChargeTypeId),
          additionalSubChargeTypeId: Number(values.additionalSubChargeTypeId),
          bookingTypeId: Number(values.bookingTypeId),
          applyForTypeId: Number(values.applyForTypeId),
          amount: Number(values.amount),
          minDayDifference: Number(values.minDayDifference),
        })
  });

  useEffect(() => {
    getServiceTypes();
    getAllGroupTypes();
    getAllFeeTypes();
    getMarkupTypes();
    getUserCategoryTypes();
    getBookingChargeCategories();
    getPaymentGateway();
    getAdditionalChargeTyps()
    getAmountTypes();
  }, []);

  useEffect(() => {
    getSupplierList(serviceName);
    getApplyForTypes(serviceName, "ADDITIONAL_CHARGE");
    getAllFeeTypes();
    getMyAdditionalChargesList(serviceName, "INITIAL_PAGE");
  }, [serviceName]);

  // HANDLER FUNCTION

  const handleClickEdit = (item: IAdditionalChargeList) => {
    let FormValue: IAdditionalChargesForm = {
      id: item.id,
      affiliateIds: item?.affiliateCommonResponses ? item?.affiliateCommonResponses?.map(e => e?.id + "") : [],
      groupIds: item?.affiliateGroupCommonResponses ? item?.affiliateGroupCommonResponses?.map(e => e?.id + "") : [],
      userCategory: item?.userCategory,
      additionalChargeTypeId: item?.additionalChargeType?.id + "" ?? "",
      additionalSubChargeTypeId: item?.additionalSubChargeType?.id + "" ?? "",
      bookingTypeId: item?.bookingType?.id + "" ?? "",
      applyForTypeId: item?.chargeApplyForType?.id + "" ?? "",
      amount: item?.amount + "",
      amountType: item?.amountType,
      startDate: item?.startDate,
      endDate: item?.endDate,
      gst: item?.gst,
      baseFareIncluded: item?.baseFareIncluded,
      minDayDifference: item?.minDayDifference + "",
      content: item?.content
    }
    if (serviceName === "FLIGHT") {
      FormValue.flightSupplierIds = item?.supplierResponses ? item?.supplierResponses?.map(e => e?.id + "") : []
      FormValue.flightSupplierIds = item?.flightAirlineResponses ? item?.flightAirlineResponses?.map(e => e?.id + "") : []
    }
    else {
      FormValue.hotelSupplierIds = item?.supplierResponses ? item?.supplierResponses?.map(e => e?.id + "") : []
    }
    bookingAdditionalChargeForm.setValues(FormValue);
    setIsModalOpen(true);
    setFormAction("edit");
  }

  const handleChangeStatus = (status: boolean, id: number, index?: number) => {
    if (status) {
      setIsLoadingIndex(index);
      updatedStatus(serviceName, id, "active");
    }
    else {
      updatedStatus(serviceName, id, "inActive");
    }
  }

  //  API FUNCTION
  const getMyAdditionalChargesList = async (service: "FLIGHT" | "HOTEL" | "BUS", paginationAction: TPaginationStatus) => {
    setIsLoading(true);
    const body = {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        sortingOrder: "ASC",
      }
    };
    const res = await postData(formEndPoints[service] + "get-all", body);
    if (res.isSuccess) {
      setMyAdditionalChargesList(res.data.data);
    }
    else {
      showToast({
        message: res.message,
        type: "error",
      });
      setMyAdditionalChargesList([])
    }
    setIsLoading(false);
  }

  const postMyCharges = async () => {
    setIsBtnLoading(true);
    const formValues = bookingAdditionalChargeForm.getTransformedValues();

    const response = await postData(formEndPoints[serviceName] + "save", formValues);
    if (response.isSuccess) {
      showToast({
        message: "Saved Successfully",
        type: "success",
      });
      getMyAdditionalChargesList(serviceName, "INITIAL_PAGE");
      setIsModalOpen(false);
      bookingAdditionalChargeForm.reset()
    }
    else {
      showToast({
        message: response.message,
        type: "error",
      });
    }
    setIsBtnLoading(false);
  };

  const updatedMyCharges = async () => {
    setIsBtnLoading(true);
    const formValues = bookingAdditionalChargeForm.getTransformedValues();
    const { id } = formValues;
    const response = await putData(`${formEndPoints[serviceName]}update/${id}`, formValues);
    if (response.isSuccess) {
      showToast({
        message: "Status Update Successfully",
        type: "success",
      });
      getMyAdditionalChargesList(serviceName, "INITIAL_PAGE");
      setIsModalOpen(false);
      setFormAction("add");
      bookingAdditionalChargeForm.reset()
    }
    else {
      showToast({
        message: response.message,
        type: "error",
      });
    }
    setIsBtnLoading(false);
  }

  const updatedStatus = async (ChargeService: IServices, ChargeId: number, chargeStatus: "active" | "inActive") => {
    setIsBtnLoading(true);
    const response = await patchData(`${formEndPoints[ChargeService]}${chargeStatus}/${ChargeId}`, {});
    if (response.isSuccess) {
      showToast({
        message: "Form Update Successfully",
        type: "success",
      });
      getMyAdditionalChargesList(ChargeService, "INITIAL_PAGE");
      setIsModalOpen(false);
      setFormAction("add");
      bookingAdditionalChargeForm.reset()
    }
    else {
      showToast({
        message: response.message,
        type: "error",
      });
    }
    setIsLoadingIndex(undefined);
    setIsBtnLoading(false);


  }

  return {
    isLoading,
    isModalOpen,
    setIsModalOpen,
    formAction,
    setFormAction,
    isBtnLoading,
    isLoadingIndex,
    groupTypes,
    affiliates,
    allFeeTypes,
    serviceTypes,
    serviceName,
    applyForTypes,
    userCategoryType,
    bookingAdditionalChargeForm,
    bookingChargeCategories,
    paymentGateway,
    paymentMode,
    markupTypes,
    supplierList,
    airlinesList,
    additionalChargeTypes,
    additionalSubChargeTypes,
    amountTypes,
    getServiceTypes,
    setServiceName,
    getUserCategoryTypes,
    getAffiliatedByGroup,
    getPaymentModeBasedOnGateway,
    getAirlinesBySuppliers,
    getAdditionalSubChargeTypesByCharge,
    postMyCharges,
    updatedMyCharges,
    myAdditionalChargesList,
    handleClickEdit,
    handleChangeStatus,
  };
};

export default useOtherCharges;
