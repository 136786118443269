import React, { useContext, useEffect, useState } from 'react'
import { Avatar } from '@mantine/core';
import { AppContext } from 'AppContext';
import { BASE_URL } from 'libs/data-access/constant';


interface IProps {
    mediaId: string | number
}

// const token = Cookies.get("token");
export default function EImage({
    mediaId
}: IProps) {


const { token} = useContext(AppContext);

  const [imageSrc, setImageSrc] = useState('');

useEffect(() => {
  const fetchImage = async () => {
    setImageSrc('');
    try {
      const response = await fetch(`${BASE_URL}get-file?id=${mediaId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          // other headers if needed
        },
      });

      if (response.ok) {
        const imageData = await response.blob();
        const imageUrl = URL.createObjectURL(imageData);
        setImageSrc(imageUrl);
      } else {
        // handle error
        console.error('Error loading image:', response.status, response.statusText);
      }
    } catch (error) {
      // handle network error
      console.error('Network error:', error);
    }
  };

  if (mediaId) {
    fetchImage();
  }
}, [token, mediaId]);


  return (
    <img src={imageSrc} className='h-full w-full'/>
  )
}
