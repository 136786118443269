import PageLayout from "PageLayout";
import { FaRegEdit } from "react-icons/fa";
import { MdFlight, MdHotel } from "react-icons/md";
import ExportDataActions from "libs/ui/src/Export/ExportDataActions";
import { Checkbox, Space, Tabs, Textarea } from "@mantine/core";
import {
  EButton,
  EDateInput,
  EEmpty,
  EModal,
  EMultiSelect,
  EPageTitle,
  ESelect,
  ETable,
  ETextInput,
} from "libs/ui";
import useOtherCharges from "./hook/useOtherCharges";
import { IServices } from "types";
import { BsBusFront } from "react-icons/bs";
import ESwitch from "libs/ui/src/Switch/ESwitch";
import moment from "moment";

export default function OtherCharges() {

  const {
    isLoading,
    isModalOpen,
    setIsModalOpen,
    formAction,
    setFormAction,
    isBtnLoading,
    groupTypes,
    affiliates,
    allFeeTypes,
    serviceName,
    serviceTypes,
    applyForTypes,
    userCategoryType,
    paymentGateway,
    paymentMode,
    bookingChargeCategories,
    markupTypes,
    myAdditionalChargesList,
    supplierList,
    airlinesList,
    additionalChargeTypes,
    additionalSubChargeTypes,
    isLoadingIndex,
    amountTypes,
    getAffiliatedByGroup,
    getPaymentModeBasedOnGateway,
    getAirlinesBySuppliers,
    getAdditionalSubChargeTypesByCharge,
    setServiceName,
    postMyCharges,
    updatedMyCharges,
    handleClickEdit,
    handleChangeStatus,
    bookingAdditionalChargeForm,
  } = useOtherCharges();

  const formValues = bookingAdditionalChargeForm.getValues();

  return (
    <PageLayout>
      {!isModalOpen ?
        <>
          <EPageTitle
            title="Other Charges"
            end={
              <div className="flex gap-2">
                <EButton onClick={() => setIsModalOpen(!isModalOpen)}>{" Add New"}</EButton>
              </div>
            }
          />

          <div className="flex w-full justify-between items-center gap-4 mt-8 my-5">
            <Tabs
              defaultValue="flight"
              value={serviceName}
              className="grow"
              onChange={(value) => setServiceName(value as IServices)}
            >
              <Tabs.List>
                <Tabs.Tab value="FLIGHT" leftSection={<MdFlight />}>
                  Flight
                </Tabs.Tab>
                <Tabs.Tab value="HOTEL" leftSection={<MdHotel />}>
                  Hotel
                </Tabs.Tab>
                <Tabs.Tab value="BUS" disabled leftSection={<BsBusFront />}>
                  Bus
                </Tabs.Tab>
              </Tabs.List>
            </Tabs>

            <div></div>
          </div>
          <Space h="md" />

          {/*TABLE DATA VIEW  */}

          <ETable
           loading={isLoading}
          >
            <thead className="whitespace-nowrap">
              <tr>
                <th>User type</th>
                <th>Groups</th>
                <th>Affiliates</th>
                <th>Charge type</th>
                <th>Subcharge type</th>
                <th>Apply on</th>
                <th>Booking type</th>
                <th>Amount</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Is default fare added</th>
                <th>Min days</th>
                <th>Suppliers</th>
                <th hidden={serviceName === "HOTEL"}>Airlines</th>
                <th>Inclusive GST</th>
                <th>Status</th>
                <th className="flex justify-end">Manage</th>
              </tr>

            </thead>
            <tbody>
              {
                !myAdditionalChargesList?.length ?
                  <>  </>
                  :
                  myAdditionalChargesList?.map((item, index) =>
                    <tr>
                      <td>{item?.userCategory} </td>
                      <td>{item?.affiliateGroupCommonResponses?.length ? item?.affiliateGroupCommonResponses?.map((item, index) => ((index > 0 ? ", " : "") + item?.name ? item?.name?.toLowerCase() : "_")) : "_"}</td>
                      <td>{item?.affiliateCommonResponses?.length ? item?.affiliateCommonResponses?.map((item, index) => ((index > 0 ? ", " : "") + item?.fullName ? item?.fullName?.toLowerCase() : "_")) : "_"} </td>
                      <td>{item?.additionalChargeType?.name} </td>
                      <td>{item?.additionalSubChargeType?.name}</td>
                      <td>{item?.chargeApplyForType?.name}</td>
                      <td>{item?.bookingType?.name}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.startDate ? moment(item?.startDate).format("DD/MM/YYYY") : "_"}</td>
                      <td>{item?.endDate ? moment(item?.endDate).format("DD/MM/YYYY") : "_"} </td>
                      <td>{item?.baseFareIncluded ? "Yes" : "No"}</td>
                      <td>{item?.minDayDifference}</td>
                      <td>{item?.supplierResponses?.[0]?.name}</td>
                      <td hidden={serviceName === "HOTEL"} >{item?.flightAirlineResponses?.[0]?.name} </td>
                      <td> {item?.gst ? "Yes" : "No"} </td>
                      <td>
                        <ESwitch
                          label={item?.status ? "Active" : "In-Active"}
                          checked={item?.status}
                          loading={isLoadingIndex === index}
                          onChange={(e) => handleChangeStatus(e, item?.id, index)}
                          withConfirmation
                          confirmationTitle="Confirmation"
                          confirmationDescription="Are you sure you want to change status?"
                        />
                      </td>
                      <td className="flex gap-2 w-full justify-end">
                        <FaRegEdit
                          size={20}
                          className="cursor-pointer text-green-400 hover:text-green-500"
                          onClick={() => {
                            handleClickEdit(item)
                          }
                          }
                        />
                      </td>
                    </tr>
                  )}
            </tbody>
          </ETable>
        </>
        :

        // ADD NEW / Edit CHARGE FORM
        <>
          <EPageTitle title={formAction === "add" ? "Add Other Charge" : "Edit Other Charge"} />
          <form
            onSubmit={bookingAdditionalChargeForm.onSubmit(
              () => {
                formAction === "add" ?
                  postMyCharges() :
                  updatedMyCharges()
              }
            )}
          >
            <div className=" p-8 pt-6">
              <div className="grid grid-cols-3 gap-4">
                <ESelect
                  label="Service"
                  withAsterisk
                  placeholder="Select"
                  data={serviceTypes}
                  disabled={formAction === "edit"}
                  value={serviceName}
                  onChange={(e) => {
                    setServiceName(e as IServices)
                    bookingAdditionalChargeForm.reset()
                  }}
                />
                <ESelect
                  label="Select User Type"
                  withAsterisk
                  placeholder="Select"
                  data={userCategoryType}
                  key={bookingAdditionalChargeForm.key("userCategory")}
                  {...bookingAdditionalChargeForm.getInputProps("userCategory")}
                  onChange={(value: any) => {
                    bookingAdditionalChargeForm.setFieldValue("userCategory", value);
                    bookingAdditionalChargeForm.setFieldValue("groupIds", []);
                    bookingAdditionalChargeForm.setFieldValue("affiliateIds", []);
                  }}
                />
                <ESelect
                  label="Select Booking Type"
                  withAsterisk
                  placeholder="Select"
                  data={markupTypes}
                  key={bookingAdditionalChargeForm.key("bookingTypeId")}
                  {...bookingAdditionalChargeForm.getInputProps("bookingTypeId")}
                />
                <ESelect
                  label="Select Apply On"
                  withAsterisk
                  placeholder="Select"
                  data={applyForTypes}
                  key={bookingAdditionalChargeForm.key("applyForTypeId")}
                  {...bookingAdditionalChargeForm.getInputProps("applyForTypeId")}
                />
                <EMultiSelect
                  label="Select Group"
                  withAsterisk={bookingAdditionalChargeForm?.getValues()?.userCategory?.toLowerCase() === "affiliate" ? true : false}
                  disabled={bookingAdditionalChargeForm?.getValues()?.userCategory?.toLowerCase() === "affiliate" ? false : true}
                  placeholder="Select"
                  data={groupTypes}
                  key={bookingAdditionalChargeForm.key("groupIds")}
                  {...bookingAdditionalChargeForm.getInputProps("groupIds")}
                  onChange={(value: Array<string>) => {
                    getAffiliatedByGroup(value.join(","));
                    bookingAdditionalChargeForm.setFieldValue("groupIds", value);
                    bookingAdditionalChargeForm.setFieldValue("affiliateIds", []);
                  }}
                />
                <EMultiSelect
                  placeholder="Select"
                  label="Select Affiliate"
                  withAsterisk={bookingAdditionalChargeForm?.getValues()?.groupIds?.length ? true : false}
                  disabled={bookingAdditionalChargeForm?.getValues()?.groupIds?.length ? false : true}
                  data={affiliates}
                  key={bookingAdditionalChargeForm.key("affiliateIds")}
                  {...bookingAdditionalChargeForm.getInputProps("affiliateIds")}
                  onChange={(value: Array<string>) => {
                    bookingAdditionalChargeForm.setFieldValue("affiliateIds", value);
                  }}
                />

                {
                  serviceName?.toLowerCase() === "flight" ?
                   <EMultiSelect
                    label="Select Flight Supplier"
                    withAsterisk
                    placeholder="Select"
                    data={supplierList}
                    key={bookingAdditionalChargeForm.key("flightSupplierIds")}
                    {...bookingAdditionalChargeForm.getInputProps("flightSupplierIds")}
                    onChange={(value: Array<string>) => {
                      getAirlinesBySuppliers(value);
                      bookingAdditionalChargeForm.setFieldValue("flightSupplierIds", value);
                      bookingAdditionalChargeForm.setFieldValue("airlineIds", []);
                    }}
                  />
                    :
                    <EMultiSelect
                      label="Select Hotel Supplier"
                      withAsterisk
                      placeholder="Select"
                      data={supplierList}
                      key={bookingAdditionalChargeForm.key("hotelSupplierIds")}
                      {...bookingAdditionalChargeForm.getInputProps("hotelSupplierIds")}
                      onChange={(value: Array<string>) => {
                        getAirlinesBySuppliers(value);
                        bookingAdditionalChargeForm.setFieldValue("hotelSupplierIds", value);
                      }}
                    />}
                <EMultiSelect
                  label="Select Airline"
                  withAsterisk={serviceName?.toLowerCase() === "flight" ? true : false}
                  placeholder="Select"
                  data={airlinesList}
                  disabled={serviceName?.toLowerCase() === "flight" ? false : true}
                  key={bookingAdditionalChargeForm.key("airlineIds")}
                  {...bookingAdditionalChargeForm.getInputProps("airlineIds")}
                  onChange={(value: Array<string>) => {
                    bookingAdditionalChargeForm.setFieldValue("airlineIds", value);
                  }}
                />

                <div></div>

                <ESelect
                  label="Select Charge Type"
                  withAsterisk
                  placeholder="Select"
                  data={additionalChargeTypes}
                  key={bookingAdditionalChargeForm.key("additionalChargeTypeId")}
                  {...bookingAdditionalChargeForm.getInputProps("additionalChargeTypeId")}
                  onChange={(value) => {
                    if (value) {
                      getAdditionalSubChargeTypesByCharge(value)
                    }
                    bookingAdditionalChargeForm.setFieldValue("additionalChargeTypeId", value ?? "");
                    bookingAdditionalChargeForm.setFieldValue("additionalSubChargeTypeId", "");
                  }}
                />
                <ESelect
                  label="Select Sub Charge Type"
                  withAsterisk
                  placeholder="Select"
                  data={additionalSubChargeTypes}
                  key={bookingAdditionalChargeForm.key("additionalSubChargeTypeId")}
                  {...bookingAdditionalChargeForm.getInputProps("additionalSubChargeTypeId")}
                />
                <div></div>
                <div className="col-span-3">
                  <p className="text-slate-300 font-semibold">Charges Amount & Time Range</p>
                </div>
                <ESelect
                  label="Amount Type"
                  withAsterisk
                  placeholder="Select"
                  data={allFeeTypes}
                  key={bookingAdditionalChargeForm.key("amountType")}
                  {...bookingAdditionalChargeForm.getInputProps("amountType")}
                />
                <ETextInput
                  label={"Amount"}
                  withAsterisk
                  placeholder="Enter Amount"
                  key={bookingAdditionalChargeForm.key("amount")}
                  {...bookingAdditionalChargeForm.getInputProps("amount")}
                />
                <div className="flex items-end justify-around">
                  <Checkbox
                    label="Inclusive GST"
                    labelPosition="right"
                    checked={bookingAdditionalChargeForm?.getValues()?.gst}
                    key={bookingAdditionalChargeForm.key("gst")}
                    {...bookingAdditionalChargeForm.getInputProps("gst")}
                    onChange={e =>
                      bookingAdditionalChargeForm.setFieldValue("gst", e.target.checked)
                    }
                  />
                  <Checkbox
                    label="Default fare Added"
                    labelPosition="right"
                    checked={bookingAdditionalChargeForm?.getValues()?.baseFareIncluded}
                    key={bookingAdditionalChargeForm.key("baseFareIncluded")}
                    {...bookingAdditionalChargeForm.getInputProps("baseFareIncluded")}
                    onChange={e =>
                      bookingAdditionalChargeForm.setFieldValue("baseFareIncluded", e.target.checked)
                    }
                  />
                </div>
                <div className="col-span-3">
                  <p className="text-slate-300 font-semibold"></p>
                </div>
                <EDateInput
                  label="Start Date"
                  withAsterisk
                  placeholder="Select"
                  minDate={new Date()}
                  value={bookingAdditionalChargeForm?.getValues()?.startDate ? new Date(bookingAdditionalChargeForm?.getValues()?.startDate) : undefined}
                  key={bookingAdditionalChargeForm.key("startDate")}
                  {...bookingAdditionalChargeForm.getInputProps("startDate")}
                  onChange={(date) => {
                    bookingAdditionalChargeForm.setFieldValue("startDate", (date ? moment(date).format("YYYY-MM-DD") + "" : ""));
                    bookingAdditionalChargeForm.setFieldValue("endDate", "");
                  }}
                />

                <EDateInput
                  label="End Date"
                  withAsterisk
                  placeholder="Select"
                  disabled={bookingAdditionalChargeForm?.getValues()?.startDate ? false : true}
                  value={bookingAdditionalChargeForm?.getValues()?.endDate ? new Date(bookingAdditionalChargeForm?.getValues()?.endDate) : undefined}
                  minDate={bookingAdditionalChargeForm?.getValues()?.startDate ? new Date(bookingAdditionalChargeForm?.getValues()?.startDate) : undefined}
                  key={bookingAdditionalChargeForm.key("endDate")}
                  {...bookingAdditionalChargeForm.getInputProps("endDate")}
                  onChange={(date) => {
                    bookingAdditionalChargeForm.setFieldValue("endDate", (date ? moment(date).format("YYYY-MM-DD") + "" : ""));
                  }}
                />
                <ETextInput
                  label={"Minimum days diff to be required."}
                  withAsterisk
                  placeholder="Enter days"
                  key={bookingAdditionalChargeForm.key("minDayDifference")}
                  {...bookingAdditionalChargeForm.getInputProps("minDayDifference")}
                />
                <div className="col-span-2" >
                  <Textarea
                    label={"Content"}
                    withAsterisk
                    size="xl"
                    resize="vertical"
                    key={bookingAdditionalChargeForm.key("content")}
                    {...bookingAdditionalChargeForm.getInputProps("content")}
                  />
                </div>
              </div>
            </div>
            <Space h={"md"} />
            <div className=" flex gap-3 items-center justify-end">
              {formAction === "edit" ? <></> : <EButton onClick={() => bookingAdditionalChargeForm.reset()} variant="outline" > Reset</EButton>}
              <EButton onClick={() => {
                bookingAdditionalChargeForm.reset();
                setIsModalOpen(false)
                setFormAction("add");
              }}
                variant="outline"
              > Cancel </EButton>
              <EButton type="submit" loading={isBtnLoading} >{formAction === "add" ? "Save" : "Update"}</EButton>
            </div>
          </form>
          <Space h={"md"} />
        </>
      }


    </PageLayout>
  );
}
