interface IProps {
  label: string;
  value: string;
  horizontal?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  card?: boolean;
}

const sizesVAlue = {
   sm: {
      label: "text-sm font-medium",
      value: "text-lg font-medium",
   },
   md: {
      label: "text-md font-normal",
      value: "text-xl font-semibold",
   },
   lg: {
      label: "text-md font-medium",
      value: "text-2xl font-bold",
   },
   xl: {
      label: "text-xl font-semibold",
      value: "text-2xl font-bold",
   }
}
export function ELabelView({ label, value, horizontal ,size="md", card }: IProps) {
  return (
    <div className={`flex ${horizontal ? "flex-row items-center gap-2" : card ? "flex-col-reverse" : "flex-col"} ${card ? `border border-slate-200 p-4 w-full justify-center items-center rounded-xl drop-shadow-sm hover:shadow` : `` }`}>
      <div className={`text-slate-500 ${sizesVAlue[size ?? "md"].label} capitalize whitespace-nowrap`}>{label}{horizontal? ": " : ""}</div>
      <div className={`text-slate-600 ${horizontal ? sizesVAlue[size ?? "md"].label : sizesVAlue[size ?? "md"].value} capitalize`}>{value}</div>
    </div>
  );
}
