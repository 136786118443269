import { Button, Select, TextInput } from '@mantine/core'
import React from 'react'
import { showToast } from '../utils/utility'
import PageLayout from '../PageLayout'
import { EPageTitle } from '../libs/ui'
import { EStat } from 'libs/ui/src/Stat/EStat'

export default function Home() {
  return (
    <PageLayout>
      <EPageTitle title="Home" subTitle="Hello Home" />
      <div className='flex gap-5'>
      <EStat
        label="Total Bookings"
        value="10"
        />
       <EStat
        label="Flight Bookings"
        />
       <EStat
        label="Hotels Bookings"
        />
        </div>
         </PageLayout>
  )
}
