import { isNotEmpty } from "@mantine/form";
import { validateArrayField } from "utils/utility";

const CommonValidations = {
  service: isNotEmpty("Please select Service"),
  discountType: isNotEmpty("Please select Discount Type"),
  uniqueCodesCount: (value: any, values: any) => {
    if (values.discountType === "UNIQUE_CODE" && !value) {
      return "Please enter No. of Unique Code";
    }
  },
  discountCategory: isNotEmpty("Please select Can Be Used"),
  customerType: isNotEmpty("Please select Customer Type"),
  name: isNotEmpty("Please enter Discount Name"),
  description: isNotEmpty("Please enter Discount Description"),
  code: isNotEmpty("Please enter Discount Code"),
  startDate: isNotEmpty("Please select Start Date"),
  endDate: isNotEmpty("Please select End Date"),
  customerLimit: isNotEmpty("Please enter Customer Limit"),
  perCustomerUsage: isNotEmpty("Please enter Per Customer Usage"),
  amount: isNotEmpty("Please enter Amount"),
  amountType: isNotEmpty("Please select Amount Type"),
  fromRange: isNotEmpty("Please enter From Range"),
  toRange: (value: any, values: any) => {
    if (!value) {
      return "Please enter To Range";
    } else if (parseInt(value) < parseInt(values.fromRange)) {
      return "Price To cannot be less than From Price";
    }
  },
  minimumLimit: (value: any, values: any) => {
    if (values.amountType !== "FIXED" && !value) {
      return "Please enter Min Limit";
    }
  },
  maximumLimit: (value: any, values: any) => {
    if (values.amountType !== "FIXED" && !value) {
      return "Please enter Maximum Price";
    } else if (
      values.amountType !== "FIXED" &&
      parseInt(value) < parseInt(values.minimumLimit)
    ) {
      return "Max Limit cannot be less than Min Limit";
    }
  },
};

const flightDiscountValidations = {
  countryIds: validateArrayField("Please select Country"),
  originAirportIds: validateArrayField("Please select Origin"),
  destinationAirportIds: validateArrayField("Please select Destination"),
  flightNo: (value: any, values: any) => {
    if (values.flightSpecific && !value) {
      return "Please enter Flight No";
    }
  },

  airlineCode: (value: any, values: any) => {
    if (values.flightSpecific && !value) {
      return "Please select Airline Code";
    }
  },
  chargeApplyForTypeId: isNotEmpty("Please select Charge Apply For Type"),
  bookingTypeId: (value: any, values: any) => {
    if (!values.flightSpecific && !value) {
      return "Please select Flight Type";
    }
  },
  journeyTypeIds: (value: any, values: any) => {
    if (!values.flightSpecific && !value?.length) {
      return "Please select Trip Type";
    }
  },
  ticketSupplierIds: (value: any, values: any) => {
    if (!values.flightSpecific && !value?.length) {
      return "Please select Suppliers";
    }
  },
  flightAirlineIds: (value: any, values: any) => {
    if (!values.flightSpecific && !value?.length) {
      return "Please select Airlines";
    }
  },
};

const busDiscountValidations = {
  discountApplyFor: isNotEmpty("Please select Discount Apply For"),
  originPlaceIds: validateArrayField("Please select Origin"),
  destinationPlaceIds: validateArrayField("Please select Destination"),
  ticketSupplierIds: validateArrayField("Please select Suppliers"),
  busCategories: validateArrayField("Please select Category Type"),
  busSeatCategories: validateArrayField("Please select Seat Type"),
};

const hotelDiscountValidations = {
  chargeApplyForTypeId: isNotEmpty("Please select Charge Apply For"),
  bookingTypeId: isNotEmpty("Please select Hotel Type"),
  countryIds: validateArrayField("Please select Country"),
  placeIds: validateArrayField("Please select Place"),
  bookingSupplierIds: validateArrayField("Please select Booking Suppliers"),
  hotelCategoryIds: validateArrayField("Please select Hotel Category"),
  hotelName: isNotEmpty("Please enter Hotel Name"),
  hotelCode: isNotEmpty("Please enter Hotel Code"),
};

const validationFormFields = {
  FLIGHT: flightDiscountValidations,
  HOTEL: hotelDiscountValidations,
  BUS: busDiscountValidations,
};

export { validationFormFields, CommonValidations };
