import { hasLength, isEmail, isNotEmpty, matches } from "@mantine/form"

export const NewAffiliateStep1Validation = 
{
    companyName: isNotEmpty("Company Name is required"),
    website: isNotEmpty("Website is required"),
    firstAddress: isNotEmpty("First Address is required"),
    countryId: isNotEmpty("Country is required"),
    stateId: isNotEmpty("State is required"),
    city: isNotEmpty("City is required"),
    pinCode: isNotEmpty("Pincode is required"),
    companyType: isNotEmpty("Company Type is required"),
    panNumber: isNotEmpty("PAN Number is required"),
    tdsExemptionNumber: hasLength({min:1},"TDS Exemption Number is required"),
    tdsExemptionLimit: isNotEmpty("TDS Exemption Limit is required"),
    tdsExemptionRate: isNotEmpty("TDS Exemption Rate is required"),
    tdsExemptionCode: isNotEmpty("TDS Exemption Code is required"),
    xlCode: isNotEmpty("XL Code is required"),
    lookToBookRatioSearch: isNotEmpty("Look To Book Ratio Search is required"),
    lookToBookRatioBook: isNotEmpty("Look To Book Ratio Book is required"),
    title: isNotEmpty("Title is required"),
    firstName: isNotEmpty("First Name is required"),
    lastName: isNotEmpty("Last Name is required"),
    email: matches(/^\S+@\S+$/,"Valid email is required"),
    contactNumber: matches(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/, "Invalid Contact Number") &&  isNotEmpty("Contact Number is required"),
    mobileNumber: isNotEmpty("Mobile Number is required") && hasLength({min:10, max:10},"Invalid Mobile Number"),
  }

  export const NewAffiliateStep2Validation = {
      aadhaarCardId: isNotEmpty("Aadhaar Card Id is required"),
      panCardId: isNotEmpty("PAN Card Id is required"),
      drivingLicenseId: isNotEmpty("Driving License Id is required"),
      gstId: isNotEmpty("GST Id is required"),
      gstNumber: isNotEmpty("GST Number is required"),
      companyLogoId: isNotEmpty("Company Logo is required"),
  }

  