import { useState, useEffect, useRef } from 'react'
import { useApi } from '../../../../../libs/data-access/src/api/useApi';
import { TPaginationStatus } from '../../../../../types';
import { PAGINATION_ITEM_LIMIT } from '../../../../../libs/data-access/constant';
import { showToast } from '../../../../../utils/utility';
import { ISupplier } from '../../../../../libs/data-access/types';
import { useReactToPrint } from "react-to-print";
export  const useSuppliers = () => {
    
    const {
        postData,
        patchData,
        putData
    } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isSwitchLoadingIndex, setIsSwitchLoadingIndex] = useState<number>();
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isOpenModalValue, setIsOpenModalValue] = useState<"New" | "Edit" | "Delete" | undefined >(undefined);
    const [searchValue, setSearchValue] = useState("");
    const [suppliersList, setSuppliersList] = useState<ISupplier[]>([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState<ISupplier | undefined>(undefined);
    const [newSuppliers, setNewSuppliers] = useState<ISupplier>({name:"", code:""});

    useEffect(() => {
        if(searchValue?.length > 2 || searchValue === ""){
        getsuppliersList("INITIAL_PAGE");
    }
    }, [searchValue])


    // API FUNCTION
    const getsuppliersList = async (paginationAction: TPaginationStatus, id?: number) => {
        setIsLoading(true);
        const response = await postData(`flight-ticket-supplier/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: paginationAction,
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id: id ?? null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setSuppliersList(response.data.data)
        } else {
            setSuppliersList([])
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            });
        };
    }


    const submitNewSuppliers = async () => {
        setIsBtnLoading(true);
        const response = await postData(`flight-ticket-supplier/save`, newSuppliers);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Supplier added successfully",
            })
            getsuppliersList("INITIAL_PAGE");
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const UpdateStatus = async (id: number, status: boolean, index: number) => {
        setIsSwitchLoadingIndex(index);
        const response = await patchData(`flight-ticket-supplier/${status ? "active-all" :  "inActive-all" }?ids=${[id]}`,{});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Supplier status updated successfully",
            })
            getsuppliersList("INITIAL_PAGE");
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsSwitchLoadingIndex(undefined);
    }

    const UpdateSupplier = async (id: number, name: string) => {
        setIsBtnLoading(true);
        const response = await putData(`flight-ticket-supplier/update`, selectedSuppliers);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Supplier updated successfully",
            })
            getsuppliersList("INITIAL_PAGE");
            setSelectedSuppliers(undefined);
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const deleteSupplier = async (id: number) => {
       const isConfirm = window.confirm("Are you sure you want to delete this Supplier?");
        if(!isConfirm) return;
        else{
            setIsBtnLoading(true);
            showToast({
                type:"success",
                title:"Ready to delete",
                message:"Add api to delete Supplier"
            })

        }
        // setIsBtnLoading(true);
        // const response = await putData(`flight-ticket-supplier/delete`, {id: id});
        // if (response.isSuccess) {
        //     showToast({
        //         type: "success",
        //         message: "Supplier deleted successfully",
        //     })
        //     getsuppliersList("INITIAL_PAGE");
        //     setSelectedSuppliers(undefined);
        // } else {
        //     showToast({
        //         type: "error",
        //         message: response.message ?? "Something went wrong",
        //     })
        // };
        // setIsBtnLoading(false);
    }

    return {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        suppliersList,
        searchValue,
        setSearchValue,
        selectedSuppliers,
        setSelectedSuppliers,
        newSuppliers,
        setNewSuppliers,
        submitNewSuppliers,
        UpdateStatus,
        UpdateSupplier,
        deleteSupplier,
    }
}