import { Loader, Switch } from '@mantine/core';
import React, { Children } from 'react'
import { EConfirm } from '../Confirm/EConfirm';
import { title } from 'process';

interface IProps  {
    label: string;
    checked: boolean;
    onChange: (value : boolean) => void;
    size?: string;
    labelLeft?: boolean;
    loading?: boolean;
    withConfirmation?: boolean;
    confirmationTitle?: string;
    confirmationDescription?: string;
    confirmationButtonText?: string;
}
export default function ESwitch(props: IProps) {

  return (
    <div className='relative'>
     { 
     props.loading  ? <div className='absolute inset-0 flex items-center justify-center'>
        <Loader variant="dots" color="blue" size="sm" />
      </div>  : <></>
      }
    <Switch
    label={props.label}
    size={props.size}
    checked={props.checked}
    labelPosition={props.labelLeft ? 'left': 'right'}
    className={`cursor-pointer text-sm ${ props.checked ? `text-blue-600  hover:text-blue-700`:`text-orange-400 hover:text-orange-500`} mt-0.5`}
    onChange={async(e) => {
      let value = e?.target?.checked
      if(props.withConfirmation){
        if(await EConfirm({
          title: props?.confirmationTitle ?? "Are you sure",
          children: props?.confirmationDescription ?? "Do you want to change the status?",
          
        })) {
          props.onChange(value)
        }
        else{
          return
        }
      }
      else{
        props.onChange(value)}}
      }
    />
    </div>
  )
}
