import PageLayout from "PageLayout";
import "@mantine/dates/styles.css";
import { useNavigate } from "react-router-dom";
import { Grid, GridCol, Space } from "@mantine/core";
import useCreateCommission from "./hook/useCreateCommission";
import {
  ESelect,
  EPageTitle,
  ETextInput,
  EDateInput,
  EMultiSelect,
  ESectionDivider,
  EButton,
} from "libs/ui";

export default function CreateCommissionB2C() {
  const navigate = useNavigate();
  const {
    ruleTypes,
    groupTypes,
    fareTypes,
    searchTypes,
    serviceTypes,
    markupTypes,
    affiliateUsers,
    allCountryList,
    supplierList,
    airlinesList,
    serviceName,
    hotelPlaces,
    hotelCategories,
    commissionB2BForm,
    airportByCoutries,
    flightAllClassTypes,
    flightCabinClassTypes,
    busCategoryTypes,
    busDestinations,
    busSeatTypes,
    allFeeTypes,
    travelServiceCategories,
    airportByDestinationCoutries,
    setServiceName,
    createCommissionB2B,
    getAllAirportByCountries,
    getAirlinesBySuppliers,
    getHotelPlacesByCountries,
    getAllAirportByDestinationCountries,
  } = useCreateCommission();

  const formValues = commissionB2BForm.getValues();
  const { startDate, service } = formValues;

  console.log("travelFormFeeRequests in render", formValues);
  return (
    <PageLayout>
      <form
        onSubmit={commissionB2BForm.onSubmit(createCommissionB2B)}
        // onSubmit={commissionB2BForm.onSubmit(
        //   (values, event) => {
        //     console.log("no errors", values);
        //   },
        //   (validationErrors, values, event) => {
        //     console.log(
        //       "errors",
        //       validationErrors, // <- form.errors at the moment of submit
        //       values, // <- form.getValues() at the moment of submit
        //       event // <- form element submit event
        //     );
        //   }
        // )}
      >
        <EPageTitle
          title="Create Commission B2B"
          end={
            <div className="flex gap-2">
              <EButton
                onClick={() => {
                  navigate(-1);
                }}
                color="gray"
              >
                Cancel
              </EButton>
              <EButton type="submit" onClick={() => {}}>
                Save
              </EButton>
            </div>
          }
        />
        <ESectionDivider title="Service" />
        <Grid>
          <GridCol span={4}>
            <ESelect
              withAsterisk
              label="Service"
              placeholder="Select"
              data={serviceTypes}
              value={service}
              key={commissionB2BForm.key("service")}
              {...commissionB2BForm.getInputProps("service")}
              onChange={(value: any) => {
                commissionB2BForm.setFieldValue("service", value);
                setServiceName(value);
              }}
            />
          </GridCol>
          <GridCol span={4}>
            <ESelect
              withAsterisk
              label="Group"
              placeholder="Select"
              data={groupTypes}
              key={commissionB2BForm.key("affiliateGroupId")}
              {...commissionB2BForm.getInputProps("affiliateGroupId")}
            />
          </GridCol>
          <GridCol span={4}>
            <EMultiSelect
              withAsterisk
              label="Affiliate"
              placeholder="Select"
              data={affiliateUsers}
              key={commissionB2BForm.key("affiliateIds")}
              {...commissionB2BForm.getInputProps("affiliateIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("affiliateIds", value?.map(e=>+e));
              }}
            />
          </GridCol>
        </Grid>
        <Space h="xl" />
        <ESectionDivider title="Parameters" />
        <Grid>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Flight Types"
              placeholder="Select"
              data={markupTypes}
              key={commissionB2BForm.key("bookingTypeIds")}
              {...commissionB2BForm.getInputProps("bookingTypeIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("bookingTypeIds", value?.map(e=>+e));
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "HOTEL"}>
            <ESelect
              withAsterisk
              label="Hotel Types"
              placeholder="Select"
              data={markupTypes}
              key={commissionB2BForm.key("bookingTypeIds")}
              {...commissionB2BForm.getInputProps("bookingTypeIds")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "BUS"}>
            <ESelect
              withAsterisk
              label="Category Types"
              placeholder="Select"
              data={busCategoryTypes}
              key={commissionB2BForm.key("bookingTypeIds")}
              {...commissionB2BForm.getInputProps("bookingTypeIds")}
            />
          </GridCol>
          <GridCol span={4}>
            <ESelect
              withAsterisk
              label="Rule Types"
              placeholder="Select"
              data={ruleTypes}
              key={commissionB2BForm.key("ruleType")}
              {...commissionB2BForm.getInputProps("ruleType")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <ESelect
              withAsterisk
              label="Fare Types"
              placeholder="Select"
              data={fareTypes}
              key={commissionB2BForm.key("flightFareTypeId")}
              {...commissionB2BForm.getInputProps("flightFareTypeId")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Search Type"
              placeholder="Select"
              data={searchTypes}
              key={commissionB2BForm.key("flightJourneyTypes")}
              {...commissionB2BForm.getInputProps("flightJourneyTypes")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("flightJourneyTypes", value?.map(e=>+e));
              }}
            />
          </GridCol>
          <GridCol span={4}>
            <EDateInput
              withAsterisk
              label="Start Date"
              placeholder="Start Date"
              minDate={new Date()}
              key={commissionB2BForm.key("startDate")}
              {...commissionB2BForm.getInputProps("startDate")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("startDate", value);
                commissionB2BForm.setFieldValue("endDate", null);
              }}
            />
          </GridCol>
          <GridCol span={4}>
            <EDateInput
              withAsterisk
              size="md"
              disabled={!startDate}
              label="End Date"
              placeholder="End Date"
              key={commissionB2BForm.key("endDate")}
              {...commissionB2BForm.getInputProps("endDate")}
              minDate={startDate ? new Date(startDate) : new Date()}
            />
          </GridCol>
          <GridCol span={4}>
            <ETextInput
              withAsterisk
              label="From Price"
              placeholder="Enter Customer Limit"
              key={commissionB2BForm.key("minimumPrice")}
              {...commissionB2BForm.getInputProps("minimumPrice")}
            />
          </GridCol>
          <GridCol span={4}>
            <ETextInput
              withAsterisk
              label="To Price"
              placeholder="Enter Customer Limit"
              key={commissionB2BForm.key("maximumPrice")}
              {...commissionB2BForm.getInputProps("maximumPrice")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              withAsterisk
              label="Origin"
              placeholder="Select"
              data={busDestinations}
              key={commissionB2BForm.key("originPlaceIds")}
              {...commissionB2BForm.getInputProps("originPlaceIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("originPlaceIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              withAsterisk
              label="Destination"
              placeholder="Select"
              data={busDestinations}
              key={commissionB2BForm.key("destinationPlaceIds")}
              {...commissionB2BForm.getInputProps("destinationPlaceIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("destinationPlaceIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Origin Country"
              placeholder="Select"
              data={allCountryList}
              key={commissionB2BForm.key("flightOriginCountryIds")}
              {...commissionB2BForm.getInputProps("flightOriginCountryIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue(
                  "flightOriginCountryIds",
                  value
                );
                getAllAirportByCountries(value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "HOTEL"}>
            <EMultiSelect
              withAsterisk
              label="Origin"
              placeholder="Select"
              data={allCountryList}
              key={commissionB2BForm.key("countryIds")}
              {...commissionB2BForm.getInputProps("countryIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("countryIds", value);
                getHotelPlacesByCountries(value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "HOTEL"}>
            <EMultiSelect
              withAsterisk
              label="Destination"
              placeholder="Select"
              data={hotelPlaces}
              key={commissionB2BForm.key("placeIds")}
              {...commissionB2BForm.getInputProps("placeIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("placeIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Destination Country"
              placeholder="Select"
              data={allCountryList}
              key={commissionB2BForm.key("flightDestinationCountryIds")}
              {...commissionB2BForm.getInputProps(
                "flightDestinationCountryIds"
              )}
              onChange={(value) => {
                commissionB2BForm.setFieldValue(
                  "flightDestinationCountryIds",
                  value
                );
                getAllAirportByDestinationCountries(value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Route Origin"
              placeholder="Select"
              data={airportByCoutries}
              key={commissionB2BForm.key("originAirportIds")}
              {...commissionB2BForm.getInputProps("originAirportIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("originAirportIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Route Destination"
              placeholder="Select"
              data={airportByDestinationCoutries}
              key={commissionB2BForm.key("destinationAirportIds")}
              {...commissionB2BForm.getInputProps("destinationAirportIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("destinationAirportIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              label="Suppliers"
              withAsterisk
              placeholder="Select"
              data={supplierList}
              key={commissionB2BForm.key("flightTicketSupplierIds")}
              {...commissionB2BForm.getInputProps("flightTicketSupplierIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue(
                  "flightTicketSupplierIds",
                  value
                );
                getAirlinesBySuppliers(value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "HOTEL"}>
            <EMultiSelect
              withAsterisk
              label="Suppliers"
              placeholder="Select"
              data={supplierList}
              key={commissionB2BForm.key("supplierIds")}
              {...commissionB2BForm.getInputProps("supplierIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("supplierIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              withAsterisk
              label="Suppliers"
              placeholder="Select"
              data={supplierList}
              key={commissionB2BForm.key("supplierIds")}
              {...commissionB2BForm.getInputProps("supplierIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("supplierIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "BUS"}>
            <EMultiSelect
              withAsterisk
              label="Seat Type"
              placeholder="Select"
              data={busSeatTypes}
              key={commissionB2BForm.key("seatCategories")}
              {...commissionB2BForm.getInputProps("seatCategories")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("seatCategories", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "HOTEL"}>
            <EMultiSelect
              withAsterisk
              label="Category Type"
              placeholder="Select"
              data={hotelCategories}
              key={commissionB2BForm.key("hotelCategoryIds")}
              {...commissionB2BForm.getInputProps("hotelCategoryIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("hotelCategoryIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "HOTEL"}>
            <ETextInput
              label="Hotal Name"
              placeholder="Enter Hotel Name"
              key={commissionB2BForm.key("hotelName")}
              {...commissionB2BForm.getInputProps("hotelName")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "HOTEL"}>
            <ETextInput
              withAsterisk
              label="Hotal Name"
              placeholder="Enter Hotel Name"
              key={commissionB2BForm.key("hotelCode")}
              {...commissionB2BForm.getInputProps("hotelCode")}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Airlines "
              placeholder="Select"
              data={airlinesList}
              key={commissionB2BForm.key("flightAirlineIds")}
              {...commissionB2BForm.getInputProps("flightAirlineIds")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("flightAirlineIds", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Flight Class"
              placeholder="Select"
              data={flightAllClassTypes}
              key={commissionB2BForm.key("flightClass")}
              {...commissionB2BForm.getInputProps("flightClass")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("flightClass", value);
              }}
            />
          </GridCol>
          <GridCol span={4} hidden={serviceName !== "FLIGHT"}>
            <EMultiSelect
              withAsterisk
              label="Flight Cabin Class"
              placeholder="Select"
              data={flightCabinClassTypes}
              key={commissionB2BForm.key("flightCabin")}
              {...commissionB2BForm.getInputProps("flightCabin")}
              onChange={(value) => {
                commissionB2BForm.setFieldValue("flightCabin", value);
              }}
            />
          </GridCol>
        </Grid>
        <Space h="xl" />
        <Grid>
          {travelServiceCategories?.map((category, index) => {
            const { name } = category;
            return (
              <GridCol key={index} span={4}>
                <h1 className="text-lg text-black font-bold mb-2  mt-2">
                  {name}
                </h1>
                <ETextInput
                  withAsterisk
                  label="Amount"
                  placeholder="Enter Amount"
                  type="number"
                  key={commissionB2BForm.key(
                    `travelFormFeeRequests.${index}.fee`
                  )}
                  {...commissionB2BForm.getInputProps(
                    `travelFormFeeRequests.${index}.fee`
                  )}
                />
                <Space h="sm" />
                <ESelect
                  withAsterisk
                  searchable
                  label="Fee Type"
                  placeholder="Select"
                  data={allFeeTypes}
                  key={commissionB2BForm.key(
                    `travelFormFeeRequests.${index}.feeType`
                  )}
                  {...commissionB2BForm.getInputProps(
                    `travelFormFeeRequests.${index}.feeType`
                  )}
                />
              </GridCol>
            );
          })}
        </Grid>
      </form>
    </PageLayout>
  );
}
