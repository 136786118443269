import { useState, useEffect, useRef } from 'react'
import { useApi } from '../../../../../libs/data-access/src/api/useApi';
import { TPaginationStatus } from '../../../../../types';
import { PAGINATION_ITEM_LIMIT } from '../../../../../libs/data-access/constant';
import { showToast } from '../../../../../utils/utility';
import { ISearchType } from '../../../../../libs/data-access/types';
import { useReactToPrint } from "react-to-print";
export  const useSearchType = () => {
    
    const {
        postData,
        patchData,
        putData
    } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isSwitchLoadingIndex, setIsSwitchLoadingIndex] = useState<number>();
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isOpenModalValue, setIsOpenModalValue] = useState<"New" | "Edit" | "Delete" | undefined >(undefined);
    const [searchValue, setSearchValue] = useState("");
    const [searchTypeList, setSearchTypeList] = useState<ISearchType[]>([]);
    const [selectedSearchType, setSelectedSearchType] = useState<ISearchType | undefined>(undefined);
    const [newSearchType, setNewSearchType] = useState<ISearchType>({name:""});

    useEffect(() => {
        if(searchValue?.length > 2 || searchValue === ""){
        getSearchTypeList("INITIAL_PAGE");
    }
    }, [searchValue])

    // API FUNCTION
    const getSearchTypeList = async (paginationAction: TPaginationStatus, id?: number) => {
        setIsLoading(true);
        const response = await postData(`flight-journey/get-all`,
            {
                paginationFilterRequest: {
                    paginationAction: paginationAction,
                    maxLimit: PAGINATION_ITEM_LIMIT,
                    id: id ?? null,
                    sortingOrder: "ASC"
                },
                search: searchValue ?? "",
            })
        setIsLoading(false);

        if (response.isSuccess) {
            setSearchTypeList(response.data.data)
        } else {
            setSearchTypeList([])
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            });
        };
    }


    const submitNewSearchType = async () => {
        setIsBtnLoading(true);
        const response = await postData(`flight-journey/save`, newSearchType);
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Search type added successfully",
            })
            getSearchTypeList("INITIAL_PAGE");
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const UpdateStatus = async (id: number, status: boolean, index: number) => {
        setIsSwitchLoadingIndex(index);
        const response = await patchData(`flight-journey/${status ? "active-all" :  "inActive-all" }?ids=${[id]}`,{});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Search type status updated successfully",
            })
            getSearchTypeList("INITIAL_PAGE");
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsSwitchLoadingIndex(undefined);
    }

    const UpdateSearchType = async (id: number, name: string) => {
        setIsBtnLoading(true);
        const response = await putData(`flight-journey/update`, {id: id, name: name});
        if (response.isSuccess) {
            showToast({
                type: "success",
                message: "Search type updated successfully",
            })
            getSearchTypeList("INITIAL_PAGE");
            setSelectedSearchType(undefined);
            setIsOpenModalValue(undefined);
        } else {
            showToast({
                type: "error",
                message: response.message ?? "Something went wrong",
            })
        };
        setIsBtnLoading(false);
    }

    const deleteSearchType = async (id: number) => {
       const isConfirm = window.confirm("Are you sure you want to delete this Search type?");
        if(!isConfirm) return;
        else{
            setIsBtnLoading(true);
            showToast({
                type:"success",
                title:"Ready to delete",
                message:"Add api to delete Markup Type"
            })

        }
        // setIsBtnLoading(true);
        // const response = await putData(`flight-journey/delete`, {id: id});
        // if (response.isSuccess) {
        //     showToast({
        //         type: "success",
        //         message: "Search type deleted successfully",
        //     })
        //     getSearchTypeList("INITIAL_PAGE");
        //     setSelectedSearchType(undefined);
        // } else {
        //     showToast({
        //         type: "error",
        //         message: response.message ?? "Something went wrong",
        //     })
        // };
        // setIsBtnLoading(false);
    }

    return {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        searchTypeList,
        searchValue,
        setSearchValue,
        selectedSearchType,
        setSelectedSearchType,
        newSearchType,
        setNewSearchType,
        submitNewSearchType,
        UpdateStatus,
        UpdateSearchType,
        deleteSearchType,
    }
}