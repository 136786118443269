import { IUser } from "./types";

export const BASE64_INITIALS = "data:image/jpeg;base64,";

export const initialUser: IUser = {
    firstName: "",
    lastName: "",
    emailId: "",
    createdDate: "",
    lastModifiedDate: "",
    createdBy: "",
    lastModifiedBy: ""
};

