import { Button, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ETable } from "../../../../libs/ui";
import { useHotelCategory } from "./hook/useHotelCategory";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function HotelCategory() {

    const {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        hotelCategoryList,
        newHotelCategory,
        setNewHotelCategory,
        selectedHotelCategory,
        setSelectedHotelCategory,
        submitNewHotelCategory,
        UpdateStatus,
        UpdateHotelCategory,
        deleteHotelCategory,
        defaultNewHotelCategory,
    } = useHotelCategory();


    return (
        <PageLayout>
            <EPageTitle
                title="Hotel Category"
                end={
                    <div className="flex gap-1">
                        <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton>
                    </div>
                }
            />


            <ETable
                loading={isLoading}
            >
                <thead>
                    <tr>
                        <th>SR. No.</th>
                        <th>Category Name</th>
                        <th>Status</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        hotelCategoryList?.map((each, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{each.name}</td>
                                    <td>
                                        <ESwitch
                                            label={each.status ? "Active" : "Inactive"}
                                            loading={isSwitchLoadingIndex === index}
                                            checked={!!each.status}
                                            onChange={() => { each?.id && UpdateStatus(each?.id, !each?.status, index) }}
                                            withConfirmation
                                        />
                                    </td>
                                    <td
                                        className="flex w-full gap-1 items-center justify-end">
                                        <Tooltip label="Edit" position="top" >
                                            <div>
                                                <FaRegEdit
                                                    size={18}
                                                    className="cursor-pointer text-green-400 hover:text-green-600"
                                                    onClick={() => {
                                                        setIsOpenModalValue("Edit")
                                                        setSelectedHotelCategory(each)
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        {/* <Tooltip label="Delete">
                                                        <div>
                                                        <MdDeleteForever
                                                            size={22}
                                                            className="cursor-pointer text-orange-400 hover:text-orange-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Delete")
                                                                setSelectedHotelCategory(each)
                                                            }}
                                                        />
                                                        </div>
                                                    </Tooltip> */}
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </ETable>

            {/* ADD NEW Modal */}
            <EModal
                title="New Supplier"
                opened={isOpenModalValue === "New"}

                onClose={() => {
                    setNewHotelCategory(defaultNewHotelCategory);
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Supplier Name"
                        placeholder="Enter Name"
                        value={newHotelCategory?.name}
                        onChange={(e) => setNewHotelCategory({ ...newHotelCategory, name: e.target.value })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newHotelCategory?.name)}
                            loading={isBtnLoading}
                            onClick={() => submitNewHotelCategory()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Supplier */}
            <EModal
                title="Edit Supplier"
                opened={isOpenModalValue === "Edit"}

                onClose={() => {
                    setSelectedHotelCategory(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                {
                    selectedHotelCategory &&
                    <div className="w-full flex flex-col gap-2">
                        <TextInput
                            label="Supplier Name"
                            placeholder="Enter Name"
                            value={selectedHotelCategory?.name}
                            onChange={(e) => setSelectedHotelCategory({ ...selectedHotelCategory, name: e.target.value })}
                        />

                        <div className="flex justify-end mt-2">
                            <Button
                                disabled={!(selectedHotelCategory?.name)}
                                loading={isBtnLoading}
                                onClick={() => selectedHotelCategory?.id && UpdateHotelCategory(selectedHotelCategory?.id, selectedHotelCategory?.name)}
                            >
                                Update
                            </Button>
                        </div>
                    </div>}
            </EModal>

            <EModal
                title="Delete Supplier"
                opened={isOpenModalValue === "Delete"}

                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2">
                    <p>Are you sure you want to delete this Supplier?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedHotelCategory(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedHotelCategory?.id && deleteHotelCategory(selectedHotelCategory?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
