import { useContext, useEffect, useState } from "react";
import moment from "moment";
import useMaster from "hook/useMaster";
import { useForm } from "@mantine/form";
import { showToast } from "utils/utility";
import { AppContext } from "AppContext";
import { useApi } from "libs/data-access/src/api/useApi";
import { IFlightMarkupB2C, IHotelMarkupB2C, IServices } from "types";
import { useNavigate, useLocation } from "react-router-dom";
import { CommonValidation, validationFormFields } from "./constants";

export default function useCreateMarkup_B2C() {

  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const { service, markup } = state || {};
  const { id: markupId } = markup || {};

  const {
    applyTypes,
    fareTypes,
    hotelPlaces,
    serviceTypes,
    markupTypes,
    searchTypes,
    applyOnTypes,
    applyForTypes,
    supplierList,
    airlinesList,
    allFeeTypes,
    busSeatTypes,
    busCategoryTypes,
    hotelCategories,
    allCountryList,
    markupfeeNameIds,
    busDestinations,
    flightAllClassTypes,
    flightCabinClassTypes,
    travelServiceCategories,

    getFareTypes,
    getApplyTypes,
    getSearchTypes,
    getMarkupTypes,
    getApplyOnTypes,
    getApplyForTypes,
    getAllCountryList,
    getSupplierList,
    getServiceTypes,
    getAllFeeTypes,
    getBusCategoryTypes,
    getBusSeatTypes,
    getHotelPlacesByCountries,
    getHotelCategories,
    getMarkupFeeNameIds,
    getAirlinesBySuppliers,
    getFlightAllClassTypes,
    getFlightCabinClassTypes,
    getTravelServiceCategories,
    getBusDestinations,
  } = useMaster();

  const { postData, putData } = useApi();

  const [serviceName, setServiceName] = useState<"FLIGHT" | "HOTEL" | "BUS">(
    location?.state?.service || "FLIGHT"
  );
  const markupB2CForm = useForm({
    mode: "uncontrolled",
    initialValues: {
      service: "FLIGHT",
    } as any,
    validate: { ...CommonValidation, ...validationFormFields[serviceName] },
  });

  useEffect(() => {
    if(travelServiceCategories?.length) {
      fetchDropdownsData();
    
    if (markup?.flightAirlineResponses) {
      getAirlinesBySuppliers(markup?.flightAirlineResponses?.map((data: any) =>
        data?.id.toString()
      ));
    }
    if(markup?.countryResponses) {
      getHotelPlacesByCountries(markup?.countryResponses?.map((data: any) => data?.id.toString()));
     }
    }
  }, [travelServiceCategories]);


  const fetchDropdownsData = async () => {
    try {
      const promises = [
        getServiceTypes(),
        getFareTypes(),
        getMarkupTypes(),
        getApplyOnTypes(),
        getApplyTypes(),
        getSearchTypes(),
        getAllCountryList(),
        getAllFeeTypes(),
        getMarkupFeeNameIds(),
        getFlightAllClassTypes(),
        getFlightCabinClassTypes(),
      ];
      await Promise.all(promises);
      // For Editing markup B2C
      if (service) {
        setFromData(service);
        setServiceName(service);
      }
    } catch (error) {
      console.error("Error fetching dropdowns data:", error);
    }
  };

  useEffect(() => {
    getApplyForTypes(serviceName, "MARKUP");
    getTravelServiceCategories(serviceName, "MARKUP");

    getSupplierList(serviceName);
    if (serviceName === "HOTEL") {
      getHotelCategories();
    }
    if (serviceName === "BUS") {
      getBusCategoryTypes();
      getBusSeatTypes();
      getBusDestinations();
    }
  }, [serviceName]);

  useEffect(() => {
    if (!markupId) {
      markupB2CForm.setInitialValues({});
      markupB2CForm.setValues((prev) => ({
        gst: false,
        service: serviceName,
        userType: "CUSTOMER",
        travelFormFeeRequests: travelServiceCategories,
      }));
    }

  }, [travelServiceCategories]);

  const setFromData = (service: IServices) => {

    const {
      id,
      gst,
      status,
      endDate,
      startDate,
      flightCabin,
      flightClass,
      bookingType,
      flightFareType,
      flightApplyType,
      maximumGrossPrice,
      minimumGrossPrice,
      chargeApplyForType,
      flightJourneyType,
      supplierResponses,
      flightAirlineResponses,
      chargeApplyOnType,
      travelServiceFormFeeResponses,
      destinationCountryResponses,
      originCountryResponses,
      placeResponses,
      countryResponses,
      travelFormFeeResponses,
      hotelCategoryResponses,
      hotelBookingSupplierResponses,
    } = markup;

    const countryIds = countryResponses?.map((data: any) =>
      data?.id.toString()
    );
    const flightOriginCountryIds = originCountryResponses?.map((data: any) =>
      data?.id.toString()
    );
    const placeIds = placeResponses?.map((data: any) => data?.id.toString());
    const flightDestinationCountryIds = destinationCountryResponses?.map(
      (data: any) => data?.id.toString()
    );
    const flightTicketSupplierIds = supplierResponses?.map((data: any) =>
      data?.id.toString()
    );
    const hotelBookingSupplierIds = hotelBookingSupplierResponses?.map(
      (data: any) => data?.id.toString()
    );
    const flightAirlineIds = flightAirlineResponses?.map((data: any) =>
      data?.id.toString()
    );
    const hotelCategoryIds = hotelCategoryResponses?.map((data: any) =>
      data?.id.toString()
    );
    const travelFees = {
      FLIGHT: travelServiceFormFeeResponses,
      HOTEL: travelFormFeeResponses,
      BUS: [],
    };


    const mapTravelFee = travelServiceCategories?.map((data)=>{
        const {id:travelId}= data
        const  travelFee = travelFees[service].find((fee: any) => fee?.id === travelId)
        const { id, feeAmount, feeType } = travelFee;
        const obj = {
          id,
          feeType: feeType,
          fee: feeAmount,
        };
        return obj;
    })


    const commonFields = {
      gst: gst,
      minimumGrossPrice,
      maximumGrossPrice,
      service: service,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      travelFormFeeRequests: mapTravelFee,
    };
    const flightFormFields = {
      flightClass,
      flightCabin,
      flightAirlineIds,
      flightDestinationCountryIds,
      flightOriginCountryIds,
      flightTicketSupplierIds,
      bookingTypeId: bookingType?.id.toString(),
      chargeApplyForTypeId: chargeApplyForType?.id.toString(),
      chargeApplyOnTypeId: chargeApplyOnType?.id.toString(),
      flightFareTypeId: flightFareType?.id.toString(),
      flightJourneyTypeId: flightJourneyType?.id.toString(),
      flightApplyTypeId: flightApplyType?.id.toString(),
    };
    const hotelFormFields = {
      placeIds,
      countryIds,
      hotelCategoryIds,
      hotelBookingSupplierIds,
      chargeApplyForTypeId: chargeApplyForType?.id.toString(),
      bookingTypeId: bookingType?.id.toString(),
    };

    const formData = {
      FLIGHT: { ...commonFields, ...flightFormFields },
      HOTEL: { ...commonFields, ...hotelFormFields },
      BUS: commonFields,
    };

    markupB2CForm.setInitialValues(formData[service]);
    markupB2CForm.setValues(formData[service]);
  };
  // API Function

  const createMarkupFromB2C = async (
    data: IFlightMarkupB2C | IHotelMarkupB2C
  ) => {
    const body: any = { ...data };
    const saveFormEndPoints = {
      FLIGHT: "flight-markup-form/save",
      HOTEL: "hotel-markup-form/save",
      BUS: "bus-markup-form/save",
    };
    body.startDate = moment(data.startDate).format("YYYY-MM-DD");
    body.endDate = moment(data.endDate).format("YYYY-MM-DD");
    body.travelFormFeeRequests = data.travelFormFeeRequests.map((data) => {
      const { id, feeType, fee } = data;
      return {
        fee,
        feeType,
        travelFormFeeId: id,
      };
    });
    body["affiliateGroupId"] = null;

    const response = await postData(saveFormEndPoints[serviceName], body);
    if (response.isSuccess) {
      showToast({
        type: "success",
        title: "Success",
        message: "Markup Created Successfully",
      });
      navigate('/markup_and_commission/markup-b2c', { state: location.state });
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: response.message,
      });
    }
    console.log("response of save form data ", response);
  };

  const editMarkupFromB2C = async (
    data: IFlightMarkupB2C | IHotelMarkupB2C
  ) => {
    const body: any = { ...data };
    const updateFormEndPoints = {
      FLIGHT: `flight-markup-form/update/${markupId}`,
      HOTEL: `hotel-markup-form/update/${markupId}`,
      BUS: `bus-markup-form/update/${markupId}`,
    };
    body.startDate = moment(data.startDate).format("YYYY-MM-DD");
    body.endDate = moment(data.endDate).format("YYYY-MM-DD");
    body["affiliateGroupId"] = null;
    body.travelFormFeeRequests = data.travelFormFeeRequests.map((data) => {
      const { id, feeType, fee } = data;
      return {
        fee,
        feeType,
        travelFormFeeId: id,
      };
    });
    const response = await putData(updateFormEndPoints[serviceName], body);
    if (response.isSuccess) {
      showToast({
        type: "success",
        title: "Success",
        message: "Markup Updated Successfully",
      });
      navigate('/markup_and_commission/markup-b2c', { state: location.state });
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: response.message,
      });
    }
    console.log("response of update form data ", response);
  };

  return {
    markupId,
    serviceName,
    markupB2CForm,
    hotelPlaces,
    fareTypes,
    markupTypes,
    applyOnTypes,
    supplierList,
    serviceTypes,
    markupfeeNameIds,
    airlinesList,
    applyForTypes,
    applyTypes,
    allFeeTypes,
    searchTypes,
    busSeatTypes,
    busCategoryTypes,
    allCountryList,
    hotelCategories,
    flightAllClassTypes,
    flightCabinClassTypes,
    busDestinations,
    travelServiceCategories,
    setServiceName,
    getAirlinesBySuppliers,
    createMarkupFromB2C,
    editMarkupFromB2C,
    getHotelPlacesByCountries,
  };
}
