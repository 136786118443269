import { Button, Select, TextInput, Tooltip } from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

import PageLayout from "../../../../PageLayout";
import { EButton, EModal, EPageTitle, ESelect, ETable } from "../../../../libs/ui";
import { defaultNewAirlines, useAirlines } from "./hook/useAirlines";
import ESwitch from "libs/ui/src/Switch/ESwitch";

export default function Airlines() {

    const {
        isLoading,
        isSwitchLoadingIndex,
        isBtnLoading,
        isOpenModalValue,
        setIsOpenModalValue,
        airlinesList,
        newAirlines,
        setNewAirlines,
        selectedAirlines,
        setSelectedAirlines,
        submitNewAirlines,
        UpdateStatus,
        UpdateAirline,
        deleteAirline,
        supplierList
    } = useAirlines();

    return (
        <PageLayout>
            <EPageTitle
                title="Airline"
                end={
                    <div className="flex gap-1">
                        <EButton
                            rightSection={<IoMdAdd />}
                            onClick={() => { setIsOpenModalValue("New") }}
                        >
                            Add New
                        </EButton>
                    </div>
                }
            />


            <ETable
                loading={isLoading}
            >
                <thead>
                    <tr>
                        <th>SR. No.</th>
                        <th>Suppliers Name</th>
                        <th>Airline Name</th>
                        <th>Status</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        airlinesList?.map((each, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{each.supplierName}</td>
                                    <td>{each.name}</td>
                                    <td>
                                        <ESwitch
                                            label={each.status ? "Active" : "Inactive"}
                                            loading={isSwitchLoadingIndex === index}
                                            checked={!!each.status}
                                            onChange={() => { each?.id && UpdateStatus(each?.id, !each?.status, index) }}
                                            withConfirmation
                                        />
                                    </td>
                                    <td
                                        className="flex w-full gap-1 items-center justify-end">
                                        <Tooltip label="Edit" position="top" >
                                            <div>
                                                <FaRegEdit
                                                    size={18}
                                                    className="cursor-pointer text-green-400 hover:text-green-600"
                                                    onClick={() => {
                                                        setIsOpenModalValue("Edit")
                                                        setSelectedAirlines(each)
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        {/* <Tooltip label="Delete">
                                                        <div>
                                                        <MdDeleteForever
                                                            size={22}
                                                            className="cursor-pointer text-orange-400 hover:text-orange-600"
                                                            onClick={() => {
                                                                setIsOpenModalValue("Delete")
                                                                setSelectedAirlines(each)
                                                            }}
                                                        />
                                                        </div>
                                                    </Tooltip> */}
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </ETable>

            {/* ADD NEW Modal */}
            <EModal
                title="New Airline"
                opened={isOpenModalValue === "New"}

                onClose={() => {
                    setNewAirlines(defaultNewAirlines);
                    setIsOpenModalValue(undefined);
                }}
            >
                <div className="w-full flex flex-col gap-2">
                    <TextInput
                        label="Airline Name"
                        placeholder="Enter Name"
                        value={newAirlines?.name}
                        onChange={(e) => setNewAirlines({ ...newAirlines, name: e.target.value })}
                    />
                    <TextInput
                        label="Airline Code"
                        placeholder="Enter code"
                        value={newAirlines?.code}
                        onChange={(e) => setNewAirlines({ ...newAirlines, code: e.target.value })}
                    />
                    <ESelect
                        label="Flight Ticket Supplier"
                        placeholder="Select"
                        data={supplierList}
                        value={newAirlines?.flightTicketSupplierId ? newAirlines?.flightTicketSupplierId + "" : null}
                        onChange={(e) => setNewAirlines({ ...newAirlines, flightTicketSupplierId: e ? +e : 0 })}
                    />

                    <div className="flex justify-end mt-2">
                        <Button
                            disabled={!(newAirlines?.name)}
                            loading={isBtnLoading}
                            onClick={() => submitNewAirlines()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </EModal>

            {/* Edit Airline */}
            <EModal
                title="Edit Airline"
                opened={isOpenModalValue === "Edit"}

                onClose={() => {
                    setSelectedAirlines(undefined)
                    setIsOpenModalValue(undefined);
                }}
            >
                {selectedAirlines &&
                    <div className="w-full flex flex-col gap-2">
                        <TextInput
                            label="Airline Name"
                            placeholder="Enter Name"
                            value={selectedAirlines?.name}
                            onChange={(e) => setSelectedAirlines({ ...selectedAirlines, name: e.target.value })}
                        />
                        <TextInput
                            label="Airline Code"
                            placeholder="Enter Code"
                            value={selectedAirlines?.code}
                            onChange={(e) => setSelectedAirlines({ ...selectedAirlines, code: e.target.value })}
                        />
                        <Select
                            label="Flight Ticket Supplier"
                            data={supplierList}
                            placeholder="Select"
                            value={selectedAirlines?.supplierId ? selectedAirlines?.supplierId + "" : null}
                            onChange={(e) => {
                                setSelectedAirlines({ ...selectedAirlines, supplierId: e ? +e : 0 })
                            }}
                        />

                        <div className="flex justify-end mt-2">
                            <Button
                                disabled={!(selectedAirlines?.name)}
                                loading={isBtnLoading}
                                onClick={() => selectedAirlines?.id && UpdateAirline(selectedAirlines?.id, selectedAirlines?.name)}
                            >
                                Update
                            </Button>
                        </div>
                    </div>}
            </EModal>

            <EModal
                title="Delete Airline"
                opened={isOpenModalValue === "Delete"}

                size="lg"
                onClose={() => setIsOpenModalValue(undefined)}
            >
                <div className="w-full flex flex-col gap-2">
                    <p>Are you sure you want to delete this Airline?</p>
                </div>
                <div className="w-full flex justify-end mt-5 gap-2">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setIsOpenModalValue(undefined)
                            setSelectedAirlines(undefined)
                        }

                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="filled"
                        color="red"
                        loading={isBtnLoading}
                        onClick={() => selectedAirlines?.id && deleteAirline(selectedAirlines?.id)}
                    >
                        Delete
                    </Button>
                </div>
            </EModal>

        </PageLayout>
    )
}
