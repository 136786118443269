import React, { FC } from "react";

interface Props {
  label: string;
}

const SectionLabel: FC<Props> = ({ label }) => {
  return (
    <h3 className="text-lg text-black font-semibold mt-3 mb-2"> {label}</h3>
  );
};

export default SectionLabel;
