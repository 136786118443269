import { useEffect, useState } from "react";
import moment from "moment";
import useMaster from "hook/useMaster";
import { useForm } from "@mantine/form";
import { useApi } from "libs/data-access/src/api/useApi";
import { CommonValidation, validationFormFields } from "./constants";
import { IFlightMarkupB2BPayload, IHotelMarkupB2BPayload, IServices } from "types";
import { showToast } from "utils/utility";
import { useLocation, useNavigate } from "react-router-dom";

export default function useCreateMarkup_B2B() {

  const navigate = useNavigate();
  const { postData, putData } = useApi();
  const location = useLocation();
  const { state } = location
  const { service, item } = state || {};

  const {
    applyTypes,
    fareTypes,
    hotelPlaces,
    serviceTypes,
    markupTypes,
    searchTypes,
    applyOnTypes,
    applyForTypes,
    supplierList,
    airlinesList,
    allFeeTypes,
    busSeatTypes,
    groupTypes,
    busCategoryTypes,
    hotelCategories,
    allCountryList,
    markupfeeNameIds,
    busDestinations,
    airportByCoutries,
    flightAllClassTypes,
    flightCabinClassTypes,
    travelServiceCategories,
    airportByDestinationCoutries,
    getFareTypes,
    getApplyTypes,
    getSearchTypes,
    getMarkupTypes,
    getApplyOnTypes,
    getApplyForTypes,
    getAllCountryList,
    getSupplierList,
    getServiceTypes,
    getAllFeeTypes,
    getBusSeatTypes,
    getAllGroupTypes,
    getAllAirportByCountries,
    getBusCategoryTypes,
    getBusDestinations,
    getHotelCategories,
    getMarkupFeeNameIds,
    getAirlinesBySuppliers,
    getFlightAllClassTypes,
    getFlightCabinClassTypes,
    getHotelPlacesByCountries,
    getTravelServiceCategories,
    getAllAirportByDestinationCountries,
  } = useMaster();

  const [serviceName, setServiceName] = useState<IServices>(state?.service || "FLIGHT");
  const [loading,setloading] =useState<boolean>(false)

  const markupB2BForm = useForm({
    mode: "uncontrolled",
    initialValues: {} as any,
    validate: { ...CommonValidation, ...validationFormFields[serviceName] },
  });



  useEffect(() => {
    getServiceTypes();
    getAllGroupTypes();
    getFareTypes();
    getMarkupTypes();
    getApplyOnTypes();
    getApplyTypes();
    getSearchTypes();
    getAllCountryList();
    getAllFeeTypes();
    getMarkupFeeNameIds();
    getFlightAllClassTypes();
    getFlightCabinClassTypes();
    handleDefaultValue();
  }, []);

  useEffect(() => {
    getApplyForTypes(serviceName, "MARKUP");
    getTravelServiceCategories(serviceName, "MARKUP");
    getSupplierList(serviceName);
    if (serviceName === "HOTEL") {
      getHotelCategories();
    }
    if (serviceName === "BUS") {
      getBusCategoryTypes();
      getBusSeatTypes();
      getBusDestinations();
    }
  }, [serviceName]);

  useEffect(() => {
    if (!state) {
      markupB2BForm.setInitialValues({});
      markupB2BForm.setValues((prev) => ({
        gst: false,
        service: serviceName,
        userType: "AFFILIATE",
        travelFormFeeRequests: travelServiceCategories,
      }));
    }
  }, [travelServiceCategories]);

  console.log("markupB2BForm", markupB2BForm.getValues());

  // HANDLER FUNCTION

  const handleDefaultValue = () => {
    if (!item) {
      return console.log("CREATE NEW MARKUP B2B");
    }
    else {
      if (serviceName === "FLIGHT") {
        const defaultValue: IFlightMarkupB2BPayload = {
          affiliateGroupId: item?.affiliateGroup.id,
          flightAirlineIds: item?.flightAirlineResponses?.map((data: any) => data.id + ""),
          flightOriginCountryIds: item?.originCountryResponses?.map((data: any) => data.id + ""),
          flightDestinationCountryIds: item?.destinationCountryResponses?.map((data: any) => data.id + ""),
          originAirportIds: item?.originAirportResponses?.map((data: any) => data.id + ""),
          destinationAirportIds: item?.destinationAirportResponses?.map((data: any) => data.id + ""),
          travelFormFeeRequests: item?.travelServiceFormFeeResponses?.map((data: any) => {
            console.log("travelFormFeeRequests", data);
            return ({
              name: data?.name,
              travelFormFeeId: data.id + "",
              fee: data.feeAmount,
              feeType: data.feeType,
            })
          }),
          flightTicketSupplierIds: item?.supplierResponses?.map((data: any) => data.id + ""),
          chargeApplyForTypeId: item?.chargeApplyForType.id,
          chargeApplyOnTypeId: item?.chargeApplyOnType.id,
          bookingTypeId: item?.bookingType.id,
          flightFareTypeId: item?.flightFareType.id,
          flightJourneyTypeId: item?.flightJourneyType.id,
          flightApplyTypeId: item?.flightApplyType.id,
          startDate: item?.startDate,
          endDate: item?.endDate,
          minimumGrossPrice: item?.minimumGrossPrice,
          maximumGrossPrice: item?.maximumGrossPrice,
          flightClass: item?.flightClass,
          flightCabin: item?.flightCabin,
          gst: item?.gst,
          userType: item?.userType,
        };
        markupB2BForm.setValues(defaultValue);
      }
      else {
         const defaultValue : IHotelMarkupB2BPayload ={
           countryIds: item?.countryResponses?.map((e:any)=>e?.id+""),
           placeIds: item?.placeResponses?.map((e:any)=>e?.id+""),
           affiliateGroupId: item?.affiliateGroup?.id,
           travelFormFeeRequests: item?.travelFormFeeResponses?.map((data: any) => {
            console.log("travelFormFeeRequests", data);
            return ({
              name: data?.name,
              travelFormFeeId: data.id + "",
              fee: data.feeAmount,
              feeType: data.feeType,
            })
          }),
           hotelBookingSupplierIds: item?.hotelBookingSupplierResponses?.map((e:any)=>e?.id+""),
           chargeApplyForTypeId: item?.chargeApplyForType?.id,
           bookingTypeId: item?.bookingType?.id,
           startDate: item?.startDate,
           endDate: item?.endDate,
           minimumGrossPrice: item?.minimumGrossPrice,
           maximumGrossPrice: item?.maximumGrossPrice,
           gst: item?.gst,
           userType: item?.userType,
           hotelCategoryIds: item?.hotelCategoryResponses?.map((e:any)=>e?.id+"")
         }
         getHotelPlacesByCountries(item?.countryResponses?.map((e:any)=>e?.id+""))
        markupB2BForm.setValues(defaultValue);
      }
    }
  }
  // API Function

  const createMarkupFromB2B = async (
  ) => {
    setloading(true);
    const fromValue = markupB2BForm.getValues();
    const body: any = {  ...fromValue };
    const saveFormEndPoints = {
      FLIGHT: "flight-markup-form/save",
      HOTEL: "hotel-markup-form/save",
      BUS: "bus-markup-form/save",
    };
    body.startDate = moment(fromValue.startDate).format("YYYY-MM-DD");
    body.endDate = moment(fromValue.endDate).format("YYYY-MM-DD");
    body.travelFormFeeRequests = fromValue.travelFormFeeRequests?.map((data:any) => {
      const { id, feeType, fee } = data;
      console.log("date body", data);
      return {
        fee,
        feeType,
        travelFormFeeId: id,
      };
    });
    const response = await postData(saveFormEndPoints[serviceName], body);
    if (response.isSuccess) {
      showToast({
        type: "success",
        title: "Success",
        message: "Markup Created Successfully",
      });
      navigate(`/group-settings/view-markup-b2b`, { state: location.state });
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: response.message,
      });
    }
    setloading(false);
  };

  const updateMarkupFormB2B = async (
  ) => {
     if(!item.id) {
        showToast({
          type:"error",
          title:"Something went wrong",
          message:"Error facing with from id!"
        })
     }
    setloading(true);
    const fromValue = markupB2BForm.getValues();

    const body: any = { ...fromValue };
    const saveFormEndPoints = {
      FLIGHT: "flight-markup-form/update/",
      HOTEL: "hotel-markup-form/update/",
      BUS: "bus-markup-form/update/",
    };
    body.startDate = moment(fromValue.startDate).format("YYYY-MM-DD");
    body.endDate = moment(fromValue.endDate).format("YYYY-MM-DD");
    body.userType = null
    // body.travelFormFeeRequests = data.travelFormFeeRequests?.map((data) => {
    //   const { travelFormFeeId, feeType, fee } = data;
    //   return {
    //     fee,
    //     feeType,
    //     travelFormFeeId: travelFormFeeId,
    //   };
    // });
    const response = await putData(saveFormEndPoints[serviceName]+item?.id, body);
    if (response.isSuccess) {
      showToast({
        type: "success",
        title: "Success",
        message: "Markup Created Successfully",
      });
      navigate(`/group-settings/view-markup-b2b`, { state: location.state });
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: response.message,
      });
    }
    setloading(false);
  };

  return {
    loading,
    serviceName,
    markupB2BForm,
    hotelPlaces,
    fareTypes,
    markupTypes,
    applyOnTypes,
    supplierList,
    serviceTypes,
    groupTypes,
    markupfeeNameIds,
    airlinesList,
    applyForTypes,
    applyTypes,
    allFeeTypes,
    searchTypes,
    busSeatTypes,
    busCategoryTypes,
    airportByCoutries,
    allCountryList,
    hotelCategories,
    flightAllClassTypes,
    flightCabinClassTypes,
    busDestinations,
    travelServiceCategories,
    airportByDestinationCoutries,
    setServiceName,
    getAirlinesBySuppliers,
    createMarkupFromB2B,
    updateMarkupFormB2B,
    getAllAirportByCountries,
    getHotelPlacesByCountries,
    getAllAirportByDestinationCountries,
  };
}
