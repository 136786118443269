import { useContext, useEffect } from 'react';

import './EAppShell.scss';

import { AppContext } from '../../../../AppContext';
import { useLocation } from 'react-router-dom';

type Props = {
  navbar: JSX.Element;
  topbar?: JSX.Element;
  children?: JSX.Element;
  footer?: JSX.Element;
};

export function EAppShell({ navbar, topbar, children, footer }: Props) {

  const location = useLocation()

  const {
    isSidebarCollapsed,
    targetRef,
    scrollTrigger
  } = useContext(AppContext);

  useEffect(() => {
    targetRef?.current?.scrollTo(0,0);
  }, [location.pathname,scrollTrigger]);
  

  return (
    <div className="app-shell ">
      {/* LEFT NAV */}
      <div className={`navbar ${isSidebarCollapsed ? "collapsed" : ""}`}>{navbar}</div>
      {/* MAIN RIGHT PANEL */}
      <div className={'main ' + (footer ? ' has-footer' : '')}>
        {/* TOPBAR */}
        {topbar ? <div className="topbar border--bottom">{topbar}</div> : <></>}
        {/* APP BODY */}
        <div ref={targetRef} className="body">{children}</div>
        {footer ? <div className="footer border-top">{footer}</div> : <></>}
      </div>
    </div>
  );
}

// export default EAppShell;
