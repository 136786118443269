import { PAGINATION_ITEM_LIMIT } from "libs/data-access/constant";
import { useApi } from "libs/data-access/src/api/useApi";
import { useEffect, useState } from "react";
import { TPaginationStatus } from "types";
import { showToast } from "utils/utility";

type IChargeType={
  id?: number;
  name: string;
  status: boolean;
}

const useManageTypes = () => {
  const { postData, patchData, putData } = useApi();
  const [loading, setLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isLoadingIndex, setIsLoadingIndex] = useState<number | undefined>(undefined);
  const [isOpenAddNewModal, setIsOpenAddNewModal] = useState(false);
  const [chargeTypeName, setChargeTypeName] = useState<string>("");
  const [selectedType, setSelectedType] = useState<IChargeType | undefined>(undefined);
  const [chargeTypeList, setChargeTypeList] = useState<IChargeType[]>([]);

  useEffect(() => {
    getAllChargeTypeList("INITIAL_PAGE");
  }, []);

  const getAllChargeTypeList = async (paginationAction: TPaginationStatus) => {
     setLoading(true);
    const body = {
      paginationFilterRequest: {
        paginationAction: paginationAction,
        maxLimit: PAGINATION_ITEM_LIMIT,
        id: null,
        sortingOrder: "ASC",
      },
    };
    const response = await postData(`additional-charge-type/get-all`, body);
    console.log("response of getting all charge type list", response);
    if (response.isSuccess) {
      setChargeTypeList(response.data.data);
    }
    setLoading(false);
  };

  const createChargeType = async () => {
    setIsBtnLoading(true);
    const body = {
      name: chargeTypeName,
    };
    const response = await postData(`additional-charge-type/save`, body);
    if (response.isSuccess) {
      showToast({
        type: "success",
        message: "Charge Type created successfully",
      });
      getAllChargeTypeList("INITIAL_PAGE");
    }
    setIsBtnLoading(false);
    setIsOpenAddNewModal(false);
    setChargeTypeName("");
  };

  const updateChargeType = async () => {
    setIsBtnLoading(true);
    const response = await putData(`additional-charge-type/update/${selectedType?.id}`, {"name":selectedType?.name});
    if (response.isSuccess) {
      showToast({
        type: "success",
        message: "Charge Type updated successfully",
      });
      getAllChargeTypeList("INITIAL_PAGE");
      setSelectedType(undefined);
    }
    setIsBtnLoading(false);
    setIsOpenAddNewModal(false);
    setChargeTypeName("");
  };

  const activeInActiveChargeType = async (
    status: boolean,
    chargeTypeId: string
  ) => {
    const endpoint = status
      ? "additional-charge-type/active-all"
      : "additional-charge-type/inActive-all";
    const response = await patchData(`${endpoint}?ids=${chargeTypeId}`, {});
    if (response.isSuccess) {
      getAllChargeTypeList("INITIAL_PAGE");
    }
    console.log("response of activeInactive charge type", response);
    setIsLoadingIndex(undefined);
  };

  return {
    loading,
    isBtnLoading,
    isLoadingIndex,
    setIsLoadingIndex,
    selectedType,
    isOpenAddNewModal,
    chargeTypeName,
    setLoading,
    chargeTypeList,
    createChargeType,
    setChargeTypeName,
    setSelectedType,
    setIsOpenAddNewModal,
    activeInActiveChargeType,
    updateChargeType
  };
};

export default useManageTypes;
