import React from 'react';
import { Modal, ModalProps } from '@mantine/core';

export function EModal({ ...props }: ModalProps) {
    return (
        <Modal
            radius={"md"}
            {...props}
            styles={{ header: { borderBottom: "solid 1px #dee2e6", boxShadow:"inset 8px 3px -7px #dee2e6", backgroundColor: "#f8f9fa" } }}
            className='relative'
        > 
        <div className='mt-6'>
            {props.children}
        </div>
        </Modal>
    )
}